import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ISelectorParams } from './ISelectorParams';
import { ISelectorValue } from './ISelectorValue';

@Component({ template: "" })
export abstract class SelectorWrapperComponentBase<T, K extends ISelectorParams<any> = ISelectorParams<T>> extends ComponentBase implements OnInit {

	//#region PROPERTIES

	@Input() public params: K;
	public values: ISelectorValue<T>[];

	//#endregion

	//#region METHODS

	constructor() { super(); }

	public ngOnInit(): void {
		if (!this.params)
			this.params = {} as K;

		this.initSearchOptions();
		this.initValues();
	}

	protected abstract initSearchOptions(): void;

	protected abstract initValues(): void;

	/** Fonction à appeler lorsque la sélection est validée.
	 * @param paSelectedValues Valeurs sélectionnées.
	 */
	public abstract onSelectionValidated(paSelectedValues: ISelectorValue<T>[]): void;

	/** Permet de configurer le texte à afficher pour chaque valeur.
	 * @param poValue 
	 */
	protected abstract getDisplayValue(poValue: T): string;

	protected getValuesFromSelectorValues(paSelectorValues: ISelectorValue<T>[]): T[] {
		return paSelectorValues.map((poSelectedValue: ISelectorValue<T>) => poSelectedValue.value);
	}

	//#endregion
}