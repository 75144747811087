import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IFormListParams } from '../../../../model/forms/IFormListParams';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un formulaire de liste. */
@Component({
	templateUrl: './formListField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListFieldComponent extends FieldBase { //todo : typer + params.

	//#region PROPERTIES

	public params: IFormListParams<any>;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	//#endregion
}