import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { IContact } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { Evenement } from "apps/idl/src/anakin/models/Evenement";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { MenuModifierEvenementComponent } from "../menu-modifier-evenement/menu-modifier-evenement.component";

@Component({
	selector: "di-carte-evenement",
	templateUrl: "./carte-evenement.component.html",
	styleUrls: ["./carte-evenement.component.scss"],
})
export class CarteEvenementComponent extends DestroyableComponentBase implements OnInit {
	@Input() evenement: Evenement;
	public texte: string;
	public infirmier: IContact;
	public moment: string;

	constructor(private el: ElementRef,	private svcDrawerPopover: DrawerPopoverService, ) {
		super();
	}
	ngOnInit(): void {
		this.initInfirmier();
		if(this.evenement.moment.type == "hours-minutes") {
			let hours=this.evenement.moment.hours;
			let minutes=this.evenement.moment.minutes;  
			this.moment=`${hours}:${minutes}`
		}

		this.texte = this.evenement.description;
	}

	private initInfirmier() {
		this.infirmier = this.evenement.infirmier
		this.el.nativeElement.style.setProperty(
			"--event-color",
			`var(--${this.getAvatarColor(this.infirmier)})`
		);
	}
	getAvatarColor(utilisateur: IContact) {
		return utilisateur.avatarCouleur ?? 'CouleurPrimaire';
	  } 
		
	public openMenu(event:Event) {
		event.stopPropagation();
		this.svcDrawerPopover.open(
			"Evenement",
			"60%",
			event.currentTarget,
			MenuModifierEvenementComponent,
			{
				event:this.evenement,
				onCancel: () => this.closeMenu(),
			}
		);
	}

	public closeMenu(): void {
		this.svcDrawerPopover.close();
	}
	  
}
