import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGroup, UserData } from '@osapp/model';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { GroupsService } from '@osapp/services';
import { EIdlLogActionId } from 'apps/idl/src/modules/logger/models/EIdlLogActionId';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { IUpdateHistory } from 'apps/idl/src/modules/patients/model/iupdate-history';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { of } from 'rxjs';
import { defaultIfEmpty, mergeMap, tap } from 'rxjs/operators';

@Component({
	templateUrl: './creation-patient.page.html',
	styleUrls: ['./creation-patient.page.scss'],
})
export class CreationPatientPage implements OnInit {
	
	public readonly logSourceId = "IDL.PAT.C::";
	public model: IPatient;
	public idPatient: string;
	public patientOrdonnance = false;

	constructor(
		private svcPatients: PatientsService,
		private router: Router,
		private svcLogger: LoggerService,
		private svcGroup: GroupsService,
		private route: ActivatedRoute
	) { }
	ngOnInit(): void {
		this.patientOrdonnance = history.state.patientOrdonnance;
		this.idPatient = this.route.snapshot.paramMap.get('idPatient');
		if(this.idPatient){
			this.svcPatients.getPatient(this.idPatient).subscribe((patient: IPatient) => {
				this.model = patient;
			})
		}
	}

	public createPatient = (patient: IPatient) => {

		this.model = patient;
		this.svcLogger.action(this.logSourceId, "Création du patient.", EIdlLogActionId.patientCreate as unknown as ELogActionId, { userId: UserData.current?._id, patientId: this.model._id });
		
		return of(this.model)
		.pipe(
			tap(() => {
				const loUpdateHistory: IUpdateHistory = {
					date: this.model.updateDate = new Date(),
					mode: this.model.updateMode = EUpdateMode.manual
				};
				this.model.updateHistory = [loUpdateHistory];
			}),
			mergeMap(() => this.svcPatients.savePatientAnakin(this.model)),
			tap(_ => this.svcPatients.saveAdressCacheData(this.model)),
			// Création des lnk liés au nouveau patient
			mergeMap(() => this.svcGroup.getGroup('grp_sectordefault')),
			mergeMap((group: IGroup) => this.svcGroup.updateGroup(group, [], [this.model])),
			defaultIfEmpty(true),
			
		).subscribe((success: boolean) => {
			if (success) {
				this.patientOrdonnance? 
				this.router.navigate(['ordonnances', 'add'],{ state: { patientId: this.model._id }})
				: this.router.navigate(["/patients", this.model._id], { state: { tabSelected: "Informations" } });
			}
		});
	}
	

	public editPatient = (patient: IPatient) => {

		this.model = patient;
		this.svcLogger.action(this.logSourceId, "Édition du patient.", EIdlLogActionId.patientUpdate as unknown as ELogActionId, { userId: UserData.current?._id, patientId: this.model._id });
		
		return of(this.model)
		.pipe(
			tap(() => {
				const loUpdateHistory: IUpdateHistory = {
					date: this.model.updateDate = new Date(),
					mode: this.model.updateMode = EUpdateMode.manual
				};
				this.model.updateHistory = [...this.model.updateHistory, loUpdateHistory];
			}),
			mergeMap(() => this.svcPatients.savePatientAnakin(this.model)),
			tap(_ => this.svcPatients.saveAdressCacheData(this.model)),
			defaultIfEmpty(true),
			
		).subscribe((success: boolean) => {
			if (success) {
				this.router.navigate(["patients", this.model._id], { state: { tabSelected: "Informations" } });
			}
		});
	}

}
