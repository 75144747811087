import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Composant qui peut être wrappé dans un SlidingListItemComponent pour afficher les options cachés derrière l'item. */
@Component({
	selector: "calao-sliding-list-item-options",
	templateUrl: './sliding-list-item-options.component.html',
	styleUrls: ['./sliding-list-item-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidingListItemOptionsComponent {

	public constructor() { }

}