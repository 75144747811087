import { IContact } from '../model/contacts/IContact';
import { StringHelper } from './stringHelper';
import { UserHelper } from './user.helper';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des choses en lien avec les conversations. */
export abstract class ConversationHelper {

	//#region METHODS

	/** Indique si un contact est éligible à devenir un particpant d'une conversation ou non.
	 * @param poContact Contact dont il faut vérifier qu'il est valide.
	 * @returns `true` si le contact est un contact utilisateur valide (champ `userId` présent) ou s'il possède un mail valide ; `false` sinon.
	 */
	public static isParticipantEligible<T extends IContact>(poContact: T): boolean {
		return UserHelper.isUser(poContact) || !StringHelper.isBlank(poContact.email);
	}

	//#endregion

}