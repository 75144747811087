import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IFrameComponent } from './components/iframe/iframe.component';

@NgModule({
	imports: [CommonModule, IonicModule],
	exports: [IFrameComponent],
	declarations: [IFrameComponent],
	providers: []
})
export class IFrameModule { }