<div class="ligne-contact__container" (click)="onClickLine(contact._id)" [ngClass]="{'selectionnee': selected}">
  <div class="ligne_contact__infos">
    <div class="ligne-contact__texte">
      <div class="ligne-contact__nom-contact">
        <p [title]="withTooltip ? contactName : ''">{{contactName}}</p>
      </div>
      <small [title]="withTooltip ? contact.comment : ''">{{contact.comment}}</small>
    </div>
    
    <lua-tag class="ligne-contact__tag" *ngIf="contact.isDoctor" label="Médecin"></lua-tag>
  </div>
  <div class="ligne-contact__icones">
    <lua-bouton-icone (onClick)="sendMailContact()" class="ligne-contact__mail-icone" iconName="mail" mode="outlined" [isDisabled]="!contact.email"></lua-bouton-icone>
    <lua-bouton-icone (onClick)="makeCallContact()"  class="ligne-contact__phone-icone" iconName="call" mode="outlined" [isDisabled]="!contact.phone"></lua-bouton-icone>
  </div>
</div>
