import { IStoreTaskParams } from '../../model/backgroundTask/taskParams/IStoreTaskParams';
import { FlagService } from '../flag.service';
import { InjectorService } from '../injector.service';
import { Store } from '../store.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export abstract class StoreTask<T extends IStoreTaskParams> extends TaskBase<T> {

	//#region FIELDS

	protected msvcStore: Store;
	protected msvcFlag: FlagService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcStore = InjectorService.instance.get<Store>(Store);
		this.msvcFlag = InjectorService.instance.get(FlagService);
	}

	//#endregion
}