import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { ETimetablePattern } from "@osapp/model";
import { EDuree } from "@osapp/modules/event-markers/models/eduree";
import { ERepetition } from "@osapp/modules/event-markers/models/erepetition";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { Acte } from "apps/idl/src/model/Acte";
import { IActe } from "../../../../../model/IActe";
import { EEtatActe } from "../../enums/EEtatActe";
import { PopoverService } from "../../services/popover.service";
import { InformationAbattementComponent } from "../information-abattement/information-abattement.component";

@Component({
	selector: "di-acte",
	templateUrl: "./acte.component.html",
	styleUrls: ["./acte.component.scss"],
})
export class ActeComponent implements OnInit, AfterViewInit {
	@Input() acte: IActe | Acte;
	@Input() ellipsis = false;
	@Input() favoriteIcon = false;
	@Input() clickable = false;
	@Input() priseEnCharge = false;
	@Input() noCheckForce = false;
	@Input() status?: EEtatActe;
	@Input() clickablePrice: boolean = false;
	@Input() index?: number;
	@Output() onCheckboxChange = new EventEmitter<boolean>();
	@Output() onActeSelected = new EventEmitter<IActe>();
	@ViewChild("descriptionElement") descriptionElement!: ElementRef;

	public infoSecondaire = "";
	public isMultiLine = false;
	public classes: any;

	constructor(
		private svcPopover: PopoverService
	){}

	ngOnInit() {
		if (this.priseEnCharge) {
			this.getInfoSecondaire();
		}
		this.updateClasses();
	}

	ngAfterViewInit() {
		const element = this.descriptionElement.nativeElement;
		this.isMultiLine = element.scrollHeight > 50;
	}

	private updateClasses() {
		this.status = this.acte.etat || EEtatActe.to_be_done;
		this.classes = {
			"multi-line": this.isMultiLine,
			"single-line": !this.isMultiLine,
			ellipsis: this.ellipsis,
			done: this.status === EEtatActe.done,
			"not-done": this.status === EEtatActe.not_done,
			"to-be-done": this.status === EEtatActe.to_be_done,
			clickable: this.clickable,
			wthCheck: this.status || this.favoriteIcon || !this.noCheckForce
		};
	}

	getClasses() {
		return this.classes;
	}

	onSelectActe(acte: IActe) {
		this.onActeSelected.emit(acte);
	}

	handleCheckboxChange(checked: boolean) {
		this.onCheckboxChange.emit(checked);
	}

	getInfoSecondaire() {
		let recurrence: Recurrence = this.acte.recurrences[0] as Recurrence
		if (!recurrence) {
			this.infoSecondaire = "";
			return;
		}
		
		let moments: string[] = recurrence.getMoments()
		const horaires = recurrence.dayRepetitions.filter((repetition) => repetition.type === "hours-minutes");
		if (horaires.length > 0) {
			const firstHoraire: IDayRepetition = ArrayHelper.getFirstElement(horaires);
			const hours = firstHoraire.hours.toString().padStart(2, '0');
			const minutes = firstHoraire.minutes.toString().padStart(2, '0');
			moments.push(`${hours}:${minutes}`);
		}

		let repetitionJours: string[] = [];
		if (recurrence.repetitionType === ERepetition.jours) {
			const joursSemaine = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
			repetitionJours = Array.isArray(recurrence.repetitionValue) 
			? recurrence.repetitionValue.map(jrs => joursSemaine[jrs])
			: [];
		}else{
			if (recurrence.repetitionType === ERepetition.tsLesXJours && typeof recurrence.repetitionValue === 'number') {
				repetitionJours.push(`tous les ${recurrence.repetitionValue} jours`)
			}
		}
		
		let repeterString: string;
		try{
			if (recurrence.durationType === EDuree.jusquADate && Array.isArray(recurrence.durationValue) && DateHelper.isDate(recurrence.durationValue[0])) {
				repeterString = `jusqu'au ${DateHelper.transform(recurrence.durationValue[0], ETimetablePattern.dd_MM_yyyy_slash)}`
			} else {
				if (recurrence.durationType === EDuree.dates && Array.isArray(recurrence.durationValue)) {
					let dates = recurrence.durationValue as Date[];
					repeterString = 'Occurrences : ';
					for (let date of dates) {
						repeterString += ' ' + DateHelper.transform(date, ETimetablePattern.dd_MM_yyyy_slash) + ','
					}
					repeterString = repeterString.slice(0, -1);
				} else {
					repeterString = `pendant ${recurrence.durationValue} ${recurrence.durationType}`
				}
			}
		} catch (error) {
			repeterString = '';
		}
		
		const momentsString = moments.join(", ");
		const repetitionSemaineString: string = `toutes les ${this.acte.weekRepetition.value || ''} semaines`;
		const repetitionJoursString = repetitionJours.join(", ");

		// Concaténation du tout avec " - " entre chaque section
		this.infoSecondaire = [momentsString, repetitionJoursString, repetitionSemaineString, repeterString]
			.filter(Boolean) // Evite les segments vides
			.join(" - ");
	}

	public showInfoAbattement(event: Event) {
		this.svcPopover.open(
			"",
			event.currentTarget,
			InformationAbattementComponent,
			{
				acte: this.acte,
				index: this.index,
				close: () => {
					this.svcPopover.close();
				}
			}
		)
	}
}
