<div class="di-affectation-soins">
	<div class="infirmier" *ngFor="let infirmier of infirmiers; let i = index">
		<lua-objet-utilisateur [firstName]="infirmier.firstName" [lastName]="infirmier.lastName.toUpperCase()"
			[avatarColor]="getAvatarColor(infirmier)"></lua-objet-utilisateur>
		<lua-radio-button (click)="selectInfirmier(infirmier._id)"></lua-radio-button>
	</div>
	<div class="actions">
		<lua-action-secondaire libelle="Affecter les soins" color="CouleurPrimaire" [isDisabled]="!selectedInfirmierId"
			[fullWidth]="true" (click)="onConfirm()"></lua-action-secondaire>
		<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true"
			(click)="onCancel()"></lua-bouton-texte>
	</div>
</div>