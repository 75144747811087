import { EPrefix } from '@osapp/model';

//#region PRÉFIXES

/** Préfixe d'id d'un patient : "pat_". */
export const C_PREFIX_PATIENT: EPrefix = "pat_" as EPrefix;
/** Préfixe d'id d'un acte : "act_". */
export const C_PREFIX_ACT: EPrefix = "act_" as EPrefix;
/** Préfixe d'id d'un chapitre : "chapter_". */
export const C_PREFIX_CHAPTER: EPrefix = "chapter_" as EPrefix;
/** Préfixe d'id d'un tarif : "lc_". */
export const C_PREFIX_LC: EPrefix = "lc_" as EPrefix;
/** Préfixe d'id d'un traitement : "trait_". */
export const C_PREFIX_TRAITEMENT: EPrefix = "trait_" as EPrefix;
/** Préfixe d'id d'une demande de préparation de médicaments : "pharma_". */
export const C_PREFIX_PHARMACIE: EPrefix = "pharma_" as EPrefix;
/** Préfixe d'id d'une séance. */
export const C_PREFIX_SEANCE: EPrefix = "seance_" as EPrefix;
/** Préfixe d'id d'un rapport : "report_". */
export const C_PREFIX_RAPPORT: EPrefix = "report_" as EPrefix;
/** Préfixe d'id d'une tournée : "tour_". */
export const C_PREFIX_TOURNEE: EPrefix = "tour_" as EPrefix;
/** Préfixe d'identifiant d'un objet constantes : "const_". */
export const C_CONSTANTES_PREFIX: EPrefix = "const_" as EPrefix;
/** Préfixe d'identifiant d'un objet injections : "inject_". */
export const C_INJECTIONS_PREFIX: EPrefix = "inject_" as EPrefix;
/** Préfixe d'identifiant d'un planning RH : "plan_". */
export const C_PREFIX_PLANNING_RH: EPrefix = "plan_" as EPrefix;
/** Préfixe d'identifiant de règle de cotation : "cotRule_". */
export const C_PREFIX_COTATION_RULE: EPrefix = "cotRule_" as EPrefix;
/** Préfixe d'identifiant de documents d'ordonnance : "ordo_". */
export const C_PREFIX_ORDONNANCE: EPrefix = "ordo_" as EPrefix;
/** Préfixe d'identifiant d'un relevé d'intervention : "inter_". */
export const C_PREFIX_INTER_STATE: EPrefix = "inter_" as EPrefix;
/** Préfixe d'identifiant de facture : "invoice_". */
export const C_PREFIX_INVOICE: EPrefix = "invoice_" as EPrefix;
/** Préfixe d'identifiant d'un paiement : "pay_". */
export const C_PREFIX_PAYEMENT: EPrefix = "pay_" as EPrefix;
/** Préfixe d'un terminal. */
export const C_PREFIX_TERMINAL: EPrefix = "term_" as EPrefix;
/** Préfixe d'identifiant de l'assurance maladie obligatoire patient : "amop_". */
export const C_PREFIX_AMOP: EPrefix = "amop_" as EPrefix;
/** Préfixe d'identifiant de l'assurance complémentaire obligatoire patient : "amcp_". */
export const C_PREFIX_AMCP: EPrefix = "amcp_" as EPrefix;
/** Préfixe d'identifiant de l'assurance maladie obligatoire : "amo_". */
export const C_PREFIX_AMO: EPrefix = "amo_" as EPrefix;
/** Préfixe d'identifiant de l'assurance complémentaire obligatoire : "amc_". */
export const C_PREFIX_AMC: EPrefix = "amc_" as EPrefix;
/** Préfixe d'identifiant d'un rejet signalement paiement' : "rsp_". */
export const C_PREFIX_RSP: EPrefix = "rsp_" as EPrefix;
/** Préfixe d'identifiant d'une signature' : "signature_". */
export const C_PREFIX_SIGNATURE: EPrefix = "signature_" as EPrefix;
/** Préfixe d'identifiant de l'historique des pages visitées par le user : "history_". */
export const C_PREFIX_HISTORIQUE: EPrefix = "history_" as EPrefix;

//#endregion

/*#region DATABASE ROLE */
/*#endregion */
//#region RÔLES
export const C_DESMOS_ROLE_ID = "desmos";
export const C_AGREMENT_ROLE_ID = "agrement";
export const C_ACCES_RETROCESSION_ROLE_ID = "AccesRetrocession";
//#endregion
export const C_PATIENT_DESMOS_SYSTEM_ID = "desmos";
export const NB_ITEM_TO_DISPLAY = 20;