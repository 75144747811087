import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/* Exemple: {{ variableBindée | boolean }}
	 * => adapte les booléens.
	 * @param poValue valeur à transformer/vérifier (correspond à 'variableBindée').
	 */
	public transform(poValue: any): string {
		if (typeof poValue === "boolean") {
			if (poValue)
				return "oui";
			else
				return "non";
		}
		else {
			console.error("BOOL.P::Le type de la valeur passée est incorrecte.", poValue);
			return poValue;
		}
	}

	//#endregion

}
