import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { FormBuilder, FormGroup } from "@angular/forms";
import { ArrayHelper, DateHelper, NumberHelper } from "@osapp/helpers";
import { UserData } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { takeUntil, tap } from "rxjs/operators";
import { Acte } from "../../../../../model/Acte";
import { EMajorationType } from "../../../../../model/EMajorationType";
import { EPathologie } from "../../../../../model/EPathologies";
import { EPlace } from "../../../../../model/EPlace";
import { ESecteur } from "../../../../../model/ESecteur";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IActeDocumentByLc } from "../../../../../model/IActeDocumentByLc";
import { IDeplacementByProfession } from "../../../../../model/IDeplacementByProfession";
import { Majoration } from "../../../../../model/Majoration";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { Deplacement } from "../../../../../modules/traitement/model/Deplacement";
import { EIndemniteType } from "../../../../../modules/traitement/model/EIndemniteType";
import { Indemnite } from "../../../../../modules/traitement/model/Indemnite";
import { IdlApplicationService } from "../../../../../services/idlApplicationService.service";
import { IndemniteService } from "../../../../../services/indemnite.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatActe } from "../../enums/EEtatActe";
import { DeviceService } from "../../services/device.service";
import { SeancesGeneratorService } from "../../services/seances-generator.service";

@Component({
	selector: "di-soins-realises",
	templateUrl: "./soins-realises.component.html",
	styleUrls: ["./soins-realises.component.scss"],
})
export class SoinsRealisesComponent
	extends DestroyableComponentBase
	implements OnInit, OnDestroy {
	@Input() public seance?: StoredSeance;
	@Input() public patient?: IPatient;
	@Input() index?: number;
	@Input() confirmSoins: (seance: StoredSeance) => void;
	@Input() deleteConfirmSoins: (seance: StoredSeance) => void;
	@Input() cancel: () => void;
	public horairePrecise: string = "";
	public total: number;
	showAllMajorations = true;
	checkedIndexes: number[] = [];
	public isMobile: boolean = false;
	public selectedActes: Acte[] = [];
	public selectedMajos: Majoration[] = [];
	public selectedDeplaces: Indemnite[] = [];
	public showAllDeplacement = true;
	public showIkConfig = false;
	public ikForm: FormGroup;
	public abattement: number = 0;
	public deplacement: Deplacement;
	public selectedOption: string = 'IK';
	public majoList: Majoration[] = [];
	public indemList: Indemnite[] = [];
	public IKSelected: Indemnite;
	public deplacementByLC: IDeplacementByProfession[];
	public isIK: boolean = false;
	public seanceARealiser: StoredSeance;
	public eligibleMIE : boolean = false;
	public sundayOrHoliday: boolean = false;
	public eligibleN1: boolean = false;
	public eligibleN2: boolean = false;
	public eligibleMAU: boolean = false;
	public eligibleIFI: boolean = false;
	public eligibleMCI: boolean = false;


	constructor(
		private svcDevice: DeviceService,
		private svcGenerateSeance: SeancesGeneratorService,
		private svcIndemnite: IndemniteService,
		private fb: FormBuilder,
		private svcApplication: IdlApplicationService,
		private svcPatient :PatientsService
	) {
		super();
	}

	ngOnInit() {
		let ikIndemnite: Indemnite;

		this.seanceARealiser = Object.assign({}, this.seance);
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)
		).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		this.ikForm = this.fb.group({
			distance: [0 ?? 0],
			montant: [0 ?? 0],
			heure: [this.horairePrecise],
		});
		const dateSeance: Date = new Date(this.seance.startDate);
		const hours = dateSeance.getHours().toString().padStart(2, '0');
		const minutes = dateSeance.getMinutes().toString().padStart(2, '0');
		this.horairePrecise = `${hours}:${minutes}`;
		this.deplacement = new Deplacement();
		this.deplacement.sectorType = ESecteur.Plaine;
		this.deplacement.isManualPrice = false
		this.IKSelected = new Indemnite(
			"IK-1-1",
			this.svcIndemnite.getIndemniteTypeFromId("IK-1-1"),
			'',
			'',);
		if (this.patient.lastIkUpdate && this.seance.mePlace == EPlace.home) {
			ikIndemnite = this.initIK(this.patient.lastIkUpdate);
			this.selectedDeplaces.push(ikIndemnite);
		}
		if (this.seance.status == EStatusSeance.done) {
			this.seanceARealiser.actes = this.seanceARealiser.actes.map((acte: Acte) => {
				const clonedActe = new Acte(acte);
				if (clonedActe.etat == EEtatActe.not_done)
					clonedActe.etat = EEtatActe.to_be_done;
				return clonedActe;
			});
			this.selectedActes = this.seance.actes.filter((acte: Acte) => acte.etat === EEtatActe.done).map((acte: Acte) => new Acte(acte));
			this.selectedMajos = [...this.seance.majorations];
			this.selectedDeplaces = this.seance.indemnites.map((indemnite: Indemnite) => Indemnite.getInstanceFromData(indemnite));
			let ikIndemniteFind = this.seance.indemnites.find(deplace =>
				deplace.type === EIndemniteType.IK ||
				deplace.type === EIndemniteType.IKM ||
				deplace.type === EIndemniteType.IKS
			);
			if(ikIndemniteFind && ikIndemniteFind.distance){
				ikIndemnite = this.initIK(ikIndemniteFind);
			}else
			{
				this.selectedDeplaces.push(ikIndemnite);
			}
		}
		else {
			this.seanceARealiser.actes = this.seanceARealiser.actes.map((acte: Acte) => {
				const clonedActe = new Acte(acte);
				clonedActe.etat = EEtatActe.done;
				return clonedActe;
			});
			this.selectedActes = this.seanceARealiser.actes;
		}
		this.eligibleIFI = this.isIFIEligible();
		this.initializeIndemniteList();
		this.initializeMajorationList();
		this.calculateTotal();
	}

	private initIK(ikIndemnite: Indemnite) {
		if (ikIndemnite) {
			ikIndemnite = Indemnite.getInstanceFromData(ikIndemnite);
			this.isIK = true;
			this.IKSelected = ikIndemnite;
			this.showIkConfig = true;
			this.ikForm.get('distance')?.setValue(this.IKSelected.distance);
			this.selectedOption = this.IKSelected.type;
			this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
			this.deplacement.sectorType = this.svcIndemnite.getSecteur(this.IKSelected.type);
			this.deplacement.distance = this.IKSelected.distance;
			this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		}
		return ikIndemnite;
	}

	public isSelectedInListIndem(indem: Indemnite) {
		return this.selectedDeplaces.find(deplacement => deplacement.type == indem.type)
	}

	public isSelectedInListMajo(majo: Majoration) {
		return this.selectedMajos.find(majoration => majoration.type == majo.type)
	}

	// Appelé au clic sur un type d'IK
	selectOption(option: string) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedDeplaces = this.selectedDeplaces.filter((indem: Indemnite) => !indem.isIKType);
		this.IKSelected.type = this.svcIndemnite.getType(option);
		this.selectedOption = option;
		this.deplacement.sectorType = this.svcIndemnite.getSecteur(option);
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.IKSelected.id = "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType;
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedDeplaces.push(this.IKSelected);
		this.calculateTotal();
	}

	// Appelé lorsqu'on saisi une distance réelle pour l'IK
	public onInputChange(distance: number) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedDeplaces = this.selectedDeplaces.filter(indem => !indem.isIKType);
		this.deplacement.distance = distance;
		this.IKSelected.distance = distance;
		this.IKSelected.label = `(${distance}km)`;
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedDeplaces.push(this.IKSelected)
		this.calculateTotal();

	}

	private initializeMajorationList(): void {
    this.svcGenerateSeance.getMajorations().pipe(
			takeUntil(this.destroyed$),
			tap((majorations: IActeDocumentByLc[]) => {
					this.majoList = majorations.map((majo) => {
							const type = this.svcGenerateSeance.getMajorationTypeFromId(majo.id);
							return new Majoration(
									majo.id,
									type,
									this.svcGenerateSeance.getMajorationPriceFromArray([majo], type),
									this.svcGenerateSeance.getMajorationDescriptionFromArray([majo], type)
							);
					});
			}),
			tap(_ => 	this.initListMajo())
    ).subscribe();
	}

	private initializeIndemniteList () : void {
		this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession).pipe(
			takeUntil(this.destroyed$),
			tap((deplacements: IDeplacementByProfession[]) => {
				this.deplacementByLC = deplacements;
				deplacements.map((deplacement: IDeplacementByProfession) => {
					if (!deplacement.lettreCle.includes(EIndemniteType.IK)) {
						const indemnite = new Indemnite(
							deplacement.id,
							this.svcIndemnite.getIndemniteTypeFromId(deplacement.id),
							deplacement.description,
							'',
							this.svcIndemnite.getDeplacementTarif(deplacement, this.seance.startDate)
						);
						this.indemList.push(indemnite)
					}
				})
			})
		).subscribe()
	}


	private initListMajo(): void {
		this.eligibleIFI = this.isIFIEligible();
		this.eligibleMIE = this.eligibleMajorationMie();
		this.eligibleMCI = this.eligibleMajorationMCI();
		this.eligibleMAU = this.eligibleMajorationMAU();
		this.sundayOrHoliday = this.svcGenerateSeance.isSundayOrPublicHoliday(new Date(this.seance.startDate));
		this.eligibleN1 = this.eligibleMajorationN1();
		this.eligibleN2 = this.eligibleMajorationN2();
		if (this.seance.status === EStatusSeance.to_be_done) {
			this.selectedMajos = [];
		}

		this.majoList.forEach((majoration) => {
			majoration.disabled = this.isMajorationDisabled(majoration.type);
			if (this.seance.status === EStatusSeance.to_be_done &&
					(
							(majoration.type === EMajorationType.Mie && this.eligibleMIE) || 
							(majoration.type === EMajorationType.SundayAndHolyday && this.sundayOrHoliday) || 
							(majoration.type === EMajorationType.Mci && this.eligibleMCI) || 
							(majoration.type === EMajorationType.NightFirstHalf && this.eligibleN1 && !this.sundayOrHoliday) || 
							(majoration.type === EMajorationType.NightSecondHalf && this.eligibleN2 && !this.sundayOrHoliday) || 
							(majoration.type === EMajorationType.Mau && this.eligibleMAU && !this.eligibleIFI && !this.eligibleMCI)
					)
			) {
					this.selectedMajos.push(majoration);
			} else if (majoration.disabled) {
					// Retirer la coche si la majoration est désactivée
					this.selectedMajos = this.selectedMajos.filter(a => a.type !== majoration.type);
			}
		});
		this.initListIndem();
	}

	public onHoraireChange(event :Event) {
		this.initListMajo()  
	}

	private isMajorationDisabled(type: EMajorationType): boolean {
    switch (type) {
        case EMajorationType.Mie:
            return !this.eligibleMIE;
        case EMajorationType.SundayAndHolyday:
            return !this.sundayOrHoliday;
				case EMajorationType.NightFirstHalf:
						return !this.eligibleN1;
				case EMajorationType.NightSecondHalf:
					return !this.eligibleN2;
				case EMajorationType.Mau:
					return !this.eligibleMAU;
				case EMajorationType.Mci:
					return !this.eligibleMCI;
        default:
            return false;
    }
	}

	private isIndemiteDisabled(type: EIndemniteType): boolean {
    switch (type) {
        case EIndemniteType.IFI:
            return !this.eligibleIFI;
				case EIndemniteType.IFD:
					return this.selectedActes.some(acte => acte.keyLetters === "IFI")
        default:
            return false;
    }
	}

	private initListIndem(): void {
		if (this.seance.status === EStatusSeance.to_be_done) {
			this.selectedDeplaces = [];
		}
		this.indemList.forEach((indemnite) => {
				indemnite.disabled = this.isIndemiteDisabled(indemnite.type);
				if (this.seance.status !== EStatusSeance.done) {
					switch (true) {
						case indemnite.type === EIndemniteType.IFD && this.seance.mePlace === EPlace.home && !this.eligibleIFI && !this.selectedActes.some(acte => acte.keyLetters === "IFI"):
							this.selectedDeplaces.push(indemnite)
							break;
						case indemnite.type === EIndemniteType.IFI && this.seance.mePlace === EPlace.home && this.eligibleIFI:
							this.selectedDeplaces.push(indemnite)
							break;
					}
				}
			})
			this.calculateTotal();
	}

	// Appelé au clic sur la checkbox IK
	toggleIKSelected() {
		// Si la config de l'IK était affiché, alors on supprime l'IK de la liste des indemnités sélectionnées
		if (this.showIkConfig) {
			this.selectedDeplaces = this.selectedDeplaces.filter((indem: Indemnite) => !indem.isIKType)
		} else {
			// Sinon on rajoute le dernier type d'IK coché ou IK plaine si il n'y a pas eu d'IK coché précédement
			this.selectedDeplaces.push(this.IKSelected)
		}
		this.showIkConfig = !this.showIkConfig;
		this.calculateTotal();
	}

	private eligibleMajorationMAU(): boolean {
    return 	this.selectedActes.length == 1 && 
						this.selectedActes[0].priceCoefficient <= 1.5 && 
						!this.eligibleIFI && 

// On vérifie qu'aucun acte n'est un acte antigrippal
this.selectedActes.every(acte => acte.id !== 'act_0350');
	}

	private isIFIEligible(): boolean {
		if (ArrayHelper.hasElements(this.seance.actes)) {
			if(this.selectedActes.some(acte => acte.keyLetters === "IFI"))
			{
				return false;
			}
			const C_IFI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC", "AMX"];
			// On regroupe toutes les lettre-clés dans un seul tableau sans doublon dans lequel on vérifie s'il y a des lettre-clés éligibles à l'IFI.
			return ArrayHelper.unique(this.selectedActes.map((acte: Acte) => acte.keyLetters))
				.some((psKeyLetter: string) => C_IFI_ELIGIBLE_KEY_LETTERS.some((psEligible: string) => psEligible === psKeyLetter));
		}
		else
			return false;
	}

	private eligibleMajorationMie(): boolean {
    return DateHelper.isDate(this.patient?.birthDate) && DateHelper.diffYear(new Date(this.seance.startDate), new Date(this.patient.birthDate)) < 7;
	}


	private eligibleMajorationN1(): boolean {
		const [hours, minutes] = this.horairePrecise.split(':').map(Number);  // Convertit en nombre
		return (hours >= 5 && hours < 8) || (hours >= 20 && hours < 23);
	}
			

	private eligibleMajorationN2(): boolean {
		const [hours, minutes] = this.horairePrecise.split(':').map(Number);  // Convertit en nombre
		return hours >= 23 || hours < 5;
	}

	public eligibleMajorationMCI(): boolean {
    return this.selectedActes.some((acte: Acte) => acte.isMciEligible) || this.patient.pathologies?.includes(EPathologie.palliatif);
	}



	toggleMajorations() {
		this.showAllMajorations = !this.showAllMajorations;
	}

	toggleCheckMajoration(event: Event, majo: Majoration) {
    // Vérifier les incompatibilités entre Dim et N1/N2
    const isSundayOrHoliday = majo.type === EMajorationType.SundayAndHolyday;
    const isNightFirstHalf = majo.type === EMajorationType.NightFirstHalf;
    const isNightSecondHalf = majo.type === EMajorationType.NightSecondHalf;

    // Retirer N1 ou N2 si Dim est sélectionné
    if (isSundayOrHoliday && (this.selectedMajos.some(a => a.type === EMajorationType.NightFirstHalf) || 
                               this.selectedMajos.some(a => a.type === EMajorationType.NightSecondHalf))) {
        this.selectedMajos = this.selectedMajos.filter(a => ![EMajorationType.NightFirstHalf, EMajorationType.NightSecondHalf].includes(a.type));
    } 
    // Retirer Dim si N1 ou N2 est sélectionné
    else if ((isNightFirstHalf || isNightSecondHalf) && this.selectedMajos.some(a => a.type === EMajorationType.SundayAndHolyday)) {
        this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.SundayAndHolyday);
    }

    // Gérer les incompatibilités entre MAU, MCI
    const isMau = majo.type === EMajorationType.Mau;
    const isMci = majo.type === EMajorationType.Mci;

    if (isMau) {
        // Désélectionner MIE et MCI si MAU est sélectionné
        this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mci);
    } else if (isMci) {
        // Désélectionner MAU si MCI est sélectionné
        this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
    }

    // Toggle la sélection de la majoration
    if (this.selectedMajos?.some(a => a.id === majo.id)) {
        this.selectedMajos = this.selectedMajos.filter(a => a.id !== majo.id); // Retirer si déjà sélectionné
    } else {
        this.selectedMajos.push(majo); // Ajouter si pas sélectionné
    }

    // Calculer le total après modification de la sélection
    this.calculateTotal();
	}



	toggleDEplacement() {
		this.showAllDeplacement = !this.showAllDeplacement;
	}

	toggleCheckDeplacement(event: Event, depla: Indemnite) {
		if (depla.type === EIndemniteType.IFD && this.selectedDeplaces.some(a => a.type === EIndemniteType.IFI)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.type !== EIndemniteType.IFI);
		}
		else if (depla.type === EIndemniteType.IFI && this.selectedDeplaces.some(a => a.type === EIndemniteType.IFD)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.type !== EIndemniteType.IFD);
		}
		if (this.selectedDeplaces?.some(a => a.id === depla.id)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.id !== depla.id);
		} else {
			this.selectedDeplaces.push(depla);
		}
		if (depla.type === EIndemniteType.IFI && this.selectedMajos.some(a => a.type === EMajorationType.Mau)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
		}
		this.calculateTotal();
	}

	getSelectedItems() {
		return this.checkedIndexes.map((index) => this.seance.majorations[index]);
	}

	private majSeance() {
		this.seance.actes.map(acte => {
			if (this.selectedActes.some(selectedActe => selectedActe.guid === acte.guid))
				acte.etat = EEtatActe.done;
			else
				acte.etat = EEtatActe.not_done
		})
		const heureRealisation = this.ikForm.get('heure')?.value;
		if (heureRealisation) {
			const [hours, minutes] = heureRealisation.split(':').map(Number);
			const dateSeance = new Date(this.seance.startDate);
			dateSeance.setHours(hours, minutes)
			this.seance.startDate = dateSeance;
		}
		this.seance.majorations = this.selectedMajos;
		this.selectedDeplaces.sort((a, b) => a.type.localeCompare(b.type));
		this.seance.indemnites = !this.IKSelected.distance 
    ? this.selectedDeplaces.filter(indem => !indem.isIKType) 
    : this.selectedDeplaces;

		//mettre a jour mon patient si IK change seulement si c'est a domicile
		if(this.patient.lastIkUpdate != this.IKSelected && this.seance.mePlace == EPlace.home && (this.IKSelected.distance ))
		{
			this.patient.lastIkUpdate = this.IKSelected;
			this.svcPatient.savePatientAnakin(this.patient).subscribe();
		}
		if (!this.seance.infirmierId)
			this.seance.infirmierId = ContactsService.getContactIdFromUserId(UserData.current._id);
	}

	private majSeanceDelete() {
		this.seance.actes.map(acte => {
			acte.etat = EEtatActe.to_be_done
		})
		this.seance.majorations = [];
		this.seance.indemnites = [];
	}

	handleClickConfirm() {
		this.majSeance();
		this.seance.statusChangeDate = new Date();
		this.seance.status = EStatusSeance.done;
		if (this.confirmSoins)
			this.confirmSoins(this.seance)
	}

	handleClickDelete() {
		this.majSeanceDelete();
		delete this.seance.statusChangeDate;
		this.seance.status = EStatusSeance.to_be_done;
		if (this.deleteConfirmSoins)
			this.deleteConfirmSoins(this.seance);
	}

	handleClickAnnule() {
		if (this.cancel)
			this.cancel();
	}

	handleCheckboxChange(event: Event, acte: Acte) {
		if (this.selectedActes?.some(a => a.guid === acte.guid)) {
			this.selectedActes = this.selectedActes.filter(a => a.guid !== acte.guid);
		} else {
			this.selectedActes.push(acte);
		}
		this.initListMajo();
		this.calculateTotal();
	}

	calculateTotal() {
		const acteTotal = this.selectedActes.reduce((sum, acte) => sum + (acte?.price || 0), 0);
		const majorationTotal = this.selectedMajos.reduce((sum, majo) => sum + (majo?.price || 0), 0);
		const indemnityTotal = this.selectedDeplaces.reduce((sum, indem) => sum + (indem?.price || 0), 0);

		this.total = acteTotal + majorationTotal + indemnityTotal;
	}
}
