import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ISector } from '../../models/isector';
import { SectorsService } from '../../services/sectors.service';

@Component({
	selector: 'calao-sectors-list',
	templateUrl: './sectors-list.component.html',
	styleUrls: ['./sectors-list.component.scss'],
})
export class SectorsListComponent extends ComponentBase {

	//#region PROPERTIES

	@Input() public sectors: ISector[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSectors: SectorsService,
		private readonly isvcUiMessage: UiMessageService,
	) {
		super();
	}

	public openSector(poSector: ISector): void {
		this.isvcSectors.routeToSector(poSector);
	}

	/** Demande la confirmation de la suppression d'un secteur. Si l'utilisateur clique sur "Ok", suppression effective.
	 * @param poSector Secteur qui doit être supprimé.
	 */
	public showRemoveSectorPopup(poSector: ISector): void {
		let lbResult = false;

		this.isvcUiMessage.showAsyncMessage<void>(
			new ShowMessageParamsPopup({
				header: "Suppression",
				message: `Voulez-vous vraiment supprimer le secteur "${poSector.name}" ?`,
				buttons: [
					{ text: "Annuler", handler: () => { } }, // Déjà initialisé à 'false'.
					{ text: "OK", handler: () => { lbResult = true; } }
				]
			})
		)
			.pipe(
				map(() => lbResult),
				takeUntil(this.destroyed$),
				mergeMap((pbConfirmation: boolean) => pbConfirmation ? this.removeSector(poSector) : of(true)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Supprime un secteur.
	 * @param poSector Secteur à supprimer.
	 */
	private removeSector(poSector: ISector): Observable<boolean> {
		return this.isvcSectors.deleteSector(poSector)
			.pipe(
				tap((pbResult: boolean) => {
					if (pbResult)
						ArrayHelper.removeElement(this.sectors, poSector);
				})
			);
	}

	//#endregion

}
