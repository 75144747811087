import { NgModule } from '@angular/core';
import { ChangeTrackingModule } from '../change-tracking/change-tracking.module';
import { ChangeTrackingReplicatorService } from './services/change-tracking-replicator.service';
import { ClassicReplicatorService } from './services/classic-replicator.service';



@NgModule({
	declarations: [],
	imports: [
		ChangeTrackingModule
	],
	providers: [ChangeTrackingReplicatorService, ClassicReplicatorService]
})
export class ReplicatorModule { }
