import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigData, IUser } from "@osapp/model";
import { AuthenticatedRequestOptionBuilder } from "@osapp/modules/api/models/authenticated-request-option-builder";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";


@Injectable({
	providedIn: "root"
})
export class UsersService {


	constructor(
		private ioHttpClient: HttpClient,

	) {
	}

	private get securityApiPath(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}/security`;
	}

	public getUser(psUserId: string): Observable<IUser> {
		return this.ioHttpClient.get(`${this.securityApiPath}/users/${psUserId.toLowerCase()}`, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions())
			.pipe(
				catchError(poError => {
					return of(false);
				})
			) as Observable<IUser>;
	}
}