<ion-grid class="pad-sectors">
	<ion-row class="txt-left">
		<div class="title-group">Secteurs</div>
	</ion-row>
	<ion-row *ngFor="let sector of sectors">
		<ion-item lines="none">
			<ion-label class="txt-sectors-list pointer" (click)="routeToSector(sector)">{{ sector.name }}</ion-label>
			<ion-button slot="end" *ngIf="!params?.readOnly" color="primary" (click)="routeToSectorEdition(sector)">
				<ion-icon name="pencil"></ion-icon>
			</ion-button>
			<ion-button slot="end" *ngIf="!params?.readOnly" color="danger" (click)="onRemove(sector)">
				<ion-icon name="close"></ion-icon>
			</ion-button>
		</ion-item>
	</ion-row>
	<ion-row>
		<ion-button *ngIf="!params?.readOnly" class="add-btn" expand="block" (click)="createSector()">
			Nouveau secteur
		</ion-button>
	</ion-row>
</ion-grid>