import { ObjectHelper } from '../../../helpers/objectHelper';

/** Erreur obtenue dans le cas où une base de données a échoué à sa première initialisation. */
export class FirstInitFailedError extends Error {

	constructor(psErrorMessage: string = `L'initialisation des bases de données a échoué.`) {
		super(psErrorMessage);
		// Nécessaire pour que le 'instanceof FirstInitFailedError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FirstInitFailedError);
	}

}