import { Component, Input } from '@angular/core';
import { Acte } from 'apps/idl/src/model/Acte';

@Component({
	selector: 'di-information-abattement',
	templateUrl: './information-abattement.component.html',
	styleUrls: ['./information-abattement.component.scss'],
})
export class InformationAbattementComponent{

	@Input()
	public acte: Acte;

	@Input()
	public index: number;

	@Input()
	public close = () => {};

	constructor() {
	}
}
