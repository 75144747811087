<div class="component-ligne-patient__container" [ngClass]="{'mobileViewForce': mobileViewForce}">
	<div class="component-ligne-patient__content" (click)="onClick(patient._id)">
		<lua-objet-patient class="component-ligne-patient__objet" [firstName]="patient.firstName | firstUpperCase"
			[lastName]="fullLastName | uppercase" [info]="adress" iconHeight="40px" iconWidth="40px"
			[customIconName]="gender || 'homme'" [gender]="gender" [deceased]="patient.deceased"></lua-objet-patient>
		<div class="component-ligne-patient__age">
			<p>{{ age }}</p>
			<small>{{ birthDate }}</small>
		</div>
		<div class="component-ligne-patient__phone">
			<p>{{ phoneNumber }}</p>
		</div>
	</div>
</div>