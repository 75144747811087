import { Component, Input, OnInit } from '@angular/core';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { filter, map, merge, mergeMap, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { GuidHelper } from '../../helpers/guidHelper';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { IDislikerDocument } from '../../model/like/IDislikerDocument';
import { ILikeable } from '../../model/like/ILikeable';
import { ILikerDocument } from '../../model/like/ILikerDocument';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreDataResponse } from '../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { Store } from '../../services/store.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';

@Component({
	selector: 'like',
	templateUrl: './like.component.html',
	styleUrls: ['./like.component.scss']

})
export class LikeComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region FIELDS

	private C_LIKER_DOCUMENT_ID = "liker";
	private C_LIKE_DOCUMENT_PREFIX = "like_";
	private C_DISLIKE_DOCUMENT_PREFIX = "dislike_";
	public likeButton: boolean;
	public dislikeButton: boolean;

	//#endregion

	//#region PROPERTIES

	@Input() public likes: number;
	@Input() public dislikes: number;
	@Input() public likerId = 'user_886865a389264247842f42adeed62d0z';
	@Input() public contentId: string;

	//#endregion


	//#region METHODS

	constructor(private isvcStore: Store, poParentPage: DynamicPageComponent<ComponentBase>) {
		super(poParentPage);

		if (!this.likerId)
			this.getOrCreateDefaultLikerId().subscribe((psId: string) => this.likerId = psId);
	}

	public ngOnInit() {
		this.hasVoted().subscribe();
	}

	/** Récupère le GUID du user s'il existe ou en crée un nouveau dans la base de données d'application, avant de le retourner. */
	private getOrCreateDefaultLikerId(): Observable<string> {
		return this.isvcStore.getOne(this.createLikerIdDataSource(), false)
			.pipe(
				switchMap((poDocument: ILikerDocument) => {
					let loLikerIdObservable: Observable<string>;
					if (poDocument)
						loLikerIdObservable = of(poDocument.likerId);
					else {
						const loLikerDocument: ILikerDocument = {
							_id: this.C_LIKER_DOCUMENT_ID,
							likerId: GuidHelper.newGuid()
						};
						loLikerIdObservable = of(loLikerDocument.likerId);
					}
					return loLikerIdObservable;
				})
			);
	}

	/** DataSource pour accéder à l'applicationStorage et en extraire le GUID affecté à l'utilisateur
	 */
	private createLikerIdDataSource(): IDataSource {
		return {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage),
			viewParams: {
				key: this.C_LIKER_DOCUMENT_ID,
				include_docs: true
			}
		};
	}

	private createLikesDataSource(psMode: string): IDataSource {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.votes),
			viewParams: {
				include_docs: true
			}
		};

		switch (psMode) {
			case 'like':
				loDataSource.viewParams.startkey =
					`${this.C_LIKE_DOCUMENT_PREFIX}${this.contentId}-`; //ex. si contentId = 'ABC' => like_ABC-
				loDataSource.viewParams.endkey =
					`${this.C_LIKE_DOCUMENT_PREFIX}${this.contentId}-${Store.C_ANYTHING_CODE_ASCII}`;
				break;

			case 'dislike':
				loDataSource.viewParams.startkey =
					`${this.C_DISLIKE_DOCUMENT_PREFIX}${this.contentId}-`; //ex. si contentId = 'ABC' => dislike_ABC-
				loDataSource.viewParams.endkey =
					`${this.C_DISLIKE_DOCUMENT_PREFIX}${this.contentId}-${Store.C_ANYTHING_CODE_ASCII}`;
				break;

			case 'myLike':
				loDataSource.viewParams.startkey =
					`${this.C_LIKE_DOCUMENT_PREFIX}${this.contentId}-${this.likerId}`; //ex. si contentId = 'ABC' => dislike_ABC-
				loDataSource.viewParams.endkey = `${this.C_LIKE_DOCUMENT_PREFIX}${this.contentId}-${this.likerId}${Store.C_ANYTHING_CODE_ASCII}`;
				break;

			case 'myDislike':
				loDataSource.viewParams.startkey =
					`${this.C_DISLIKE_DOCUMENT_PREFIX}${this.contentId}-${this.likerId}`; //ex. si contentId = 'ABC' => dislike_ABC-
				loDataSource.viewParams.endkey = `${this.C_DISLIKE_DOCUMENT_PREFIX}${this.contentId}-${this.likerId}${Store.C_ANYTHING_CODE_ASCII}`;
				break;

		}

		return loDataSource;
	};

	private getContent(): Observable<ILikeable> {
		const loDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries),
			viewParams: {
				startkey: `${this.contentId}`,
				endkey: `${this.contentId}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			}
		};

		return this.isvcStore.getOne(loDataSource);
	}

	private getLikesCount(): Observable<number> {
		return this.isvcStore.get(this.createLikesDataSource('like'))
			.pipe(map((paLikes: IStoreDocument[]) => paLikes.length));
	}

	private getDisLikesCount(): Observable<number> {
		return this.isvcStore.get(this.createLikesDataSource('dislike'))
			.pipe(map((paDisLikes: IStoreDocument[]) => paDisLikes.length));
	};


	/** Enregistre un document like dans la base correspondante et supprime son vote inverse. */
	public onLikeClicked(): void {
		const loLikerDocument: ILikerDocument = {
			_id: `like_${this.contentId}-${this.likerId}`,
			likerId: this.likerId
		};

		/** Ajoute un document pour le like dans la BDD (votes_entries) */
		const loUpdateVotes: Observable<IStoreDataResponse> = this.isvcStore.put(loLikerDocument, 'city_core_common_votes_entries')
			.pipe(
				tap(() => this.likeButton = true),
				switchMapTo(
					of(this.dislikeButton)
				),
				filter(       // Si le bouton dislike n'est pas activé, alors on enlève le dislike dans votes_entries.
					(btn => btn === true)
				),
				switchMapTo(
					this.isvcStore.delete(`dislike_${this.contentId}-${this.likerId}`, 'city_core_common_votes_entries')
				),
				tap(() => this.dislikeButton = false)
			);

		// Mise à jour de forms_entries après les modifications dans votes_entries.
		concat(loUpdateVotes, this.updateContentDocument()).subscribe();
	}

	/** Enregistre un document dislike dans la base correspondante. */
	public onDislikeClicked(): void {
		const loDislikerDocument: IDislikerDocument = {
			_id: `dislike_${this.contentId}-${this.likerId}`,
			dislikerId: this.likerId
		};

		/** Ajoute un document pour le dislike dans la BDD (votes_entries) */
		const loUpdateVotes: Observable<IStoreDataResponse> = this.isvcStore.put(loDislikerDocument, 'city_core_common_votes_entries')
			.pipe(
				tap(() => this.dislikeButton = true),
				switchMapTo(
					of(this.likeButton)
				),
				filter(       // Si le bouton like n'est pas activé, alors on enlève le dislike dans votes_entries.
					(btn => btn === true)
				),
				switchMapTo(
					this.isvcStore.delete(`like_${this.contentId}-${this.likerId}`, 'city_core_common_votes_entries')
				),
				tap(() => this.likeButton = false)
			);

		// Mise à jour de forms_entries après les modifications dans votes_entries.
		concat(loUpdateVotes, this.updateContentDocument()).subscribe();
	}

	/** Détermine si l'utilisateur a déjà liké ou disliké le contenu */
	private hasVoted(): Observable<boolean> {
		const loLikes$: Observable<boolean> = this.isvcStore.getOne(this.createLikesDataSource('myLike'), false)
			.pipe(
				map((poResult: IStoreDocument) => poResult !== undefined),
				map((poResult: boolean) => this.likeButton = poResult)
			);

		const loDislikes$: Observable<boolean> = this.isvcStore.getOne(this.createLikesDataSource('myDislike'), false)
			.pipe(
				map((poResult: IStoreDocument) => poResult !== undefined),
				map((poResult: boolean) => this.dislikeButton = poResult)
			);

		return loLikes$.pipe(merge(loDislikes$));
	}

	/** Met à jour le document du report avec les nouvelles valeur de like et de dislike (form_entries) */
	private updateContentDocument(): Observable<IStoreDataResponse> {
		return this.getContent()
			.pipe(
				mergeMap((poDocument: ILikeable) => forkJoin([this.getLikesCount(), this.getDisLikesCount()])
					.pipe(
						map((paVotesArray) => {
							poDocument.likes = paVotesArray[0];
							poDocument.dislikes = paVotesArray[1];
							return poDocument;
						})
					)),
				mergeMap(
					poDocument => this.isvcStore.put(poDocument)
				)
			);
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void { }

	//#endregion

}
