<div *ngIf="showToolbar" class="toolbar-container">

	<div class="toolbar-position-container flx-just-cont-end">
		<div *ngFor="let element of eltContainer">
			<osapp-fab-button *ngIf="element.position === 'left'" [params]="element"></osapp-fab-button>
		</div>
	</div>

	<div class="toolbar-position-container flx-just-cont-center">
		<div *ngFor="let element of eltContainer">
			<osapp-fab-button *ngIf="element.position === 'middle'" [params]="element"></osapp-fab-button>
		</div>
	</div>

	<div class="toolbar-position-container">
		<div *ngFor="let element of eltContainer">
			<osapp-fab-button *ngIf="element.position === 'right'" [params]="element"></osapp-fab-button>
		</div>
	</div>

</div>