<ion-list>
	<input type="text" />
	<ion-button shape="round">+</ion-button>

	<ion-item>
		<ion-input type="text" [(ngModel)]="newNumber"></ion-input>
	</ion-item>
	<ion-button shape="round" (click)="onAdd()">+</ion-button>

	<div *ngFor="let phoneNumber of value; let i = index">
		<ion-item>
			<ion-input type="text" [(ngModel)]="value[i]"></ion-input>
		</ion-item>
		<ion-button shape="round" (click)="onRemove(i)">-</ion-button>
	</div>

</ion-list>