export enum ESyncType {
	fillIfEmpty = "fillIfEmpty",
	manual = "manual",
	none = "none",
	offlineFirst = "offlineFirst",
	onlineFirst = "onlineFirst",
	/** Base de données sans réplication (toutes les requêtes se font sur le serveur). */
	remote = "remote",
	/** Base de données qui ne doivent être répliquées qu'une seule fois. */
	replicateOnStart = "replicateOnStart",
	uploadOnly = "uploadOnly"
}