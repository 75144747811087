import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigData } from '../../../../model/config/ConfigData';
import { IChangelogsPageParams } from '../models/ichangelogs-page-params';

@Component({
	templateUrl: './changelogs.page.html',
	styleUrls: ['./changelogs.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogsPage {

	//#region PROPERTIES

	public readonly title: string;
	public readonly url: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poRouter: Router) {
		const loState: IChangelogsPageParams | null = poRouter.getCurrentNavigation()?.extras?.state as IChangelogsPageParams;
		this.title = loState?.title ?? "Nouveautés";
		this.url = loState?.url ?? ConfigData.appInfo.changelogsUrl;
	}

	//#endregion METHODS

}
