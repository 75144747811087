<div class="prise-en-charge-container">
	<div class="prise-en-charge-content">
		<div class="info-content">
			<lua-input
				label="A partir du"
				type="date"
				[fullWidth]="true"
				[(ngModel)]="aPartirDu"
				name="aPartirDu"
				(valueChange)="handleChangeAPartirDu($event)"
			></lua-input>
			<lua-switch
				label="Prise en charge 100% CPAM"
				[isChecked]="priseEnChargeCPAM"
				(clickSwitch)="togglePriseEnChargeCPAM($event)"
			></lua-switch>
		</div>
		<div class="info-content">
			<h2>Lieu</h2>
			<lua-data-picker
				[options]="lieuOptions"
				[fullWidth]="true"
				[(ngModel)]="lieu"
				[multiSelection]="false"
				name="lieu"
			></lua-data-picker>
		</div>
		<div class="info-content">
			<h2>A quel moment?</h2>
			<lua-data-picker
				[options]="momentOptions"
				[multiSelection]="true"
				[fullWidth]="true"
				[(ngModel)]="moments"
				name="moments"
			></lua-data-picker>
			
			<!-- <div *ngFor="let horaire of horaires; let i = index">
				<lua-input-time
				  [fullWidth]="true"
				  size="large"
				  [(ngModel)]="horaires[i]"
				  name="horairePrecise{{i}}"
				></lua-input-time>
			</div>
			<lua-bouton-texte
				libelle="Ajouter une heure"
				[fullWidth]="true"
				(click)="ajouterHoraire()"
			></lua-bouton-texte> -->
			<lua-bouton-texte
			*ngIf="!selectionnerHeure"
			libelle="Sélectionner une heure"
			[fullWidth]="true"
			(click)="showSelectionnerHeure()"
		></lua-bouton-texte>
			<!-- <lua-input-time
				*ngIf="selectionnerHeure"
				[fullWidth]="true"
				size="large"
				[(ngModel)]="horairePrecise"
				name="horairePrecise"
			></lua-input-time> -->
			<lua-input
			*ngIf="selectionnerHeure"
			[(ngModel)]="horairePrecise"
			name="horairePrecise"
			type="time"
			placeholder="hh:mm"
			[fullWidth]="true"
			size="medium"></lua-input>
		</div>
		<div class="info-content">
			<h2>Quels jours ?</h2>
			<lua-switch
				label="Tous les jours"
				[isChecked]="tousLesJours"
				(clickSwitch)="toggleTousLesJours($event)"
			></lua-switch>
			<lua-switch
				label="Dimanche et jours feriés inclus?"
				[isChecked]="dimanchesEtJoursFeriesInclus"
				(clickSwitch)="toggleDimancheJoursFeriesInclus($event)"
			></lua-switch>
			<lua-data-picker
				[options]="jourOptions"
				[multiSelection]="true"
				[fullWidth]="true"
				[(ngModel)]="jours"
				name="jours"
			></lua-data-picker>
			<lua-bouton-texte
				*ngIf="!selectionnerRepetition"
				libelle="Tous les X jours/semaine(s)"
				[fullWidth]="true"
				(click)="showSelectionnerRepetition()"
			></lua-bouton-texte>
			<ng-container *ngIf="selectionnerRepetition">
				<p class="repetition-label">Toutes les</p>
				<div class="repetition-container">
					<lua-select
						[options]="repetitionOptions"
						[(ngModel)]="repetition"
					></lua-select>
					<lua-select
						[options]="repetitionTypeOptions"
						[(ngModel)]="repetitionType"
						(ngModelChange)="onRepetitionChange()"
					></lua-select>
				</div>
			</ng-container>
		</div>
		<div class="info-content repeter-container">
			<h2>Répéter</h2>
			<div class="horizontal-content" *ngFor="let duree of dureeOptions">
				<lua-radio-button
					[label]="duree.type === 'nombre' ? 'pendant' : 'jusqu\'au'"
					(click)="selectDuree(duree.value)"
					[checked]="selectedDuree === duree.value"
				></lua-radio-button>
				<ng-container *ngIf="duree.type === 'nombre'">
					<lua-input
						*ngIf="selectedDuree === duree.value"
						[ngClass]="{'radio-field-disabled': selectedDuree !== duree.value}"
						class="duree-input"
						type="number"
						min="1"
						[fullWidth]="true"
						[disabled]="selectedDuree !== duree.value"
						[(ngModel)]="dureeValue"
					></lua-input>
					<lua-input
						*ngIf="selectedDuree !== duree.value"
						[ngClass]="{'radio-field-disabled': selectedDuree !== duree.value}"
						class="duree-input"
						type="number"
						min="1"
						[fullWidth]="true"
						[disabled]="selectedDuree !== duree.value"
					></lua-input>
					<p [ngClass]="{'radio-field-disabled': selectedDuree !== duree.value}" class="duree-input">{{ duree.label }}</p>
				</ng-container>
				<ng-container *ngIf="duree.type === 'date'">
					<lua-input
						*ngIf="selectedDuree === duree.value"
						[ngClass]="{'radio-field-disabled': selectedDuree !== duree.value}"
						class="duree-input"
						type="date"
						[disabled]="false"
						[fullWidth]="true"
						[(ngModel)]="dureeValue"
					></lua-input>
					<lua-input
						*ngIf="selectedDuree !== duree.value"
						[ngClass]="{'radio-field-disabled': selectedDuree !== duree.value}"
						class="duree-input"
						type="date"
						[fullWidth]="true"
						[disabled]="true"
					></lua-input>
				</ng-container>
			</div>
		</div>
		<div class="prise-en-charge-footer">
			<div class="footer-buttons">
				<lua-alerte
					*ngIf="invalidMessage"
					type="error"
					[message]="invalidMessage"
				>
				</lua-alerte>
				<lua-action-principale
					[fullWidth]="true"
					libelle="Terminé"
					(click)="savePriseEnCharge()"
				></lua-action-principale>
				<lua-bouton-texte
					[fullWidth]="true"
					libelle="Annuler"
					(click)="cancelPriseEnCharge()"
				></lua-bouton-texte>
			</div>
		</div>
	</div>
</div>
