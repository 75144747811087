import { EDmsMediaPriority } from './edms-media-priority';

export class DmsMediaResult {

	//#region PROPERTIES

	/** Identifiant du fichier. */
	readonly mediaId: string;

	/** Donnée du fichier récupéré, `undefined` si non trouvé ou problème de récupération. */
	readonly data?: string | Blob;

	/** Type de fichier dms. */
	readonly dmsMediaType: EDmsMediaPriority;

	/** Indique si le fichier concerné est le principal attendu (`true`), ou un secondaire (`false`). */
	public get isPrimary(): boolean { return this.dmsMediaType === EDmsMediaPriority.primary; }

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psMediaId: string,
		poDmsMediaType: EDmsMediaPriority,
		poData?: string | Blob
	) {
		this.mediaId = psMediaId;
		this.dmsMediaType = poDmsMediaType;
		this.data = poData;
	}

	//#endregion METHODS

}
