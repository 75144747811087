import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReplicatorModule } from './replicator/replicator.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		ReplicatorModule
	]
})
export class StoreModule { }