export enum ELifeCycleEvent {
	viewWillEnter = "viewWillEnter",
	viewDidEnter = "viewDidEnter",
	viewWillLeave = "viewWillLeave",
	viewDidLeave = "viewDidLeave",
	viewCanEnter = "viewCanEnter",
	viewCanLeave = "viewCanLeave",
	viewDestroy = "viewDestroy",
	viewInit = "viewInit",
	viewBackFrom = "viewBackFrom",
	viewBackTo = "viewBackTo"
}