import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalModule } from '../modal/modal.module';
import { DatabaseSyncAnimatedIconComponent } from './components/database-sync-animated-icon/database-sync-animated-icon.component';
import { DatabaseSyncStatusIconComponent } from './components/database-sync-status-icon/database-sync-status-icon.component';
import { DatabaseSyncStatusComponent } from './components/database-sync-status/database-sync-status.component';
import { DatabasesSyncStatusListComponent } from './components/databases-sync-status-list/databases-sync-status-list.component';
import { DatabasesSyncStatusModalComponent } from './components/databases-sync-status-modal/databases-sync-status-modal.component';
import { IDatabaseGroupingConfiguration } from './model/IDatabaseGroupingConfiguration';
import { IDmsSyncConfig } from './model/IDmsSyncConfig';
import { DatabaseSynchroService, DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from './services/database-synchro.service';

const components: Type<any>[] = [
	DatabaseSyncStatusComponent,
	DatabasesSyncStatusListComponent,
	DatabasesSyncStatusModalComponent,
	DatabaseSyncStatusIconComponent,
	DatabaseSyncAnimatedIconComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		ModalModule
	],
	exports: components,
	providers: [DatabaseSynchroService]
})
export class DatabaseSynchroModule {
	public static forRoot(paDatabasesGroupingConfigs: IDatabaseGroupingConfiguration[], poDmsSyncConfig: IDmsSyncConfig): ModuleWithProviders<DatabaseSynchroModule> {
		return {
			ngModule: DatabaseSynchroModule,
			providers: [
				{ provide: DATABASES_GROUPING_CONFIG, useValue: paDatabasesGroupingConfigs },
				{ provide: DMS_SYNC_CONFIG, useValue: poDmsSyncConfig }
			]
		}
	}
}