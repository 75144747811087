<div class="di-liste-seance">
	<ng-container *ngIf="pagination && displayCount > 0">
	  <div *ngFor="let group of displayedGroupedSeances">
		<div class="seance-par-jour">
		  <h4 class="titre">{{ group.date }}</h4>
		  <div *ngFor="let seance of group.seances">
			<di-carte-seance [seance]="seance" [ordonnance]="ordonnance" [patient]="patient"></di-carte-seance>
		  </div>
		</div>
	  </div>
  
	  <lua-action-secondaire *ngIf="displayCount < nbTotalSeances" 
		class="show-more-btn-container"
		libelle="Afficher les séances suivantes" 
		[fullWidth]="false" 
		(click)="showMore()"></lua-action-secondaire>
	</ng-container>
  
	<ng-container *ngIf="!pagination">
	  <div *ngFor="let group of visibleGroupedSeances">
		<div class="seance-par-jour">
		  <h4 class="titre">{{ group.date }}</h4>
		  <div *ngFor="let seance of group.seances">
			<di-carte-seance [seance]="seance" [ordonnance]="ordonnance" [patient]="patient"></di-carte-seance>
		  </div>
		</div>
	  </div>
	</ng-container>
  </div>