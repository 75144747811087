import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { IDatabaseDocument } from '../model/databaseDocument/IDatabaseDocument';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { ApplicationService } from './application.service';
import { Store } from './store.service';

/** Service permettant de créer les document de vues dans les différentes bases de données. */
@Injectable({ providedIn: "root" })
export class DatabaseDocumentInitializerService {

	//#region FIELDS

	/** Tableau des documents de bases de données à créer. */
	private maDatabaseDocuments: Array<IDatabaseDocument> = [];
	/** Indique si les bases de données sont initialisées ou non. */
	private mbAreDatabasesInitialized = false;
	/** Indique si on est déjà en train de crér des documents de bases de données ou non. */
	private mbIsWorking = false;

	//#endregion

	//#region METHODS

	constructor(private isvcStore: Store, psvcApplication: ApplicationService) {

		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(
				tap(
					_ => {
						this.mbAreDatabasesInitialized = this.mbIsWorking = true;
						if (this.maDatabaseDocuments.length > 0)
							this.createDocuments();
					},
					poError => console.error("Erreur flag DBinitialized in designDocumentInitializerService : ", poError)
				)
			)
			.subscribe();
	}

	//#region DesignDocuments

	/** Ajoute un document dans la liste des documents à créer en base de données.
	 * @param poDatabaseDocument Document à créer dans les bases de données.
	 */
	public addDocument(poDatabaseDocument: IDatabaseDocument): void {
		this.maDatabaseDocuments.push(poDatabaseDocument);

		if (this.mbAreDatabasesInitialized && !this.mbIsWorking)
			this.createDocuments();
	}

	/** Crée les documents de design dans les bases de données associées. */
	private createDocuments(): void {
		const loDatabaseDocument: IDatabaseDocument = ArrayHelper.getFirstElement(this.maDatabaseDocuments.splice(0, 1));
		const lsTargetDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(loDatabaseDocument.document, "", false);
		const lbHasDbIdInCacheData = !StringHelper.isBlank(lsTargetDatabaseId);

		this.getDatabaseIdsWherePutDocument(loDatabaseDocument) // On récupère toutes les bases de données où créer le document.
			.pipe(mergeMap((psDatabaseId: string) => this.isvcStore.put(loDatabaseDocument.document, lbHasDbIdInCacheData ? "" : psDatabaseId))) // Pour chacune, on crée le document.
			.subscribe(
				(poResult: IStoreDataResponse) => { },
				poError => console.error(poError),
				() => { // Puis lorsque tout est terminé,
					if (this.maDatabaseDocuments.length > 0) // s'il y a encore des documents à créer, on rappelle la méthode.
						this.createDocuments();
					else // Sinon, on a terminé.
						this.mbIsWorking = false;
				}
			);
	}

	/** Récupère le tableau d'identifiants de bases de données où il est nécessaire de créer le document.
	 * @param psDocId Identifiant du document dont il faut vérifier l'existence en base de données.
	 * @param paDatabaseIds Tableau des identifiants de bases de données où il faut vérifier l'existence du document.
	 */
	private getDatabaseIdsWherePutDocument(poDatabaseDocument: IDatabaseDocument): Observable<string> {

		return from(poDatabaseDocument.databaseIds.sort())
			.pipe( // Depuis les bases de données indiquées.
				distinctUntilChanged(),
				mergeMap((psDatabaseId: string) => this.isvcStore.docExists(poDatabaseDocument.document._id, psDatabaseId) // On vérifie l'existence du document avant de le créer.
					.pipe(map((pbResult: boolean) => !pbResult ? psDatabaseId : null)) // S'il n'existe pas alors on indique la base de données où il faut le créer.
				),
				filter((psDatabaseId: string) => !StringHelper.isBlank(psDatabaseId)) // On élimine les bases de données n'ayant pas d'identifiant valide.
			);
	}

	//#endregion
}