import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { concatMap, filter, startWith, tap } from 'rxjs/operators';

// TODO TB : Regrouper avec l'updateService
@Injectable({
	providedIn: 'root'
})
export class PwaUpdateService {

	//#region FIELDS

	private readonly C_LOG_ID = "PWAUPDT.S::";

	/** Indique si le service est déjà en train de vérifier la mise à jour. */
	private lbChecking = false;

	//#endregion

	//#region METHODS

	constructor(private readonly ioSwUpdate: SwUpdate) { }

	private async updateApp(): Promise<void> {
		if (confirm("Une nouvelle version est disponible. Voulez-vous la charger?")) {
			await this.ioSwUpdate.activateUpdate(); // Si l'utilisateur valide, on l'applique puis on reload.
			window.location.reload();
		}
	}

	/** Initialise la détection de mises à jour. La recherche se fait toutes les minutes. */
	public initUpdateCheck(): void {
		if (this.ioSwUpdate.isEnabled && !this.lbChecking) {
			this.lbChecking = true;

			this.ioSwUpdate.versionUpdates
				.pipe(
					filter((poEvent: VersionReadyEvent) => poEvent.type === "VERSION_READY"),
					concatMap(() => this.updateApp())
				) // On s'abonne aux mises à jour de la webapp.
				.subscribe();

			interval(60000)
				.pipe(
					startWith(0),
					concatMap(() => this.ioSwUpdate.checkForUpdate()), // On boucle pour vérifier toutes les minutes si il y a une mise à jour.
					tap(() => console.debug(`${this.C_LOG_ID}Webapp update checked.`))
				)
				.subscribe();
		}
	}

	//#endregion

}
