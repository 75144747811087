<ion-content>
	<ion-list *ngIf="!areFormsVisible" lines="full">
		<ion-item (click)="takePicture()">
			<ion-icon slot="start" name="camera-outline"></ion-icon>
			<ion-label>Appareil photo</ion-label>
		</ion-item>
		<ion-item (click)="pickImage()">
			<ion-icon slot="start" name="images-outline"></ion-icon>
			<ion-label>Galerie</ion-label>
		</ion-item>
		<ion-item (click)="pickFile()">
			<ion-icon slot="start" name="document-outline"></ion-icon>
			<ion-label>Documents</ion-label>
		</ion-item>
		<ng-container *ngIf="formConfigs.length > 0">
			<ng-container *ngIf="formConfigs.length === 1; else multipleForms">
				<ion-item (click)="openForm(formConfigs[0])">
					<ion-icon slot="start" [name]="formConfigs[0].icon"></ion-icon>
					<ion-label>{{ formConfigs[0].label }}</ion-label>
				</ion-item>
			</ng-container>
			<ng-template #multipleForms>
				<ion-item (click)="showForms()">
					<ion-icon slot="start" name="clipboard"></ion-icon>
					<ion-label>Formulaires</ion-label>
				</ion-item>
			</ng-template>
		</ng-container>
	</ion-list>

	<ion-list *ngIf="areFormsVisible" lines="full">
		<ion-item *ngFor="let formConfig of formConfigs" (click)="openForm(formConfig)">
			<ion-icon slot="start" [name]="formConfig.icon"></ion-icon>
			<ion-label>{{ formConfig.label }}</ion-label>
		</ion-item>
	</ion-list>
</ion-content>