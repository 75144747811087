import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CheckboxComponent } from './components/checkbox/checkbox.component';

@NgModule({
	imports: [IonicModule],
	exports: [CheckboxComponent],
	declarations: [CheckboxComponent],
	providers: []
})
export class CalaoCheckboxModule { }