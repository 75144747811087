import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalModule } from '../modal/modal.module';
import { DrawingModalComponent } from './components/drawing-modal/drawing-modal.component';
import { DrawingComponent } from './components/drawing/drawing.component';

const components: Type<any>[] = [
	DrawingComponent,
	DrawingModalComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		ModalModule
	],
	exports: components
})
export class DrawingModule { }