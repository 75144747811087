import { Component, Input, OnInit } from '@angular/core';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { takeUntil } from 'rxjs/operators';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';
import { ConfirmationSuppressionComponent } from '../../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { PanneauChoixContactComponent } from '../../../shared/components/panneaux/panneau-choix-contact/panneau-choix-contact.component';
import { PanneauChoixMedecinTraitantComponent } from '../../../shared/components/panneaux/panneau-choix-medecin-traitant/panneau-choix-medecin-traitant.component';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';

@Component({
  selector: 'di-menu-detail-patient',
  templateUrl: './menu-detail-patient.component.html',
  styleUrls: ['./menu-detail-patient.component.scss'],
})
export class MenuDetailPatientComponent extends DestroyableComponentBase implements OnInit {

  constructor(
		private svcPanneau: PanneauService,
		private svcDrawerPopover : DrawerPopoverService,
		private svcPatient : PatientsService
  ) { super() }


	@Input() patient? : IPatient;
	@Input() typeAction: "Contact" | "Medecin";	
	@Input() anchorElement? : any;

  ngOnInit() { 
		this.svcPanneau.closePanel$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
		});
	}

	public edit(){
		switch(true){
			case this.typeAction === "Contact":
				this.openPanneauContact();
				break;
			case this.typeAction === "Medecin":
				this.openPanneauMedecin();
			break;
		}
		this.svcDrawerPopover.close();
	}

	public delete(){
		this.svcDrawerPopover.close();
		this.svcDrawerPopover.open("Confirmation de suppression","50%",this.anchorElement.nativeElement, ConfirmationSuppressionComponent, {
				onConfirm: () => this.handleConfirm(),
				onCancel: () => this.handleCancel()
				})
	}

	private openPanneauContact(): void {
		this.svcPanneau.open("Qui doit on contacter ?", PanneauChoixContactComponent, {
      patient: this.patient,
    });
	}

	private openPanneauMedecin(): void {
		this.svcPanneau.open("Médecin traitant", PanneauChoixMedecinTraitantComponent, {
      patient: this.patient,
    });  
	}

	private handleConfirm (){
		switch(true){
			case this.typeAction === 'Contact':
				this.patient.contacts = [];
			break;
			case this.typeAction === 'Medecin':
				this.patient.doctors = [];
			break;
		}
		this.svcPatient.savePatientAnakin(this.patient).subscribe();
		this.svcDrawerPopover.close();
	}

	private handleCancel (){
		this.svcDrawerPopover.close();
	}
}
