import { GuidHelper } from "@osapp/helpers";
import { C_PREFIX_SEANCE } from "../anakin.constants";

export class SeanceKey {
    id: string;
    traitementId: string;
    patientId: string;

    constructor(traitementId: string, patientId: string) {
        this.id = GuidHelper.newGuid(),
            this.traitementId = traitementId,
            this.patientId = patientId
    }

    public toString(): string {
        return `${C_PREFIX_SEANCE}${this.id}~${this.traitementId}~${this.patientId}`;
    }
}