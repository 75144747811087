import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ArrayHelper } from '@osapp/helpers';
import { NavigationItems } from 'apps/idl/src/anakin/shared/navigation-items.constants';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';

@Component({
	selector: "di-sidemenu-desktop",
	templateUrl: './sidemenu-desktop.component.html',
	styleUrls: ['./sidemenu-desktop.component.scss'],
})
export class SidemenuDesktopComponent implements OnInit{

	public menuIcones = NavigationItems;
	public teletransmissionItems={
		iconName: 'upload',
		label: 'Télétransmissions',
		route: ['teletransmission'],
		badgeCount: 0,
	};
	public desktopIcons;
	public menuHovered = false;
	public menuDesktopOpened : boolean;
	public classes: {[key: string]: boolean} = {};
	public selectedRoute : string;
	@Input() menuToggleFunction : () => void;
	@Input() menuOpened : boolean;

	constructor(private router: Router,
		private svcNavigation: NavigationService,
		private cdr: ChangeDetectorRef,
		private ioInAppBrowser: InAppBrowser
	) { }

	ngOnInit() {
		this.desktopIcons = [...this.menuIcones, this.teletransmissionItems];
		this.selectedRoute = 'transmissions';
		this.menuDesktopOpened = this.menuOpened;
		this.updateClasses();
		this.svcNavigation.navigationItems$.subscribe(items => {
			this.menuIcones = items;
			this.cdr.detectChanges();
		});	

		this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const urlSegments = event.urlAfterRedirects.split('/');
      const matchingRoute = this.findMatchingRoute(urlSegments);

      if (matchingRoute) {
        this.setSelectedRoute(matchingRoute);
      }
    });
	}

	private findMatchingRoute(urlSegments: string[]): string[] | null {
    // Parcourt les éléments de menu pour trouver une route qui correspond au début de l'URL actuelle
    for (const item of this.menuIcones) {
      if (urlSegments.some(segment => item.route.includes(segment))) {
        return item.route;
      }
    }
    return null;
  }

	public redirect(route: string[]): void {
		if (!route.includes("#"))
		{
			this.setSelectedRoute(route);
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate(route)
			});
		}
	}

	public handleRedirectAmeli() {
		const url = 'https://www.ameli.fr/meurthe-et-moselle/infirmier/exercice-liberal/prescription-prise-charge/regles-exercice-formalites/bilan-de-soins-infirmiers-bsi';
		this.ioInAppBrowser.create(url);
	}

	handleHoverMenu() {
		this.menuHovered = true;
	}
	handleMenuLeave() {
		this.menuHovered = false;
	}
	openMenu() {
		this.menuToggleFunction();
		this.menuDesktopOpened = true;
		this.updateClasses(); 
	}
	closeMenu() {
		this.menuToggleFunction();
		this.menuDesktopOpened = false;
		this.updateClasses(); 
	}
	private updateClasses() {
		this.classes = {
		  'menu-open': this.menuDesktopOpened,
		};
	}
	
	public getClasses() {
		return this.classes;
	}

	public setSelectedRoute = (route: string[]) => {
		this.selectedRoute = ArrayHelper.getFirstElement(route); 
	}
}
