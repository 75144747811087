import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { GesturesDirective, GESTURES_CONFIG } from "./directives/gestures.directive";
import { IGesturesConfig } from "./model/IGesturesConfig";

@NgModule({
	declarations: [GesturesDirective],
	exports: [GesturesDirective],
	imports: [
		CommonModule
	]
})
export class GesturesModule {

	public static forRoot(poGesturesConfig: IGesturesConfig): ModuleWithProviders<GesturesModule> {
		return {
			ngModule: GesturesModule,
			providers: [
				{ provide: GESTURES_CONFIG, useValue: poGesturesConfig }
			]
		}
	}

}
