/** Permet de mettre à disposition des méthodes pour aider à manipuler booléen. */
export abstract class BooleanHelper {

	//#region METHODS

	/** Retourne `true` si la valeur est un booléen stringifié, `false` sinon.
	 * @param psBoolean Valeur à tester si elle est un booléen stringifié.
	 */
	public static isStringBoolean(psBoolean: string): boolean {
		return psBoolean === "true" || psBoolean === "false";
	}

	/** Convertit et retourne une valeur en booléen uniquement dans le cas où la valeur est déjà un booléen ou si la valeur est un booléen stringifié.
	 * @param poValue Valeur à convertir en booléen.
	 * @throws Retourne une erreur si la valeur n'est pas convertible en booléen.
	 */
	public static convertToBoolean(poValue: boolean | string): boolean {
		if (typeof poValue === "boolean")
			return poValue;
		else if (this.isStringBoolean(poValue))
			return JSON.parse(poValue);
		else
			throw new Error("La conversion en valeur booléenne n'est pas possible !");
	}

	//#endregion
}