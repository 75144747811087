<div class="di-menu-selection-infirmier">
	<div class="infirmier-list">
		<ng-container *ngIf="infirmiers">
			<div class="infirmier" *ngFor="let infirmier of infirmiers; let i = index">
				<lua-objet-utilisateur [firstName]="infirmier.firstName" [lastName]="infirmier.lastName.toUpperCase()"
					[avatarColor]="infirmier.avatarColor"></lua-objet-utilisateur>
				<lua-radio-button (click)="onSelectionItem(infirmier)"></lua-radio-button>
			</div>
		</ng-container>
	</div>
	<lua-bouton-texte libelle="Afficher tout le monde" [fullWidth]="true" (click)="afficherTous()"></lua-bouton-texte>
	<div class="info">
		<lua-divider-horizontal></lua-divider-horizontal>
		<div class="info-text">
			<lua-avatar [withTooltip]="false" color="Noir20"></lua-avatar>
			<p>Les soins non affectés seront affichés pour tous les utilisateurs</p>
		</div>
	</div>
</div>