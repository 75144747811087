import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeeplinkService } from './deeplink.service';

/**
 * Afin de pouvoir tester l'integration du deeplink, utiliser la commande:\
 * `adb shell am start -W -a android.intent.action.VIEW -d "${url à tester}"`.\
 * Afin d'installer le plugin ionic Deeplinks, utiliser la commande suivante:\
 * `ionic cordova plugin add ionic-plugin-deeplinks --variable DEEPLINK_SCHEME=https --variable DEEPLINK_HOST=example.com --variable ANDROID_PATH_PREFIX="" --variable URL_SCHEME=myapp`
 */
@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [DeeplinkService]
})
export class DeeplinkModule { }
