import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { PerformanceManager } from '../../performance/PerformanceManager';
import { ELogActionId } from "./ELogActionId";
import { ILogActionParams } from './ILogActionParams';
import { ILogActionPropertyReturnType } from "./ILogActionPropertyReturnType";
import { ILogSource } from "./ILogSource";

export class LogActionHandler {

	//#region METHODS

	constructor(private moTarget: ILogSource) { }

	/** Permet de générer un log action après l'éxecution de la fonction passée en paramètre.
	 * @param poParams
	 * @param paArguments
	 * @param pfFunction
	 * @returns
	 */
	public handle<T extends (...paArgs: any[]) => any>(
		poParams: ILogActionParams<Parameters<T>, ILogActionPropertyReturnType<ReturnType<T>>>,
		paArguments: Parameters<T>,
		pfFunction: T): ReturnType<T> {
		const loPerformanceManager = new PerformanceManager().markStart();
		try {
			const loResult: any = pfFunction.apply(this.moTarget, paArguments);

			if (poParams.ignoreLogging && poParams.ignoreLogging(this.moTarget, ...paArguments))
				return loResult;

			if (loResult instanceof Observable)
				return this.logObservableAction<T>(loResult, this.moTarget, paArguments, poParams, loPerformanceManager) as any;
			else if (loResult instanceof Promise)
				return this.logPromiseAction<T>(loResult, this.moTarget, paArguments, poParams, loPerformanceManager) as any;
			else {
				this.logSuccess(this.moTarget, paArguments, poParams, loResult, loPerformanceManager);
				return loResult as ReturnType<T>;
			}
		}
		catch (poError) {
			this.logError(this.moTarget, paArguments, poParams, poError, loPerformanceManager);
			throw poError;
		}
	}

	private async logPromiseAction<T extends (...paArgs: any[]) => Promise<any>>(
		poPromise: Promise<ILogActionPropertyReturnType<ReturnType<T>>>,
		poTarget: ILogSource,
		paArguments: Parameters<T>,
		poParams: ILogActionParams<Parameters<T>, ILogActionPropertyReturnType<ReturnType<T>>>,
		poPerformanceManager: PerformanceManager
	): Promise<ILogActionPropertyReturnType<ReturnType<T>>> {
		try {
			let poValue: ILogActionPropertyReturnType<ReturnType<T>>;
			poValue = await poPromise;
			this.logSuccess(poTarget, paArguments, poParams, poValue, poPerformanceManager);
			return poValue;
		} catch (poError) {
			this.logError(poTarget, paArguments, poParams, poError, poPerformanceManager);
			throw poError;
		}
	}

	private logObservableAction<T extends (...paArgs: any[]) => Promise<any>>(
		poObservable: Observable<ILogActionPropertyReturnType<ReturnType<T>>>,
		poTarget: ILogSource,
		paArguments: Parameters<T>,
		poParams: ILogActionParams<Parameters<T>, ILogActionPropertyReturnType<ReturnType<T>>>,
		poPerformanceManager: PerformanceManager
	): Observable<ILogActionPropertyReturnType<ReturnType<T>>> {

		return poObservable.pipe(tap(
			(poResult: ILogActionPropertyReturnType<ReturnType<T>>) =>
				this.logSuccess(poTarget, paArguments, poParams, poResult, poPerformanceManager),
			poError => this.logError(poTarget, paArguments, poParams, poError, poPerformanceManager)
		));
	}

	private buildData<T extends Array<any>, V>(
		poTarget: ILogSource,
		poArguments: T,
		poParams: ILogActionParams<T, V>,
		poPerformanceManager: PerformanceManager,
		poResult?: V
	): any {
		let loData: any;

		if (poParams.dataBuilder) {
			try {
				loData = poParams.dataBuilder.apply(poTarget, [poTarget, poResult, ...Array.from(poArguments)]);
			}
			catch (poError) {
				loData = { dataBuilderError: poError.message ? poError.message : "dataBuilder function throws an error" };
			}
		}
		else
			loData = {};

		loData.duration = +poPerformanceManager.markEnd().measure().toFixed(0);

		return loData;
	}

	private logSuccess<T extends Array<any>, V>(
		poTarget: ILogSource,
		poArguments: T,
		poParams: ILogActionParams<T, V>,
		poResult: V,
		poPerformanceManager: PerformanceManager
	): void {
		if (!poParams.ignoreResultLogging || !poParams.ignoreResultLogging(poResult, ...poArguments)) {
			if (poParams.successMessage) {
				poTarget.isvcLogger.action(
					poTarget.logSourceId,
					typeof poParams.successMessage === "string" ?
						poParams.successMessage : poParams.successMessage.apply(poTarget, [poResult, ...Array.from(poArguments)]),
					poParams.actionId as ELogActionId,
					this.buildData(poTarget, poArguments, poParams, poPerformanceManager, poResult),
					undefined,
					true
				);
			}
		}
	}

	private logError<T extends Array<any>, V>(
		poTarget: ILogSource,
		poArguments: T,
		poParams: ILogActionParams<T, V>,
		poError: any,
		poPerformanceManager: PerformanceManager
	): void {
		if (poParams.errorMessage) {
			poTarget.isvcLogger.action(
				poTarget.logSourceId,
				typeof poParams.errorMessage === "string" ?
					poParams.errorMessage : poParams.errorMessage.apply(poTarget, [poError, ...Array.from(poArguments)]),
				poParams.actionId as ELogActionId,
				this.buildData(poTarget, poArguments, poParams, poPerformanceManager),
				poError,
				true
			);
		}
	}

	//#endregion

}
