import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({ name: 'urlSanitizer' })
export class UrlSanitizerpipe implements PipeTransform {

	//#region METHODS

	constructor(private ioSanitizer: DomSanitizer) { }

	public transform(url: string): SafeUrl {
		return this.ioSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	//#endregion
}