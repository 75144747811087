<div class="di-groupe-liste-seance">
	<div class="di-groupe-liste-seance__container">
		<div class="container__content">
			<div class="di-liste-seance__container">
				<di-liste-seance 
					[visibleGroupedSeances]="groupedSeances" 
					[ordonnance]="ordonnance" 
					[patient]="patient" 
					[pagination]="true">
				</di-liste-seance>
			</div>
		</div>
	</div>