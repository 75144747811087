import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GroupsModule } from '../groups/groups.module';
import { FormSectorsList } from './components/form-sectors-list/form-sectors-list.component';
import { SectorsListComponent } from './components/sectors-list/sectors-list.component';
import { SectorsService } from './services/sectors.service';

const components: Type<any>[] = [
	SectorsListComponent,
	FormSectorsList
]
@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		GroupsModule
	],
	exports: components,
	providers: [SectorsService]
})
export class SectorsModule { }
