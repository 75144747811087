import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { EPrefix } from '../../../model/EPrefix';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';
import { IIntegrity } from '../models/IIntegrity';

@Injectable()
export class IntegrityService {

	//#region METHODS

	constructor(
		private isvcStore: Store
	) { }

	public getLastIntegrity(psStartKey: string): Observable<IIntegrity> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: psStartKey + Store.C_ANYTHING_CODE_ASCII,
				endkey: psStartKey,
				include_docs: true,
				limit: 1,
				descending: true
			}
		};

		return this.isvcStore.get<IIntegrity>(loDataSource)
			.pipe(
				map((paResults: IIntegrity[]) => ArrayHelper.getFirstElement(paResults))
			);
	}

	public getIntegrities(paIntegritiesIds: string[]): Observable<IIntegrity[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				keys: paIntegritiesIds,
				include_docs: true
			}
		};

		return this.isvcStore.get<IIntegrity>(loDataSource);
	}

	public getLastIntegritiesByEntityIds(paEntitiesIds: string[]): Observable<Map<string, IIntegrity>> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: EPrefix.integrity,
				endkey: EPrefix.integrity + Store.C_ANYTHING_CODE_ASCII
			}
		};

		return this.isvcStore.get<IStoreDocument>(loDataSource)
			.pipe(
				mergeMap((paResults: IStoreDocument[]) => {
					const laIntegritiesIds: string[] = [];

					const loIntegritiesByTraitementId: Map<string, IStoreDocument[]> = ArrayHelper.groupBy(paResults,
						(poStoreDocument: IStoreDocument) => IdHelper.extractParentId(poStoreDocument._id));
					const loIntegrityIdByTraitementId: Map<string, string> = MapHelper.map(loIntegritiesByTraitementId, (paDocuments: IStoreDocument[]) =>
						ArrayHelper.getLastElement(paDocuments)._id
					);
					const loTraitementIdsByIntegrityIds: Map<string, string[]> = MapHelper.reverseWithKeySelector(loIntegrityIdByTraitementId);

					loIntegrityIdByTraitementId.forEach((psIntegrityId: string, psTraitementId: string) => {
						if (paEntitiesIds.includes(psTraitementId))
							laIntegritiesIds.push(psIntegrityId);
					});

					return this.getIntegrities(laIntegritiesIds).pipe(map((paIntegrities: IIntegrity[]) =>
						ArrayHelper.groupByUnique(paIntegrities, (poIntegrity: IIntegrity) =>
							ArrayHelper.getFirstElement(loTraitementIdsByIntegrityIds.get(poIntegrity._id))
						)
					));
				})
			);
	}

	public saveIntegrity(poIntegrity: IIntegrity): Observable<IStoreDataResponse> {
		return this.isvcStore.put<IIntegrity>(poIntegrity);
	}

	//#endregion
}
