import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LinkInfo } from '../../model/link/LinkInfo';
import { PopoverComponent } from './popover.component';

@Component({
	selector: 'link-popover',
	templateUrl: './linkPopover.component.html'
})
export class LinkPopoverComponent extends PopoverComponent {

	//#region PROPERTIES

	/** Liste des liens disponibles dans le menu. */
	@Input() public links: LinkInfo[];
	/** Titre du menu. */
	@Input() public title: string;

	//#endregion

	//#region METHODS

	constructor(poPopoverCtrl: PopoverController) { super(poPopoverCtrl); }

	//#endregion
}