import { Component, Input, OnInit } from "@angular/core";
import { EMajorationType } from "apps/idl/src/model/EMajorationType";

@Component({
	selector: "di-majoration",
	templateUrl: "./majoration.component.html",
	styleUrls: ["./majoration.component.scss"],
})
export class MajorationComponent implements OnInit {
	constructor() {}

	public ngOnInit(): void {}

	@Input() type: EMajorationType | "" = "";
	@Input() price = "";
	@Input() indemnity = false;
	@Input() indemnityLabel = "";
	@Input() disabled :boolean = false;

	getMajorationDetails() {
		if (this.indemnity) {
			return {
				iconName: "directions_car",
				customIconeName: "",
				label: this.indemnityLabel,
			};
		} else {
			switch (this.type) {
				case EMajorationType.NightFirstHalf:
					return {
						iconName: "bedtime",
						customIconeName: "",
						label: "Nuit (20h -23h & 5h-8h)",
					};
				case EMajorationType.NightSecondHalf:
					return {
						iconName: "night_sight_max",
						customIconeName: "",
						label: "Nuit (23h - 5h)",
					};
				case EMajorationType.Mau:
					return {
						iconName: "",
						customIconeName: "mau",
						label: "Majoration acte unique",
					};
				case EMajorationType.Mci:
					return {
						iconName: "",
						customIconeName: "mci",
						label: "Majoration de coordination infirmière",
					};
				case EMajorationType.Mie:
					return {
						iconName: "",
						customIconeName: "mie",
						label: "Majoration Enfant de moins de 7 ans",
					};
				case EMajorationType.SundayAndHolyday:
					return {
						iconName: "",
						customIconeName: "md",
						label: "Dimanche / jour férié",
					};
				default:
					return {
						iconName: "",
						customIconeName: "",
						label: "",
					};
			}
		}
	}

	getIconMode() {
		return this.indemnity ? "outlined" : "filled";
	}

	getIconSize() {
		return this.getMajorationDetails().customIconeName ? "40px" : "auto";
	}
}
