import { Injectable } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { Version } from '../model/application/Version';
import { EBarElementAction } from '../model/barElement/EBarElementAction';
import { ConfigData } from '../model/config/ConfigData';
import { DynHostItem } from '../model/DynHostItem';
import { IIndexedArray } from '../model/IIndexedArray';
import { ESlideTo } from '../model/slidebox/ESlideTo';
import { IBlockedSlidesEvent } from '../model/slidebox/IBlockedSlidesEvent';
import { ISlidebox } from '../model/slidebox/ISlidebox';
import { ISlideboxData } from '../model/slidebox/ISlideboxData';
import { ISlideboxEvent } from '../model/slidebox/ISlideboxEvent';
import { ISlideboxState } from '../model/slidebox/ISlideboxState';
import { Database } from '../model/store/Database';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../model/store/EStoreEventStatus';
import { EStoreEventType } from '../model/store/EStoreEventType';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreEvent } from '../model/store/IStoreEvent';
import { EPermission } from '../modules/permissions/models/EPermission';
import { IPermission } from '../modules/permissions/models/ipermission';
import { PermissionsService } from '../modules/permissions/services/permissions.service';
import { ApplicationService } from './application.service';
import { Store } from './store.service';

@Injectable({ providedIn: 'root' })
export class SlideboxService {

	//#region FIELDS

	/** Vitesse de l'animation en milliseconde. */
	private static readonly C_ANIMATION_SPEED_MS = 500;

	/** Tableau de tous les conteneurs de slides. */
	private maSlideboxStates: Array<ISlideboxState> = [];
	/** Sujet (= observable ET observeur) permettant de récupérer les données de la slidebox. */
	private moSlideboxSubject: Subject<ISlideboxEvent> = new Subject();
	/** Sujet permettant de faire transiter les modifications des identifiants de slides bloqués. */
	private moUpdateBlockedSlideIdsSubject: Subject<IBlockedSlidesEvent> = new Subject();

	//#endregion

	//#region METHODS

	constructor(
		/** Service du Store. */
		private readonly isvcStore: Store,
		/** Service de l'application. */
		private readonly isvcApplication: ApplicationService,
		private readonly isvcPermissions: PermissionsService
	) { }

	/** Réalise le changement de slide en débloquant temporairement le swipe, en fonction du type d'action à faire (next, previous ou index précis).
	 * @param pnSlideToAction Type d'action de la slide à réaliser.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qui veut changer de slide.
	 * @param pnSlideToIndex Index de la slide vers laquelle se rendre.
	 */
	private async execSlideTo(pnSlideToAction: ESlideTo, psSlideboxComponentId: string, pnSlideToIndex?: number): Promise<boolean> {
		const loSlideboxState: ISlideboxState = this.getSlideContainer(psSlideboxComponentId);

		if (loSlideboxState) {
			const loContainer: IonSlides = loSlideboxState.container;
			const lnContainerLength: number = await loContainer.length();
			const lnActiveIndex: number = await loContainer.getActiveIndex();

			if (NumberHelper.isValid(lnActiveIndex)) {
				if (loSlideboxState.data.areSwipesLocked) // Si le swipe est bloqué, on le débloque (cas clic bouton)...
					await loContainer.lockSwipes(!loSlideboxState.data.areSwipesLocked);

				switch (pnSlideToAction) {

					case ESlideTo.next:
						await (
							lnActiveIndex !== lnContainerLength - 1 ? loContainer.slideTo(lnActiveIndex + 1) : loContainer.slideTo(0)
						);
						break;

					case ESlideTo.previous:
						await (
							lnActiveIndex !== 0 ? loContainer.slideTo(lnActiveIndex - 1) : loContainer.slideTo(lnContainerLength - 1)
						);
						break;

					case ESlideTo.indexTarget:
					default:
						await loContainer.slideTo(pnSlideToIndex, SlideboxService.C_ANIMATION_SPEED_MS);
						break;
				}

				await loContainer.lockSwipes(loSlideboxState.data.areSwipesLocked); // ... Puis on le bloque à nouveau.
				return true;
			}
			else
				return false;
		}
		else
			throw new Error(`SB.S:: Impossible de slider, conteneur de slide '${psSlideboxComponentId}' introuvable.`);
	}

	/** Récupère l'événement d'une slidebox spécifique.
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut récupérer l'événement.
	 */
	public getSlideboxData(psSlideboxComponentId: string): Observable<ISlideboxEvent> {
		const loSlideboxState: ISlideboxState = this.getSlideContainer(psSlideboxComponentId);

		if (loSlideboxState) { // Si le slidebox demandé est déjà initialisé, on peut retourner l'événement associé.
			const loEvent: ISlideboxEvent = {
				action: EBarElementAction.init,
				componentId: psSlideboxComponentId,
				params: loSlideboxState.data
			};

			return of(loEvent);
		}
		else {
			return this.moSlideboxSubject.asObservable()
				.pipe(filter((poEvent: ISlideboxEvent) => poEvent.componentId === psSlideboxComponentId));
		}
	}

	/** Va sur la slide suivante, va sur la slide du début si on était sur la dernière (cyclique).
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut changer de slide active.
	 */
	public goToNextSlide(psSlideboxComponentId: string): Promise<boolean> {
		return this.execSlideTo(ESlideTo.next, psSlideboxComponentId);
	}

	/** Va sur la slide précédente, va sur la slide fin si on était sur la première (cyclique).
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut changer de slide active.
	 */
	public goToPreviousSlide(psSlideboxComponentId: string): Promise<boolean> {
		return this.execSlideTo(ESlideTo.previous, psSlideboxComponentId);
	}

	/** Initialise les données de la slidebox en fonction d'un id et d'un tableau de mappage.
	 * @param psId Identifiant du slidebox.
	 * @param poMapStringToType Tableau de mappage entre une chaîne de caractères et le type de slidebox à créer.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qu'on veut initialiser.
	 */
	public initSlideboxData(psId: string, poMapStringToType: IIndexedArray<DynHostItem>, psSlideboxComponentId: string): Observable<ISlideboxData> {
		const loDatabase: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));

		if (loDatabase && loDatabase.id && loDatabase.isReady)
			return this.getComponentDescriptor(psId, loDatabase.id, poMapStringToType, psSlideboxComponentId);

		else {
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterComponentsDatabaseInitApplicationEvent(poEvent, loDatabase)),
					take(1),
					mergeMapTo(this.getComponentDescriptor(psId, loDatabase.id, poMapStringToType, psSlideboxComponentId))
				);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui qui vérifie que la base de données des composants a été initialisée.
	 * @param poEvent Événement d'application reçu.
	 * @param poWantedDatabase Base de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 */
	private filterComponentsDatabaseInitApplicationEvent(poEvent: IApplicationEvent, poWantedDatabase: Database): boolean {
		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
			(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init) {

			poWantedDatabase = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));
			lbIsFilterOkay = poWantedDatabase ? (poEvent as IStoreEvent).data.databaseId === poWantedDatabase.id : false;
		}

		return lbIsFilterOkay;
	}

	/** Supprime un conteneur de slides dont l'identifiant est passé en paramètres.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qu'on veut suprimer des références du service.
	 */
	public removeSlideContainer(psSlideboxComponentId: string): boolean {
		return ArrayHelper.removeElementByFinder(this.maSlideboxStates, (poItem: ISlideboxState) => poItem.componentId === psSlideboxComponentId) !== undefined;
	}

	/** Rafraîchit la slidebox en envoyant un événement.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qui est destiné à recevoir l'événement.
	 */
	public raiseRefreshEvent(psSlideboxComponentId: string): void {
		this.moSlideboxSubject.next({ componentId: psSlideboxComponentId, action: EBarElementAction.reset } as ISlideboxEvent);
	}

	/** Récupère les descripteurs des slidebox dans la base de données et à l'aide du Subscriber qui lui est passé en paramètre envoie les descripteurs aux observeurs.
	 * @param psId Id du slidebox que l'on veut initialiser.
	 * @param psDatabaseId Id de la base de données à récupérer.
	 * @param poMapStringToType Objet de correspondance entre une chaîne de caractères et la classe associée.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qu'on veut initialiser.
	 */
	private getComponentDescriptor(psId: string, psDatabaseId: string, poMapStringToType: IIndexedArray<DynHostItem>, psSlideboxComponentId: string)
		: Observable<ISlideboxData> {

		const lsPartialKey = `comp_slidebox_${psId}_v`;
		const loParams: IDataSource = {
			databaseId: psDatabaseId,
			viewParams: {
				startkey: `${lsPartialKey}${Version.zeroVersion.toFormattedString()}`,
				endkey: `${lsPartialKey}${ConfigData.appInfo.appVersion}`,
				include_docs: true
			}
		};

		return this.isvcStore.get(loParams)
			.pipe(
				catchError(poError => { console.error(`Erreur récupération base de données ${psDatabaseId} : `, poError); return throwError(poError); }),
				mergeMap((paResults: ISlideboxData[]) => {
					if (!ArrayHelper.hasElements(paResults))
						return throwError(`Erreur de récupération du descripteur : pas de document portant le nom 'comp_slidebox_${psId}_vX.YY.ZZZ'.`);

					else {
						const loSlideboxData: ISlideboxData = this.transformResultToSlideboxData(ArrayHelper.getLastElement(paResults), poMapStringToType);
						// Envoi des données du composant pour le composant.
						this.moSlideboxSubject.next({ componentId: psSlideboxComponentId, action: EBarElementAction.init, params: loSlideboxData });

						return of(loSlideboxData);
					}
				})
			);
	}

	/** Ajoute un objet-état d'une slidebox (ou remplace l'ancien si déjà présent) afin que des composants/services puissent le manipuler.
	 * @param poSlideboxState Objet-état d'une slidebox à ajouter au service pour le manipuler.
	 */
	public setSlideboxState(poSlideboxState: ISlideboxState): void {
		ArrayHelper.replaceElementByFinder(this.maSlideboxStates, (poItem: ISlideboxState) => poItem.componentId === poSlideboxState.componentId, poSlideboxState);
	}

	/** Change de slide vers celle souhaitée, ne fait rien si la slide souhaitée n'est pas trouvée.
	 * @param psId Identifiant de la slide vers laquelle aller.
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut changer de slide active.
	 */
	public slideTo(psId: string, psSlideboxComponentId: string): Promise<boolean>;
	/** Change de slide vers celle souhaitée.
	 * @param pnIndex Index de la slide vers laquelle aller.
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut changer de slide active.
	 */
	public slideTo(pnIndex: number, psSlideboxComponentId: string): Promise<boolean>;
	public async slideTo(poIdOrIndex: string | number, psSlideboxComponentId: string): Promise<boolean> {

		const loSlideboxState: ISlideboxState = this.getSlideContainer(psSlideboxComponentId);

		if (loSlideboxState) {
			const lnActiveIndex: number = await loSlideboxState.container.getActiveIndex();
			let lnSearchedIndex: number;

			if (typeof poIdOrIndex === "string") {
				const lnSearchedSlideboxIndex: number = this.searchIndexById(poIdOrIndex, psSlideboxComponentId);
				lnSearchedIndex = lnSearchedSlideboxIndex !== -1 ? lnSearchedSlideboxIndex : undefined; // Si l'index trouvée n'est pas '-1' c'est que la slide recherché existe.
			}
			else
				lnSearchedIndex = poIdOrIndex;

			if (lnSearchedIndex === lnActiveIndex) // Cas où on est déjà sur la slide vers laquelle on veut aller.
				return false;
			else {
				if (NumberHelper.isValid(lnSearchedIndex)) {
					try { return this.execSlideTo(ESlideTo.indexTarget, psSlideboxComponentId, lnSearchedIndex); }
					catch (poError) {
						const lsMessage = "SB.S:: Erreur execSlideTo() :";
						console.error(lsMessage, poError);
						throw new Error(lsMessage);
					}
				}
				else
					throw new Error(`SB.S:: index recherché '${poIdOrIndex}' invalide : index=${lnSearchedIndex}.`);
			}
		}
		else
			throw new Error(`SB.S:: Slidebox dont avec componentId = '${psSlideboxComponentId}' introuvable, impossible d'aller à l'index '${poIdOrIndex}'.`);
	}

	/** Transforme les informations reçues de la base de données vers le type souhaité, SlideboxData.
	 * @param poResult Résultat obtenu de la base de données contenant les données de la slidebox à créer.
	 * @param poMapStringToType Objet de correspondance entre une chaîne de caractères et la classe associée.
	 */
	private transformResultToSlideboxData(poResult: ISlideboxData, poMapStringToType: IIndexedArray<DynHostItem>): ISlideboxData {
		const laSlideBoxes: Array<ISlidebox> = [];

		// Pour chaque slideItem on crée la slide correspondante si le composant qu'on veut créer existe dans notre objet de mapping.
		for (let lnIndex = 0, lnLength = poResult.slideBoxes.length; lnIndex < lnLength; ++lnIndex) {
			const loItem: ISlidebox = poResult.slideBoxes[lnIndex];

			if (poMapStringToType[loItem.slideItem as string] && !loItem.hidden &&
				(!ArrayHelper.hasElements(loItem.permissions) ||
					loItem.permissions.every((poPermission: IPermission) => this.isvcPermissions.evaluatePermission(poPermission.permission as EPermission, poPermission.type)))
			) {
				const loSlidebox: ISlidebox = {
					id: loItem.id,
					title: loItem.title,
					slideItem: poMapStringToType[loItem.slideItem as string],
					index: loItem.index,
					params: poMapStringToType[loItem.slideItem as string].params ? poMapStringToType[loItem.slideItem as string].params : loItem.params
				};
				laSlideBoxes.push(loSlidebox);
			}
		}

		const loSlideboxData: ISlideboxData = {
			_id: poResult._id,
			id: poResult.id,
			defaultSlideId: poResult.defaultSlideId,
			areSwipesLocked: poResult.areSwipesLocked,
			slideBoxes: laSlideBoxes,
			pageTitle: poResult.pageTitle,
			blockedIds: poResult.blockedIds
		};

		return loSlideboxData;
	}

	/** Cherche l'index d'une slide en fonction de son identifiant, retourne `undefined` si non trouvé.
	 * @param psSlideId Identifiant de la slide dont on veut trouver son index.
	 * @param psSlideboxComponentId Identifiant du composant slidebox dont on veut récupérer l'index d'une slide spécifique.
	 */
	public searchIndexById(psSlideId: string, psSlideboxComponentId: string): number {
		const loSlideboxState: ISlideboxState = this.getSlideContainer(psSlideboxComponentId);

		if (loSlideboxState) {
			// Si une slidebox est 'hidden', son index ne sera pas en accord avec l'index du conteneur de slides affiché donc
			// on récupère les slidebox actives dans un premier temps puis on récupère l'index de la slidebox recherchée pour qu'il soit en accord.
			return loSlideboxState.data.slideBoxes
				.filter((poSlidebox: ISlidebox) => !poSlidebox.hidden) // On récupère les slidebox qui sont actives,
				.findIndex((poSlidebox: ISlidebox) => poSlidebox.id === psSlideId); // puis on récupère l'index de la slidebox recherchée.
		}
		else
			return undefined;
	}

	/** Récupère l'observable de notification de mise à jour des identifiants de slides bloqués, filtré par l'identifiant donné.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qui est destiné à recevoir la mise à jour des identifiants de slides boqués.
	 */
	public getBlockedSlideIdsUpdatedObservable(psSlideboxComponentId: string): Observable<IBlockedSlidesEvent> {
		return this.moUpdateBlockedSlideIdsSubject.asObservable()
			.pipe(filter((poEvent: IBlockedSlidesEvent) => poEvent.componentId === psSlideboxComponentId));
	}

	/** Envoie l'événement permettant de notifier que les identifiants de slides bloqués ont été mis à jour.
	 * @param paBlockedSlideIds Tableau des nouveaux identifiants de slides bloqués.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qui est destiné à recevoir l'événement.
	 */
	public raiseBlockedSlideIdsUpdated(paBlockedSlideIds: string[], psSlideboxComponentId: string): void {
		this.moUpdateBlockedSlideIdsSubject.next({ componentId: psSlideboxComponentId, blockedSlideIds: paBlockedSlideIds } as IBlockedSlidesEvent);
	}

	/** Récupère le conteneur de slide en fonction de son indentifiant, `undefined` si non trouvé.
	 * @param psSlideboxComponentId Identifiant du composant slidebox qu'on veut récupérer.
	 */
	private getSlideContainer(psSlideboxComponentId: string): ISlideboxState {
		return this.maSlideboxStates.find((poItem: ISlideboxState) => poItem.componentId === psSlideboxComponentId);
	}

	/** Récupère le dernier conteneur de slide ajouté, `undefined` si aucun n'est présent.
	 * ### ATTENTION : À éviter si possible ! Permet notamment de résoudre l'absence de connaissance du 'componentId' de la slidebox depuis les actionButtonField.
	 */
	public getLastSlideContainer(): ISlideboxState {
		return ArrayHelper.getLastElement(this.maSlideboxStates);
	}

	//#endregion
}