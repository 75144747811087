import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';

@Component({
	selector: "osapp-spinner",
	templateUrl: 'spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent extends ComponentBase {

	//#region PROPERTIES

	/** Message à afficher à l'utilisateur. */
	private msMessage = "Chargement ...";
	/** @implements */
	public get message(): string { return this.msMessage; }
	@Input() public set message(psMessage: string) {
		if (psMessage !== this.msMessage) {
			this.msMessage = psMessage;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion
}