import { NgModule } from '@angular/core';
import { ChangeTrackingService } from './services/change-tracking.service';



@NgModule({
	declarations: [],
	imports: [],
	providers: [ChangeTrackingService]
})
export class ChangeTrackingModule { }
