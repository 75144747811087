import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { takeUntil } from "rxjs/operators";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { DeviceService } from "../../../shared/services/device.service";
import { PanneauService } from "../../../shared/services/panneau.service";
import { PanneauDetailTransmissionComponent } from "../../../transmissions/components/panneau-detail-transmission/panneau-detail-transmission.component";


@Component({
	selector: "di-menu-fil-de-soins",
	templateUrl: "./menu-fil-de-soins.component.html",
	styleUrls: ["./menu-fil-de-soins.component.scss"],
})
export class MenuFilDeSoinsComponent
	extends DestroyableComponentBase
	implements OnInit
{
	@Input() patient :IPatient
	@Input() menu = [
		{
			iconName: "contract",
			buttonColor: "Blanc",
			label: "Ordonnance",
			onClick: () => this.handleOrdonnanceClick(),
		},
		{
			iconName: "team_dashboard",
			buttonColor: "Blanc",
			label: "Transmission",
			onClick: () => this.handleTransmissionClick(),
		},
		// {
		// 	iconName: "medical_information",
		// 	buttonColor: "Blanc",
		// 	label: "Surveillance",
		// 	onClick: () => this.handleSurveillanceClick(),
		// },
		// {
		// 	iconName: "add",
		// 	buttonColor: "Blanc",
		// 	label: "Actes sans ordonnance",
		// 	onClick: () => this.handleActesSansOrdonnanceClick(),
		// },
		// {
		// 	iconName: "photo_camera",
		// 	buttonColor: "Blanc",
		// 	label: "Photo",
		// 	onClick: () => this.handlePhotoClick(),
		// },
	];
	public showMenu = false;
	public isMobileView: boolean = false;
	public rightPosition: string;
	constructor(
		private svcDevice: DeviceService, 
		private el: ElementRef,
		private router : Router,
		private svcPanneau : PanneauService
		) 
		{
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobileView = flag;
				this.rightPosition = this.isMobileView ? "30px" : "20%";
			});

		this.rightPosition = this.isMobileView ? "30px" : "20%";
		this.el.nativeElement.style.setProperty(
			"--my-right-position",
			`${this.rightPosition}`
		);
	}

	handleShowMenu() {
		this.showMenu = true;
	}
	handleCloseMenu() {
		this.showMenu = false;
	}

	handleOrdonnanceClick() {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['ordonnances', 'add'],{ state: { patientId: this.patient._id }});
		});
	}

	handleTransmissionClick() {
		this.handleCloseMenu();
		this.svcPanneau.open("Patients", PanneauDetailTransmissionComponent, {patient : this.patient})
	}
	/*TODO */
	handleSurveillanceClick() {}
	/*TODO */
	handleActesSansOrdonnanceClick() {}
	/*TODO */
	handlePhotoClick() {}
}
