<div class="form-contacts-container">
	<form [formGroup]="contactForm" (ngSubmit)="handleClickSaveBtn()" class="form-contacts-form">
		<div class="form-contacts-header">
			<ng-container *ngIf="mode === 'edit'">
				<h1 class="form-contacts-name">
					{{ contact.lastName | uppercase }} {{ contact.firstName | titlecase }}
				</h1>
				<div class="form-contact-toolbar gap-10">
					<lua-bouton-toolbar iconName="call" [primary]="true" labelType="vertical" label="Appeler"
						[disabled]="!contact.phone" (onClick)="makeCallContact()"></lua-bouton-toolbar>
					<lua-bouton-toolbar (onClick)="sendMailContact()" iconName="mail" [primary]="true" labelType="vertical"
						label="Email" [disabled]="!contact.email"></lua-bouton-toolbar>
					<lua-bouton-toolbar iconName="directions_car" (onClick)="navigateToAddress()" [primary]="true"
						labelType="vertical" label="Itinéraire"
						[disabled]="!contact.street || !contact.zipCode || !contact.city"></lua-bouton-toolbar>
					<lua-bouton-toolbar iconName="sms" [primary]="true" labelType="vertical" label="Message"
						[disabled]="!contact.phone"></lua-bouton-toolbar>
				</div>
			</ng-container>
			<ng-container *ngIf="mode === 'add'">
				<h1>Nouveau Contact</h1>
			</ng-container>
		</div>
		<div class="form-contacts-body">
			<div class="gap-20 form-contacts-infos-obligatoire">
				<lua-input formControlName="lastName" label="Nom (obligatoire)"
					placeholder="Quel est le nom du contact (obligatoire) ?" [fullWidth]="true"></lua-input>
				<lua-input formControlName="firstName" label="Prénom" placeholder="Quel est le prénom du contact ?"
					[fullWidth]="true"></lua-input>
				<lua-switch (clickSwitch)="toggleIsMedecin($event)" label="Le contact est un médecin" [isChecked]="isMedecin"
					[texteSecondaire]="texteSecondaireIsMedecin"
					[disabled]="medecinTraitantCreation || prescripteurCreation"></lua-switch>
			</div>
			<div *ngIf="isMedecin" class="gap-20 form-contacts-infos-telmail">
				<div>
					<h2>Identification du médecin</h2>
					<small>Ces informations sont nécessaires pour la télétransmission auprès
						de la sécurité sociale.</small>
				</div>
				<lua-input formControlName="finess" type="text" label="N° Finess" placeholder="N° Finess"
					[fullWidth]="true"></lua-input>
				<lua-select formControlName="exerciceCondition" label="Condition d'Exercice" [options]="optsConditionExercice"
					[disabled]="false"></lua-select>
				<lua-select *ngIf="shouldShowSpecialiteSelect()" formControlName="codeSpecialite" label="Spécialité d'Exercice"
					[options]="optsSpeExercice" [disabled]="false"></lua-select>
				<lua-input formControlName="facturationNumber" label="N° RPPS ou ADELI" placeholder="N° RPPS ou ADELI"
					[fullWidth]="true"></lua-input>
			</div>
			<div class="gap-20 form-contacts-infos-telmail">
				<h2>Téléphone et email</h2>
				<lua-input formControlName="phone" label="Téléphone" (input)="onPhoneInputFormat($event)"
					placeholder="Quel est le téléphone du contact ?" [fullWidth]="true"></lua-input>
				<lua-input formControlName="email" label="Email" placeholder="Quel est l'email du contact ?"
					[fullWidth]="true"></lua-input>
			</div>
			<div class="gap-20 form-contacts-infos-adresse">
				<h2>Adresse</h2>
				<lua-input formControlName="street" label="Adresse" placeholder="Quel est l'adresse du contact ?"
					[fullWidth]="true"></lua-input>
				<lua-input formControlName="zipCode" type="text" label="Code postal"
					placeholder="Quel est le code postal du contact ?" [fullWidth]="true"></lua-input>
				<lua-input formControlName="city" label="Ville" placeholder="Quel est la ville du contact ?"
					[fullWidth]="true"></lua-input>
			</div>
			<div class="gap-20 form-contacts-infos-commentaire">
				<h2>Commentaire</h2>
				<lua-input formControlName="comment" [multiline]="true" label="Commentaire" placeholder="Commentaire"
					[fullWidth]="true"></lua-input>
			</div>
		</div>
		<div class="gap-20 form-contacts-footer">
			<lua-action-principale libelle="Enregistrer" [isDisabled]="contactForm.invalid" (click)="handleClickSaveBtn()"
				[fullWidth]="true"></lua-action-principale>
			<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="handleClickCancelBtn()"></lua-bouton-texte>
			<ng-container *ngIf="mode === 'edit' && !this.context">
				<lua-divider-horizontal size="medium"></lua-divider-horizontal>
				<div #confirmationSuppression>
					<lua-bouton-texte class="form-contacts-btn-suppr" [fullWidth]="true" color="Error" libelle="Supprimer"
						startIcon="delete" (click)="deleteContact()"></lua-bouton-texte>
				</div>
			</ng-container>
		</div>
	</form>
</div>