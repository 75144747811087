import {
	ApplicationRef,
	ComponentRef,
	Inject,
	Injectable,
	createComponent,
} from "@angular/core";

import { DOCUMENT } from "@angular/common";
import { SnackbarComponent } from "../components/snackbar/snackbar.component";

@Injectable({
	providedIn: "root",
})
export class SnackbarService {
	private sanckbarComponentRef: ComponentRef<SnackbarComponent> | null = null;

	constructor(
		private appRef: ApplicationRef,
		@Inject(DOCUMENT) private document: Document
	) {}

	showToast(
		type?: string,
		position?: string,
		message?: string,
		iconName?: string,
		buttonText?: string,
		buttonIconName?: string,
		delay?: string
	): void {
		if (!this.sanckbarComponentRef) {
			this.sanckbarComponentRef = createComponent(SnackbarComponent, {
				environmentInjector: this.appRef.injector,
			});

			type && this.sanckbarComponentRef.setInput("type", type);
			position && this.sanckbarComponentRef.setInput("position", position);
			message && this.sanckbarComponentRef.setInput("message", message);
			iconName && this.sanckbarComponentRef.setInput("iconName", iconName);
			buttonText &&
				this.sanckbarComponentRef.setInput("buttonText", buttonText);
			buttonIconName &&
				this.sanckbarComponentRef.setInput("buttonIconName", buttonIconName);
			delay && this.sanckbarComponentRef.setInput("delay", delay);

			this.appRef.attachView(this.sanckbarComponentRef.hostView);

			const domElem = this.sanckbarComponentRef.location.nativeElement;
			this.document.body.appendChild(domElem);
			if (delay) {
				setTimeout(() => {
					this.hideToast();
				}, parseInt(delay, 10));
			}
		}
	}

	hideToast(): void {
		if (this.sanckbarComponentRef) {
			this.appRef.detachView(this.sanckbarComponentRef.hostView);
			this.sanckbarComponentRef.destroy();
			this.sanckbarComponentRef = null;
		}
	}
}
