<div class="menu-detail-patient-container">
	<div>
		<lua-list-item class="list-item" iconColor="CouleurPrimaire" textColor="Noir" iconName="edit" text="Modifier"
			(click)="edit()">
		</lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" iconColor="Rouge" textColor="Noir" iconName="delete" text="Supprimer"
			(click)="delete()">
		</lua-list-item>
	</div>
</div>