import { ArrayHelper } from './arrayHelper';
import { NumberHelper } from './numberHelper';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des énumérations. */
export abstract class EnumHelper {

	//#region METHODS

	/** Récupère les clés de l'enum sous forme d'un tableau.
	 * @param poEnum Énumération dont on veut récupérer les clés.
	 */
	public static getKeys<E>(poEnum: E): string[] {
		const laKeys: string[] = Object.keys(poEnum);

		// Si le premier élément de l'énum est un nombre en chaîne de caractères, il s'agit d'une énum de 'number'.
		return this.getKeysOrValues(laKeys, NumberHelper.isStringNumber(ArrayHelper.getFirstElement(laKeys)));
	}

	/** Récupère les valeurs associées aux clés de l'enum et les retourne sous forme d'un tableau.
	 * @param poEnum Énumération dont on veut récupérer les valeurs.
	 */
	public static getValues<E, T = string | number>(poEnum: E): T[] {
		const laValues: T[] = Object.values(poEnum);

		// Si le dernier élément de l'énum est un nombre, il s'agit d'une énum de 'number'.
		return this.getKeysOrValues(laValues, NumberHelper.isValid(ArrayHelper.getLastElement(laValues)));
	}

	private static getKeysOrValues<T>(paKeysOrValues: T[], pbIsNumberEnum: boolean): T[] {
		// Dans le cas d'une énum de 'number', les clés ET les valeurs sont présentes dans le tableau.
		// La méthode appelante s'occupe de faire en sorte que les éléments recherchés soient dans la première moitié du tableau.
		if (pbIsNumberEnum)
			return paKeysOrValues.slice(paKeysOrValues.length * 0.5);
		else
			return paKeysOrValues;
	}

	/** Récupère la clé correspondant à la valeur donnée, `undefined` si non trouvée.
	 * @param poEnum Énumération parmi laquelle se trouve la clé qu'on veut récupérer.
	 * @param poValue Valeur de la clé à retrouver.
	 */
	public static getKey<E>(poEnum: any, peValue: E): string {
		return this.getKeys(poEnum).find((psKey: string) => poEnum[psKey] === peValue);
	}

	//#endregion
}