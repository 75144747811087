<div class="di-carte-evenement" (click)="openMenu($event)">
	<div class="header">
		<strong>{{ texte }}</strong>
		<strong > {{ moment }} </strong>
	</div>

	<lua-avatar
		*ngIf="infirmier"
		size="small"
		[firstName]="infirmier.firstName"
		[lastName]="infirmier.lastName"
		[color]="infirmier.avatarCouleur"
		[withTooltip]="false"
	></lua-avatar>
</div>
