import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LinkModule } from '../link/link.module';
import { DynHostDirective } from './dynHost.directive';

const modules: Array<Type<any>> = [
	CommonModule,
	IonicModule,
	LinkModule
];
const directives: Array<Type<any>> = [
	DynHostDirective
];

/** * Module de gestion des dynHost. */
@NgModule({
	imports: modules,
	exports: directives,
	declarations: directives
})
export class DynHostModule {
	constructor() { }
}