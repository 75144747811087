import { Injectable } from '@angular/core';
import { IGalleryFile } from '@osapp/model';
import { GalleryService } from '@osapp/services';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';



@Injectable({
  providedIn: 'root',
})
export class UploadFileService {

	constructor(
		private svcGallery : GalleryService,
		private svcSnackbar : SnackbarService
	) {
	}
	
	public saveFilesWithErrorHandler(files: IGalleryFile[], entityId: string): Observable<boolean> {
    return from(this.svcGallery.saveFilesANAKIN(files, entityId)).pipe(
        map(() => true),
        catchError((error) => {
							let errorMsg = "";
							if (typeof error.error === 'string') {
								try 
								{
									const parsedError = JSON.parse(error.error);
									if (parsedError && parsedError.message) {
											errorMsg = parsedError.message;
									}
								} catch (e) {
										console.error('Error parsing JSON:', e);
								}   
							}         
							this.svcSnackbar.showToast(
                "error",
                "bottom center",
                `Erreur lors de l'enregistrement du fichier. : ${errorMsg}`,
                null,
                null,
                null,
                "7000"
            );
            return throwError(error);;
        })
    );
	}
}