import { DatePipe } from '@angular/common';
import { addDays, addHours, addMilliseconds, addMinutes, addMonths, addSeconds, addWeeks, addYears, compareAsc, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarWeeks, differenceInCalendarYears, differenceInHours, differenceInMilliseconds, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInWeeks, differenceInYears, endOfDay, endOfYear, getDaysInMonth, isValid, isWithinInterval, nextMonday, parse, setDay, startOfDay, startOfHour, startOfISOWeek, startOfMinute, startOfMonth, startOfYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { range } from 'lodash';
import { EDateTimePickerMode } from '../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../model/date/ETimetablePattern';
import { EWeekDay } from '../model/date/EWeekDay';
import { IDateTimePickerParams } from '../model/date/IDateTimePickerParams';
import { IMinMaxDates } from '../modules/date/model/IMinMaxDates';
import { EUTCAccuracy } from '../modules/date/model/eutcaccuracy.enum';
import { NumberHelper } from './numberHelper';

type IDateTypes = Date | string | number;

export abstract class DateHelper {

	//#region FIELDS

	private static moHolidays = new Holidays("FR");
	private static moHolidayCache = new Map<number, Map<number, Set<number>>>();

	//#endregion

	//#region PROPERTIES

	/** Localisation pour la date : 'fr-FR'. */
	public static readonly C_LOCAL_FR = "fr-FR";

	/** Date pipe en français. */
	private static readonly C_FR_DATE_PIPE = new DatePipe(DateHelper.C_LOCAL_FR);

	/** Texte "Valider". */
	public static readonly C_DONE_TEXT: string = "Valider";
	/** Texte "Annuler". */
	public static readonly C_CANCEL_TEXT: string = "Annuler";
	/** Icône "calendar". */
	public static readonly C_DEFAULT_ICON: string = "calendar";
	/** Nombre maximum de minutes : 59. */
	public static readonly C_MAX_MINUTE = 59;
	/** Nombre maximum d'heures. */
	public static readonly C_MAX_HOURS = 23;

	//#endregion

	//#region DAYS AND MONTHS

	/** Tableau des différents jours de la semaine. */
	public static readonly C_DAY_NAMES = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	/** Tableau des différents diminutifs des jours de la semaine. */
	public static readonly C_DAY_SHORT_NAMES = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];

	/** Tableau des différents mois de l'année. */
	public static readonly C_MONTH_NAMES = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
	/** Tableau des différents diminutifs des mois de l'année. */
	public static readonly C_MONTH_SHORT_NAMES = ["janv.", "fév.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."];

	//#endregion

	//#region METHODS

	/** Permet de construire les paramètres pour un composant DateTimeSpinner.\
	 * La date minimum est la date courante - 1 an et la date maximum est la date courante + 1 an;
	 * @param peDisplayFormat Format d'affichage de la date une fois séléctionnée.
	 * @param peMode Format d'affichage de la date dans le sélécteur. Si non renseigné, `psDisplayFormat` sera utilisé.
	 * @param pnMinutesInterval Interval de minutes qu'il est possible de choisir. Par défaut `1`.
	 */
	public static datePickerParamsFactory(peDisplayFormat: ETimetablePattern, peMode: EDateTimePickerMode = EDateTimePickerMode.datetime,hasNoMaxMinAnnee?:boolean): IDateTimePickerParams {
		return {
			displayFormat: peDisplayFormat,
			max: hasNoMaxMinAnnee ? undefined : DateHelper.fillDay(DateHelper.addYears(new Date(), 1)).toISOString(),
			min: hasNoMaxMinAnnee ? undefined : DateHelper.resetDay(DateHelper.addYears(new Date(), -1)).toISOString(),
			doneText: DateHelper.C_DONE_TEXT,
			cancelText: DateHelper.C_CANCEL_TEXT,
			dayNames: DateHelper.C_DAY_NAMES,
			dayShortNames: DateHelper.C_DAY_SHORT_NAMES,
			monthNames: DateHelper.C_MONTH_NAMES,
			monthShortNames: DateHelper.C_MONTH_SHORT_NAMES,
			pickerMode: peMode
		};
	}

	/** Ajoute une quantité de jours à une date.
	 * @param poData Date initiale à partir de laquelle on ajoute des jours.
	 * @param pnDays Nombre de jours à ajouter.
	 * @returns Retourne une date avec le nombre de jours ajouté.
	 */
	public static addDays(poData: IDateTypes, pnDays: number): Date {
		return addDays(DateHelper.prepareDate(poData), pnDays);
	}

	/** Ajoute une quantité de millisecondes à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des jours.
	 * @param pnMillis Nombre de millisecondes à ajouter.
	 * @returns Retourne une date avec le nombre de millisecondes ajouté.
	 */
	public static addMilliseconds(poDate: Date, pnMillis: number): Date {
		return addMilliseconds(poDate, pnMillis);
	}

	/** Ajoute une quantité de mois à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des mois.
	 * @param pnCount Nombre de mois à ajouter.
	 * @returns Retourne une date avec le nombre de mois ajouté.
	 */
	public static addMonths(poDate: Date, pnCount: number): Date {
		return addMonths(poDate, pnCount);
	}

	/** Ajoute une quantité de secondes à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des secondes.
	 * @param pnSeconds Nombre de secondes à ajouter.
	 * @returns Retourne une date avec le nombre de secondes ajouté.
	 */
	public static addSeconds(poDate: Date, pnSeconds: number): Date {
		return addSeconds(poDate, pnSeconds);
	}

	/** Ajoute une quantité de minutes à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des minutes.
	 * @param pnMinutes Nombre de minutes à ajouter.
	 * @returns Retourne une date avec le nombre de minutes ajouté.
	 */
	public static addMinutes(poDate: Date, pnMinutes: number): Date {
		return addMinutes(poDate, pnMinutes);
	}

	/** Ajoute une quantité d'heures à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des heures.
	 * @param pnHours Nombre d'heures à ajouter.
	 * @returns Retourne une date avec le nombre d'heures ajouté.
	 */
	public static addHours(poDate: Date, pnHours: number): Date {
		return addHours(poDate, pnHours);
	}

	/** Ajoute une quantité de semaines à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des semaines.
	 * @param pnWeeks Nombre de semaines à ajouter.
	 * @returns Retourne une date avec le nombre de semaines ajouté.
	 */
	public static addWeeks(poDate: Date, pnWeeks: number): Date {
		return addWeeks(poDate, pnWeeks);
	}


	/** Ajoute une quantité d'années à une date.
	 * @param poDate Date initiale à partir de laquelle on ajoute des années.
	 * @param pnYears Nombre d'années à ajouter.
	 * @returns Retourne une date avec le nombre d'années ajouté.
	 */
	public static addYears(poDate: Date, pnYears: number): Date {
		return addYears(poDate, pnYears);
	}

	/** Compare 2 dates entre elles et retourne une valeur pour définir la différence.
	 * @param poDate1 Première date à comparer.
	 * @param poDate2 Deuxième date à comparer.
	 * @returns
	 * - poDate1 < poDate2 : `< 0`
	 * - poDate1 = poDate2 : `0`
	 * - poDate1 > poDate2 : `> 0`
	 */
	public static compareTwoDates(poDate1: IDateTypes, poDate2: IDateTypes): number {
		if (!poDate1 && !poDate2)
			return 0;
		if (!poDate1)
			return -1;
		if (!poDate2)
			return 1;

		return compareAsc(DateHelper.prepareDate(poDate1), DateHelper.prepareDate(poDate2));
	}

	/** Permet de récupérer le nombre d'années entières écoulées de différence entre 2 dates.
	 * @param poDate1
	 * @param poDate2
	 */
	public static diffYear(poDate1: IDateTypes, poDate2: IDateTypes): number {
		return differenceInYears(DateHelper.prepareDate(poDate1), DateHelper.prepareDate(poDate2));
	}

	/** Permet de récupérer le nombre d'années de différence entre 2 dates (ne prend en compte que l'année).
	 * @param poDate1
	 * @param poDate2
	 */
	public static diffCalendarYear(poDate1: IDateTypes, poDate2: IDateTypes): number {
		return differenceInCalendarYears(DateHelper.prepareDate(poDate1), DateHelper.prepareDate(poDate2));
	}

	/** Permet de récupérer le nombre de minutes de différence entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffMinutes(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInMinutes(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre de minutes de différence entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffSeconds(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInSeconds(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre de jours entre 2 dates. Ne prends pas en compte l'heure.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffDays(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInCalendarDays(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre de semaines entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffWeeks(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return Math.abs(differenceInWeeks(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2)));
	}

	private static prepareDate(pdDate: IDateTypes): Date | number {
		if (typeof pdDate === "string")
			return new Date(pdDate);

		return pdDate;
	}

	/** Permet de récupérer le nombre de semaines calendaires entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffCalendarWeeks(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return Math.abs(differenceInCalendarWeeks(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2), { weekStartsOn: 1 }));
	}

	/** Permet de récupérer la différences entre deux jours de semaine (sans prise en compte de la date, juste du jour).
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffWeekDays(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return new Date(pdDate1).getDay() - new Date(pdDate2).getDay();
	}

	/** Permet de récupérer le nombre de mois entre 2 dates (différence de mois mais pas forcément un mois complet).
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffCalendarMonths(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInCalendarMonths(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre de mois entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffMonths(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInMonths(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre de millisecondes entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffMillis(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInMilliseconds(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Permet de récupérer le nombre d'heures entre 2 dates.
	 * @param pdDate1
	 * @param pdDate2
	 */
	public static diffHours(pdDate1: IDateTypes, pdDate2: IDateTypes): number {
		return differenceInHours(DateHelper.prepareDate(pdDate1), DateHelper.prepareDate(pdDate2));
	}

	/** Compare deux dates et retourne un booléen indiquant si les deux dates sont égales à la milliseconde près.
	 * @param poDateA Date à comparer.
	 * @param poDateB Date à comparer.
	 */
	public static areMillisecondEqual(poDateA: Date, poDateB: Date): boolean {
		return poDateA instanceof Date && poDateB instanceof Date && poDateA.getTime() === poDateB.getTime();
	}

	/** Compare deux dates et retourne un booléen indiquant si les deux dates sont égales au jour près.
	 * @param poDateA Date à comparer.
	 * @param poDateB Date à comparer.
	 */
	public static areDayEqual(poDateA: Date | number | string, poDateB: Date | number | string): boolean {
		const ldDateA = new Date(poDateA);
		const ldDateB = new Date(poDateB);

		return ldDateA.getDate() === ldDateB.getDate() &&
			ldDateA.getMonth() === ldDateB.getMonth() &&
			ldDateA.getFullYear() === ldDateB.getFullYear();
	}

	/** Transforme une date en date spécifique pour url.
	 * @param pdDate Date à transformer date spécifique pour url.
	 */
	public static toDateUrl(pdDate: Date): string {
		return DateHelper.transform(pdDate, ETimetablePattern.isoFormat_hyphen);
	}

	/** Retourne `true` si le paramètre peut être transformé en date valide, `false` sinon. */
	public static isDate(poDate: string | Date | number): boolean {
		return isValid(DateHelper.prepareDate(poDate));
	}

	/** Retourne un tableau contenant toutes les valeurs possibles des minutes en fonction d'un intervalle.
	 * (0, 1, ..., 59).
	 * @example pnInterval = 5 : valeurs retournées => [0, 5, 10, ...].
	 */
	public static getMinuteValues(pnInterval: number = 1, pnMax: number = DateHelper.C_MAX_MINUTE): number[] {
		return range(0, pnMax + 1, pnInterval); // +1 pour fin inclusive
	}

	/** Retourne un tableau contenant toutes les valeurs possibles des heures.
	 * (0, 1, ..., 23).
	 */
	public static getHourValues(pnMax: number = DateHelper.C_MAX_HOURS): number[] {
		return range(0, pnMax + 1); // +1 pour fin inclusive
	}

	/** Transforme une date en chaîne de caractères selon le format définit.
	 * @param poDate Date qu'on veut transformer de type `Date`, `number` (date en millisecondes), ou `string` (date au format ISO).
	 * @param psFormat Format de la date.
	 */
	public static transform(poDate: Date | number | string, psFormat: string): string {
		return DateHelper.C_FR_DATE_PIPE.transform(poDate, psFormat);
	}

	/** Transforme une chaîne de caractères représentant une date au format `yyyyMMDDhhmm` en `Date`. */
	public static parseReverseDate(lsDate: string): Date {
		try {
			if (!NumberHelper.isStringNumber(lsDate))
				throw new Error;

			if (lsDate.length > 8)
				return new Date(`${lsDate.substring(0, 4)}-${lsDate.substring(4, 6)}-${lsDate.substring(6, 8)}T${lsDate.substring(8, 10) ?? "00"}:${lsDate.substring(10, 12) ?? "00"}Z`);
			else
				return new Date(`${lsDate.substring(0, 4)}-${lsDate.substring(4, 6)}-${lsDate.substring(6, 8)}Z`);
		} catch (error) {
			throw new Error(`Invalid reverse date format : ${lsDate}.`);
		}
	}

	/** Transforme une chaîne de caractères représentant une date en `Date`.
	 * @param lsDate Date au format string.
	 * @param pePattern Paterne de la date.
	 */
	public static parseStringDate(lsDate: string, pePattern: ETimetablePattern): Date {
		return parse(lsDate, pePattern, new Date());
	}

	/** Retourne `true` si la date testée est présente dans un intervalle donné (intervalles inclus), `false` sinon. Ne prend pas en compte les heures.
	 * @param poValue Date à tester si elles présente dans un intervalle.
	 * @param poDateA Date de début de l'intervalle.
	 * @param poDateB Date de fin de l'intervalle.
	 */
	public static isBetweenTwoDays(poValue: IDateTypes, poDateA: IDateTypes, poDateB: IDateTypes): boolean {
		if (!DateHelper.isDate(poValue) || !DateHelper.isDate(poDateA) || !DateHelper.isDate(poDateB))
			return false;

		const ldValue = new Date(poValue);
		const ldBeginInterval = new Date(poDateA);
		const ldEndInterval = new Date(poDateB);

		// Si la date de début de l'intervalle est inférieure ou égale à la date testée ET
		// que la date de fin de l'intervalle est supérieure ou égale à la date testée.
		// Alors la date testée est présente dans l'intervalle.
		return DateHelper.diffDays(ldBeginInterval, ldValue) <= 0 && DateHelper.diffDays(ldEndInterval, ldValue) >= 0;
	}

	/** Retourne `true` si la date testée est présente dans un intervalle donné (intervalles inclus), `false` sinon.
	 * @param poValue Date à tester si elles présente dans un intervalle.
	 * @param poDateA Date de début de l'intervalle.
	 * @param poDateB Date de fin de l'intervalle.
	 */
	public static isBetweenTwoDates(poValue: IDateTypes, poDateA: IDateTypes, poDateB: IDateTypes): boolean {
		const loValue: number | Date = DateHelper.prepareDate(poValue);

		if (!DateHelper.isDate(loValue))
			return false;

		const loDateA: number | Date = DateHelper.prepareDate(poDateA);
		const loDateB: number | Date = DateHelper.prepareDate(poDateB);

		try {
			return isWithinInterval(loValue, {
				start: DateHelper.isDate(loDateA) ? loDateA : loValue,
				end: DateHelper.isDate(loDateB) ? loDateB : loValue
			});
		}
		catch (poError) {
			return false;
		}
	}

	/** Réinitialise les valeurs en dessous de l'année (m, j, h, min, s, ms).
	 * @param pdDate
	 */
	public static resetYear(pdDate: IDateTypes): Date {
		return startOfYear(DateHelper.prepareDate(pdDate));
	}

	/** Retourne la date avec pour valeur 31/12 23h 59min 59s 999ms.
	 * @param pdDate
	 */
	public static fillYear(pdDate: Date): Date {
		return endOfYear(pdDate);
	}

	/** Réinitialise les valeurs en dessous du jour (h, min, s, ms).
	 * @param pdDate
	 */
	public static resetDay(pdDate: IDateTypes): Date {
		return startOfDay(DateHelper.prepareDate(pdDate));
	}

	/** Retourne la date avec pour valeur 23h 59min 59s 999ms.
	 * @param pdDate
	 */
	public static fillDay(pdDate: Date): Date {
		return endOfDay(new Date(pdDate));
	}

	/** Réinitialise les valeurs en dessous de l'heure (min, s, ms).
	 * @param pdDate
	 */
	public static resetHours(pdDate: Date): Date {
		return startOfHour(pdDate);
	}

	/** Réinitialise les valeurs en dessous de la minute (s, ms).
	 * @param pdDate
	 */
	public static resetMinutes(pdDate: Date): Date {
		return startOfMinute(pdDate);
	}

	/** Retourne la date maximale de l'ensemble passé en paramètre. */
	public static getMax(paDates: Array<string | Date>): Date {
		if (!paDates || paDates.length === 0)
			return undefined;

		return new Date(
			paDates.reduce((pdPrevious: Date, pdCurrent: Date | string) =>
				+pdPrevious > +new Date(pdCurrent) ? pdPrevious : new Date(pdCurrent), "0"
			)
		);
	}

	/** Convertit un nombre de jours en millisecondes.
	 * @param pnDays Nombre de jours à convertir en millisecondes.
	 */
	public static daysToMilliseconds(pnDays: number): number {
		return pnDays * 86400000;
	}

	/** Convertit un nombre d'heures en millisecondes.
	 * @param pnDays Nombre d'heures à convertir en millisecondes.
	 */
	public static hoursToMilliseconds(pnDays: number): number {
		return pnDays * 3600000;
	}

	/** Convertit un nombre de minutes en millisecondes.
	 * @param pnDays Nombre de minutes à convertir en millisecondes.
	 */
	public static minutesToMilliseconds(pnDays: number): number {
		return pnDays * 60000;
	}

	/** Retourne la date de début de semaine depuis une date.
	 * @param poValue Valeur de date dont il faut retrouver le début de semaine.
	 */
	public static resetWeek(poValue: IDateTypes): Date {
		return startOfISOWeek(DateHelper.prepareDate(poValue));
	}

	/** Indique si la date est un jour férié.
	 * @param poValue Valeur de date.
	 */
	public static isPublicHoliday(poValue: IDateTypes): boolean {
		const pdDate = poValue instanceof Date ? poValue : new Date(poValue);
		const loMonthHolidays: Map<number, Set<number>> = DateHelper.moHolidayCache.get(pdDate.getFullYear()) ?? DateHelper.initYearHolidays(pdDate);
		return !!(loMonthHolidays.get(pdDate.getMonth())?.has(pdDate.getDate()));
	}

	private static initYearHolidays(pdDate: Date): Map<number, Set<number>> {
		const loMonthHolidays = new Map<number, Set<number>>();

		DateHelper.moHolidays.getHolidays(pdDate).forEach((poHoliday: HolidaysTypes.Holiday) => {
			const lnMonth: number = poHoliday.start.getMonth();
			const loHolidays = loMonthHolidays.get(lnMonth) ?? new Set<number>();
			loHolidays.add(poHoliday.start.getDate());
			loMonthHolidays.set(lnMonth, loHolidays);
		});

		DateHelper.moHolidayCache.set(pdDate.getFullYear(), loMonthHolidays);

		return loMonthHolidays;
	}

	/** Retourne la date de fin de semaine depuis une date.
	 * @param poValue Valeur de date dont il faut retrouver la fin de semaine.
	 */
	public static endWeek(poValue: IDateTypes): Date {
		const ldEndWeekDate: Date = DateHelper.addDays(DateHelper.resetWeek(new Date(poValue)), EWeekDay.sunday);
		ldEndWeekDate.setHours(23, 59, 59, 999);
		return ldEndWeekDate;
	}

	/** Retourne la date du jour voulu appartenant à la semaine de la date passé en paramètre.
	 * @param poValue Valeur de date dont il faut retrouver la fin de semaine.
	 * @param peDayOfTheWeek Index du jour dans la semaine.
	 */
	public static getDateOfWeekDay(poValue: IDateTypes, peDayOfTheWeek: EWeekDay): Date {
		const ldDate: Date = DateHelper.addDays(DateHelper.resetWeek(new Date(poValue)), peDayOfTheWeek);

		return ldDate;
	}

	/** Permet de créer un tableau de dates qui va de la date passée en paramètre jusqu'à cette date + le nombre de jours passés en paramètre.
	 * @param poStartDate
	 * @param pnNumberOfDays
	 * @returns
	 */
	public static getDatesFrom(poStartDate: IDateTypes, pnNumberOfDays: number): Date[] {
		return Array.from(new Array((pnNumberOfDays < 0 ? 0 : pnNumberOfDays) + 1).keys()).map((_, pnIndex: number) => DateHelper.addDays(poStartDate, pnIndex));
	}

	/** Permet de retourner les dates max et min d'un tableau de dates.
	 * @param paDates
	 * @returns Les dates max et min dans un objet.
	 */
	public static getMinAndMaxDates(paDates: IDateTypes[]): IMinMaxDates {
		const laDates: Date[] = Array.from(paDates ?? []).map((poDate: IDateTypes) => new Date(poDate)).sort(DateHelper.compareTwoDates);

		if (laDates.length === 0)
			return {};

		return {
			min: laDates[0],
			max: laDates[laDates.length - 1]
		};
	}

	/** Retourne uniquement l'heure et les minutes d'une date, ex: `12:45`.
	 * @param poValue Valeur de date.
	 */
	public static getHoursAndMinutes(poValue: IDateTypes): string {
		return new Date(poValue).toLocaleTimeString("fr-FR", {
			hour: "2-digit",
			minute: "2-digit"
		});
	}

	/** Récupère le nombre de jours dans le mois.
	 * @param poValue
	 * @returns
	 */
	public static getMonthAmountOfDays(poValue: IDateTypes): number {
		return getDaysInMonth(this.prepareDate(poValue));
	}

	/** Récupère le nombre de jours dans le mois.
	 * @param poValue
	 * @returns
	 */
	public static getRemainingMonthAmountOfDays(poValue: IDateTypes): number {
		return getDaysInMonth(this.prepareDate(poValue));
	}

	/** Retourne la date de début de mois depuis une date.
	 * @param poValue Valeur de date dont il faut retrouver le début de mois.
	 */
	public static resetMonth(poValue: IDateTypes): Date {
		return startOfMonth(DateHelper.prepareDate(poValue));
	}

	/** Transforme une date en chaîne ISO 8601 avec infos de timezone.
	 * @param poValue
	 * @returns date au format `2014-10-25T10:46+01:00`
	 */
	public static formatIsoTimeZone(poValue: IDateTypes): string {
		const lsTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const ldZonedTime: Date = utcToZonedTime(new Date(poValue), lsTimeZone);

		return format(ldZonedTime, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: lsTimeZone });
	}

	/** Transforme une date au format UTC en prenant en compte une précision. (ex: 20211013072217047)
	 * @param poValue
	 * @param pePrecision
	 */
	public static toUTCString(poValue: IDateTypes, pePrecision: EUTCAccuracy = EUTCAccuracy.milliseconds): string {
		if (!DateHelper.isDate(poValue))
			return "";

		return new Date(poValue).toISOString().replace(/-|T|:|\.|Z/g, "").substring(0, pePrecision);
	}

	/** Tri les objets du tableau par date.
	 * @param paArray Tableau des objet à trier.
	 * @param pfGetDate Fonction qui mermet de récupérer la date dans les objets.
	 */
	public static sortByDate<T>(paArray: T[], pfGetDate?: (T: any) => any): T[] {
		return paArray.sort((poPrevious: T, poNext: T) => DateHelper.compareTwoDates(pfGetDate(poPrevious), pfGetDate(poNext)));
	}

	/** Défini le jour de la semaine.
	 * @param poDate
	 * @param pnDay
	 */
	public static setWeekDay(poDate: IDateTypes, pnDay: number): Date {
		return setDay(this.prepareDate(poDate), pnDay, { weekStartsOn: 1 });
	}

	/** Récupère le premier jour de la semaine suivante.
	 * @param poDate
	 */
	public static nextStartOfWeek(poDate: IDateTypes): Date {
		return nextMonday(this.prepareDate(poDate));
	}

	public static areDatesInSameHour(date1: Date, date2: Date): boolean {
    const diffInMs = Math.abs(date1.getTime() - date2.getTime()); // Calculer la différence en millisecondes
    const diffInMinutes = diffInMs / 60000; // Convertir en minutes
    return diffInMinutes <= 1;
}

	//#endregion
}