import { ObjectHelper } from '../../../../helpers/objectHelper';
import { NoInternetConnectionError } from '../../../network/models/errors/no-internet-connection-error';

export class NoDatabaseInternetConnectionError extends NoInternetConnectionError {

	//#region PROPERTIES

	private msDatabaseId: string;
	/** Identifiant de la base de données qu'on a voulu initialiser. */
	public get databaseId(): string { return this.msDatabaseId; }

	//#endregion

	//#region METHODS

	constructor(psDatabaseId: string, psErrorMessage: string = "Pas de connexion internet pour initialiser les bases de données.") {
		super(psErrorMessage);
		this.msDatabaseId = psDatabaseId;
		// Nécessaire pour que le 'instanceof NoDatabaseInternetConnectionError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoDatabaseInternetConnectionError);
	}

	//#endregion

}