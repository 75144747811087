<ng-container *ngIf="to.data.readOnly; else edit">
	<ng-container *ngIf="value">
		<div class="color-field-area">
			<span>Couleur</span>
			<div class="color-viewer" [style.background]="value"></div>
		</div>
	</ng-container>
</ng-container>

<ng-template #edit>
	<calao-color-picker [label]="to.label || 'Couleur'" [(color)]="value"></calao-color-picker>
</ng-template>