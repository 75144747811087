import { ObjectHelper } from '../../helpers/objectHelper';

export class InitComponentError extends Error {

	constructor(psErrorMessage: string) {
		super(psErrorMessage);
		// Nécessaire pour que le 'instanceof InitComponentError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, InitComponentError);
	}

}