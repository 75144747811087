import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ArrayHelper, DateHelper, FileHelper } from '@osapp/helpers';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { takeUntil, tap } from 'rxjs/operators';
import { FacturationService } from '../../../../../modules/facturation/facturation.service';
import { Invoice } from '../../../../../modules/facturation/models/invoice';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { DeviceService } from '../../../shared/services/device.service';


interface IFspItem {
  blob: Blob;
  name: string;
  url: string;
}


@Component({
  selector: 'di-liste-facture-ordonnance',
  templateUrl: './liste-facture-ordonnance.component.html',
  styleUrls: ['./liste-facture-ordonnance.component.scss'],
})


export class ListeFactureOrdonnanceComponent extends DestroyableComponentBase implements OnInit {

  @Input() public ordonnance?: Traitement;
  @Input() public patient?: IPatient;
  @Input() public mode?: string;

  public factures: Invoice[] = [];
  public groupedFactures: { date: string, factures: Invoice[] }[] = [];
  public isMobile: boolean = true;


  constructor(
    private router: Router,
    private svcFacturation: FacturationService,
    private svcDevice: DeviceService,
    private readonly svcFilesystem: FilesystemService,
    private readonly ioFileOpener: FileOpener,
  ) {
    super()
  }


  ngOnInit() {
    this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });
    this.loadListFacture();
  }

  private loadListFacture() {
    this.svcFacturation.getInvoicesByTraitement(this.ordonnance._id).pipe(
      tap((factures: Invoice[]) => {
        this.factures = factures.sort((a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber));
        this.manageSeances();
      })
    ).subscribe()
  }

  private manageSeances() {
    if (!ArrayHelper.hasElements(this.factures)) return;
    this.groupSeancesByDate();
  }

  private groupSeancesByDate(): void {
    const factureByDate: { [key: string]: Invoice[] } = {};
    const today = new Date();


    this.factures.forEach(facture => {
      const dateKey = this.getFormattedDate(new Date(facture.date), today);
      if (!factureByDate[dateKey]) {
        factureByDate[dateKey] = [];
      }
      factureByDate[dateKey].push(facture);
    });


    this.groupedFactures = Object.keys(factureByDate).map(date => ({
      date: date,
      factures: factureByDate[date]
    }));
  }

  public getFormattedDate(date: Date, today: Date): string {

    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

    return isToday
      ? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
      : date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }

  public goBack() {
    this.router.navigate(['/ordonnances']);
  }

  public handelOpenFacture(facture: Invoice, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.printInvoice(facture);
  }

  public handelOpenFsp(facture: Invoice) {
    this.printFsp(facture);
  }

  private async downloadPdfAsync(poInvoice: Invoice, poFsp: IFspItem): Promise<void> {
    const lsInvoicePdfName = `Facture n°${poInvoice.invoiceNumber} - ${poFsp.name}.pdf`;
    if (this.isMobile) {
      const lsPath = `Download/${lsInvoicePdfName}`;

      const lsUri = await this.svcFilesystem.createFileAsync(lsPath, poFsp.blob, Directory.ExternalStorage, true);
      await this.ioFileOpener.open(lsUri, "application/pdf");
    }
    else
      FileHelper.downloadBlob(poFsp.blob, lsInvoicePdfName);
  }

  public async printFsp(invoice: Invoice): Promise<void> {
    try {
      // Assure que la méthode attendue est asynchrone et renvoie une Map<Invoice, IFspItem[]>
      const fsps = await this.svcFacturation.displayFspAnakin([invoice]);

      // Récupére la valeur de la Map correspondant à l'Invoice, qui est un tableau
      const fspItems = fsps ? fsps.get(invoice) : null;

      if (fspItems && fspItems.length > 0) {
        // Utilise le premier élément du tableau
        const fsp: IFspItem = fspItems[0];

        // Télécharge et ouvre le PDF correspondant
        await this.downloadPdfAsync(invoice, fsp);
      } else {
        console.error("Aucune FSP trouvé pour cette facture.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération ou du traitement des FSP : ", error);
    }
  }

  async printInvoice(invoice: Invoice) {
    await this.svcFacturation.exportInvoicePdfAnakin(invoice, this.isMobile);
  }
}