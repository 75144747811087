export enum ESuffix {
	all_pending = "all_pending",
	download = "_download",
	upload = "_upload",
	delete = "_delete",
	calendars = "_calendars",
	contacts = "_contacts",
	edit = "_edit",
	workspace = "_workspace",
	view = "_view"
}