import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './termesOfSalesPage.component.html'
})
export class TermsOfSalesPageComponent implements OnInit {
  //#region FIELDS
  
  //#endregion

  //#region PROPERTIES
  
  //#endregion

  //#region METHODS
  constructor() {
  }

  public ngOnInit(): void {
  }
  //#endregion
}