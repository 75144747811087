import exifr from 'exifr';
import { EImageOrientation } from '../model/EImageOrientation';

export interface IExifData {
	Make: string;
	Model: string;
	Orientation: number;
	XResolution: number;
	YResolution: number;
	ResolutionUnit: number;
	Software: string;
	DateTime: string;
	undefined: string;
	ExifIFDPointer: number;
	ExposureTime: number;
	FNumber: number;
	ISOSpeedRatings: number[];
	DateTimeOriginal: string;
	FocalLength: number;
	ColorSpace: number;
	PixelXDimension: number;
	PixelYDimension: number;
	thumbnail: IThumbnail;
}

export interface IThumbnail {
	Compression: number;
	XResolution: number;
	YResolution: number;
	ResolutionUnit: number;
	JpegIFOffset: number;
	JpegIFByteCount: number;
	blob: Blob;
}

export abstract class ImageHelper {

	//#region METHODS

	/** Retourne le type d'orientation en fonction des données exif de l'image. */
	private static getOrientation(poImage: HTMLImageElement): Promise<EImageOrientation> {
		return exifr.orientation(poImage);
	}

	/** Indique si l'image est retournée en fonction de `peOrientation`, l'orientation obtenue en lisant les données exif de l'image. */
	public static isReverse(peOrientation: EImageOrientation): boolean {
		return peOrientation === EImageOrientation.Reverse || peOrientation === EImageOrientation.DownAndReverse ||
			peOrientation === EImageOrientation.LeftAndReverse || peOrientation === EImageOrientation.RightAndReverse;
	}

	/** Retourne la(les) classe(s) à appliquer pour que l'image soit droite en fonction de l'orientation transmise dans les données exif. */
	public static getCssOrientation(peImageOrientation: EImageOrientation): string {
		let lsClass = "";

		// Cas des rotations à -90, 90 ou 180 degres.
		switch (peImageOrientation) {
			case EImageOrientation.Left:
			case EImageOrientation.LeftAndReverse:
				lsClass = "rotate-right";
				break;
			case EImageOrientation.Right:
			case EImageOrientation.RightAndReverse:
				lsClass = "rotate-left";
				break;
			case EImageOrientation.Down:
			case EImageOrientation.DownAndReverse:
				lsClass = "rotate-down";
				break;
		}

		// Cas où l'image est retournée.
		if (ImageHelper.isReverse((peImageOrientation as EImageOrientation)))
			lsClass += " reverse";

		return lsClass;
	}


	/** Retourne la(les) classe(s) à appliquer pour que l'image soit droite en fonction de l'orientation transmise dans les données exif. */
	public static async getCssOrientationAsync(poImageOrientationOrImageElement: EImageOrientation | HTMLImageElement): Promise<string> {
		if (typeof poImageOrientationOrImageElement === "number")
			return this.getCssOrientation(poImageOrientationOrImageElement);

		return this.getCssOrientation(await this.getOrientation(poImageOrientationOrImageElement as HTMLImageElement));
	}

	//#endregion
}