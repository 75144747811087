import { ObjectHelper } from "../../../../helpers/objectHelper";

export class ChangelogsError extends Error {

	constructor() {
		super(`Impossible de créer la page : url ou titre manquant !`);
		// Nécessaire pour que le 'instanceof ChangelogsError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ChangelogsError);
	}

}