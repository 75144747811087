<div class="di-menu-fil-de-soins">
	<lua-bouton-floating iconName="add" [right]="rightPosition" bottom="30px"
		(onClick)="handleShowMenu()"></lua-bouton-floating>

	<lua-overlay *ngIf="showMenu" (onClick)="handleCloseMenu()" class="overlay_menu">
		<div class="menu-buttons-container">
			<ng-container *ngFor="let item of menu">
				<div class="button-wrapper" (click)="item.onClick(); $event.stopPropagation()">
					<lua-bouton-icone [iconName]="item.iconName"></lua-bouton-icone>
					<p class="button-label">{{ item.label }}</p>
				</div>
			</ng-container>
		</div>
	</lua-overlay>
</div>