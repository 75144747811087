import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UserData } from '../../../model/application/UserData';
import { Store } from '../../../services/store.service';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { LoggerService } from '../../logger/services/logger.service';

@Injectable()
export class ConflictsService {

	//#region FIELDS

	private static readonly C_LOG_ID = "CONFLICTS.S::";
	private readonly C_CONFLICT_LOG_ACTION_ID = "calao-conflict" as ELogActionId;

	//#endregion

	//#region METHODS

	constructor(private isvcStore: Store, private isvcLogger: LoggerService) { }

	public initConflictsLogging(): void {
		this.isvcStore.conflicts$
			.pipe(
				tap((paConflicts: string[]) =>
					this.isvcLogger.action(ConflictsService.C_LOG_ID, `Des conflits ont étés détectés par l'utilisateur ${UserData?.current?._id}`, this.C_CONFLICT_LOG_ACTION_ID, paConflicts)
				)
			)
			.subscribe();
	}

	//#endregion

}
