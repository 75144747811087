import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
/** Ce service permet d'indexer des données par clé et de s'abonner à leurs changements. */
export class GlobalDataService {

	//#region FIELDS

	private moData: Map<string, BehaviorSubject<any>> = new Map;

	//#endregion

	//#region PROPERTIES

	public readonly logSourceId = "GLOBDATA.S::";

	//#endregion

	//#region METHODS

	constructor() { }

	/** Récupère la donnée derrière la clé.
	 * @param psKey Clé de la donnée.
	 */
	public getData<T = any>(psKey: string): Observable<T> {
		const loData$: BehaviorSubject<T> = this.moData.get(psKey);
		if (!loData$) {
			this.setData(psKey, null);
			return this.getData(psKey);
		}
		return loData$.asObservable();
	}

	/** Récupère la valeur courrante pour une clé. 
	 * @param psKey 
	 */
	public getCurrentData<T = any>(psKey: string): T {
		return this.moData.get(psKey)?.value;
	}

	/** Affecte une valeur à une clé.
	 * @param psKey Clé de la donnée.
	 * @param poValue Valeur.
	 */
	public setData<T = any>(psKey: string, poValue: T): void {
		let loData$: BehaviorSubject<T> = this.moData.get(psKey);
		if (loData$) {
			if (loData$.value !== poValue)
				loData$.next(poValue);
		}
		else {
			loData$ = new BehaviorSubject(poValue);
			this.moData.set(psKey, loData$);
		}

		console.debug(`${this.logSourceId}Data for the key ${psKey} set to`, poValue);
	}

	//#endregion

}
