import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { Version } from '../../model/application/Version';
import { ConfigData } from '../../model/config/ConfigData';
import { IMenuComponentDescriptor } from '../../model/IMenuComponentDescriptor';
import { IPopoverItem } from '../../model/IPopoverItem';
import { Database } from '../../model/store/Database';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../../model/store/EStoreEventStatus';
import { EStoreEventType } from '../../model/store/EStoreEventType';
import { IDataSource } from '../../model/store/IDataSource';
import { IStoreEvent } from '../../model/store/IStoreEvent';
import { ApplicationService } from '../../services/application.service';
import { Store } from '../../services/store.service';

@Injectable({ providedIn: "root" })
export class MenuService {

	//#region FIELDS

	/** Tableau des objets personnalisés du popover. */
	private moPopoverCustomItems: Array<IPopoverItem>;

	//#endregion

	//#region METHODS

	constructor(private isvcStore: Store, private isvcApplication: ApplicationService) { }

	/** Permet de vider le popover sans supprimer les champs par défaut. */
	public clearPopover(): void {
		if (ArrayHelper.hasElements(this.moPopoverCustomItems))
			this.moPopoverCustomItems = this.moPopoverCustomItems.filter((poItem: IPopoverItem) => poItem.isPinned);
	}

	/** Récupère un menu en gardant celui ayant la version la plus récente, renvoie une erreur si aucun menu n'est trouvé.
	 * @param psMenuKey Identifiant du menu à trouver.
	 */
	public getMenu(psMenuKey: string): Observable<IMenuComponentDescriptor> {
		const loDatabase: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));

		if (loDatabase && loDatabase.id && loDatabase.isReady)
			return this.getComponentDescriptor(psMenuKey, loDatabase.id);
		else {
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterComponentsDatabaseInitApplicationEvent(poEvent, loDatabase)),
					take(1),
					mergeMapTo(this.getComponentDescriptor(psMenuKey, loDatabase.id))
				);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui qui vérifie que la base de données des composants a été initialisée.
	 * @param poEvent Événement d'application reçu.
	 * @param poWantedDatabase Base de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 */
	private filterComponentsDatabaseInitApplicationEvent(poEvent: IApplicationEvent, poWantedDatabase: Database): boolean {
		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
			(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init) {

			poWantedDatabase = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)));

			lbIsFilterOkay = poWantedDatabase ? (poEvent as IStoreEvent).data.databaseId === poWantedDatabase.id : false;
		}

		return lbIsFilterOkay;
	}

	/** Renvoi le tableau des objets personnalisés du popover. */
	public getPopoverCustomItems(): Array<IPopoverItem> {
		return this.moPopoverCustomItems;
	}

	/** Récupère le descripteur de menu dans la base de données ayant la version la plus récente, renvoie une erreur si aucun menu n'est trouvé.
	 * @param psMenuKey Clé du menu que l'on veut initialiser.
	 * @param psDatabaseId Id de la base de données à récupérer.
	 */
	private getComponentDescriptor(psMenuKey: string, psDatabaseId: string): Observable<IMenuComponentDescriptor> {
		const loParams: IDataSource = {
			databaseId: psDatabaseId,
			viewParams: { include_docs: true }
		};

		return this.isvcStore.get(loParams)
			.pipe(
				map((paResults: IMenuComponentDescriptor[]) => paResults.filter((poCompDesc: IMenuComponentDescriptor) => poCompDesc.type === psMenuKey && Version.fromDescriptorId(poCompDesc._id).compareTo(Version.fromString(ConfigData.appInfo.appVersion)) <= 0)),
				mergeMap((paMenus: IMenuComponentDescriptor[]) => {
					const loMenu: IMenuComponentDescriptor = ArrayHelper.getLastElement(paMenus);

					if (loMenu) {
						console.debug(`MNU.S::Menu component ${loMenu._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
						return of(loMenu);
					}
					else {
						const lsMessage = `No menu component descriptor matching ID ${psMenuKey}.`;
						console.error(`MNU.S:: ${lsMessage}`);
						return throwError(lsMessage);
					}
				}),
				catchError(poError => {
					console.error(`MNU.S:: Erreur récupération base de données ${psDatabaseId} : `, poError);
					return throwError(poError);
				})
			);
	}

	/** Modifie le tableau des objets personnalisés du popover.
	 * @param paLinkItems Tableau d'objets personnalisés pour le popover.
	 */
	public setCustomPopoverItem(paLinkItems: Array<IPopoverItem>): void {
		if (!this.moPopoverCustomItems)
			this.moPopoverCustomItems = paLinkItems;

		else {
			paLinkItems.forEach((poLinkItem: IPopoverItem) => {
				if (!this.moPopoverCustomItems.some((poPopoverItem: IPopoverItem) => poPopoverItem.id === poLinkItem.id))
					this.moPopoverCustomItems.unshift(poLinkItem);
			});
		}
	}

	//#endregion
}