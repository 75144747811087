import { ObjectHelper } from '../../helpers/objectHelper';

export class NoCurrentUserDataError extends Error {

	constructor() {
		super("Aucune donnée renseignée pour l'utilisateur courant.");
		// Nécessaire pour que le 'instanceof NoCurrentUserDataError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoCurrentUserDataError);
	}

}