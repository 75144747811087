import { Observable, ReplaySubject, Subject } from "rxjs";

export class RoutingAwaiter {

	//#region FIELDS

	private readonly moRoutingStartSubject = new Subject<void>();

	private moRoutingReadySubject = new ReplaySubject<boolean>(1);

	//#endregion

	//#region PROPERTIES

	public get ready$(): Observable<boolean> { return this.moRoutingReadySubject.asObservable(); }

	public get navigationStart$(): Observable<void> { return this.moRoutingStartSubject.asObservable(); }

	//#endregion

	//#region METHODS

	public continueRouting(): void {
		this.moRoutingReadySubject.next(true);
	}

	public cancelRouting(): void {
		this.moRoutingReadySubject.next(false);
		this.moRoutingReadySubject = new ReplaySubject<boolean>(1); // Permet de ne pas réutiliser la réponse négative obtenue dans le cas d'une annulation.
	}

	public sendRoutingStartEvent(): void {
		this.moRoutingStartSubject.next();
	}

	//#endregion

}