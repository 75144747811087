import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ChangelogsError } from '../models/changelogs-error';
import { IChangelogsComponentParams } from '../models/ichangelogs-component-params';

@Component({
	selector: "calao-changelogs",
	templateUrl: './changelogs.component.html',
	styleUrls: ['./changelogs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogsComponent implements IChangelogsComponentParams, OnInit {

	//#region PROPERTIES

	/** @implements */
	@Input() public url: SafeUrl;
	/** @implements */
	@Input() public hideLoader: boolean;

	//#endregion

	//#region METHODS

	constructor() { }

	public ngOnInit(): void {
		if (!this.url)
			throw new ChangelogsError();
	}

	//#endregion

}