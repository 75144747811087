import { ComponentRef, Injectable, Type } from '@angular/core';
import { PanneauDesktopComponent } from 'lighting-up-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanneauService {
  private sidePanelComponentRef: ComponentRef<PanneauDesktopComponent>;
  private closePanelSubject = new Subject<void>();

  setPanneauComponentRef(componentRef: ComponentRef<PanneauDesktopComponent>) {
    this.sidePanelComponentRef = componentRef;
    this.sidePanelComponentRef.instance.panelClosed.subscribe(() => {
			const main = document.querySelector('main');
			main.classList.remove('no-scroll');
      this.closePanelSubject.next();
    });
  }

  open(title: string, content: Type<any>, inputs: any) {
    if (this.sidePanelComponentRef) {
			const main = document.querySelector('main');
			main.classList.add('no-scroll');
      this.sidePanelComponentRef.instance.title = title
      this.sidePanelComponentRef.instance.setContent(content, inputs);
      this.sidePanelComponentRef.instance.openPanel();
    }
  }

  public setTitle(title: string): void {
    this.sidePanelComponentRef.instance.title = title
  }

  close() {
    if (this.sidePanelComponentRef?.instance?.open) {
      this.sidePanelComponentRef.instance.closePanel();
    }
  }

  get closePanel$() {
    return this.closePanelSubject.asObservable();
  }
}
