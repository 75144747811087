import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { DiagnosticsComponent } from './diagnostics.component';

@NgModule({
	imports: [CommonModule, IonicModule, FormsModule, PipeModule],
	exports: [DiagnosticsComponent],
	declarations: [DiagnosticsComponent]
})
export class DiagnosticsModule { }