<div class="panneau-patient-ordonance">
	<div>
		<div class="panneau-patient-ordonance__container">
			<div class="container__content">
				<di-recherche-patients [mobileViewForce]="true" [displayCount]="true"
					(onClickLigne)="onClickPatient($event)" [patientOrdonnance]="true" ></di-recherche-patients>
			</div>
			<lua-bouton-floating
				class="page-patients__add-button--mobile"
				iconName="add"
				right="30px"
				bottom="30px"
				(click)="navigateToCreatePatient()"
			></lua-bouton-floating>
		</div>
	</div>

</div>