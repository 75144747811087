import { Inject, Injectable, InjectionToken, Optional, Type } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../services/platform.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { EFlag } from '../../flags/models/EFlag';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { PatchBase } from '../patches/PatchBase';

export const PATCHES_CONFIG = new InjectionToken<Type<PatchBase>[]>("configForPatches");

@Injectable()
export class PatchService {

	//#region FIELDS

	private static readonly C_LOG_ID = "PATCH.S::";

	/** Tableau des types de patchs connus. */
	private readonly maClassPatches: Type<PatchBase>[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcMessage: UiMessageService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPlatform: PlatformService,
		@Inject(PATCHES_CONFIG) @Optional() paClassPatches: Type<PatchBase>[]
	) {
		this.maClassPatches = paClassPatches || [];
	}

	/** Applique tous les patchs connus du service, déterminés dans le module de l'app avec `PatchModule.forRoot([tableau des patchs])`. */
	public applyPatches(): Observable<boolean> {
		return defer(() => this.isvcPlatform.readyAsync)
			.pipe(
				mergeMap(() => this.maClassPatches),
				concatMap((poClassPatch: Type<PatchBase>) => {
					const loPatch: PatchBase = InjectorService.instance.get(poClassPatch);
					const loPerfManager = new PerformanceManager();
					loPerfManager.markStart();

					return loPatch.applyPatch()
						.pipe(
							mergeMap((pbResult: boolean) => {
								if (pbResult) {
									console.info(`${PatchService.C_LOG_ID}Patch '${loPatch.patchDescription}' effectué en ${loPerfManager.markEnd().measure()}ms!`);
									return of(pbResult);
								}
								else
									return throwError("L'application du patch a échoué");
							}),
							catchError(poError => this.onApplyPatchError(poError, loPatch))
						);
				}),
				toArray(),
				map((paResults: boolean[]) => paResults.every((pbResult: boolean) => pbResult)),
				tap(_ => this.isvcFlag.setFlagValue(EFlag.patchesDone, true))
			);
	}

	/** Log une erreur et affiche une popup pour signaler qu'une erreur est survenue lors de l'application d'un patch.
	 * @param poError Erreur survenue.
	 * @param poPatch Patch qui n'a pas pu être appliqué.
	 * @returns `Observable<false>`
	 */
	private onApplyPatchError(poError: any, poPatch: PatchBase): Observable<false> {
		console.error(`${PatchService.C_LOG_ID}Erreur application du patch '${poPatch.patchDescription}'`, poError);

		// On attend que l'utilisateur clique sur "continuer" pour continuer l'application des patchs.
		return this.isvcMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: `Erreur lors de l'application du patch :\n${poPatch.patchDescription}`,
				header: "Erreur",
				buttons: [{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }],
				backdropDismiss: false
			})
		)
			.pipe(mapTo(false));
	}

	//#endregion
}
