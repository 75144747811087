import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { map, tap } from 'rxjs/operators';
import { PanneauService } from '../../../services/panneau.service';

@Component({
  selector: 'di-panneau-choix-contact',
  templateUrl: './panneau-choix-contact.component.html',
  styleUrls: ['./panneau-choix-contact.component.scss'],
})
export class PanneauChoixContactComponent implements OnInit {

  @Input() patient?: IPatient;


  public model: IPatient;
	public showListContact : boolean;
	public contacts : IContact[] = [];
	public contactPatient : IContact;


  constructor(
    private svcPanneau: PanneauService,
    private svcPatients: PatientsService,
		private svcContacts : ContactsService,
    private router: Router) { }

		ngOnInit() {
			this.model = this.patient;
			if (this.patient.contacts?.length > 0) {
					this.initFormContact();
			} else {
				this.initListContact();
			}
		}	
	
	

	private initListContact() {
		this.showListContact = true;
		this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, false, true).pipe(
			map((contacts: IContact[]): IContact[] => {
				return contacts.map((contact: IContact) => {
					if (!contact.isDoctor && contact.finess) {
						contact.isDoctor = true;
					}
					return contact;
				});
			}),
			tap((contactsList: IContact[]) => {
				if (ArrayHelper.hasElements(contactsList))
					this.contacts = contactsList;
			})
		).subscribe();
	}

	private initFormContact () {
		this.svcContacts.getContact(ArrayHelper.getFirstElement(this.patient.contacts)).pipe(
			tap((contact: IContact) => {
				this.contactPatient = contact;
				this.showListContact = false;
			})
		).subscribe();
	}

  public udpdateContact(contactId: string) {
    this.model = {
      ...this.model,
      contacts: [contactId]
    }
    this.svcPatients.savePatientAnakin(this.model).subscribe(() => {
      this.svcPanneau.close();
    })
  }

	public udpdatePatient(){
		this.svcPanneau.close();
	}

  public handleClickAddContact() {
		this.svcPanneau.close();
		this.router.navigate(["contacts", "add"], { state: { route: ['patients', this.model._id], context: 'patient_contact', patient: this.model } });
	}

	public handleCancelContact(){
		this.svcPanneau.close();
	}

  public handleClickAddContactOnMobile() {
    this.svcPanneau.close();
  }
}
