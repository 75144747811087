export enum EStoreEventStatus {
	failed = "failed",
	successed = "successed",
	working = "working",
	preparing = "preparing",
	/** Statut pour une base de donnée qui n'est pas initialisé et qui a besoin de l'être. */
	required = "required",
	/** Statut pour une base de données qui est initialisée et a besoin d'une réplication. */
	needReplication = "needReplication",
	/** Demande l'initialisation de la réplication depuis le serveur. */
	initToLocalReplication = "initToLocalReplication"
}