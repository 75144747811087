export enum EDisplayControlType {
	/** Ne fonctionne pas avec un textarea. */
	date = "date",
	email = "email",
	number = "number",
	password = "password",
	search = "search",
	tel = "tel",
	text = "text",
	url = "url",
	birthdate = "birthdate",
	socialNumber = "socialNumber"
}