<ion-content>
	<ion-title>Demande d'autorisation</ion-title>

	<form (ngSubmit)="validate()" [formGroup]="formControl" class="unlock-marg">
		<ion-item>
			<ion-label position="floating">Prénom</ion-label>
			<ion-input [(ngModel)]="firstname" formControlName="firstname" autocapitalize="words"></ion-input>
		</ion-item>

		<ion-item>
			<ion-label position="floating">Nom</ion-label>
			<ion-input [(ngModel)]="lastname" formControlName="lastname" autocapitalize="characters">
			</ion-input>
		</ion-item>
		<ion-text [hidden]="formControl.controls.lastname.valid" color="danger">
			Vous devez indiquer votre nom.
		</ion-text>

		<ion-button color="primary" type="submit" [disabled]="!formControl.valid">Envoyer par mail</ion-button>
		<ion-button color="medium" (click)="cancel()">Annuler</ion-button>
	</form>


</ion-content>