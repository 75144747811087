import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { PatchBase } from './patches/PatchBase';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from './patches/Sqlite2ToSqliteEvcoreExtbuildFreePatch';
import { PATCHES_CONFIG, PatchService } from './services/patch.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		PatchService,
		Sqlite2ToSqliteEvcoreExtbuildFreePatch
	]
})
export class PatchModule {

	//#region METHODS

	public static forRoot(paClassPatches: Type<PatchBase>[]): ModuleWithProviders<PatchModule> {
		return {
			ngModule: PatchModule,
			providers: [
				{ provide: PATCHES_CONFIG, useValue: paClassPatches }
			]
		};
	}

	//#endregion

}