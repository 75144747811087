import { NgModule, Type } from '@angular/core';
import { LikeModule } from '../../like/like.module';
import { SharedComponentsModule } from '../../sharedComponents.module';

export { FormListComponent } from './formList.component';

const modules: Array<Type<any>> = [
	SharedComponentsModule,
	LikeModule
];

/** Module de gestion des formlist. */
@NgModule({
	imports: modules,
	exports: [],
	declarations: []
})
export class FormListModule { }