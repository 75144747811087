import { DateHelper, IdHelper } from "@osapp/helpers";
import { EPrefix, IStoreDocument } from "@osapp/model";

export class Historique implements IStoreDocument {

    //#region PROPERTIES

    /** @implements */
    public _id: string;
    /** @implements */
    public _rev?: string;

    /** @implements */
    public lastVisitedTransmission: Date;

    constructor(userId: string, siteId: string) {
        this._id = IdHelper.buildId(EPrefix.historique, `${userId}~${siteId}`);
        const newDate: Date = new Date();
        this.lastVisitedTransmission = DateHelper.addDays(newDate, -1);
    }
}