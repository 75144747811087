<form [formGroup]="ajoutSeanceForm" class="di-ajouter-seance">
	<div class="form-section">
		<h2>Quel jour ?</h2>
		<lua-input
			label="Date"
			type="date"
			[fullWidth]="true"
			formControlName="date"
		></lua-input>
	</div>
	<div class="form-section">
		<h2>A quel moment ?</h2>
		<lua-data-picker
			[options]="moments"
			[multiSelection]="false"
			[fullWidth]="true"
			formControlName="moment"
			(selectedOptionsChange)="onMomentChange()"
		></lua-data-picker>
		<lua-bouton-texte
			*ngIf="!selectionnerHeure"
			libelle="Sélectionner une heure"
			[fullWidth]="true"
			(click)="showSelectionnerHeure()"
		></lua-bouton-texte>
		<lua-input
			*ngIf="selectionnerHeure"
			formControlName="heure"
			type="time"
			placeholder="hh:mm"
			[fullWidth]="true"
			size="medium"
			(valueChange)="onHeureChange()"
			></lua-input>
	</div>
	<div class="form-section">
		<lua-input
			[fullWidth]="true"
			[multiline]="true"
			placeholder="Commentaire sur la séance"
			label="Commentaire"
			formControlName="commentaire"
		></lua-input>
	</div>
	<div class="form-footer">
		<lua-action-principale
			[fullWidth]="true"
			libelle="Enregistrer"
			[isDisabled]="!ajoutSeanceForm.valid"
			(click)="onSave()"
		></lua-action-principale>
		<lua-bouton-texte
			[fullWidth]="true"
			libelle="Annuler"
			(click)="onCancel()"
		></lua-bouton-texte>
	</div>
</form>
