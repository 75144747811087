<div class="di-acte" [ngClass]="getClasses()">
	<ng-container *ngIf="!noCheckForce">
		<lua-checkbox *ngIf="status || favoriteIcon" [checked]="status === 'done'" [disabled]="status === 'not done'"
			[iconName]="favoriteIcon && 'favorite'" (checkedChange)="handleCheckboxChange($event)"></lua-checkbox>
		<!-- <small class="cotation__item" *ngIf="!favoriteIcon ">x {{acte.taxAllowance | percent}}</small> -->
	</ng-container>
	<div class="detail-acte" (click)="onSelectActe(acte)">
		<div class="description-container">
			<p #descriptionElement class="description">{{acte.keyLetters}} {{ acte.priceCoefficient }} - {{ acte.label }}</p>
			<small *ngIf="priseEnCharge && infoSecondaire" class="info-secondaire">{{ infoSecondaire }}</small>
		</div>
		<lua-tag *ngIf="status === 'not done'" color="Noir80" mode="outlined" label="Non réalisé"></lua-tag>
		<div 
			(click)="clickablePrice && acte.taxAllowance !== 1 && showInfoAbattement($event)" 
			class="price" 
			[ngClass]="{'clickable': clickablePrice && acte.taxAllowance !== 1}"
			*ngIf="status !== 'not done'"
		>
			<p *ngIf="acte.price">{{ acte.price | priceFormat:2 }} €</p>
			<p *ngIf="!favoriteIcon && acte.price === 0">GRATUIT</p>
		</div>

	</div>
</div>