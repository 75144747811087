import { Directory } from '@capacitor/filesystem';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ICacheData } from '../../../model/store/ICacheData';
import { InjectorService } from '../../../services/injector.service';
import { PlatformService } from '../../../services/platform.service';
import { ISqlDocument } from '../models/ISqlDocument';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des données Sqlite. */
export abstract class SqlHelper {

	//#region PROPERTIES

	private static msSqliteDatabasesPath: string;
	/** Récupère le chemin vers les bases de données sqlite sur l'app mobile, chaîne vide si on n'est pas sur l'app mobile. */
	public static get mobileAppDatabasesPath(): string {
		if (this.msSqliteDatabasesPath === undefined)
			this.initDatabasesLocationData();

		return this.msSqliteDatabasesPath;
	}

	public static readonly mobileAppDatabasesDirectory = Directory.Library;

	//#endregion

	//#region METHODS

	private static initDatabasesLocationData(): void {
		const lsvcPlatform: PlatformService = InjectorService.instance.get(PlatformService);

		if (lsvcPlatform.isMobileApp) {
			if (lsvcPlatform.isAndroid)
				this.msSqliteDatabasesPath = "../databases/";
			else if (lsvcPlatform.isIOS)
				this.msSqliteDatabasesPath = "LocalDatabase/";
		}
		else
			this.msSqliteDatabasesPath = "";
	}

	/** Retourne le nom de la base de données d'où provient le document, chaîne vide si non renseigné.
	 * @param poDocument Document de données.
	 * @param pbFailIfNothing Indique si on lève une erreur dans le cas où on n'a pas de base de données dans la cacheData et pas de base de données par défaut de renseignée, 'true' par défaut.
	 */
	public static getDatabaseIdFromCacheData<T extends ISqlDocument>(poDocument: T, pbFailIfNothing: boolean = true): string {
		return StoreHelper.getDatabaseIdFromCacheData(poDocument as any, undefined, pbFailIfNothing);
	}

	/** Récupération de la cacheData d'un document, `undefined` si le document n'est pas renseigné ou s'il ne contient pas de cacheData.
	 * @param poDocument Document dont il faut récupérer la cacheData.
	 */
	public static getDocumentCacheData<T extends ISqlDocument>(poDocument: T): ICacheData {
		return StoreHelper.getDocumentCacheData(poDocument as any);
	}

	/** Vérifie si le document possède une cacheData ou non.
	 * @param poDocument Document dont il faut vérifier l'existence des cacheData.
	 */
	public static hasCacheData<T extends ISqlDocument>(poDocument: T): boolean {
		return StoreHelper.hasCacheData(poDocument as any);
	}

	/** Met à jour les cacheData du document, les crée si besoin.
	 * @param poDocument Modèle de données.
	 * @param poCacheData Données de cache à appliquer.
	 */
	public static updateDocumentCacheData<T extends ISqlDocument>(poDocument: T, poCacheData: ICacheData): void {
		StoreHelper.updateDocumentCacheData(poDocument as any, poCacheData);
	}

	/** Supprime la cacheData d'un document et retourne une copie de celle-ci.
	 * @param poDocument Document dont il faut supprimer la cacheData.
	 */
	public static deleteDocumentCacheData<T extends ISqlDocument>(poDocument: T): ICacheData {
		return StoreHelper.deleteDocumentCacheData(poDocument as any);
	}

	/** Indique si le document possède l'état `dirty`.
	 * @param poDocument Document qu'il faut vérifier.
	 */
	public static isDocumentDirty<T extends ISqlDocument>(poDocument: T): boolean {
		return StoreHelper.isDocumentDirty(poDocument as any);
	}

	/** Applique l'état `dirty` au document.
	 * @param poDocument Document qu'il faut rendre `dirty`.
	 */
	public static makeDocumentDirty<T extends ISqlDocument>(poDocument: T): void {
		StoreHelper.makeDocumentDirty(poDocument as any);
	}

	/** Enlève l'état `dirty` au document.
	 * @param poDocument Document qu'il faut rendre `not dirty`.
	 */
	public static makeDocumentNotDirty<T extends ISqlDocument>(poDocument: T): void {
		StoreHelper.makeDocumentNotDirty(poDocument as any);
	}

	/** Retourne `true` si les deux documents sont considérés comme égaux (même référence ou même identifiant).
	 * @param poDocumentA Document qu'il faut comparer avec le second.
	 * @param poDocumentB Document qu'il faut comparer avec le premier.
	 * @param psUniqueKey Clé unique permettant de déterminer si les documents sont égaux, "id" par défaut.
	 */
	public static areDocumentsEqual<T extends ISqlDocument>(poDocumentA: T, poDocumentB: T, psUniqueKey: keyof T = "id" as unknown as keyof T): boolean {
		return poDocumentA === poDocumentB || poDocumentA[psUniqueKey] === poDocumentB[psUniqueKey];
	}

	/** Retourne une chaîne de caractères assainie (que des caractères alphanumériques, '-', '_').
	 * @param psName Chaîne de caractères à assainir.
	 */
	public static sanitize(psName: string): string {
		if (StringHelper.isBlank(psName))
			return "";
		else {
			// On supprime tous les caractères qui ne sont pas alphanumériques, '-' et '_'.
			const loRegex = /[^a-zA-Z0-9_\-]*/gi;

			if (psName.endsWith(".db")) // On laisse l'extension de la base de données si elle est présente.
				return `${psName.substring(0, psName.length - 3).replace(loRegex, "")}.db`;
			else
				return psName.replace(/[^a-zA-Z0-9_\-]*/gi, "");
		}
	}

	//#endregion
}
