import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { GuidHelper } from '../../helpers/guidHelper';
import { LifeCycleObserverComponentBase } from '../../helpers/LifeCycleObserverComponentBase';
import { IContact } from '../../model/contacts/IContact';
import { IGroup } from '../../model/contacts/IGroup';
import { DynHostItem } from '../../model/DynHostItem';
import { IIndexedArray } from '../../model/IIndexedArray';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { ISlideboxData } from '../../model/slidebox/ISlideboxData';
import { SlideboxService } from '../../services/slidebox.service';
import { DynamicPageComponent } from '../dynamicPage/dynamicPage.component';
import { MenuService } from '../menu/menu.service';
import { ContactsDynHostComponent } from './contactsDynHost/contactsDynHost.component';
import { GroupsDynHostComponent } from './groupsDynHost/groupsDynHost.component';

@Component({
	selector: 'contacts-book',
	templateUrl: './contacts.component.html'
})
export class ContactsComponent extends LifeCycleObserverComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	private static readonly C_MAP_STRING_TO_DYN_HOST_ITEM: IIndexedArray<DynHostItem<ContactsDynHostComponent<IContact> | GroupsDynHostComponent<IGroup>>> = {
		"contacts": new DynHostItem(ContactsDynHostComponent),
		"groupes": new DynHostItem(GroupsDynHostComponent)
	};

	private mbIsFirstTimeRefreshSlidebox = true;

	//#endregion

	//#region PROPERTIES

	/** "contactsBook". */
	public readonly id = "contactsBook";
	public readonly componentId = GuidHelper.newGuid();

	public slideboxData: ISlideboxData;

	//#endregion

	//#region METHODS

	constructor(
		poParentPage: DynamicPageComponent<ComponentBase>,
		private isvcSlidebox: SlideboxService,
		/** Service du menu. */
		private isvcMenu: MenuService) {

		super(poParentPage);
	}

	protected onLifeCycleEvent(poValue: ILifeCycleEvent): void {
		switch (poValue.data.value) {

			case ELifeCycleEvent.viewDidEnter:
				this.onIonViewDidEnter();
				break;

			case ELifeCycleEvent.viewWillLeave:
				this.onIonViewWillLeave();
				break;
		}
	}

	public ngOnInit(): void {
		this.isvcSlidebox.initSlideboxData(this.id, ContactsComponent.C_MAP_STRING_TO_DYN_HOST_ITEM, this.componentId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(poResult: ISlideboxData) => this.slideboxData = poResult,
				poError => console.error(`Erreur init slidebox : `, poError)
			);
	}

	/** Quand on rentre dans la page on rafraîchit le slidebox. */
	public onIonViewDidEnter(): void {
		// On vérifie que le composant soit deja lancé sinon ça fera deux refresh d'affilé (1 pour le lancement de la première fois et 2 avec celui qu'on s'apprête à faire).
		if (!this.mbIsFirstTimeRefreshSlidebox)
			this.isvcSlidebox.raiseRefreshEvent(this.componentId);
		else
			this.mbIsFirstTimeRefreshSlidebox = false;
	}

	/** Quand on sort de la page on reset la toolbar pour ne plus avoir de boutons d'ajout de contact. */
	public onIonViewWillLeave(): void {
		this.isvcMenu.clearPopover();
	}

	//#endregion
}
