import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { OsappImageComponent } from './osapp-image.component';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		PipeModule
	],
	exports: [OsappImageComponent],
	declarations: [OsappImageComponent]
})
export class ImageModule { }