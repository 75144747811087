import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ContactHelper } from '../../../../helpers/contactHelper';
import { IContact } from '../../../../model/contacts/IContact';
import { EAvatarSize } from '../../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../../model/picture/IAvatar';
import { IContactItemParams } from '../../models/IContactItemParams';

@Component({
	selector: "calao-contact-item",
	templateUrl: './contact-item.component.html',
	styleUrls: ['./contact-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactItemComponent<T extends IContact> extends ComponentBase implements IContactItemParams<T> {

	//#region PROPERTIES

	private moContact: T;
	/** @implements */
	public get contact(): T { return this.moContact; }
	@Input() public set contact(poContact: T) {
		if (poContact && poContact._rev !== this.moContact?._rev) {
			this.moContact = poContact;
			this.setCompleteName();
			this.detectChanges();
		}
	}

	private msFirstName = "";
	/** @implements */
	public get firstName(): string { return this.msFirstName; }
	@Input() public set firstName(psFirstName: string) {
		if (psFirstName && !this.moContact && psFirstName !== this.msFirstName) {
			this.msFirstName = psFirstName;
			this.setCompleteName();
			this.detectChanges();
		}
	}

	private msLastName = "";
	/** @implements */
	public get lastName(): string { return this.msLastName; }
	@Input() public set lastName(psLastName: string) {
		if (psLastName && !this.moContact && psLastName !== this.msLastName) {
			this.msLastName = psLastName;
			this.setCompleteName();
			this.detectChanges();
		}
	}

	private moAvatar: IAvatar = {
		size: EAvatarSize.big,
		icon: "person-outline"
	};
	/** @implements */
	public get avatar(): IAvatar { return this.moAvatar; }
	@Input() public set avatar(poAvatar: IAvatar) {
		if (poAvatar && poAvatar !== this.moAvatar) {
			this.moAvatar = poAvatar;
			this.detectChanges();
		}
	}

	private mbIsFirstNameBefore = true;
	/** @implements */
	public get isFirstNameBefore(): boolean { return this.mbIsFirstNameBefore; }
	@Input() public set isFirstNameBefore(pbIsFirstNameBeforeLastName: boolean) {
		if (typeof pbIsFirstNameBeforeLastName === "boolean" && pbIsFirstNameBeforeLastName !== this.mbIsFirstNameBefore) {
			this.mbIsFirstNameBefore = pbIsFirstNameBeforeLastName;
			this.setCompleteName();
			this.detectChanges();
		}
	}

	private msAdditionalInfos: string;
	/** @implements */
	public get additionalInfos(): string { return this.msAdditionalInfos; }
	@Input() public set additionalInfos(psAdditionalInfos: string) {
		if (psAdditionalInfos && psAdditionalInfos !== this.msAdditionalInfos) {
			this.msAdditionalInfos = psAdditionalInfos;
			this.detectChanges();
		}
	}

	private msCompleteName: string;
	/** Nom complet du contact : `Prénom NOM`. */
	public get completeName(): string { return this.msCompleteName; }

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Affecte le nom complet du contact. */
	private setCompleteName(): void {
		if (this.moContact)
			this.msCompleteName = ContactHelper.getCompleteFormattedName(this.moContact, this.mbIsFirstNameBefore);
		else
			this.msCompleteName = ContactHelper.getCompleteFormattedName(this.msFirstName, this.msLastName, this.mbIsFirstNameBefore);
	}

	//#endregion

}