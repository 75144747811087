import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArrayHelper, FileHelper } from '@osapp/helpers';
import { ModalService } from '@osapp/modules/modal/services/modal.service';
import { OsappApiHelper } from '@osapp/modules/osapp-api/helpers/osapp-api.helper';
import { LoadingService, PlatformService, ShowMessageParamsPopup, UiMessageService, WorkspaceService } from '@osapp/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigData, EDatabaseRole, EPrefix, IDataSource, IStoreDocument } from '../../../../../../../libs/osapp/src/model';
import { Store } from '../../../../../../../libs/osapp/src/services/store.service';
import { Traitement } from '../../../../model/Traitement';
import { TraitementService } from '../../../../services/traitement.service';
import { IAccordPrealable } from '../../../patients/model/IAccordPrealable';
import { AccordPrealableListModalComponent } from './accordPrealable-pdf-modal/accordPrealable-list-modal.component';
import { Directory } from '@capacitor/filesystem';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';



@Injectable()
export class AccordPrealableService {

	constructor(private isvcStore: Store,
		private isvcWorkspace: WorkspaceService,
		private isvcTraitement: TraitementService,
		private isvcUiMessage: UiMessageService,
		private readonly ioHttp: HttpClient,
		private isvcLoading: LoadingService,
		private readonly isvcModal: ModalService,
		private svcFilesystem: FilesystemService,
		private svcPlatform: PlatformService,
		private readonly ioFileOpener: FileOpener
	) { }

	/** Creation ou update du document d'accord préalable
	 * @param accord Le document d'accord à creer.
	 */
	public createOrUpdate(accord: IAccordPrealable) {
		return this.isvcStore.put(accord);
	}

	/** Get du document d'accord préalable
	 * @param traitementId traitement Id en lien avec l'accord.
	 */
	public get(traitementId: String): Observable<IAccordPrealable> {
		const databaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		const loDataSource: IDataSource = {
			databaseId: databaseId,
			viewParams: {
				include_docs: true,
				keys: [`${EPrefix.accordPrealable}${traitementId}`]
			}
		};

		return this.isvcStore.getOne(loDataSource).pipe(
			map((doc: IStoreDocument) => {
				return doc as IAccordPrealable;
			}),
			catchError((error: any) => {
				return throwError(error);
			})
		);
	}

	/** Delete du document d'accord préalable
	 * @param accord Le document d'accord à supprimer.
	 */
	public delete(accord: IAccordPrealable) {
		return this.isvcStore.delete(accord);

	}


	async displayAp(accord: IAccordPrealable): Promise<void> {
		const loApList = new Map<IAccordPrealable, { blob: Blob; name: string; url: string; }[]>();
		let lbHasFsp: boolean = true;

		const loLoader = await this.isvcLoading.create({ message: 'Génération du PDF en cours...' });
		await loLoader.present();

		const laInvoiceFsp: string[] = await this.getInvoiceApPdf(accord);
		const laBlobs: Blob[] = laInvoiceFsp.map((psBase64: string) => FileHelper.base64toBlob(psBase64, 'application/pdf'));

		laBlobs.forEach((poBlob: Blob, pnIndex: number) => {
			const ApPdf = {
				blob: poBlob,
				name: `Accord Préalable n°${pnIndex + 1}`,
				url: window.URL.createObjectURL(poBlob)
			};

			if (loApList.has(accord))
				loApList.get(accord).push(ApPdf);
			else
				loApList.set(accord, [ApPdf]);
		});

		if (!lbHasFsp)
			lbHasFsp = laBlobs.length > 0;

		await loLoader.dismiss();

		if (lbHasFsp) {
			await this.isvcModal.open<boolean>({
				component: AccordPrealableListModalComponent,
				componentProps: {
					ApList: loApList
				}
			}).toPromise();
		}
	}

	async downloadApPdf(accord: IAccordPrealable): Promise<void> {
		const pdfFileName = "accord-prealable.pdf"
		const invoiceFsp: string[] = await this.getInvoiceApPdfAnakin(accord);
		const blobs: Blob[] = invoiceFsp.map((psBase64: string) => FileHelper.base64toBlob(psBase64, 'application/pdf'));
		if (this.svcPlatform.isMobileApp) {
			const path = `Download/${pdfFileName}`;
			const uri = await this.svcFilesystem.createFileAsync(path, blobs[0], Directory.ExternalStorage, true);
			await this.ioFileOpener.open(uri, "application/pdf");
		} else {
			FileHelper.downloadBlob(blobs[0], pdfFileName);
		}
	}

	private getBaseUrl(): string {
	return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds())}`;
}

	private getHttpOptions(): { headers: HttpHeaders, observe: "response" } {
	return {
		headers: new HttpHeaders({
			appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
			token: ConfigData.authentication.token,
			"api-key": ConfigData.environment.API_KEY,
		}),
		observe: "response"
	};
}


	public async getInvoiceApPdf(accord: IAccordPrealable): Promise < string[] > {
	let traitement: Traitement;
	await this.isvcTraitement.getTraitement(accord.traitement).toPromise()
		.then((traitementResultat: Traitement) => {
			traitement = traitementResultat;
			console.log('Traitement récupéré:', traitementResultat);
		})
		.catch(error => {
			console.error('Erreur lors de la récupération du traitement :', error);
			throw error;
		});
	const url = `${this.getBaseUrl()}/entities/patients/${traitement.patientId}/accordPrealable/${accord._id}/pdf`;

	try {
		const response: HttpResponse<string[]> = await this.ioHttp.post<string[]>(url, { id: accord._id, rev: accord._rev }, this.getHttpOptions()).toPromise();
		return response.body;
	} catch(poError) {
		console.error(poError);
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Une erreur inconnue est survenue" }));
		return [];
	}
}

public async getInvoiceApPdfAnakin(accord: IAccordPrealable): Promise < string[] > {
	let traitement: Traitement;
	await this.isvcTraitement.getTraitement(accord.traitement).toPromise()
		.then((traitementResultat: Traitement) => {
			traitement = traitementResultat;
		})
		.catch(error => {
			console.error('Erreur lors de la récupération du traitement :', error);
			throw error;
		});
	const url = `${this.getBaseUrl()}/entities/patients/${traitement.patientId}/accordPrealable/${accord._id}/pdf`;
		const response: HttpResponse<string[]> = await this.ioHttp.post<string[]>(url, { id: accord._id, rev: accord._rev }, this.getHttpOptions()).toPromise();
		return response.body;
	
}
}
