import {
	Component,
	ElementRef,
	Input,
	ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, forkJoin, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ITraitement } from "../../../../../model/ITraitement";
import { TraitementService } from "../../../../../services/traitement.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { ConfirmationSuppressionComponent } from "../../../shared/components/confirmation-suppression/confirmation-suppression.component";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { SeanceService } from "../../../shared/services/seance.service";
import { InterruptionOrdonnanceComponent } from "../interruption-ordonnance/interruption-ordonnance.component";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { SnackbarService } from "../../../shared/services/snackbar.service";
import { Traitement } from "apps/idl/src/model/Traitement";

@Component({
	selector: "di-menu-ordonnance",
	templateUrl: "./menu-ordonnance.component.html",
	styleUrls: ["./menu-ordonnance.component.scss"],
})
export class MenuOrdonnanceComponent{
	@ViewChild("confirmationSuppression", { static: false })
	confirmationSuppression!: ElementRef<HTMLDivElement>;

	@Input() ordonnance: Traitement;
	@Input() anchorElement?: any;
	@Input() edit?: boolean = true;
	@Input() hasSeanceFacturee: boolean = false 

	public showPopover: boolean = false;

	constructor(
		private svcTraitement: TraitementService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService,
		private router: Router,
		private svcSnackbar: SnackbarService
	) { }

	handleConfirmSuppression(ordonnance: Traitement) {
		this.svcDrawerPopover.close();

		// On regarde si l'ordonnance a des séances facturées avant de supprimer
		this.svcSeance.selectSeancesByTraitement(ordonnance).pipe(
			map((seances: StoredSeance[]) => seances.some((seance: StoredSeance) => seance.status === EStatusSeance.completed)),
			mergeMap((hasCompletedSeances: boolean) => {
				if (!hasCompletedSeances) {
					return this.svcTraitement.deleteTraitement(ordonnance, true).pipe(
						mergeMap(() => this.svcSeance.selectSeancesByTraitement(this.ordonnance)),
						mergeMap((listSeance: StoredSeance[]) => {
							if (listSeance.length === 0) {
								return of(null);
							}
							const deleteSeanceObservables = listSeance.map(seance => this.svcSeance.deleteSeance(seance));
							return forkJoin(deleteSeanceObservables);
						})
					)
				} else {
					// On ne supprime pas l'ordonnance, donc la liste des ordonnances n'a pas besoin d'être actualisée
					this.svcSnackbar.showToast(
						"error",
						"bottom center",
						"L'ordonnance ne peut pas être supprimée car des séances ont déjà été facturées",
						null,
						null,
						null,
						"7000"
					);
					return EMPTY;
				}
			})
		).subscribe({
			next: () => {
				// Emit pour que la liste des ordonnances soit actualisée
				this.svcTraitement.emitDeleteTraitement();
			},
			error: (error) => {
				console.error('Erreur lors de la suppression :', error);
			}
		})
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	//to-do : renouvellemnt d'ordonnance
	handleRenouvelerClick() { }

	handleFacturationClick(event: Event) {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["facturation/controle", this.ordonnance._id])
		});
	}

	//to-do : Interrrompre d'ordonnance
	handleConfirmInterruption(ordonnance: ITraitement) { }

	handleInterrompreClick(event: MouseEvent) {
		// TODO : décommenter quand l'interruption d'ordo sera développée

		// this.showPopover = true;
		// this.svcDrawerPopover.open(
		// 	"",
		// 	"50%",
		// 	this.anchorElement?.nativeElement,
		// 	InterruptionOrdonnanceComponent,
		// 	{
		// 		title: "Interruption de l'ordonnance",
		// 		description:
		// 			"Les soins à venir vont être supprimés, les soins passés seront conservés.",
		// 		labelActionPrincipale: "Interrompre l'ordonnance",
		// 		withIcon: true,
		// 		onConfirm: () => this.handleConfirmInterruption(this.ordonnance),
		// 		onCancel: () => this.closeMenu(),
		// 	},
		// 	() => (this.showPopover = false)
		// );
	}

	handleSupprimerClick(event: MouseEvent) {
		// On ne peut pas supprimer d'ordonnance qui ont au moins une séance facturée
		if (this.hasSeanceFacturee){
			return;
		}
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"Confirmation de suppression",
			"50%",
			this.anchorElement?.nativeElement,
			ConfirmationSuppressionComponent,
			{
				onConfirm: () => this.handleConfirmSuppression(this.ordonnance),
				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false)
		);
	}

	handleEditClick(event: MouseEvent) {
		this.svcDrawerPopover.close();
		this.router.navigate(["ordonnances", "edit", this.ordonnance._id]);
	}
}
