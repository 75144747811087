export abstract class SocialNumberHelper {

	//#region METHODS

	/** Formate le numéro de sécurité sociale avec les espaces.
	 * @param psValue
	 */
	public static format(psValue: string): string {
		const laSpaceIndex: number[] = [1, 3, 5, 7, 10, 13];
		return (psValue ?? "").split("").map((psChar: string, pnIndex: number) => laSpaceIndex.includes(pnIndex) ? ` ${psChar}` : psChar).join("");
	}

	//#endregion

}