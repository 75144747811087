import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SearchModule } from '../search/search.module';
import { SelectorComponent } from './selector.component';

const exportComponents: Array<Type<any>> = [
	SelectorComponent
];

/** @deprecated */
@NgModule({
	declarations: exportComponents,
	exports: exportComponents,
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		SearchModule
	]
})
export class SelectorModule { }
