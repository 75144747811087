import { Component, Input, OnInit } from "@angular/core";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { EMoments } from "../../enums/EMoments";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { IStoreDataResponse } from "@osapp/model";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { HoursMinutesRepetition } from "@osapp/modules/event-markers/models/hours-minutes-repetition";
import { ArrayHelper, DateHelper } from "@osapp/helpers";
import { SnackbarService } from "../../services/snackbar.service";
import { SeanceService } from "../../services/seance.service";
import { DayRepetition } from "@osapp/modules/event-markers/models/day-repetition";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { EEtatActe } from "../../enums/EEtatActe";
import { Acte } from "apps/idl/src/model/Acte";
import { moments } from "apps/idl/src/anakin/shared/moments.constants";

@Component({
	selector: "di-ajouter-seance",
	templateUrl: "./ajouter-seance.component.html",
	styleUrls: ["./ajouter-seance.component.scss"],
})
export class AjouterSeanceComponent implements OnInit {
	
	@Input() seance: StoredSeance;
	@Input() close: () => void = () => { };

	public moments = moments;
	public selectionnerHeure: boolean = false;
	public ajoutSeanceForm: FormGroup;
	public validForm: boolean = false;

	constructor(
		private fb: FormBuilder,
		private svcSeance: SeanceService,
		private svcSnackbar: SnackbarService
	) { }

	momentOrHeureValidator(): ValidatorFn {
		return (formGroup: FormGroup) => {
			const moment = formGroup.get('moment')?.value;
			const heure = formGroup.get('heure')?.value;
			const isMomentValid = Array.isArray(moment) && moment.length > 0;
			const isHeureValid = !!heure;
			return isMomentValid || isHeureValid ? null : { momentOrHeureRequired: true };
		};
	}

	ngOnInit(): void {
		this.ajoutSeanceForm = this.fb.group({
			date: [DateHelper.toDateUrl(this.seance.startDate), Validators.required],
			moment: [[]],
			commentaire: [""],
		},
			{ validators: this.momentOrHeureValidator() }
		);
	}

	showSelectionnerHeure() {
		this.selectionnerHeure = true;
		this.ajoutSeanceForm.addControl("heure", new FormControl(""));
	}

	onHeureChange() {
		this.ajoutSeanceForm.get("moment")?.setValue("");
	}

	onMomentChange() {
		this.ajoutSeanceForm.get("heure")?.setValue("");
	}

	public onSave(): void {
		const formValues = this.ajoutSeanceForm.value;
		const seanceToSave = this.generateSeance(formValues);
		this.svcSeance.createSeance(seanceToSave).subscribe(
			(result: IStoreDataResponse) => {
				this.close();
				this.showSaveResultToast(result.ok);
			},
			(error) => this.handleSaveError(error)
		);
	}

	private generateSeance(formValues: any): StoredSeance {
		const { moment: formMomentValues, heure: formHeureValue, date: formDateValue, commentaire: formCommentaireValue } = formValues;
		const { moment, startDate } = this.getMomentAndStartDate(formMomentValues, formHeureValue, formDateValue);
		const actes = this.seance.actes.map((acte: Acte) => {
			let newActe = new Acte(acte);
			newActe.etat = EEtatActe.to_be_done;
			return newActe;	
		});
		const seanceToSave = new StoredSeance(actes, 0, startDate, moment);
		seanceToSave.status = EStatusSeance.to_be_done;
		seanceToSave.isManuel = true;
		seanceToSave.commentaireManuel = formCommentaireValue;
		seanceToSave.traitementId = this.seance.traitementId;
		seanceToSave.traitementRev = this.seance.traitementRev;
		seanceToSave.patientId = this.seance.patientId;
		seanceToSave.mePlace = this.seance.mePlace;
		return seanceToSave;
	}

	private getMomentAndStartDate(formMomentValues: EMoments[], formHeureValue: string, formDateValue: Date): { moment: DayRepetition, startDate: Date } {
		if (formMomentValues) {
			const formMoment: EMoments = ArrayHelper.getFirstElement(formMomentValues);
			const moment = Recurrence.getRepetition(formMoment);
			const startDate = StoredSeance.getStartDateFromMoment(formMoment, formDateValue);
			return { moment, startDate };
		} else if (formHeureValue) {
			const hoursMinutesMoment: HoursMinutesRepetition = this.createHoursMinutesMoment(formHeureValue);
			const startDate = StoredSeance.getStartDateFromMoment(hoursMinutesMoment, formDateValue);
			return { moment: hoursMinutesMoment, startDate };
		}
		return { moment: null, startDate: new Date() };
	}


	private createHoursMinutesMoment(heure: string): HoursMinutesRepetition {
		const [hours, minutes] = heure.split(":");
		return {
			type: "hours-minutes",
			hours: parseInt(hours, 10),
			minutes: parseInt(minutes, 10)
		};
	}

	private showSaveResultToast(success: boolean) {
		const message = success ? "La séance a bien été ajoutée." : "La séance n'a pas pu être ajoutée.";
		const toastType = success ? "success" : "error";
		this.svcSnackbar.showToast(toastType, "bottom center", message, null, null, null, "4000");
	}

	private handleSaveError(error: any) {
		this.svcSnackbar.showToast("error", "bottom center", "Une erreur s'est produite lors de l'ajout de la séance.", null, null, null, "4000");
	}

	onCancel() {
		this.close();
	}
}
