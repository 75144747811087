import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { ConfigData } from '../../../../model/config/ConfigData';
import { PlatformService } from '../../../../services/platform.service';
import { AuthenticatedRequestOptionBuilder } from '../../../api/models/authenticated-request-option-builder';

@Injectable()
export class DevicesSecurityService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DEVICE.SEC.S::";

	//#endregion

	//#region PROPERTIES



	//#endregion

	//#region METHODS

	constructor(private readonly isvcPlatform: PlatformService, private readonly ioHttpClient: HttpClient) { }

	/** Fait une requête sur le serveur pour demander d'autoriser cet appareil.
	 * @param psFirstname Prénom de la personne qui fait une demande de déblocage.
	 * @param psLastname Nom de la personne qui fait une demande de déblocage.
	 */
	public enrollDevice(psFirstname: string, psLastname: string): Observable<void> {
		return this.ioHttpClient.put(
			`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}/security/enrollments/devices/${ConfigData.appInfo.deviceId}`,
			{
				firstName: psFirstname,
				lastName: psLastname,
				widgetId: this.isvcPlatform.widgetId,
				appName: ConfigData.appInfo.appName,
				appVersion: ConfigData.appInfo.appVersion,
				platform: this.isvcPlatform.platform,
				model: this.isvcPlatform.model,
				date: new Date().toISOString()
			},
			AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
		).pipe(
			tap(
				poResult => console.debug(`${DevicesSecurityService.C_LOG_ID}Demande d'autorisation device ${ConfigData.appInfo.deviceId} envoyée :`, poResult),
				poError => console.error(`${DevicesSecurityService.C_LOG_ID}Demande d'autorisation device ${ConfigData.appInfo.deviceId} échouée :`, poError)
			),
			mapTo(undefined)
		);
	}

	//#endregion

}
