<form
	*ngIf="eventForm"
	[formGroup]="eventForm"
	(ngSubmit)="saveEvenement()"
	class="di-evenement-form"
>
	<div class="description-container">
		<lua-input
			formControlName="description"
			[multiline]="true"
			label="Description (obligatoire)"
			placeholder="Quel est l'évènement ?"
			[fullWidth]="true"
		></lua-input>
		<lua-input
			formControlName="date"
			label="Date"
			type="date"
			[fullWidth]="true"
		></lua-input>
	</div>
	<div class="moment-container">
		<h2>A quel moment?</h2>
		<lua-data-picker
			[options]="momentOptions"
			[fullWidth]="true"
			[selectedOptions]="selectedMoment"
			[resetSelection]="!!eventForm.get('time')?.value"
			(selectedOptionsChange)="onMomentChange($event)"
		></lua-data-picker>
		<lua-bouton-texte
			*ngIf="!showTimeInput"
			libelle="Sélectionner une heure"
			[fullWidth]="true"
			(click)="showTimeSelection()"
		></lua-bouton-texte>
		<lua-input
			formControlName="time"
			*ngIf="showTimeInput"
			type="time"
			placeholder="hh:mm"
			[fullWidth]="true"
			size="medium"
		></lua-input>
	</div>

	<div class="buttons-container">
		<lua-action-principale
			[fullWidth]="true"
			libelle="Enregistrer l’évènement"
			[isDisabled]="eventForm.invalid ||( selectedMoment?.length===0 && !eventForm.get('time')?.value )"
			(click)="saveEvenement()"
		></lua-action-principale>
		<lua-bouton-texte
			[fullWidth]="true"
			libelle="Annuler"
			(click)="cancelEvenement()"
		></lua-bouton-texte>
	</div>
</form>
