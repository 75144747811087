import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FilePickerComponent } from './filePicker.component';

const components: Type<any>[] = [
	FilePickerComponent
];

@NgModule({
	imports: [IonicModule, CommonModule],
	exports: components,
	declarations: components
})
export class FilePickerModule { }