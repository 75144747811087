import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "timetable" })
export class TimetablePipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme une valeur de date en date avec horaires formatée.
	 * @param poFirstValue Première partie à transformer.
	 * @param psFirstPattern Pattern de date/horaire pour le rendu.
	 * @param poSecondValue Seconde partie à transformer.
	 * @param psSecondPattern Pattern de date/horaire pour le rendu.
	 * @param psLocale Localisation pour la date (anglais, française, ...), française par défaut.
	 */
	public transform(poFirstValue: Date | number | string, psFirstPattern: string, poSecondValue: Date | number | string, psSecondPattern: string,
		psLocale: string = "fr-FR"): string {

		const loDatePipe = new DatePipe(psLocale);

		// On transforme la date en date formatée et on remplace ':' par 'h' pour la lisibilité des heures s'il y en a.
		return `${loDatePipe.transform(poFirstValue, psFirstPattern).replace(':', 'h')}-${loDatePipe.transform(poSecondValue, psSecondPattern).replace(':', 'h')}`;
	}

	//#endregion
}