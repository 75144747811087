<div class="container" *ngIf="databaseSyncStatus">
	<div class="database-info">
		<p class="database-name">{{ databaseSyncStatus.title }}</p>
		<p *ngIf="databaseSyncStatus.description" class="detail">({{ databaseSyncStatus.description }})</p>
	</div>
	<ion-badge color="primary">
		<ng-container *ngIf="iconName !== 'sync-loading-circle'; else animatedIcon"></ng-container>
		<ion-icon [name]="iconName">
		</ion-icon>

		<ng-template #animatedIcon>
			<div class="icon-synchro">
				<calao-database-sync-animated-icon width="50%" height="47%"></calao-database-sync-animated-icon>
			</div>
		</ng-template>
	</ion-badge>
</div>