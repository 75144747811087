import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SpinnerComponent } from './spinner.component';

/** Module de gestion du spinner. */
@NgModule({
	imports: [CommonModule, IonicModule],
	exports: [SpinnerComponent],
	declarations: [SpinnerComponent]
})
export class SpinnerModule {
	constructor() { }
}