import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IdHelper } from '../../../helpers/idHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { EPrefix } from '../../../model/EPrefix';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { GroupsService } from '../../../services/groups.service';
import { C_SECTORS_ROLE_ID } from '../../permissions/services/permissions.service';
import { ISector } from '../models/isector';

@Injectable()
export class SectorsService {

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly ioRouter: Router,
	) { }

	/** Retourne les secteurs d'un utilisateur.
	 * @param psUserContactId 
	 * @param pbLive 
	 */
	public getUserSectors(psUserContactId: string = UserHelper.getUserContactId(), pbLive?: boolean): Observable<ISector[]> {
		return this.isvcGroups.getUserGroups(psUserContactId, pbLive).pipe(
			map((paGroups: IGroup[]) => paGroups.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID)))
		);
	}

	/** Retourne les secteurs d'un tableau de contacts.
	 * @param paContacts 
	 * @param pbLive 
	 */
	public getContactsSectors(paContacts: IContact[], pbLive?: boolean): Observable<Map<string, ISector[]>> {
		return this.isvcGroups.getContactsGroups(paContacts, pbLive).pipe(tap((poSectorsByContactsIds: Map<string, ISector[]>) =>
			poSectorsByContactsIds.forEach((paGroups: IGroup[]) => paGroups.filter((poGroup: IGroup) => this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID)))
		));
	}

	/** Retourne tous les secteurs.
	 * @param pbLive 
	 */
	public getSectors(pbLive?: boolean): Observable<ISector[]> {
		return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID], pbLive);
	}

	/** Sauvegarde un secteur.
	 * @param poSector 
	 */
	public saveSector(poSector: ISector): Observable<boolean> {
		return this.isvcGroups.updateGroup(poSector);
	}

	/** Supprime sector.
	 * @param poSector 
	 */
	public deleteSector(poSector: ISector): Observable<boolean> {
		return this.isvcGroups.deleteGroup(poSector);
	}

	/** Route vers la création d'un secteur. */
	public routeToSectorCreation(psSiteId?: string): Promise<boolean> {
		return this.ioRouter.navigate(["sectors", ERouteUrlPart.new], {
			state: {
				_id: IdHelper.buildId(EPrefix.group),
				roles: [C_SECTORS_ROLE_ID],
				name: "Secteur",
				siteId: psSiteId
			}
		});
	}

	/** Route vers l'édition d'un secteur.
	 * @param poSector 
	 */
	public routeToSectorEdition(poSector: ISector): Promise<boolean> {
		return this.ioRouter.navigate(["sectors", poSector._id, ERouteUrlPart.edit]);
	}

	/** Route vers un secteur.
	 * @param poSector 
	 */
	public routeToSector(poSector: ISector): Promise<boolean> {
		return this.ioRouter.navigate(["sectors", poSector._id]);
	}

	//#endregion

}
