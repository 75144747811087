import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EntityHelper } from '@osapp/helpers/entityHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { UserData } from '@osapp/model/application/UserData';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IContact } from '@osapp/model/contacts/IContact';
import { IContactCacheData } from '@osapp/model/contacts/IContactCacheData';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { IEntityLink } from '@osapp/model/entities/IEntityLink';
import { IEntityLinkPart } from '@osapp/model/entities/IEntityLinkPart';
import { IFormListEvent } from '@osapp/model/forms/IFormListEvent';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { OsappApiHelper } from '@osapp/modules/osapp-api/helpers/osapp-api.helper';
import { C_SECTORS_ROLE_ID } from '@osapp/modules/permissions/services/permissions.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { NetworkService } from '@osapp/services/network.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { mergeWith } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, from, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, distinctUntilChanged, filter, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { C_PATIENT_DESMOS_SYSTEM_ID, C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../../../app/app.constants';
import { IdlApiHelper } from '../../../helpers/idl-api.helper';
import { ITraitement } from '../../../model/ITraitement';
import { SearchPatientsError } from '../../../model/errors/SearchPatientsError';
import { EIdlLogActionId } from '../../logger/models/EIdlLogActionId';
import { PatientSelectorModalOpenerService } from '../components/patient-selector-modal/services/patient-selector-modal-opener.service';
import { EExportResult } from '../model/EExportResult';
import { IPatient } from '../model/IPatient';
import { ISearchPatientParams } from '../model/isearch-patient-params';
import { PatientNamePipe } from '../pipes/patient-name.pipe';
import { ExportService } from './export.service';

interface IRecentPatient extends IStoreDocument {
	recentIds: string[];
}
interface ISearchPatientsHttpOptions {
	headers: HttpHeaders,
	observe: "body",
	responseType: "json"
}

@Injectable({ providedIn: "root" })
export class PatientsService implements ILogSource {

	//#region FIELDS

	/** Id du dernier patient consulté : "recentPatientId". */
	private readonly C_RECENT_PATIENT_ID: string = "recentPatientId";
	/** Clé pour l'événement d'archivage de patient : "archivePatient". */
	private readonly C_DELETE_PATIENT: string = "archivePatient"; //TODO RP mettre à jour la valeur en deletePatient ici et en base dans formDesc_patientsBook_vX
	/** Nombre maximum de patiens dans la liste des patients récents : 10. */
	private readonly C_MAX_RECENT_PATIENTS_RETENTION: number = 10;

	public static readonly C_FILTER_PATIENTS_ID = "patientsFilterId";

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "IDL.PAT.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		/** Service du store. */
		private readonly isvcStore: Store,
		/** Service d'affichage des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioRouter: Router,
		private readonly isvcContacts: ContactsService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioHttpClient: HttpClient,
		private readonly isvcNetwork: NetworkService,
		private readonly isvcGroups: GroupsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcPatientSelectorModalOpener: PatientSelectorModalOpenerService,
		private readonly isvcExport: ExportService,
		private readonly ioPatientNamePipe: PatientNamePipe
	) { }


	private patientChangeSubject = new BehaviorSubject<any>(null);
  patientChanges$ = this.patientChangeSubject.asObservable();

	private getHttpOptions(): { headers: HttpHeaders } {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				token: ConfigData.authentication.token,
				"api-key": ConfigData.environment.API_KEY,
			})
		};
	}

	private getPatientsBaseUrl(psWorkspaceId: string): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${psWorkspaceId}/entities/patients`;
	}

	public async importPatientFromFsv(psPatientExternalId: string): Promise<IPatient> {
		const lsWorkspaceId: string = ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds());
		const lsUrl = `${this.getPatientsBaseUrl(lsWorkspaceId)}/${psPatientExternalId}?systemId=fsv`;

		const loImportedPatient: IPatient = await this.ioHttpClient.get<IPatient>(lsUrl, this.getHttpOptions()).toPromise();

		if (loImportedPatient) {
			delete loImportedPatient.AMO;
			delete loImportedPatient.AMC;
			await this.isvcStore.put(loImportedPatient, lsWorkspaceId).toPromise();
		}

		return loImportedPatient;
	}

	/** Ajout des patients dans le tableau des patients récemment consultés, dans la bdd locale de l'app.
	 * @param psId Id du patient à ajouter dans la liste des patients récemment consultés.
	 */
	public addRecentPatientId(psId: string): Observable<IStoreDataResponse> {
		const loParams: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
			viewParams: {
				key: this.C_RECENT_PATIENT_ID,
				include_docs: true
			}
		};

		return this.isvcStore.get(loParams)
			.pipe(
				mergeMap((paResults: IRecentPatient[]) => {
					const loData: IRecentPatient = ArrayHelper.hasElements(paResults) ?
						this.getAndManageRecentPatients(paResults, psId) : { _id: this.C_RECENT_PATIENT_ID, recentIds: [psId] };

					return this.isvcStore.put(loData, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)));
				})
			);
	}

	/** Suppression du patient passé en paramètres.
	 * @param poPatient Patient à supprimer
	 * @param psDatabaseId id de la base de données sur laquelle il faut effectuer l'archivage.
	 */
	private deletePatient(poPatient: IPatient, psDatabaseId: string): Observable<boolean> {
		const lsStartKey = `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, poPatient._id])}`;
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: lsStartKey,
				endkey: lsStartKey + Store.C_ANYTHING_CODE_ASCII,
			},
		};

		return this.isvcStore.get<ITraitement>(loDataSource)
			.pipe(
				mergeMap((paTraitements: ITraitement[]) => {
					const ldFillDay: Date = DateHelper.fillDay(new Date());
					const laInProgressTraitements: ITraitement[] = paTraitements.filter((poTraitement: ITraitement) => {
						return DateHelper.compareTwoDates(ldFillDay, poTraitement.endDate) < 0;
					});

					return this.isvcEntityLink.ensureIsDeletableEntity(poPatient, laInProgressTraitements);
				}),
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => {
					const loAsyncPopup = new ShowMessageParamsPopup();
					loAsyncPopup.buttons = [
						{ text: "Annuler", cssClass: "", role: undefined, handler: () => { return { response: false }; } },
						{ text: "Supprimer", cssClass: "", role: undefined, handler: () => { return { response: true }; } }
					];
					loAsyncPopup.message = `Voulez-vous vraiment supprimer le patient ${ContactHelper.getCompleteFormattedName(poPatient)} ?`;
					loAsyncPopup.header = "Suppression";

					return this.innerDeletePatient(loAsyncPopup, poPatient, psDatabaseId);
				}),
				defaultIfEmpty(false)
			);
	}

	private innerDeletePatient(poAsyncPopup: ShowMessageParamsPopup, poPatient: IPatient, psDatabaseId: string): Observable<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(poAsyncPopup)
			.pipe(
				filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
				mergeMap(_ => {
					return this.isvcStore.delete(poPatient._id, psDatabaseId)
						.pipe(
							tap(__ => {
								this.isvcLogger.action(this.logSourceId, "Suppression du patient.", EIdlLogActionId.patientDelete as unknown as ELogActionId, { userId: UserData.current?._id, patientId: poPatient._id });
								console.log(`PAT.S::Patient ${psDatabaseId}/${poPatient._id} supprimé.`);
							}),
							map(__ => true)
						);
				}),
				defaultIfEmpty(false),
				catchError(poError => {
					console.error(`PAT.S::Erreur de suppression du patient ${poPatient._id}.`, poError);
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ message: `Erreur lors de la suppression du patient ${ContactHelper.getCompleteFormattedName(poPatient)}.` })
					);
					return EMPTY;
				})
			);
	}

	/** Récupère la liste des derniers patients vus après avoir géré la taille du tablea, supprimé les doublons et inséré le nouvel id.
	 * @param paDocs tableau des documents des patients récents.
	 * @param psId id du dernier patient vu.
	 */
	private getAndManageRecentPatients(paDocs: IRecentPatient[], psId: string): IRecentPatient {
		const loRecentPatient: IRecentPatient = ArrayHelper.getLastElement(paDocs);

		// Si tableau trop gros on le coupe à C_MAX_RENCENT_PATIENTS_RETENTION éléments.
		if (loRecentPatient.recentIds.length >= this.C_MAX_RECENT_PATIENTS_RETENTION)
			loRecentPatient.recentIds.splice(loRecentPatient.recentIds.length - 1, 1);

		const lnDuplicateItemIndex: number = loRecentPatient.recentIds.indexOf(psId);

		// Suppression des doublons.
		if (lnDuplicateItemIndex !== -1)
			loRecentPatient.recentIds.splice(lnDuplicateItemIndex, 1); // Il y a un doublon dans la liste des ids récents donc on le supprime.

		// On insère le dernier id à la première position du tableau.
		loRecentPatient.recentIds.unshift(psId);

		return loRecentPatient;
	}

	/** Récupération de la liste des derniers patients vus. */
	public getRecentPatients(): Observable<Array<IPatient>> {
		const loParams: IDataSource = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
			viewParams: {
				key: this.C_RECENT_PATIENT_ID,
				include_docs: true
			}
		};

		// On récupère la liste des derniers patients dans les paramètres internes de l'utilisateur.
		return this.isvcStore.get(loParams)
			.pipe(
				mergeMap((paResults: IRecentPatient[]) => {
					const laRecentPatients: IRecentPatient = ArrayHelper.getLastElement(paResults);

					if (ArrayHelper.hasElements(laRecentPatients.recentIds))
						return this.getRecentPatientsFromContactsAndSendToFormList(laRecentPatients);

					else // Il n'y a pas de patients récents.
						return of([]);
				})
			);
	}

	/** Récupération des patients dans la base des contacts pour les envoyer à la formList.
	 * @param poRecentPatient Objet récupéré de la base de données et contenant la liste des derniers patients vus.
	 */
	private getRecentPatientsFromContactsAndSendToFormList(poRecentPatient: IRecentPatient): Observable<IPatient[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.contacts),
			viewParams: {
				include_docs: true,
				keys: poRecentPatient.recentIds
			}
		};

		return this.isvcStore.get(loParams)
			.pipe(
				catchError(poError => { console.error("PAT.S::Erreur récupération derniers patients : ", poError); return throwError(poError); }),
				mergeMap((paResults: IPatient[]) => {
					poRecentPatient.recentIds.forEach((psId: string, pnIndex: number, paArray: Array<string>) => {
						if (!paResults.some((poResult: IPatient) => poResult._id === psId))
							paArray.splice(pnIndex, 1);
					});
					return this.manageRecentPatients(poRecentPatient, paResults);
				})
			);
	}

	/** Gère les actions à réaliser lors d'un clic sur un bouton itemOption en fonction de l'id reçu.
	 * @param poEvent Objet correspondant à l'événement levé lors d'un clic sur un bouton itemOption dans la formlist.
	 */
	public manageItemOptionEvent(poEvent: IFormListEvent): void {
		switch (poEvent.id) {

			case this.C_DELETE_PATIENT:
				const loPatient: IPatient = poEvent.model as IPatient;
				const lsDatabaseId: string = StringHelper.isBlank(poEvent.dataSource.databaseId) ?
					StoreHelper.getDatabaseIdFromCacheData(loPatient) : poEvent.dataSource.databaseId;

				this.deletePatient(loPatient, lsDatabaseId).subscribe();
				break;

			default:
				console.warn(`PAT.S::L'id de l'événement n'a pas d'action associée.`);
				break;
		}
	}

	/** Met à jour la nouvelle liste des patients récents (suppression des patients supprimés), et récupération des documents correspondants pour les afficher.
	 * @param poNewRecentPatient Nouvelle liste des patients récents (les patients supprimés ont été enlevés du tableau des récents).
	 * @param paPatients Tableau des patients.
	 */
	private manageRecentPatients(poNewRecentPatient: IRecentPatient, paPatients: Array<IPatient>): Observable<IPatient[]> {

		if (poNewRecentPatient.recentIds.length !== paPatients.length) {
			return this.isvcStore.put(poNewRecentPatient, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)))
				.pipe(
					catchError(poError => { console.error("PAT.S::Erreur put recent patients : ", poError); return throwError(poError); }),
					mapTo(paPatients)
				);
		}
		else
			return of(paPatients);
	}

	/** Va sur la page de visu d'un patient.
	 * @param poPatient Patient vers lequel naviguer.
	 */
	public goToPatient(poPatient: IPatient, psSlide?: string): Observable<boolean>;
	/** Va sur la page de visu d'un patient.
	 * @param poPatient Identifiant du patient vers lequel naviguer.
	 */
	public goToPatient(psPatientId: string, psSlide?: string): Observable<boolean>;
	public goToPatient(poPatientOrPatientId: IPatient | string, psSlide?: string): Observable<boolean> {
		const lsPatientId: string = typeof poPatientOrPatientId === "string" ? poPatientOrPatientId : poPatientOrPatientId._id;

		return from(this.ioRouter.navigate(["patients", lsPatientId], { queryParams: { slide: psSlide } }));
	}

	/** Navigue vers la page de création d'un patient.
	 * @param poPatient Modèle du patient à créer (si des renseignement à son sujet sont déjà disponibles).
	 */
	public goToNewPatient(poPatient?: IPatient): Promise<boolean> {
		const loExtras: NavigationExtras = poPatient ? { state: { model: poPatient } } : undefined;
		return this.ioRouter.navigate(["patients", ERouteUrlPart.new], loExtras);
	}

	/** Va sur la page d'edit d'un patient.
	 * @param psPatientId Identifiant du patient vers lequel naviguer.
	 */
	public goToPatientEdit(psPatientId: string, pbAskExport?: boolean): Observable<boolean> {
		return from(this.ioRouter.navigate(["patients", psPatientId, ERouteUrlPart.edit], { queryParams: pbAskExport ? { askExport: pbAskExport } : undefined }));
	}

	/** Récupère les identifiants des entités liées d'un patient.
	 * @param psPatientId Identifiant du patient dont il faut récupérer les entité liées.
	 * @param pePrefix Préfixe des entités liées à retrouver.
	 */
	private getPatientEntityLinkIdsAndDatabaseIds(psPatientId: string, pePrefix: EPrefix): Observable<IEntityLinkPart[]> {
		return this.isvcEntityLink.getEntityLinks(psPatientId, [pePrefix])
			.pipe(
				map((paEntityLinks: IEntityLink[]) =>
					paEntityLinks.map((poEntityLink: IEntityLink) => EntityHelper.getEntityLinkPartFromPrefix(poEntityLink, pePrefix))
				)
			);
	}

	/** Récupère les entités liées d'un patient.
	 * @param psPatientId Identifiant du patient dont il faut récupérer les entité liées.
	 * @param pePrefix Préfixe des entités liées à retrouver.
	 * @param pfFilter Fonction pour filtrer les résultats obtenus, pas de filtrage si non renseigné.
	 */
	public getPatientEntityLinks<T extends IStoreDocument>(
		psPatientId: string,
		pePrefix: EPrefix,
		pfFilter?: (poItem: T) => boolean,
		pbLive?: boolean
	): Observable<T[]> {
		return this.isvcEntityLink.getLinkedEntities<T>(psPatientId, [pePrefix], pbLive)
			.pipe(
				map((paResults: T[]) => pfFilter ? paResults.filter((poItem: T) => pfFilter(poItem)) : paResults),
				distinctUntilChanged((paOldResults: T[], paNewResults: T[]) => ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults))
			);
	}

	/** Récupère les identifiants des entités liées d'un patient.
	 * @param psPatientId Identifiant du patient dont il faut récupérer les entité liées.
	 * @param pePrefix Préfixe des entités liées à retrouver.
	 */
	public getPatientEntityLinkIds(psPatientId: string, pePrefix: EPrefix): Observable<string[]> {
		return this.getPatientEntityLinkIdsAndDatabaseIds(psPatientId, pePrefix)
			.pipe(
				map((paResults: IEntityLinkPart[]) =>
					ArrayHelper.unique(paResults.map((poItem: IEntityLinkPart) => poItem.entityId))
				)
			);
	}

	/** Récupère un patient par son identifiant.
	 * @param psPatientId Identifiant du patient à récupérer.
	 * @param pbLive
	 */
	public getPatient(psPatientId: string, pbLive?: boolean): Observable<IPatient> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: psPatientId
			}
		};

		return this.isvcStore.getOne<IPatient>(loDataSource, false);
	}

	/** Récupère les patients. */
	public getPatients(): Observable<IPatient[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: C_PREFIX_PATIENT,
				endkey: C_PREFIX_PATIENT + Store.C_ANYTHING_CODE_ASCII
			}
		};

		return this.isvcStore.get<IPatient>(loDataSource);
	}

	/** Récupère les patients. */
	// On utilise une vue pour ne pas inclure les patients qui n'existent plus dans le résultat
	public getPatientsByIds(paIds: string[], pbLive?: boolean): Observable<IPatient[]> {
		const dataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewName: "patients/by_id",
			viewParams: {
				keys: paIds
			},
			live: pbLive
		};

		return this.isvcStore.get<IPatient>(dataSource);
	}

	/** Récupère la liste des patients du site courant. */
	public getSitePatients(pbLive?: boolean): Observable<IPatient[]> {
		return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID])
			.pipe(
				mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContacts(paSectors, C_PREFIX_PATIENT, pbLive))
			);
	}

	/** Récupère la liste des patients du site courant. */
	public getSitePatientsAnakin(pbLive?: boolean): Observable<IPatient[]> {
		return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID])
			.pipe(
				mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContactsAnakin(paSectors, C_PREFIX_PATIENT, pbLive))
			);
	}

	/** Sélectionne un patient à l'aide d'un sélecteur.
	 * @param psPageTitle Titre de la page du sélecteur de patient.
	 * @param pbSelectNewPatient Indique, dans le cas d'une création de patient, si on doit le sélectionner après sa création.
	 */
	public selectPatient(psPageTitle: string, pbSelectNewPatient: boolean = false): Observable<IPatient> {
		return this.isvcPatientSelectorModalOpener.open({ title: psPageTitle, selectNewPatient: pbSelectNewPatient })
			.pipe(
				filter((paPatients: IPatient[]) => ArrayHelper.hasElements(paPatients)),
				map((paPatients: IPatient[]) => ArrayHelper.getFirstElement(paPatients))
			);
	}

	/** Ouvre une modale de sélection de patients.
	 * @param paPreselectedIds Tableau des identifiants de patients présélectionnés.
	 * @param paData Tableau des patients parmi lequel faire la sélection.
	 * @param psPageTitle Titre de la modale.
	 */
	public selectPatients(paPreselectedIds: string[], paData: IPatient[], psPageTitle: string): Observable<IPatient[]> {
		return this.isvcPatientSelectorModalOpener.open({ title: psPageTitle, preselectedPatientIds: paPreselectedIds, patients: paData, multiple: true });
	}

	/** Retourne `true` si le membre générique est un patient, `false` sinon.
	 * @param poMember Membre générique dont il faut vérifier s'il est un patient ou non.
	 */
	public static isPatient(poMember: IGroupMember): boolean {
		return IdHelper.hasPrefixId(poMember._id, C_PREFIX_PATIENT);
	}

	/** Sauveagarde en cache les informations sur l'adresse du Contact.
	 * @param poContact
	 */
	public saveAdressCacheData(poPatient: IPatient): void {
		this.isvcContacts.saveAdressCacheData(poPatient);
	}

	/** Si on detecte un changement dans l'adresse on supprime les données GPS pour ne plus se baser dessus car elles son prioritaire.
	 * @param poContact
	 * @param poContactCacheData
	 */
	public deleteGPSDataIfNeeded(poContact: IContact, poContactCacheData?: IContactCacheData): boolean {
		return this.isvcContacts.deleteGPSDataIfNeeded(poContact, poContactCacheData);
	}

	/** Si le contact est un homme on supprime le nom de jeune fille.
	 * @param poContact
	 */
	public deleteMaidenNameIfNeeded(poContact: IContact): boolean {
		return this.isvcContacts.deleteMaidenNameIfNeeded(poContact);
	}

	//#region SEARCH

	private getSearchPatientBaseUrl(psWorkspaceId: string): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${psWorkspaceId}/entities/patients/search`;
	}

	private getSearchPatientsHttpOptions(): ISearchPatientsHttpOptions {
		return {
			headers: new HttpHeaders({
				appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
				token: ConfigData.authentication.token,
				"api-key": ConfigData.environment.API_KEY,
			}),
			observe: "body",
			responseType: "json"
		} as ISearchPatientsHttpOptions;
	}

	/** Recherche des patient en appelant l'api et retourne les résultats possibles (tableau vide si aucune correspondance).
	 * @param psSearchValue Valeur recherchée pour trouver un patient.
	 * @throws Erreur sous forme de chaîne de caractères (pas de réseau par exemple), ou de type `SearchPatientsError`.
	 */
	public searchPatients(psSearchValue: string): Observable<IPatient[]> {
		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => pbHasNetwork ?
					of(this.getSearchPatientsHttpOptions()) : throwError("Recherche sur desmos impossible sans réseau internet.")
				),
				mergeMap((poHttpOptions: ISearchPatientsHttpOptions) => this.searchPatientsRequest(poHttpOptions, psSearchValue)),
				map((paResults: IPatient[][]) => ArrayHelper.flat(paResults))
			);
	}

	private searchPatientsRequest(poHttpOptions: ISearchPatientsHttpOptions, psSearchValue: string): Observable<IPatient[][]> {
		return from(this.isvcWorkspace.getSharedUserWorkspaceIds())
			.pipe(
				mergeMap((psWorkspaceId: string) => {
					return this.ioHttpClient.get<IPatient[]>(
						`${this.getSearchPatientBaseUrl(psWorkspaceId)}?searchPattern=${psSearchValue}&systemId=${C_PATIENT_DESMOS_SYSTEM_ID}`,
						poHttpOptions
					);
				}),
				toArray(),
				catchError((poError: HttpErrorResponse) =>
					throwError(new SearchPatientsError(IdlApiHelper.getExportErrorMessage(poError, "Un problème est survenu lors de la recherche de patients sur desmos.")))
				)
			);
	}

	/** Récupère l'identifiant du secteur lié au patient.
	 * @param psPatientId
	 * @returns
	 */
	public getPatientSectorId(psPatientId: string): Observable<string> {
		return this.isvcGroups.getContactGroupsIds(psPatientId)
			.pipe(map((paPatientGroupIds: string[]) => ArrayHelper.getFirstElement(paPatientGroupIds)));
	}

	/** Récupère l'identifiant du secteur lié au patient.
	 * @param paPatientIds
	 * @param pbLive
	 * @returns
	 */
	public getPatientsSectorIds(paPatientIds: string[], pbLive?: boolean): Observable<Map<string, string>> {
		return this.isvcGroups.getContactsGroupIds(paPatientIds, pbLive)
			.pipe(map((poGroupIdsByPatientId: Map<string, string[]>) => MapHelper.map(poGroupIdsByPatientId, (paPatientGroupIds: string[]) => ArrayHelper.getFirstElement(paPatientGroupIds))));
	}

	/** Sauvegarde un patient.
	 * @param poPatient
	 */
	public savePatient(poPatient: IPatient, pbExport?: boolean): Observable<boolean> {
		// On supprime le flu xml avant l'enregistrement en base.
		delete poPatient.carteVitaleXML;

		if (StringHelper.isBlank(poPatient._id))
			poPatient._id = IdHelper.buildId(C_PREFIX_PATIENT);

		return this.isvcStore.put(poPatient)
			.pipe(
				mergeMap((poResponse: IStoreDataResponse) => {
					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLink.saveEntityLinks(poPatient).pipe(mapTo(poResponse));
					else
						return of(poResponse);
				}),
				filter((poResponse: IStoreDataResponse) => poResponse.ok),
				mergeMap(() => {
					if (pbExport) {
						return this.isvcExport.exportPatient(poPatient)
							.pipe(
								map((peExportResult: EExportResult) => peExportResult !== EExportResult.patientFailed),
								catchError(poError => {
									console.error("PAT.S:: Erreur export du patient :", poError);
									this.isvcUiMessage.showMessage(
										new ShowMessageParamsPopup({ header: `Erreur lors de l'export du patient.`, message: poError.message })
									);
									return of(undefined);
								}),
							);
					}
					return of(true);
				}),
				defaultIfEmpty(false)
			);
	}

	/** Sauvegarde un patient.
	 * @param patient
	 */
	public savePatientAnakin(patient: IPatient, pbExport: boolean = false, anchorElement?: any): Observable<boolean> {
		if (StringHelper.isBlank(patient._id))
			patient._id = IdHelper.buildId(C_PREFIX_PATIENT);

		const databaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		return this.isvcStore.put(patient, databaseId).pipe(
			mergeMap((poResponse: IStoreDataResponse) => {
				if (ConfigData.appInfo.useLinks) {
					return this.isvcEntityLink.saveEntityLinks(patient).pipe(mapTo(poResponse));
				}

				else
					return of(poResponse);
			}),
			filter((poResponse: IStoreDataResponse) => poResponse.ok),
			mergeMap(() => {
				if (pbExport) {
					return this.isvcExport.exportPatientAnakin(patient, anchorElement).pipe(
						map((exportResult: EExportResult) => exportResult !== EExportResult.patientFailed),
						catchError(_ => of(undefined)),

					);
				}
				return of(true);
			}),
			tap(_ => this.patientChangeSubject.next(null)),
			defaultIfEmpty(false)
		);
	}

	public deletePatientAnakin(patient: IPatient): Observable<boolean> {

		const databaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));

		//On prépare la requête pour la suppression des traitements du patient
		const startKey = `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, patient._id])}`;
		const dataSource: IDataSource = {
			databasesIds: [databaseId],
			viewParams: {
				include_docs: true,
				startkey: startKey,
				endkey: startKey + Store.C_ANYTHING_CODE_ASCII,
			},
		};

		// On récupère les traitements du patient
		return this.isvcStore.get<ITraitement>(dataSource).pipe(
			mergeMap((traitements: ITraitement[]) => {
				//On récupère les traitements en cours
				const fillDay: Date = DateHelper.fillDay(new Date());
				const inProgressTraitements: ITraitement[] = traitements.filter((traitement: ITraitement) => {
					return DateHelper.compareTwoDates(fillDay, traitement.endDate) < 0;
				});
				//On vérifie qu'on peut bien supprimer les traitements en cours du patient
				return this.isvcEntityLink.ensureIsDeletableEntity(patient, inProgressTraitements);
			}),
			filter((result: boolean) => result),
			mergeMap(_ => {
				//On supprime le patient
				return this.isvcStore.delete(patient._id, databaseId).pipe(map((response: IStoreDataResponse) => response.ok));
			}),
			catchError(error => {
				console.error(`PAT.S::Erreur de suppression du patient ${patient._id}.`, error);
				return of(false);
			})
		);
	}

	public async exportPatientXml(poPatient: IPatient, psXmlBase64: string): Promise<IPatient> {
		try {
			return await this.isvcExport.exportPatientXml(poPatient, psXmlBase64).toPromise();
		} catch (poError) {
			console.error(`PAT.S::Erreur d'export Xml du patient ${poPatient._id}.`, poError);
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: poError.error?.error ?? "Erreur", message: poError.error?.message ?? "Le patient n'a pas été créé dans FSV. Il faudra refaire une lecture carte Vitale." }));
			return poPatient;
		};
	}

	/** Recherche un patient dans un liste en fonction de ses informations.
	 * @param paPatients
	 * @param psFirstName
	 * @param psLastName
	 * @param psSocialNumber
	 * @returns
	 */
	public searchPatient(paPatients: IPatient[], poParams: ISearchPatientParams): IPatient[] {
		return paPatients.filter((poPatient: IPatient) => {
			if (poParams.fullMatch) { // Tout doit correspondre.
				return poParams.firstName.toLowerCase() === poPatient.firstName?.toLowerCase() && poParams.lastName.toLowerCase() === poPatient.lastName?.toLowerCase() &&
					poParams.socialNumber === poPatient.socialNumber && DateHelper.areDayEqual(poParams.birthDate, poPatient.birthDate);
			}

			let lbIsEqual = true;
			let correspondancesTrouvees: string[] = [];

			for (const lsKey in poParams) {
				lbIsEqual = lsKey !== "fullMatch"
					&& (ObjectHelper.isNullOrEmpty(poParams[lsKey])
						|| (lsKey === "birthDate" && DateHelper.areDayEqual(poParams.birthDate, poPatient.birthDate))
						|| (lsKey !== "birthDate" && typeof poParams[lsKey] === "string" && typeof poPatient[lsKey] === "string" && (poParams[lsKey] as string).toLowerCase() === (poPatient[lsKey] as string)?.toLowerCase()));

				//Si on trouve une correspondance
				if (lbIsEqual) {
					//On ajoute la correspondance trouvée
					correspondancesTrouvees.push(lsKey);
				}
			}

			//Si a la fin du traitement on a trouvé plus d'une correspondance 
			//alors on retourne le patient courant
			return correspondancesTrouvees.length > 1;
		});
	}

	/** Fusionne 2 patients entre eux.
	 * @param poOldPatient
	 * @param poNewPatient
	 */
	public mergePatientsData(poOldPatient: IPatient, poNewPatient: IPatient): IPatient {
		return mergeWith(poOldPatient, poNewPatient, (poOldValue: any, poNewValue: any, psKey: keyof IPatient) => {
			if (psKey === "_id")
				return poOldValue;
		});
	}

	public formatPatientName(poPatient: IPatient): string {
		return this.ioPatientNamePipe.transform(poPatient);
	}

	public getFullNamePatient(patient: IPatient): string {
		if (patient.usualLastName && patient.lastName.toUpperCase() !== patient.usualLastName.toUpperCase()) {
			return `${patient.usualLastName} (${patient.lastName})`;
		} else {
			return patient.lastName;
		}
	}

	//#endregion

	//#endregion
}