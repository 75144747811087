import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItems } from 'apps/idl/src/anakin/shared/navigation-items.constants';
import { NavigationService } from '../../../shared/services/navigation.service';
import { HistoriqueService } from '../../../shared/services/historique.service';

@Component({
	selector: "di-menu-mobile",
	templateUrl: './menu-mobile.component.html',
	styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {

	public homeTuiles = NavigationItems;

	constructor(private router: Router,
		private svcNavigation: NavigationService,
		private cdr: ChangeDetectorRef,
		private svcHistorique: HistoriqueService
	) { }

	ngOnInit() {
		this.svcNavigation.navigationItems$.subscribe(items => {
			this.homeTuiles = items;
			this.cdr.detectChanges();
		});

		this.svcHistorique.getBadgeTransmissionFromHistorique().subscribe();
	}

	public redirect(route: string[]): void {
		if (!route.includes("#"))
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate(route)
			});
	}
}
