import { StringHelper } from '../../../helpers/stringHelper';
import { InjectorService } from '../../../services/injector.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../services/uiMessage.service';
import { IValidationPopupDecoratorParams } from '../model/IValidationPopupDecoratorParams';

export function ValidationPopup(poParams: IValidationPopupDecoratorParams = {}): MethodDecorator {
	return function (poTarget: Object, psMethodName: string | symbol, poDescriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> {
		const lfOriginalMethod: Function = poDescriptor.value; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.value = function (): void {
			const laArguments: IArguments = arguments; // on capture les arguments de la méthode.
			const loTarget: any = this; // Représente la classe qui appelle le décorateur.

			InjectorService.instance.get(UiMessageService).showMessage(
				new ShowMessageParamsPopup({
					header: StringHelper.isBlank(poParams.title) ? "Validation" : poParams.title,
					message: StringHelper.isBlank(poParams.message) ? "Valider?" : poParams.message,
					buttons: [
						{ text: StringHelper.isBlank(poParams.cancelButton) ? "ANNULER" : poParams.cancelButton, role: UiMessageService.C_CANCEL_ROLE },
						// On appelle l'ancienne méthode en la mettant dans le contexte de l'appelant.
						{ text: StringHelper.isBlank(poParams.okButton) ? "CONTINUER" : poParams.okButton, handler: function () { lfOriginalMethod.apply(loTarget, laArguments); } }
					]
				})
			);
		};

		return poDescriptor;
	}
}