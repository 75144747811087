import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "di-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
	@Input() size = 50;
	@Input() color = "CouleurPrimaire";
	@Input() loaderText = "Chargement...";
	public visible: boolean;


	constructor() {}

	public ngOnInit(): void {}

	open() {
    this.visible = true;
  }

	close() {
    this.loaderText = '';
		this.color = "CouleurPrimaire";
    this.visible = false;
  }
}
