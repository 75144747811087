import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';

@Component({
	selector: 'calao-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent extends ComponentBase {

	//#region FIELDS

	@Output("colorChange") private readonly moColorEventEmitter = new EventEmitter<string>();

	//#endregion

	//#region PROPERTIES

	private msColor: string;
	public get color(): string {
		return this.msColor;
	}
	@Input()
	public set color(psColor: string) {
		if (psColor !== this.msColor)
			this.moColorEventEmitter.emit(this.msColor = psColor);
	}

	private maColors: string[] = ['#008A64', '#31918E', '#0085A3', '#6763D5', '#C15ECE', '#FF63A5', '#F194B4', '#FF8B78', '#FFC35D', '#FFB100', '#C1A875', '#857555', '#D2234D', '#C15ECE', '#BFA5A6', '#A5C1BF', '#A9AABC', '#444655'];
	public get colors(): string[] {
		return this.maColors;
	}
	@Input()
	public set colors(paColors: string[]) {
		if (ArrayHelper.hasElements(paColors) && !ArrayHelper.areArraysEqual(paColors, this.maColors)) {
			this.maColors = paColors;
			this.detectChanges();
		}
	}


	private msLabel: string;
	public get label(): string {
		return this.msLabel;
	}
	@Input()
	public set label(psLabel: string) {
		if (this.msLabel !== psLabel) {
			this.msLabel = psLabel;
			this.detectChanges();
		}
	}


	//#endregion

	//#region METHODS

	constructor(
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	//#endregion

}
