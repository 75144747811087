import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { IonicModule } from '@ionic/angular';
import { BottomSheetHeaderComponent } from './components/bottom-sheet-header/bottom-sheet-header.component';
import { BottomSheetService } from './services/bottom-sheet.service';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule,
	MatBottomSheetModule
];
const components: Type<any>[] = [
	BottomSheetHeaderComponent
];

@NgModule({
	imports: modules,
	declarations: components,
	exports: components,
	providers: [BottomSheetService]
})
export class OsappBottomSheetModule { }