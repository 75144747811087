import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ContactHelper } from '@osapp/helpers/contactHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { IIndexedArray } from '@osapp/model/IIndexedArray';
import { UserData } from '@osapp/model/application/UserData';
import { IEventMarker } from '@osapp/model/calendar/IEventMarker';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { IGroupMember } from '@osapp/model/contacts/IGroupMember';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { ICacheData } from '@osapp/model/store/ICacheData';
import { IChangeEvent } from '@osapp/model/store/IChangeEvent';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { IStoreDocument } from '@osapp/model/store/IStoreDocument';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { EUTCAccuracy } from '@osapp/modules/date/model/eutcaccuracy.enum';
import { IDateTime } from '@osapp/modules/date/model/idate-time';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { EFlag } from '@osapp/modules/flags/models/EFlag';
import { IIntegrity } from '@osapp/modules/integrity/models/IIntegrity';
import { IntegrityService } from '@osapp/modules/integrity/services/integrity.service';
import { Loader } from '@osapp/modules/loading/Loader';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { IDataSourceRemoteChanges } from '@osapp/modules/store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '@osapp/modules/utils/models/model-resolver';
import { IRange } from '@osapp/modules/utils/models/models/irange';
import { DestroyableServiceBase } from '@osapp/modules/utils/services/destroyable-service-base';
import { ApplicationService } from '@osapp/services/application.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { EventService } from '@osapp/services/event.service';
import { FlagService } from '@osapp/services/flag.service';
import { GlobalDataService } from '@osapp/services/global-data.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { BehaviorSubject, EMPTY, Observable, Subject, concat, defer, forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, filter, finalize, map, mapTo, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../app/app.constants';
import { Acte } from '../model/Acte';
import { EMajorationType } from '../model/EMajorationType';
import { EPathologie } from '../model/EPathologies';
import { ERepetition } from '../model/ERepetition';
import { ETraitementState } from '../model/ETraitementState';
import { IActe } from '../model/IActe';
import { IDeplacementByProfession } from '../model/IDeplacementByProfession';
import { ISaveTraitementParams } from '../model/ISaveTraitementParams';
import { ITraitement } from '../model/ITraitement';
import { ITraitementIntegrity } from '../model/ITraitementIntegrity';
import { Majoration } from '../model/Majoration';
import { Seance } from '../model/Seance';
import { Traitement } from '../model/Traitement';
import { IDateWithSeances } from '../model/seances/IDateWithSeances';
import { ActesService } from '../modules/actes/actes.service';
import { ConcatActesPipe } from '../modules/actes/concat-actes.pipe';
import { EConstraintType } from '../modules/actes/model/EConstraintType';
import { ActeOccurrenceDateTimeComparator } from '../modules/actes/model/acte-occurrence-date-time-comparator';
import { ActeOccurrenceRangeComparator } from '../modules/actes/model/acte-occurrence-range-comparator';
import { CancelConstraint } from '../modules/actes/model/cancel-constraint';
import { Constraint } from '../modules/actes/model/constraint';
import { DelayConstraint } from '../modules/actes/model/delay-constraint';
import { ReactivateConstraint } from '../modules/actes/model/reactivate-constraint';
import { EIdlLogActionId } from '../modules/logger/models/EIdlLogActionId';
import { OrdonnancesService } from '../modules/ordonnances/services/ordonnances.service';
import { IPatient } from '../modules/patients/model/IPatient';
import { PatientsService } from '../modules/patients/services/patients.service';
import { EChoiceFilter } from '../modules/seances/choose-seances-to-modify-modal/EChoiceFilter';
import { EAdminActionOnMultipleSeances } from '../modules/seances/model/eadmin-action-on-multiple-seances.enum';
import { IChooseSeancesToModifiyResponse } from '../modules/seances/model/ichoose-seances-to-modifiy-response';
import { CancelActeModalComponent, ICancelActeResponse } from '../modules/traitement/cancelActeModal/cancelActeModal.component';
import { EIndemniteType } from '../modules/traitement/model/EIndemniteType';
import { ITraitementSlidePageParams } from '../modules/traitement/model/ITraitementSlidePageParams';
import { ExtraCharge } from '../modules/traitement/model/extra-charge';
import { IExtraCharge } from '../modules/traitement/model/iextra-charge';
import { TraitementStatePipe } from '../pipes/traitement-state.pipe';
import { SeanceService } from './seance.service';

type GetTraitementOptions = {
	isLive: boolean;
	startKeyEndKey?: string;
	key?: string;
	keys?: string[];
};

type GetTraitementOptionsRemote = {
	isLive: boolean;
	startKeyEndKey?: string;
	key?: string;
	keys?: string[];
	activePageManager: ActivePageManager;
};

@Injectable({ providedIn: "root" })
export class TraitementService extends DestroyableServiceBase implements ILogSource {

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId = "IDL.TRAIT.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region FIELDS

	public static readonly C_BEGIN_DATE_PROPERTY = "beginDate";
	public static readonly C_CREATE_DATE_PROPERTY = "createDate";
	public static readonly C_END_DATE_PROPERTY = "endDate";
	public static readonly C_STATE_PROPERTY = "state";
	public static readonly C_BREAK_DATE_PROPERTY = "breakDate";
	public static readonly C_RESUME_ACTES_PROPERTY = "resumeActes";
	public static readonly C_NBSEANCES_PROPERTY = "nbSeances";
	public static readonly C_ACTES_PROPERTY = "actes";
	public static readonly C_TRAITEMENT_SAVE_TEXT = "Enregistrement des données en cours. Veuillez patienter ...";
	public static readonly C_TRAITEMENT_ENDING_SOON_MARKER_KEY = "nbOfTraitEndingSoon";

	private static readonly C_MAX_DOCS_TO_SAVE_WITHOUT_LOADER = 100; // Valeur arbitraire

	private moSaveTraitementRequest: Subject<ISaveTraitementParams> = new Subject();

	public get defaultOrdoDescription(): string {
		return `Ordo. ${DateHelper.transform(new Date(), ETimetablePattern.dd_MM_yyyy_slash)}`;
	}

	private deleteTraitementAction = new Subject<void>();
	private ordonnanceChangeSubject = new BehaviorSubject<any>(null);
  ordonnanceChanges$ = this.ordonnanceChangeSubject.asObservable();


	//#endregion

	//#region METHODS

	constructor(
		private isvcGlobalData: GlobalDataService,
		private isvcStore: Store,
		private isvcEntityLink: EntityLinkService,
		private isvcSeance: SeanceService,
		private ioTraitementState: TraitementStatePipe,
		private ioRouter: Router,
		private isvcPatients: PatientsService,
		private ioConcatActesPipe: ConcatActesPipe,
		private isvcUiMessage: UiMessageService,
		private isvcActe: ActesService,
		private svcOrdonnance: OrdonnancesService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcIntegrity: IntegrityService,
		private readonly isvcEvents: EventService,
		private readonly ioModalCtrl: ModalController,
		private readonly isvcFlag: FlagService,
		private readonly isvcLoading: LoadingService,
	) {
		super();
		//Nécessaire pour utiliser le store dans les tests e2e
		// @ts-ignore
		if (window.Cypress) {
			// @ts-ignore
			window.traitmentService = this;
		}

	}

	public ngOnDestroy(): void {
		this.moSaveTraitementRequest.complete();
		super.ngOnDestroy();
	}


	deletion$ = this.deleteTraitementAction.asObservable();
	public emitDeleteTraitement() {
		this.deleteTraitementAction.next();
	}

	/** Retourne une source de données pour récupérer un ou plusieurs traitements.
	 * @param poOptions Options pour la source de données à récupérer.
	 */
	private getTraitementDataSource(poOptions: GetTraitementOptions): IDataSource;
	private getTraitementDataSource(poOptions: GetTraitementOptionsRemote): IDataSourceRemoteChanges;
	private getTraitementDataSource(poOptions: GetTraitementOptions | GetTraitementOptionsRemote): IDataSource {
		return {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: poOptions.startKeyEndKey,
				endkey: StringHelper.isBlank(poOptions.startKeyEndKey) ? undefined : poOptions.startKeyEndKey + Store.C_ANYTHING_CODE_ASCII,
				key: poOptions.key,
				keys: poOptions.keys,
			},
			live: poOptions.isLive,
			remoteChanges: !!(poOptions as GetTraitementOptionsRemote).activePageManager,
			activePageManager: (poOptions as GetTraitementOptionsRemote).activePageManager
		} as IDataSource;
	}

	/** Récupère un traitement en base de données en fonction de son identifiant.
	 * @param psTraitementId Identifiant du traitement à récupérer en base de données.
	 * @param pbLive Indique si on veut récupérer les changements en base de données sur un traitement donné.
	 */
	public getTraitement(psTraitementId: string, pbLive: boolean = false): Observable<Traitement> {
		if (StringHelper.isBlank(psTraitementId))
			return of(undefined);

		const loDataSource: IDataSource = this.getTraitementDataSource({ isLive: pbLive, key: psTraitementId });

		return this.isvcStore.getOne<ITraitement>(loDataSource, false)
			.pipe(
				filter((poTraitementData?: ITraitement) => !!poTraitementData),
				map((poTraitementData: ITraitement) => Traitement.createFromData(poTraitementData))
			);
	}

	/** Récupère les traitements en base de données en fonction de leur identifiant.
	 * @param paTraitementIds Identifiants des traitements à récupérer en base de données.
	 * @param pbLive Indique si on veut récupérer les changements en base de données sur un traitement donné.
	 */
	public getTraitementByIds(paTraitementIds: string[], pbLive: boolean = false): Observable<Traitement[]> {
		if (!ArrayHelper.hasElements(paTraitementIds))
			return of([]);

		const loDataSource: IDataSource = this.getTraitementDataSource({ isLive: pbLive, keys: paTraitementIds });

		return this.isvcStore.get<ITraitement>(loDataSource)
			.pipe(
				map((paTraitementsData: ITraitement[]) => paTraitementsData.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement)))
			);
	}

	/** Récupère tous les traitements.
	 * @param pbLive
	 * @param poActivePageManager Passer ce paramètre pour récupérer les modifications du serveur.
	 */
	public getTraitements(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Traitement[]> {
		const loDataSource: IDataSource = this.getTraitementDataSource({
			isLive: pbLive,
			activePageManager: poActivePageManager,
			startKeyEndKey: `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT])}`
		});

		return this.isvcStore.get<ITraitement>(loDataSource)
			.pipe(map((paTraitements: ITraitement[]) => paTraitements.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement))));
	}

	public getTraitementANAKIN(psTraitementId: string, pbLive: boolean = false): Observable<Traitement> {
		if (StringHelper.isBlank(psTraitementId))
			return of(undefined);

		const loDataSource: IDataSource = this.getTraitementDataSource({ isLive: pbLive, key: psTraitementId });

		return this.isvcStore.getOne<ITraitement>(loDataSource, false).pipe(
			filter((poTraitementData?: ITraitement) => !!poTraitementData),
			mergeMap((poTraitementData: ITraitement) => {
				if (poTraitementData.prescriptionDate === undefined && poTraitementData.prescripteurContactId === undefined) {
					return this.svcOrdonnance.getTraitementOrdonnances(poTraitementData._id).pipe(
						map((ordonnances) => {
							if (ordonnances.length > 0) {
								const ordonnance = ArrayHelper.getFirstElement(ordonnances);
								poTraitementData.prescriptionDate = ordonnance.prescriptionDate ?? undefined;
								poTraitementData.renouvellement = ordonnance.renouvellement ?? undefined;
								poTraitementData.prescripteurContactId = ordonnance.prescripteurContactId ?? undefined;
								poTraitementData.originePrescription = ordonnance.originePrescription ?? undefined;
								poTraitementData.isAld = ordonnance.isAld ?? undefined;
								poTraitementData.isAdc = !!ordonnance.adcDate;
								poTraitementData.adcDate = ordonnance.adcDate ?? undefined;
								poTraitementData.documents = ordonnance.documents ?? undefined;
								poTraitementData.linkedActesGuids = ordonnance.linkedActesGuids ?? undefined;
							}
							return Traitement.createFromData(poTraitementData);
						})
					);
				} else {
					return of(Traitement.createFromData(poTraitementData));
				}
			})
		);
	}

	////TODO : A voir si on en a besoin pour retourner les documents de l'ordonnance
	// public getITraitementANAKIN(psTraitementId: string, pbLive: boolean = false): Observable<ITraitement> {
	// 	if (StringHelper.isBlank(psTraitementId))
	// 		return of(undefined);

	// 	const loDataSource: IDataSource = this.getTraitementDataSource({ isLive: pbLive, key: psTraitementId });

	// 	return this.isvcStore.getOne<ITraitement>(loDataSource, false).pipe(
	// 		filter((poTraitementData?: ITraitement) => !!poTraitementData),
	// 		mergeMap((poTraitementData: ITraitement) => {
	// 			if (poTraitementData.prescriptionDate === undefined && poTraitementData.prescripteurContactId === undefined) {
	// 				return this.svcOrdonnance.getTraitementOrdonnances(poTraitementData._id).pipe(
	// 					map((ordonnances) => {
	// 						if (ordonnances.length > 0) {
	// 							const ordonnance = ArrayHelper.getFirstElement(ordonnances);
	// 							poTraitementData.prescriptionDate = ordonnance.prescriptionDate ?? undefined;
	// 							poTraitementData.renouvellement = ordonnance.renouvellement ?? undefined;
	// 							poTraitementData.prescripteurContactId = ordonnance.prescripteurContactId ?? undefined;
	// 							poTraitementData.originePrescription = ordonnance.originePrescription ?? undefined;
	// 							poTraitementData.isAld = ordonnance.isAld ?? undefined;
	// 							poTraitementData.isAdc = !!ordonnance.adcDate;
	// 							poTraitementData.adcDate = ordonnance.adcDate ?? undefined;
	// 							poTraitementData.documents = ordonnance.documents ?? undefined;
	// 							poTraitementData.linkedActesGuids = ordonnance.linkedActesGuids ?? undefined;
	// 						}
	// 						return poTraitementData;
	// 					})
	// 				);
	// 			} else {
	// 				return of(poTraitementData);
	// 			}
	// 		})
	// 	);
	// }

	public getTraitementsANAKIN(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Traitement[]> {
		const loDataSource: IDataSource = this.getTraitementDataSource({
			isLive: pbLive,
			activePageManager: poActivePageManager,
			startKeyEndKey: `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT])}`,
		});

		return this.isvcStore.get<ITraitement>(loDataSource).pipe(
			mergeMap((paTraitements: ITraitement[]) => {
				if (paTraitements.length === 0) {
					return of([]);
				}
				return forkJoin(paTraitements.map((poTraitement: ITraitement) => {
					if (poTraitement.prescriptionDate === undefined && poTraitement.prescripteurContactId === undefined) {
						return this.svcOrdonnance.getTraitementOrdonnances(poTraitement._id)
							.pipe(
								map((ordonnances) => {
									if (ordonnances.length > 0) {
										const ordonnance = ArrayHelper.getFirstElement(ordonnances);
										poTraitement.prescriptionDate = ordonnance.prescriptionDate ?? undefined;
										poTraitement.renouvellement = ordonnance.renouvellement ?? undefined;
										poTraitement.prescripteurContactId = ordonnance.prescripteurContactId ?? undefined;
										poTraitement.originePrescription = ordonnance.originePrescription ?? undefined;
										poTraitement.isAld = ordonnance.isAld ?? undefined;
										poTraitement.isAdc = !!ordonnance.adcDate;
										poTraitement.adcDate = ordonnance.adcDate ?? undefined;
										poTraitement.documents = ordonnance.documents ?? undefined;
										poTraitement.linkedActesGuids = ordonnance.linkedActesGuids ?? undefined;
									}
									return Traitement.createFromData(poTraitement);
								})
							);
					} else {
						return of(Traitement.createFromData(poTraitement));
					}
				}));
			})
		);
	}

	public getAllTraitementsOrdonnances(): Observable<Traitement[]> {
		const dataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewName: "traitements/get_list_ordonnances",
		};

		return this.isvcStore.get<Traitement>(dataSource).pipe(
			mergeMap((paTraitements: ITraitement[]) => {
				if (paTraitements.length === 0) {
					return of([]);
				}
				return forkJoin(paTraitements.map((poTraitement: ITraitement) => of(Traitement.createFromData(poTraitement))));
			})
		);
	}

	// public getOneTraitement(id: string): Observable<Traitement> {
	// 	const dataSource: IDataSource = {
	// 		databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
	// 		viewName: "traitements/get_list_ordonnances",
	// 		viewParams: {
	// 			key: id
	// 		}
	// 	};
	// 	return this.isvcStore.getOne<Traitement>(dataSource)
	// }


	/** Récupère tous les traitements en cours
 * @param pbLive
 * @param poActivePageManager Passer ce paramètre pour récupérer les modifications du serveur.
 */
	public getTraitementsEnCours(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Traitement[]> {
		const loDataSource: IDataSource = this.getTraitementDataSource({
			isLive: pbLive,
			activePageManager: poActivePageManager,
			startKeyEndKey: `${C_PREFIX_TRAITEMENT}${IdHelper.buildVirtualNode([UserData.currentSite._id, C_PREFIX_PATIENT])}`
		});

		return this.isvcStore.get<ITraitement>(loDataSource)
			.pipe(
				map((paPatientTraitements: ITraitement[]) =>
					paPatientTraitements.filter((poTraitement: ITraitement) => this.ioTraitementState.transform(poTraitement) === ETraitementState.enCours)),
				map((paTraitements: ITraitement[]) => paTraitements.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement)))
			);
	}

	public getTraitementsByStatus(pbLive?: boolean, poActivePageManager?: ActivePageManager, status?: ETraitementState, isExclude?: boolean): Observable<Traitement[]> {
		return this.getTraitementsANAKIN(pbLive, poActivePageManager).pipe(
			map(traitements =>
				traitements.filter(traitement =>
					isExclude
						? traitement.state !== status
						: traitement.state === status
				)
			)
		);
	}

	/** Lève un événement pour une demande d'enregistrement de traitement.
	 * @param poParams Traitement à enregistrer.
	 */
	public raiseSaveTraitementRequest(poParams: ISaveTraitementParams): void {
		this.moSaveTraitementRequest.next(poParams);
	}

	/** Récupère un observable qui fait transiter les traitements à enregistrer.
	 * @param psTraitementId Identifiant du traitement dont il faut récupérer les demandes d'enregistrement (pour ne pas envoyer une demande à tous).
	 */
	public getSaveTraitementRequestAsObservable(psTraitementId: string): Observable<ISaveTraitementParams> {
		return this.moSaveTraitementRequest.asObservable().pipe(filter((poParams: ISaveTraitementParams) => poParams.traitementId === psTraitementId));
	}

	public getIntervenantName(poIntervenant: IGroupMember): string {
		if (IdHelper.hasPrefixId(poIntervenant?._id, EPrefix.contact) || IdHelper.hasPrefixId(poIntervenant?._id, C_PREFIX_PATIENT))
			return ContactHelper.getCompleteFormattedName(poIntervenant as IContact);
		else if (IdHelper.hasPrefixId(poIntervenant?._id, EPrefix.group))
			return (poIntervenant as IGroup).name;
		return '';
	}

	/** Récupère les traitements d'un patient et vérifie s'il y en a "en cours" ou non.
	 * @param poPatient Patient dont il faut vérifier l'existence de traitements "en cours".
	 * @returns `true` s'il existe des traitements "en cours" pour ce patient, `false` sinon.
	 */
	public checkOngoingTraitements(poPatient: IPatient): Observable<boolean> {
		return this.getPatientTraitements(poPatient._id)
			.pipe(
				map((paPatientTraitements: ITraitement[]) =>
					paPatientTraitements.filter((poTraitement: ITraitement) => this.ioTraitementState.transform(poTraitement) === ETraitementState.enCours)
				),
				map((paOngoingTraitements: ITraitement[]) => ArrayHelper.hasElements(paOngoingTraitements))
			);
	}

	/** Récupère les traitements "en cours" d'un patient.
 * @param poPatient Patient dont il faut récupérer les traitements "en cours".
 * @returns tableau de traitements `Traitement[]` s'il existe des traitements "en cours" pour ce patient, `rien` sinon.
 */
	public getOngoingPatientTraitements(poPatient: IPatient): Observable<Traitement[]> {
		return this.getPatientTraitements(poPatient._id)
			.pipe(
				map((paPatientTraitements: ITraitement[]) =>
					paPatientTraitements.filter((poTraitement: ITraitement) => this.ioTraitementState.transform(poTraitement) === ETraitementState.enCours)
				),
				map((paTraitements: ITraitement[]) => paTraitements.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement)))
			);
	}

	/** Récupère les traitements liés à l'identifiant d'un patient.
	 * @param psPatientId Identifiant du patient dont on veut récupérer les traitements qui lui sont liés.
	 */
	public getPatientTraitements(psPatientId: string, pbLive?: boolean): Observable<ITraitement[]> {
		const loDataSource: IDataSource = this.getTraitementDataSource({
			isLive: pbLive,
			startKeyEndKey: IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite._id, psPatientId]), "")
		});

		return this.isvcStore.get<ITraitement>(loDataSource)
			.pipe(map((paTraitementsData: ITraitement[]) => paTraitementsData.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement))));
	}

	/** Ouvre un traitement existant.
	 * @param psTraitementId Identifiant du traitement qu'il faut ouvrir.
	 */
	public openTraitement(psTraitementId: string, paInitializingSlideActions: string[] = [], poParams?: IIndexedArray<any>): void {
		const loNavigationExtras: NavigationExtras = ArrayHelper.hasElements(paInitializingSlideActions) ? {
			queryParams: { ...(poParams ?? {}), actions: paInitializingSlideActions.join(",") }
		} : {};

		this.ioRouter.navigate(["traitements", psTraitementId, ERouteUrlPart.edit], loNavigationExtras);
	}

	/** Navigue vers la page de création d'un traitement.
	 * @param poSelectedPatient Patient auquel lié le nouveau traitement.
	 */
	public navigateToNewTraitement(poSelectedPatient: IPatient, psTraitementStartDate?: string, pbIsFirst?: boolean): void {
		const loQueryParams: Params = {
			startDate: psTraitementStartDate ? psTraitementStartDate : undefined
		};

		this.ioRouter.navigate(
			["traitements", ERouteUrlPart.new],
			{
				queryParams: loQueryParams,
				state: { patient: poSelectedPatient } as ITraitementSlidePageParams
			} as NavigationExtras
		);
	}

	/** Remplit le nombre de séances d'une liste de traitements.
	 * @param paTraitements
	 */
	public fillTraitementsSeancesNumber<T extends ITraitement>(paTraitements: T[]): Observable<T[]> {
		return this.isvcIntegrity.getLastIntegritiesByEntityIds(paTraitements.filter((poTraitement: T) => !poTraitement.nbSeances).map((poTraitement: T) => poTraitement._id))
			.pipe(
				tap((poIntegritiesByEntityIds: Map<string, ITraitementIntegrity>) => {
					paTraitements.forEach((poTraitement: ITraitement) => {
						if (poIntegritiesByEntityIds.has(poTraitement._id)) {
							const loIntegrity: IIntegrity = poIntegritiesByEntityIds.get(poTraitement._id);
							if (loIntegrity.relations?.events) {
								const laEventsList: string[] = Object.keys(loIntegrity.relations.events);
								poTraitement.nbSeances = laEventsList.length ?? 0;
							};
						};
					});
				}),
				mapTo(paTraitements)
			);
	}

	/** Crée un nouveau traitement en ajoutant automatiquement un prescripteur et le patient lié puis le retourne.
	 * @param poPatient Patient du traitement, nécessaire pour créer le nouveau traitement.
	 */
	@LogAction<Parameters<TraitementService["createTraitement"]>, ReturnType<TraitementService["createTraitement"]>>({
		actionId: EIdlLogActionId.traitementCreate,
		successMessage: "Création du traitement.",
		errorMessage: "Echec de la création du traitement.",
		dataBuilder: (_, poTraitement: Traitement) => ({ userId: UserData.current?._id, traitementId: poTraitement._id })
	})
	public createTraitement(poPatient: IPatient): Observable<Traitement> {
		return of(this.innerCreateTraitement(poPatient));
	}

	private innerCreateTraitement(poPatient: IPatient, poNewTraitement?: Traitement): Traitement {
		const loNewTraitement = ObjectHelper.isNullOrEmpty(poNewTraitement) ? new Traitement(poPatient._id) : poNewTraitement;
		this.deleteStateTermine(loNewTraitement);
		const loTraitementCacheData: ICacheData = { databaseId: StoreHelper.getDocumentCacheData(poPatient).databaseId, dirty: true };

		// On met à jour les cacheData.
		StoreHelper.updateDocumentCacheData(loNewTraitement, loTraitementCacheData);
		// On ajoute les pathologies automatiquement au traitement.
		loNewTraitement.pathologies = poPatient.pathologies;

		return loNewTraitement;
	}

	/** Duplique un traitement existant.
	 * @param psTraitementId Id du traitement à dupliquer.
	 */
	@LogAction<Parameters<TraitementService["duplicateTraitement"]>, ReturnType<TraitementService["duplicateTraitement"]>>({
		actionId: EIdlLogActionId.traitementDuplicate,
		successMessage: "Duplication du traitement.",
		errorMessage: "Echec de la duplication du traitement.",
		dataBuilder: (_, __, psTraitementId: string) => ({ userId: UserData.current?._id, traitementId: psTraitementId })
	})
	public duplicateTraitement(psTraitementId: string): Observable<boolean> {
		return this.getTraitement(psTraitementId)
			.pipe(
				mergeMap((poTraitement: Traitement) => {
					const loNewTraitement: Traitement = Traitement.createFromData(poTraitement);
					loNewTraitement._id = IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite._id, poTraitement.patientId]));
					loNewTraitement._rev = undefined;
					delete loNewTraitement.nbSeances;
					return this.isvcPatients.getPatient(poTraitement.patientId)
						.pipe(
							map((poPatient: IPatient) => this.innerCreateTraitement(poPatient, loNewTraitement))
						);
				}),
				mergeMap((poNewTraitement: Traitement) => this.saveTraitement(poNewTraitement))
			);
	}

	/** On sauvegarde le nouveau traitement, ou on demande à l'utilisateur de confirmer la mise à jour d'un traitement existant.
	 * @param poTraitement Traitement à enregistrer.
	 */
	@LogAction<Parameters<TraitementService["saveTraitement"]>, ReturnType<TraitementService["saveTraitement"]>>({
		actionId: EIdlLogActionId.traitementUpdate,
		successMessage: "Mise à jour du traitement.",
		errorMessage: "Echec de la mise à jour du traitement.",
		dataBuilder: (_, __, poTraitement: Traitement) => ({ userId: UserData.current?._id, traitementId: poTraitement._id })
	})

	public saveTraitement(poTraitement: Traitement): Observable<boolean> {
		let loader: Loader;

		return from(this.isvcLoading.create("Mise à jour du traitement...")).pipe(
			tap((createdLoader: Loader) => loader = createdLoader),
			mergeMap(() => loader.present()),
			mergeMap(() =>
				StoreHelper.isDocumentDirty(poTraitement) ? this.saveTraitementDocument(poTraitement) : of(undefined)
			),
			mergeMap(() => this.isvcEntityLink.saveEntityLinks(poTraitement)),
			tap(() => console.debug("TRAIT.S::Traitement enregistré.")),
			finalize(() => loader?.dismiss())
		);
	}

	public saveTraitementANAKIN(poTraitement: Traitement): Observable<Traitement> {
		return of(true).pipe(
			mergeMap(() => {
				return this.saveTraitementDetail(poTraitement)
			}),
			mergeMap(() => {
				return this.isvcEntityLink.saveEntityLinks(poTraitement);
			}),
			mergeMap(() => {
				return this.getTraitementANAKIN(poTraitement._id);
			}),
			tap((updatedOrdonnance) => {
				this.ordonnanceChangeSubject.next(updatedOrdonnance);
			}),
			catchError(error => {
				console.error("Erreur lors de l'enregistrement du traitement :", error);
				return of(null);
			})
		);
	}

	private saveTraitementDocument(poTraitement: Traitement): Observable<boolean> {
		return this.isvcPatients.getPatient(poTraitement.patientId).pipe(
			mergeMap((poPatient: IPatient) => this.isvcSeance.generateSeances(poTraitement, poPatient)),
			mergeMap(() => this.saveTraitementDetail(poTraitement))
		);
	}

	private saveTraitementDetail(poTraitement: Traitement): Observable<boolean> {
		const laNewEventMarkers: IEventMarker[] = this.generateTraitementEventMarkers(poTraitement);

		//On doit vérifier si les séances sont toutes validées
		this.verifierTraitementSeancesValidees(poTraitement);

		let loLoader: Loader;
		return this.createTraitementIntegrity(poTraitement, laNewEventMarkers)
			.pipe(
				mergeMap((poTraitementIntegrity: ITraitementIntegrity) => {
					return this.isvcIntegrity.getLastIntegrity(IdHelper.buildId(EPrefix.integrity, poTraitement._id)).pipe(
						mergeMap((poOldIntegrity: ITraitementIntegrity) => {
							const laNewEventIds: string[] = laNewEventMarkers.map((poEventMarker: IEventMarker) => poEventMarker._id);
							const laOldEventIds: string[] = Object.keys(poOldIntegrity?.relations?.events ?? {});
							const laEventIdsToDelete: string[] = ArrayHelper.getDifferences(laOldEventIds, laNewEventIds, this.compareEventIds);
							const laEventIdsToAdd: string[] = ArrayHelper.getDifferences(laNewEventIds, laOldEventIds, this.compareEventIds);
							const laDocumentsToSave: IStoreDocument[] = [poTraitement];

							return defer(() => ArrayHelper.hasElements(laEventIdsToDelete) ? this.isvcStore.get({ databaseId: StoreHelper.getDatabaseIdFromCacheData(poTraitement), viewParams: { include_docs: true, keys: laEventIdsToDelete } }) : of([])).pipe(
								tap((paEventsToDelete: IStoreDocument[]) => {
									paEventsToDelete.forEach((poEventToDelete: IStoreDocument) => {
										poEventToDelete._deleted = true;
										laDocumentsToSave.push(poEventToDelete);
									});
									if (ArrayHelper.hasElements(laEventIdsToAdd)) {
										laNewEventMarkers.forEach((poEventMarker: IEventMarker) => {
											if (ArrayHelper.removeElement(laEventIdsToAdd, poEventMarker._id))
												laDocumentsToSave.push(poEventMarker);
										});
									}
									if (!ArrayHelper.areArraysEqual(Object.keys(poOldIntegrity?.relations?.events ?? {}), Object.keys(poTraitementIntegrity.relations.events)))
										laDocumentsToSave.push(poTraitementIntegrity);
								}),
								mergeMap(async () => {
									if (laDocumentsToSave.length > TraitementService.C_MAX_DOCS_TO_SAVE_WITHOUT_LOADER)
										loLoader = await (await this.isvcLoading.create("Veuillez patienter ...")).present();
								}),
								mergeMap(() => this.isvcStore.putMultipleDocuments(laDocumentsToSave, StoreHelper.getDatabaseIdFromCacheData(poTraitement), undefined, true)),
								finalize(() => loLoader?.dismiss())
							);
						})
					);
				}),
				map((paResponses: IStoreDataResponse[]) => paResponses.every((poResponse: IStoreDataResponse) => poResponse.ok))
			);
	}

	private verifierTraitementSeancesValidees(poTraitement: Traitement) {
		if (!ArrayHelper.hasElements(poTraitement.seances)) return;

		if (poTraitement.seances.some(x => !x.isProtected)) {
			//Toutes les séances ne sont pas validées, on retire donc le statut terminé du traitement
			delete poTraitement.state;
		} else {
			//toutes les séances sont validées
			poTraitement.state = ETraitementState.termine;
		}
	}

	private compareEventIds(psIdA: string, psIdB: string): boolean {
		return IdHelper.extractParentId(psIdA) === IdHelper.extractParentId(psIdB);
	}

	private generateTraitementEventMarkers(poTraitement: Traitement): IEventMarker[] {
		return ArrayHelper.flat(poTraitement.seances.map((poSeance: Seance) => this.isvcEvents.generateEventMarkers({
			_id: poTraitement._id,
			startDate: poSeance.startDate,
			endDate: poSeance.endDate
		}, undefined, undefined, (pdEvent: Date) => this.isvcSeance.generateSeanceEventId(pdEvent, poTraitement))));
	}

	private createTraitementIntegrity(poTraitement: Traitement, paEventMarkers: IEventMarker[]): Observable<ITraitementIntegrity> {
		const lsIntegrityId = IdHelper.buildChildId(EPrefix.integrity, poTraitement._id, DateHelper.toUTCString(new Date, EUTCAccuracy.milliseconds));
		const loEvents: IIndexedArray<string> = {};

		paEventMarkers.map((poEventMarker: IEventMarker) => loEvents[poEventMarker._id] = "");

		const loTraitementIntegrity: ITraitementIntegrity = {
			_id: lsIntegrityId,
			userId: UserData.current?._id,
			deviceId: ConfigData.appInfo?.deviceId,
			previousIntgId: "",
			appVersion: ConfigData.appInfo?.appVersion,
			parentRev: poTraitement._rev,
			relations: {
				events: loEvents
			}
		};

		return this.isvcIntegrity.getLastIntegrity(ArrayHelper.getFirstElement(lsIntegrityId.split("-")))
			.pipe(
				map((poPreviousIntegrity: IIntegrity) => {
					if (!ObjectHelper.isNullOrEmpty(poPreviousIntegrity))
						loTraitementIntegrity.previousIntgId = poPreviousIntegrity._id;
					return loTraitementIntegrity;
				})
			);
	}

	/** Construit le sous chemin vers un acte dans le traitement.
	 * @param psActeGuid
	 */
	public buildActeSubPath(psActeGuid: string): string {
		return `actes${EntityLinkService.C_DEEPLINK_SUB_PATH_SEPARATOR}${psActeGuid}`;
	}

	public generateTraitementActePath(poTraitement: Traitement, poActe: Acte): string {
		return `${poTraitement._id}${EntityLinkService.C_DEEPLINK_SUB_PATH_SEPARATOR}actes${EntityLinkService.C_DEEPLINK_SUB_PATH_SEPARATOR}${poActe.guid}`;
	}

	/** Supprime un traitement avec ses séances et liens.
	 * @param poTraitement
	 */
	@LogAction<Parameters<TraitementService["deleteTraitement"]>, ReturnType<TraitementService["deleteTraitement"]>>({
		actionId: EIdlLogActionId.traitementDelete,
		successMessage: "Suppression du traitement.",
		errorMessage: "Echec de la suppression du traitement.",
		dataBuilder: (_, __, poTraitement: Traitement, pbForceDelete?: boolean) => ({ userId: UserData.current?._id, traitementId: poTraitement._id, forceDelete: pbForceDelete })
	})
	public deleteTraitement(poTraitement: ITraitement, pbForceDelete?: boolean): Observable<boolean> {
		const loTraitement: Traitement = ModelResolver.toClass(poTraitement, Traitement);
		const loInit$: Observable<boolean> = pbForceDelete ? of(true) : this.isvcEntityLink.ensureIsDeletableEntity(loTraitement);
		return loInit$
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => this.innerDeleteTraitement(loTraitement)),
				defaultIfEmpty(false)
			);
	}

	private innerDeleteTraitement(poTraitement: Traitement): Observable<boolean> {
		// delete if prestation
		return this.isvcEntityLink.deleteEntityLinksById(poTraitement._id)
			.pipe(
				mergeMap(() => this.isvcEvents.getEntityEventMarkerIds(
					IdHelper.getLastGuidFromId(poTraitement._id),
					{ from: poTraitement.beginDate, to: poTraitement.endDate },
					false,
					(pdEvent?: Date) => this.isvcSeance.generateSeanceEventId(pdEvent, poTraitement)
				)),
				mergeMap((paIds: string[]) => this.isvcEvents.deleteEventMarkers(paIds, StoreHelper.getDatabaseIdFromCacheData(poTraitement))),
				mergeMap(_ => this.isvcStore.delete(poTraitement)),
				map((poResponse: IStoreDataResponse) => poResponse.ok)
			);
	}


	/** Vérifie si on peut supprimer un traitement
	 *
	 * @param poSeances Les séances du traitement
	 * @returns true si on peut supprimer le traitement false sinon
	 */
	public canDeleteTraitement(poSeances: Seance[]): boolean {
		return !poSeances.some((poSeance: Seance) =>
			poSeance.isBilled || poSeance.isBillable
		)
	}

	/** Interrompt le traitement.
	 * @param poTraitement Traitement à annuler.
	 * @param paSeances Séances du traitement.
	 * @param pdBreakDate Date d'interruption du traitement.
	 * @param psExplanation Explication.
	 */
	@LogAction<Parameters<TraitementService["stopTraitement"]>, ReturnType<TraitementService["stopTraitement"]>>({
		actionId: EIdlLogActionId.traitementStop,
		successMessage: "Interruption du traitement.",
		errorMessage: "Echec de l'interruption du traitement.",
		dataBuilder: (_, __, poTraitement: Traitement) => ({ userId: UserData.current?._id, traitementId: poTraitement._id })
	})
	public stopTraitement(poTraitement: Traitement, paSeances: Seance[], pdBreakDate: Date, psExplanation: string): Observable<Traitement> {
		poTraitement.breakDate = pdBreakDate;
		poTraitement.explanation = psExplanation;
		StoreHelper.makeDocumentDirty(poTraitement);

		return this.cancelSeancesInRange(
			{ from: poTraitement.breakDate },
			paSeances.filter((poSeance: Seance) => poSeance.startDate >= poTraitement.breakDate),
			poTraitement,
			false
		)
			.pipe(mapTo(poTraitement));
	}

	//Permet de modifier la propriété "state" du traitement
	public modifierStateTraitement(idTraitementOrTraitemetAMaj: string | Traitement, newState?: ETraitementState): Observable<boolean> {
		let traitementAMaj$: Observable<Traitement>;

		if (typeof idTraitementOrTraitemetAMaj === 'string') {
			traitementAMaj$ = this.getTraitement(idTraitementOrTraitemetAMaj);
		} else {
			traitementAMaj$ = of(idTraitementOrTraitemetAMaj);
		}

		return traitementAMaj$.pipe(
			switchMap((traitementAMaj: Traitement) => {
				if (!traitementAMaj || traitementAMaj.state === newState) {
					return of(true); // Retourne true si le traitement n'existe pas ou s'il est déjà dans le nouvel état
				} else {
					if (!newState) {
						delete traitementAMaj.state;
					} else {
						traitementAMaj.state = newState;
					}
					return this.isvcStore.put(traitementAMaj).pipe(
						map((poResult: IStoreDataResponse) => poResult.ok),
						catchError(() => of(false)) // En cas d'erreur, retourne false
					);
				}
			}),
			catchError(() => of(false)) // En cas d'erreur, retourne false
		);
	}

	/**
 * Permet de supprimer l'état "Terminé" du traitement
 */
	private deleteStateTermine(traitement: Traitement): void {
		if (traitement.state === ETraitementState.termine) {
			delete traitement.state;
		}
	}

	/** Réactive le traitement.
	 * @param poTraitement Traitement à réactiver.
	 * @param paSeances Séances du traitement.
	 */
	@LogAction<Parameters<TraitementService["reactivateTraitement"]>, ReturnType<TraitementService["reactivateTraitement"]>>({
		actionId: EIdlLogActionId.traitementReactivate,
		successMessage: "Réactivation du traitement.",
		errorMessage: "Echec de la réactivation du traitement.",
		dataBuilder: (_, __, poTraitement: Traitement) => ({ userId: UserData.current?._id, traitementId: poTraitement._id })
	})
	public reactivateTraitement(poTraitement: Traitement, paSeances: Seance[]): Observable<Traitement> {
		const ldNow: Date = DateHelper.resetDay(new Date);
		if (poTraitement.breakDate || poTraitement.explanation) {
			delete poTraitement.breakDate;
			delete poTraitement.explanation;
			StoreHelper.makeDocumentDirty(poTraitement);
		}

		return this.reactivateFrom(ldNow, paSeances, poTraitement).pipe(
			mapTo(poTraitement)
		);
	}

	/** Permet d'extraire les identifiants des patients liés aux traitements passés en paramètre.
	 * @param paTraitements
	 */
	public extractPatientsIds(paTraitements: ITraitement[]): string[] {
		return paTraitements.map((poTraitement: ITraitement) => Traitement.extractPatientId(poTraitement._id));
	}

	public getResumeActes(poTraitement: ITraitement): string {
		return this.ioConcatActesPipe.transform(poTraitement.actes.map((poActe: IActe) => new Acte(poActe)), ",");
	}

	public replaceActe(poTraitement: Traitement, paSeances: Seance[], pnActeIndex: number, poOldActe: Acte, poNewActe: Acte): Traitement {
		const loNewActe: Acte = this.isvcActe.applyNewActe(poOldActe, poNewActe);

		if (loNewActe) {
			this.isvcSeance.applyNewActe(paSeances, poOldActe, loNewActe);
			ArrayHelper.replaceElementByIndex(poTraitement.actes, pnActeIndex, poNewActe);
			StoreHelper.makeDocumentDirty(poTraitement);
		}

		return poTraitement;
	}

	/** Remplace l'acte d'un traitement à partir de la séance passée en paramètre.
	 * @param poTraitement
	 * @param poFrom
	 * @param paSeances
	 * @param poOldActe
	 * @param poNewActe
	 * @returns
	 */
	public replaceActeFrom(poTraitement: Traitement, poFrom: Seance, paSeances: Seance[], poOldActe: Acte, poNewActe: Acte): Traitement {
		const loNewActe: Acte = this.isvcActe.applyNewActe(poOldActe, poNewActe);

		if (loNewActe) {
			loNewActe.startDate = DateHelper.resetDay(poFrom.startDate);
			poOldActe.endDate = DateHelper.fillDay(DateHelper.addDays(loNewActe.startDate, -1));

			const loDateWithSeances: IDateWithSeances = this.isvcSeance.groupSeancesByDays(
				SeanceService.sortSeancesByDate(paSeances.filter((poSeance: Seance) => poSeance.actes.some((poActe: Acte) => poActe.guid === poOldActe.guid)))
			).find((poDateWithSeance: IDateWithSeances) => DateHelper.diffDays(poDateWithSeance.date, poFrom.startDate) === 0);

			const lnSeanceIdx: number = loDateWithSeances.seances.findIndex((poSeance: Seance) => poFrom.equals(poSeance));

			poOldActe.endOffset = loDateWithSeances.seances.length - lnSeanceIdx;
			loNewActe.startOffset = lnSeanceIdx;

			this.isvcSeance.applyNewActe(paSeances.filter((poSeance: Seance) => DateHelper.compareTwoDates(poSeance.startDate, poFrom.startDate) >= 0), poOldActe, loNewActe);
			poTraitement.actes.push(loNewActe);
			StoreHelper.makeDocumentDirty(poTraitement);
			this.removeActesWithoutSeance(poTraitement, paSeances);
		}

		return poTraitement;
	}

	public replaceActeForSeance(poTraitement: Traitement, poSeance: Seance, paSeances: Seance[], poOldActe: Acte, poNewActe: Acte): Traitement {
		const loOtherOldActe: Acte = new Acte(poOldActe);

		if (poNewActe) {
			poOldActe.endDate = DateHelper.resetDay(poSeance.startDate);
			poNewActe.startDate = loOtherOldActe.startDate = new Date(poOldActe.endDate);
			const loRecurrence = new Recurrence;
			loRecurrence.repetitionType = ERepetition.jours;
			loRecurrence.dayRepetitions.push(new HoursMinutesRepetition({ hours: poSeance.startDate.getHours(), minutes: poSeance.startDate.getMinutes() }));
			poNewActe.recurrences.push(loRecurrence);

			const loDateWithSeances: IDateWithSeances = this.isvcSeance.groupSeancesByDays(
				SeanceService.sortSeancesByDate(paSeances.filter((poSeanceToFilter: Seance) => poSeanceToFilter.actes.some((poActe: Acte) => poActe.guid === poOldActe.guid)))
			).find((poDateWithSeance: IDateWithSeances) => DateHelper.diffDays(poDateWithSeance.date, poSeance.startDate) === 0);

			const lnSeanceIdx: number = loDateWithSeances.seances.findIndex((poOtherSeance: Seance) => poSeance.equals(poOtherSeance));

			poOldActe.endOffset = loDateWithSeances.seances.length - lnSeanceIdx;
			loOtherOldActe.startOffset = lnSeanceIdx + 1; // Pour laisser la place de l'acte remplacé.

			this.isvcSeance.applyNewActe(poSeance, poOldActe, poNewActe);
			// On réaplique l'ancien acte pour être sûr d'avoir les modifs.
			ArrayHelper.replaceElementByFinder(poTraitement.actes, (poActe: Acte) => poActe.guid === poOldActe.guid, poOldActe);

			poTraitement.actes.push(poNewActe);

			if (DateHelper.diffDays(poOldActe.startDate, loOtherOldActe.startDate) !== 0)
				poTraitement.actes.push(loOtherOldActe);

			StoreHelper.makeDocumentDirty(poTraitement);
			this.removeActesWithoutSeance(poTraitement, paSeances);
		}

		return poTraitement;
	}

	private removeActesWithoutSeance(poTraitement: Traitement, paSeances: Seance[]): void {
		const laActesGuids: string[] = ArrayHelper.unique(ArrayHelper.flat(paSeances.map((poSeance: Seance) => poSeance.actes.map((poActe: Acte) => poActe.guid))));

		if (ArrayHelper.hasElements(ArrayHelper.removeElementsByFinder(poTraitement.actes, (poActe: Acte) => !laActesGuids.includes(poActe.guid))))
			StoreHelper.makeDocumentDirty(poTraitement);
	}

	public initNumberOfTraitEndingSoon(): void {
		const ldToday = new Date();

		const loActivePageManager = new ActivePageManager(this, this.ioRouter, (psNewUrl: string) => psNewUrl.indexOf(ApplicationService.C_HOME_ROUTE_URL) >= 0);

		this.isvcFlag.waitForFlag(EFlag.appAvailable, true)
			.pipe(
				//TODO implémenter une solution pour relancer le calcul quand on change de jour avec l'application ouverte.
				switchMap(_ => this.getTraitements(true, loActivePageManager)),
				map((paTraitements: Traitement[]) => paTraitements.filter((poTraitement: Traitement) => Traitement.isTraitementActive(poTraitement, ldToday) && poTraitement.endsSoon).length),
				tap((pnNumberOfTraitementEndingSoon: number) => this.isvcGlobalData.setData(TraitementService.C_TRAITEMENT_ENDING_SOON_MARKER_KEY, pnNumberOfTraitementEndingSoon))
			)
			.subscribe();
	}

	public getTraitementsUpdates(): Observable<IChangeEvent<Traitement>> {
		return this.isvcStore.localChanges(this.getTraitementDataSource({ isLive: true, startKeyEndKey: C_PREFIX_TRAITEMENT }))
			.pipe(tap((poEvent: IChangeEvent<Traitement>) => {
				poEvent.document = Traitement.createFromData(poEvent.document);
			}));
	}

	public async changeAMIOrAMXActesToAMXOrAMI(poTraitement: Traitement, paSeances: Seance[], pdFrom: Date = new Date()): Promise<void> {
		pdFrom = DateHelper.compareTwoDates(pdFrom, new Date()) === 0 ? DateHelper.resetDay(DateHelper.addDays(pdFrom, 1)) : pdFrom;
		const laActesToCheck: Acte[] = poTraitement.actes.filter((poActe: Acte) => ArrayHelper.hasElements(TraitementService.getActeSeancesFrom(poActe, paSeances, pdFrom)));

		for (let lnIndex = 0; lnIndex < laActesToCheck.length; ++lnIndex) {
			const poOldActe: Acte = laActesToCheck[lnIndex];
			const lsNewActeId: string = poTraitement.pathologies?.includes(EPathologie.dependant) ? poOldActe.acteAMX : poOldActe.acteAMI;

			if (!StringHelper.isBlank(lsNewActeId)) {
				const loNewActe: Acte = await this.isvcActe.getActeById(lsNewActeId).toPromise();
				if (!ObjectHelper.isNullOrEmpty(loNewActe)) {
					const loFromSeance: Seance = ArrayHelper.getFirstElement(SeanceService.sortSeancesByDate(TraitementService.getActeSeancesFrom(poOldActe, paSeances, pdFrom)));
					this.replaceActeFrom(poTraitement, loFromSeance, paSeances, poOldActe, loNewActe);
				};
			};
		};
	}

	public static getActeSeancesFrom(poActe: Acte, paSeances: Seance[], pdFrom: Date): Seance[] {
		return paSeances?.filter((poSeance: Seance) => poSeance.startDate >= pdFrom && poSeance.actes.map((poSeanceActe: Acte) => poSeanceActe.guid).includes(poActe.guid));
	}

	/** Met à jour le traitement si les pathologies ont chnagées. Retourne `true` si les séances ont été modifiées.
	 * @param poTraitement
	 * @param paSeances
	 * @param paPathologies
	 */
	public onTraitementPathologiesChanged(poTraitement: Traitement, paSeances: Seance[], paPathologies: EPathologie[] = []): Observable<boolean> {
		const lbForceUpdate: boolean = typeof poTraitement.pathologies === "string"; // Pathologies dans l'ancien format.
		poTraitement.pathologies = poTraitement.pathologies ? coerceArray(poTraitement.pathologies) : [];
		const leUnselectedPathologie: EPathologie = ArrayHelper.getFirstElement(ArrayHelper.getDifferences(poTraitement.pathologies, paPathologies));
		const leSelectedPathologie: EPathologie = ArrayHelper.getFirstElement(ArrayHelper.getDifferences(paPathologies, poTraitement.pathologies));
		const lbDependantChange: boolean = leUnselectedPathologie === EPathologie.dependant || leSelectedPathologie === EPathologie.dependant;

		return this.updatePatientPathologieRequest(poTraitement, paPathologies, lbDependantChange, lbForceUpdate)
			.pipe(
				mergeMap(() => {
					poTraitement.pathologies = paPathologies;

					if (!ArrayHelper.hasElements(paSeances))
						return of(false);

					if ((!StringHelper.isBlank(leUnselectedPathologie) && leUnselectedPathologie === EPathologie.dependant) ||
						(!StringHelper.isBlank(leSelectedPathologie) && leSelectedPathologie === EPathologie.dependant)) {
						return from(this.changeAMIOrAMXActesToAMXOrAMI(poTraitement, paSeances))
							.pipe(mapTo(true));
					}
					else
						return of(leUnselectedPathologie === EPathologie.palliatif || leSelectedPathologie === EPathologie.palliatif);
				})
			);
	}

	private updatePatientPathologieRequest(poTraitement: ITraitement, paPathologies: EPathologie[], pbDependantChange?: boolean, pbForceUpdate?: boolean): Observable<boolean> {
		const lsHeader: string = paPathologies.includes(EPathologie.dependant) ?
			"Attention les AMI vont etre changés en AMX" :
			"Attention les AMX vont etre changés en AMI";

		const loParamPopup = pbDependantChange && poTraitement.actes?.length > 0 ?
			new ShowMessageParamsPopup({
				header: lsHeader,
				buttons: [
					{ text: "Ok", handler: () => UiMessageService.getFalsyResponse() }
				]
			}) :
			new ShowMessageParamsPopup({
				header: "Voulez-vous mettre à jour la fiche patient ?",
				buttons: [
					{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
				]
			});

		const loInit$: Observable<IUiResponse<boolean, any>> = pbForceUpdate ? of(UiMessageService.getTruthyResponse()) : this.isvcUiMessage.showAsyncMessage(loParamPopup);

		return loInit$.pipe(
			mergeMap((poUiResponse: IUiResponse<any, any>) => {
				if (poUiResponse.response) {
					return this.isvcPatients.getPatient(Traitement.extractPatientId(poTraitement._id))
						.pipe(
							mergeMap((poPatient: IPatient) => {
								poPatient.pathologies = paPathologies;
								return this.isvcPatients.savePatient(poPatient);
							})
						);
				}
				return of(false);
			})
		);
	}

	/** Effectue une action pour un ensemble de séances et ajoute les contraintes dans le traitement.
	 * @param paSeances Tableau des séances à modifier.
	 * @param pfSeanceAction Fonction effectuant l'action sur chaque séance.
	 * @param poTraitement
	 * @param pfCreateConstraint Fonction de création de la contrainte.
	 */
	public changeSeancesWithConstraint(
		paSeances: Seance[],
		pfSeanceAction: (poSeance: Seance, poTraitement: Traitement) => Observable<Seance> | Seance | void,
		poTraitement?: Traitement,
		pfCreateConstraint?: (poActe: Acte) => Constraint
	): Observable<Seance[]> {
		if (poTraitement) {
			let isConstraintCancel: boolean = false;
			return defer(() => {
				if (pfCreateConstraint) {
					const loSeancesByActeGuid = new Map<string, Seance[]>();
					paSeances.forEach((poSeance) => poSeance.actes.forEach((poActe) => {
						let laSeance = loSeancesByActeGuid.get(poActe.guid);
						if (!laSeance)
							loSeancesByActeGuid.set(poActe.guid, laSeance = []);

						laSeance.push(poSeance);
					}));
					poTraitement.actes.filter((Acte) => loSeancesByActeGuid.has(Acte.guid)).forEach((poActe: Acte) => {
						const loConstraint: Constraint = pfCreateConstraint(poActe);
						isConstraintCancel = this.updateConstraintStatusCancel(isConstraintCancel, loConstraint);
						if (loConstraint) {
							const laSeance = loSeancesByActeGuid.get(poActe.guid);
							laSeance.forEach((poSeance) => {
								if (poSeance.isProtected)
									loConstraint.ExcludeOccurrenceComparators.push(new ActeOccurrenceDateTimeComparator({ day: poSeance.startDate, hours: poSeance.startDate.getHours(), minutes: poSeance.startDate.getMinutes() }))
							})
							poActe.constraints.push(loConstraint);
							StoreHelper.makeDocumentDirty(poTraitement);
						}
					});

					if (StoreHelper.isDocumentDirty(poTraitement) && !isConstraintCancel) {
						this.deleteStateTermine(poTraitement);
						return this.saveTraitement(poTraitement).pipe(mapTo(paSeances));
					}
				}

				return of(paSeances);
			})
				.pipe(
					mergeMap((paResultSeances: Seance[]) => paResultSeances),
					concatMap((poSeance: Seance) => {
						const loResult: Observable<Seance> | Seance | void = poSeance.isProtected ? poSeance : pfSeanceAction(poSeance, poTraitement);
						if (loResult instanceof Observable)
							return loResult;
						return of(loResult ?? poSeance) as Observable<Seance>;
					}),
					toArray()
				);
		}

		return this.groupeSeancesByTraitement(paSeances).pipe(
			mergeMap((poSeancesByTraitement: Map<Traitement, Seance[]>) => {
				const laCancelTraitementSeances: Observable<Seance[]>[] = [];
				poSeancesByTraitement.forEach((paTraitementSeances: Seance[], poGroupTraitement: Traitement) => {
					laCancelTraitementSeances.push(
						this.changeSeancesWithConstraint(paTraitementSeances, pfSeanceAction, poGroupTraitement, pfCreateConstraint)
					);
				});

				return concat(...laCancelTraitementSeances);
			}),
			toArray(),
			map((paSeancesResults: Seance[][]) => ArrayHelper.flat(paSeancesResults))
		);
	}

	private updateConstraintStatusCancel(isConstraintCancel: boolean, loConstraint: { type: EConstraintType | null }): boolean {
		if (isConstraintCancel) {
			return true;
		} else {
			if (loConstraint === undefined) {
				return false;
			}
			return loConstraint.type === EConstraintType.cancel;
		}
	}

	private groupeSeancesByTraitement(paSeances: Seance[]): Observable<Map<Traitement, Seance[]>> {
		return this.isvcStore.get<ITraitement>({
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paSeances.map((poSeance: Seance) => poSeance.traitementId),
				include_docs: true
			}
		}).pipe(
			map((paTraitements: ITraitement[]) => paTraitements.map((poTraitement: ITraitement) => Traitement.createFromData(poTraitement))),
			map((paTraitements: Traitement[]) => {
				const loTraitementsById: Map<string, Traitement> = ArrayHelper.groupByUnique(paTraitements, (poTraitement: Traitement) => poTraitement._id);

				return ArrayHelper.groupBy(paSeances, (poSeance: Seance) => loTraitementsById.get(poSeance.traitementId));
			})
		);
	}

	/** Annule les séances.
	 * @param poRange
	 * @param paSeances Tableau des séances à annuler.
	 * @param poTraitement
	 * @param pbCreatePrestation
	 */
	public cancelSeancesInRange(poRange: IRange<Date>, paSeances: Seance[], poTraitement?: Traitement, pbCreatePrestation?: boolean): Observable<Seance[]> {
		return this.changeSeancesWithConstraint(
			paSeances,
			(poActionSeance: Seance, poActionTraitement: Traitement) => this.isvcSeance.cancelSeance(poActionSeance, poActionTraitement, pbCreatePrestation),
			poTraitement,
			() => {
				const loConstraint = new CancelConstraint;
				loConstraint.occurrenceComparators.push(new ActeOccurrenceRangeComparator(poRange));
				return loConstraint;
			}
		);
	}

	/** Annule les séances.
	 * @param poDateTime
	 * @param paSeances Tableau des séances à annuler.
	 * @param poTraitement
	 * @param pbCreatePrestation
	 */
	public cancelDaySeances(poDateTime: IDateTime, paSeances: Seance[], poTraitement: Traitement, pbCreatePrestation?: boolean): Observable<Seance[]> {
		return this.changeSeancesWithConstraint(
			paSeances,
			(poActionSeance: Seance, poActionTraitement: Traitement) => this.isvcSeance.cancelSeance(poActionSeance, poActionTraitement, pbCreatePrestation),
			poTraitement,
			() => {
				const loConstraint = new CancelConstraint;
				loConstraint.occurrenceComparators.push(new ActeOccurrenceDateTimeComparator(poDateTime));
				return loConstraint;
			}
		);
	}

	/** Réactive les séances à partir d'une date.
	 * @param pdFrom
	 * @param paSeances
	 * @param poTraitement
	 * @param pdTo
	 */
	public reactivateFrom(pdFrom: Date, paSeances: Seance[], poTraitement: Traitement, pdTo?: Date): Observable<Seance[]> {
		const laSeances: Seance[] = paSeances.filter((poSeance: Seance) => poSeance.startDate >= pdFrom);

		return this.changeSeancesWithConstraint(
			laSeances,
			(poSeance: Seance) => this.isvcSeance.reactivate(poSeance),
			poTraitement,
			() => this.isvcSeance.getConstraint(
				ReactivateConstraint,
				{ filters: [EChoiceFilter.future], seances: laSeances },
				ArrayHelper.getFirstElement(SeanceService.sortSeancesByDate(laSeances)),
				pdTo
			)
		);
	}

	/** Valide plusieurs séances selon la date choisi.
	 * @param peAction
	 * @param paSeances
	 * @param poStartSeance
	 * @param pdEndDate
	 * @param poTraitement
	 * @returns `true` si une des séances comportent plusieurs intervenants.
	 */
	public adminActionOnMultipleSeances(
		peAction: EAdminActionOnMultipleSeances,
		paSeances: Seance[],
		poStartSeance: Seance,
		pdEndDate: Date,
		poTraitement: Traitement
	): Observable<boolean> {
		pdEndDate = DateHelper.fillDay(pdEndDate);

		return this.reactivateFrom(
			poStartSeance.startDate,
			paSeances.filter((poSeance: Seance) => poStartSeance.startDate <= poSeance.startDate && poSeance.endDate <= pdEndDate),
			poTraitement,
			pdEndDate
		).pipe(
			mergeMap((paResultSeances: Seance[]) => paResultSeances),
			mergeMap((poSeance: Seance) => {
				if (peAction === EAdminActionOnMultipleSeances.validate)
					return this.isvcSeance.validateSeance(poSeance, poTraitement, true);
				else if (peAction === EAdminActionOnMultipleSeances.unvalidate)
					return of(this.isvcSeance.unvalidateSeance(poSeance));
				return EMPTY;
			}),
			map((poSeance: Seance) => this.isvcSeance.hasSeanceMultipleIntervenant(poSeance)),
			toArray(),
			map((paResults: boolean[]) => paResults.includes(true)),
			defaultIfEmpty(false)
		);
	}

	public addIndemnite(
		poTraitement: Traitement,
		paSeances: Seance[],
		poIndemnite: IExtraCharge<EIndemniteType>,
		paDeplacementsByProfession: IDeplacementByProfession[]
	): void {
		const loIndemnite: ExtraCharge<EIndemniteType> = poTraitement.addIndemnite(poIndemnite);

		paSeances.forEach((poSeance: Seance) => {
			if (loIndemnite.match(poSeance.startDate) && !poSeance.wasProtected(loIndemnite.updateDate ?? loIndemnite.createDate)) {
				if (loIndemnite.disabled)
					this.isvcSeance.disableIndemnite(poSeance, loIndemnite.id);
				else
					this.isvcSeance.enableIndemnite(poTraitement, poSeance, loIndemnite.id, paDeplacementsByProfession);
			}
		});
	}

	public addMajoration(poTraitement: Traitement, paSeances: Seance[], poMajoration: IExtraCharge<EMajorationType>, poMajorationToAdd: Majoration): void {
		const loMajoration: ExtraCharge<EMajorationType> = poTraitement.addMajoration(poMajoration);

		paSeances.forEach((poSeance: Seance) => {
			if (loMajoration.match(poSeance.startDate) && !poSeance.wasProtected(loMajoration.updateDate ?? loMajoration.createDate)) {
				if (loMajoration.disabled)
					this.isvcSeance.disableMajoration(poSeance, loMajoration.id);
				else
					this.isvcSeance.enableMajoration(poSeance, poMajorationToAdd);
			}
		});
	}

	/** Applique un nouvel horaire à un groupe de séances.
	 * @param pdNewDate Nouvel horaire.
	 * @param poSourceSeance Séance source de la modification.
	 * @param poResponse Réponse contenant les séances à impacter.
	 * @param poTraitement
	 */
	public applyNewDateToSeances(
		pdNewDate: Date,
		poSourceSeance: Seance,
		poResponse: IChooseSeancesToModifiyResponse,
		poTraitement?: Traitement
	): Observable<Seance[]> {
		ArrayHelper.pushIfNotPresent(poResponse.seances, poSourceSeance, (poSeance: Seance) => poSeance.equals(poSourceSeance));
		const lnDeltaMinutes: number = DateHelper.diffMinutes(pdNewDate, poSourceSeance.startDate);

		return this.changeSeancesWithConstraint(
			poResponse.seances,
			(poSeance: Seance) => {
				this.isvcSeance.setSeanceDates(poSeance, DateHelper.addMinutes(poSeance.startDate, lnDeltaMinutes));
				return of(poSeance);
			},
			poTraitement,
			() => {
				const loConstraint: DelayConstraint = this.isvcSeance.getConstraint(
					DelayConstraint,
					poResponse,
					poSourceSeance
				);

				loConstraint.setDelay(poSourceSeance.startDate, pdNewDate);

				return loConstraint;
			}
		);
	}

	/** Permet d'afficher la modale d'annulation d'acte.
 * @param poSeance
 * @param poTraitement
 * @param paSeances
 * @param poActe
 */
	public cancelActeWithModal(poSeance: Seance, poTraitement: Traitement, paSeances: Seance[], poActe: Acte): Observable<Seance[]> {
		return defer(() => this.ioModalCtrl.create({ component: CancelActeModalComponent }))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResult: OverlayEventDetail<ICancelActeResponse>) => !!poResult.data),
				mergeMap((poResult: OverlayEventDetail<ICancelActeResponse>) => {
					const laSeances: Seance[] = this.isvcSeance.insertSeance(paSeances, poSeance);
					return this.changeSeancesWithConstraint(
						poResult.data.hasToCancelFollowings ? laSeances.slice(laSeances.indexOf(poSeance)) : [poSeance],
						(poActionSeance: Seance, poActionTraitement: Traitement) => this.isvcSeance.cancelActe(poActionTraitement, poActionSeance, poActe),
						poTraitement,
						(poConstraintActe: Acte) => {
							if (poConstraintActe.guid !== poActe.guid)
								return undefined;

							const loCancelConstraint = new CancelConstraint;

							if (poResult.data.hasToCancelFollowings)
								loCancelConstraint.occurrenceComparators.push(new ActeOccurrenceRangeComparator({ from: poSeance.startDate }));
							else {
								loCancelConstraint.occurrenceComparators.push(new ActeOccurrenceDateTimeComparator({
									day: poSeance.startDate,
									hours: poSeance.startDate.getHours(),
									minutes: poSeance.startDate.getMinutes()
								}));
							}
							return loCancelConstraint;
						});
				})
			);
	}


	public getTraitementIds(paTraitementIds: string[], pbLive = false): Observable<string[]> {
		const loDataSource: IDataSource = this.getTraitementDataSource({ isLive: pbLive, keys: paTraitementIds });
		loDataSource.viewParams.include_docs = false;

		return this.isvcStore.get(loDataSource)
			.pipe(
				map((paTraitement: IStoreDocument[]) => paTraitement.map((poTraitement) => {
					return poTraitement._id;
				}))
			);
	}

	//#endregion
}
