import { ObjectHelper } from "../../../../helpers/objectHelper";
import { ESyncType } from "../../../../model/store/ESyncType";

export class UnknownSyncTypeError extends Error {

	constructor(peSyncType: ESyncType) {
		super(`Le type de synchronisation des bases de données '${peSyncType}' est inconnu.`);
		// Nécessaire pour que le 'instanceof UnknownSyncTypeError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, UnknownSyncTypeError);
	}

}