import { ObjectHelper } from '../../../helpers/objectHelper';

/** Erreur levée lorsqu'on peut récupérer plusieurs ids de base de données et qu'il n'est pas possible de déterminer lequel récupérer. */
export class MultipleDatabaseIdsFoundError extends Error {

	constructor() {
		super("Impossible de déterminer l'id de base de données à utiliser parmi plusieurs.");
		// Nécessaire pour que le 'instanceof MultipleDatabaseIdsFoundError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, MultipleDatabaseIdsFoundError);
	}

}