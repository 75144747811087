import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "di-etat-facturation",
	templateUrl: "./etat-facturation.component.html",
	styleUrls: ["./etat-facturation.component.scss"],
})
export class EtatFacturationComponent implements OnInit {
	constructor() { }

	ngOnInit() { }

	@Input() totalSeance: number = 0;
	@Input() billedStatus!: "all" | "some" | "none";
	@Output() onButtonClick = new EventEmitter<MouseEvent>();

	getBillDetails() {
		switch (this.billedStatus) {
			case "all":
				return {
					text: "Toutes les séances sont facturées",
					class: "all",
					iconColor: "Indigo",
					iconeName: "check_circle",
				};

			case "none":
				return {
					text: "Aucune séance à facturer",
					class: "none",
					iconColor: "Noir80",
					iconeName: "info",
				};
			default:
				return {
					text: `${this.totalSeance} séance${this.totalSeance > 1 ? 's' : ''} à facturer`,
					class: "some",
					iconColor: "Info",
					iconeName: "east",
				};
		}
	}

	handleButtonClick(event: MouseEvent) {
		this.onButtonClick.emit(event);
	}
}
