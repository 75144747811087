import { Component, Input, OnInit } from "@angular/core";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { EvenementFormComponent } from "../evenement-form/evenement-form.component";
import { Evenement } from "apps/idl/src/anakin/models/Evenement";
import { EvenementService } from "../../../shared/services/evenement.service";
import { catchError, finalize, switchMap } from "rxjs/operators";
import { ConfirmationSuppressionComponent } from "../../../shared/components/confirmation-suppression/confirmation-suppression.component";

@Component({
	selector: "menu-modifier-evenement",
	templateUrl: "./menu-modifier-evenement.component.html",
	styleUrls: ["./menu-modifier-evenement.component.scss"],
})
export class MenuModifierEvenementComponent implements OnInit {
	@Input() event: Evenement;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcEvenement: EvenementService
	) {}


	ngOnInit() {}
	modifEvent(event: Event) {
		this.svcDrawerPopover.open(
			"",
			"60%",
			event.currentTarget,
			EvenementFormComponent,
			{
	          evenement:this.event,
				onSave: (eventToSave) => {
					this.saveEvent(eventToSave)
				},
				onCancel: () => this.closeMenu(),
			}
		);
	}

	public closeMenu(): void {
		this.svcDrawerPopover.close();
	}
	deleteEvent(event: Event) {
		//TODO: ajouter un loader
		this.svcDrawerPopover.open("Confirmation de suppression", "50%", event.currentTarget,ConfirmationSuppressionComponent, {
		  onConfirm: () => this.handleConfirm(),
		  onCancel: () => this.closeMenu()
		})
	  }
	
	saveEvent = (eventToSave: Evenement): void => {
	
		this.svcEvenement.createOrUpdate(eventToSave).pipe(
			
			finalize(() => {
				this.closeMenu()
			})
		).subscribe();
		
		
	  }
	  handleConfirm () {
		this.svcEvenement.delete(this.event).subscribe((isDeleted: boolean) => {
		 this.closeMenu()
		})
}
}
