import { ObjectHelper } from '../../../helpers/objectHelper';

export class NoDatabaseIdFoundError extends Error {

	constructor() {
		super("Aucune base de donnée associée à la source de données n'a été trouvé.");
		// Nécessaire pour que le 'instanceof NoDatabaseIdFoundError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoDatabaseIdFoundError);
	}

}