export enum ELinkTemplate {
	default = "default",
	full = "full",
	item = "item",
	itemNoLine = "item-no-line",
	image = "image",
	icon = "icon",
	avatar = "avatar",
	subtitle = "subtitle",
	tile = "tile"
}