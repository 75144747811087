<div class="transmission-page__container">
	<div class="transmission-page__content">
		<div class="content__entete">
			<div class="entete__title-boutons">
				<div class="entete__title">
					<h1 class="entete__title-text">Transmission</h1>
					<small>{{transmissionCountMessage}}</small>
				</div>
				<div class="entete__boutons">
					<lua-bouton-icone iconName="search" size="medium" mode="filled" [isDisabled]="false"
						(click)="handleSearchClick($event)"></lua-bouton-icone>
					<lua-action-secondaire *ngIf="!isMobileView" [avecIcone]="true" libelle="Transmission" nomIcone="add"
						[fullWidth]="false" positionIcone="L" (click)="handleAddTransmissionClick($event)"></lua-action-secondaire>
				</div>
			</div>
			<div class="entete__action">
				<lua-recherche *ngIf="showSearchPatient" 
					(onValueChange)="filterPatients($event)" 
					iconeDefault="search"
					[secondaryAction]="false" 
					[searchText]="searchedValue"
					placeHolderText="NOM Prénom du patient">
				</lua-recherche>
			</div>
		</div>
		<div *ngIf="!aucuneTransmission" class="content__list">
			<ng-container *ngFor="let transmission of filteredTransmissions | slice:0:displayCount; let i = index">
				<ng-container *ngIf="isDateDifferent(i)">
					<div class="list__date">
						<di-separateur-date [date]="formatDate(transmission.dateLastModification)"></di-separateur-date>
					</div>
				</ng-container>
				<lua-papier class="list__item" [ngClass]="'filled'" mode="filled" [fullWidth]="true">
					<di-carte-transmission [isMobileView]="isMobileView" [transmission]="transmission" [isComment]="false"
						[isNew]="isNewTag(transmission)">
					</di-carte-transmission>
					<ng-container *ngFor="let commentaire of transmission.commentaires">
						<di-carte-transmission [transmission]="transmission" [commentaire]="commentaire" [isComment]="true"
							[isNew]="false">
						</di-carte-transmission>
					</ng-container>
				</lua-papier>
			</ng-container>
			<lua-action-secondaire *ngIf="displayCount < filteredTransmissions.length" class="button-precedent-transmi" libelle="Transmissions précédentes" [fullWidth]="false"
				(click)="handlePrecedentTransmissionClick($event)"></lua-action-secondaire>
		</div>
		<div *ngIf="aucuneTransmission" class="content__noResultat">
			<lua-message-aucune-donnee [mainText]="MainTextNoResult" [iconName]="'team_dashboard'" [subText]="SubTextNoResult"
				[buttonLabel]="'Nouvelle transmission'"
				(onClick)="handleAddTransmissionClick($event)"></lua-message-aucune-donnee>
		</div>
		<lua-bouton-floating *ngIf="isMobileView" class="btn-ajout-contact-mobile" bottom="40px" right="40px" iconName="add"
			(click)="handleAddTransmissionClick($event)">
		</lua-bouton-floating>
	</div>
</div>