import { Directive, EventEmitter, HostListener, Inject, InjectionToken, Optional, Output } from '@angular/core';
import { IGesturesConfig } from '../model/IGesturesConfig';

export const GESTURES_CONFIG = new InjectionToken<IGesturesConfig>("GESTURES_CONFIG");

@Directive({
	selector: '[calaoGestures]'
})
export class GesturesDirective {

	constructor(
		@Inject(GESTURES_CONFIG) @Optional() private poGesturesConfig?: IGesturesConfig
	) { }

	@Output()
	public readonly longPress = new EventEmitter();

	private moTimeOut: any;

	/** Événement du clic sur browser */
	@HostListener('mousedown', ['$event'])
	/** Événement du clic sur device */
	@HostListener('touchstart', ['$event'])
	public onMouseDown(poEvent: Event): void {
		this.longPressAction(poEvent);
	}

	@HostListener('mouseup')
	@HostListener('mouseleave')
	@HostListener('touchend')
	public onMouseUp(): void {
		this.longPressStopAction();
	}

	private longPressAction(poEvent: Event): void {
		console.log(poEvent);
		this.moTimeOut = setTimeout(() => {
			this.longPress.emit(poEvent);
		}, this.poGesturesConfig?.longPress?.time ?? 1000);
	}

	private longPressStopAction(): void {
		clearTimeout(this.moTimeOut);
	}

}
