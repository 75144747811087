<ion-item lines="none" class="container-fluid cmp-tiles" [ngClass]="to.data.titleCssClass">
	<h5>{{to.label}}</h5>
</ion-item>
<ion-item lines="none">
	<ion-grid>
		<ion-row *ngFor="let row of content" [ngClass]="row.cssClass">
			<ion-col *ngFor="let col of row.content" [ngClass]="col.cssClass">

				<div class="bloc1">
					<p *ngFor="let subCol of col.top?.subCols" [ngClass]="subCol.cssClass">
						{{subCol.value | fieldsBinding:bindingVar}}
					</p>
				</div>

				<div class="bloc2">
					<p *ngFor="let subCol of col.middle?.subCols" [ngClass]="subCol.cssClass">
						{{subCol.value | fieldsBinding:bindingVar}}
					</p>
				</div>

				<div class="bloc3">
					<p *ngFor="let subCol of col.bottom?.subCols" [ngClass]="subCol.cssClass">
						{{subCol.value | fieldsBinding:bindingVar}}</p>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-item>