import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/dateHelper';
import { StringHelper } from '../helpers/stringHelper';

@Pipe({ name: 'adaptiveDate' })
export class AdaptiveDatePipe implements PipeTransform {

	//#region FIELDS

	private moDatePipe: DatePipe;

	//#endregion

	//#region METHODS

	constructor(poDatePipe: DatePipe) {
		this.moDatePipe = poDatePipe;
		(this.moDatePipe as any).locale = "fr-FR"; //Localisation forcée en dur, externalisation dans le fichier de configuration à prévoir.
	}

  /* Exemple: {{ variableBindée | adaptiveDate }}
   * => adapte la date en fonction de la date courante (12:39, hier, lun., 7 oct.)
   * @param poValue valeur à transformer/vérifier (correspond à 'variableBindée').
   * @param psPattern paramètre de filtrage (correspond à 'fr-FR').
   */
	public transform(poValue: any): string {
		const loCurrentDate: Date = new Date();
		const loOldDate: Date = new Date(poValue);
		const lnDiffDay: number = DateHelper.diffDays(loCurrentDate, loOldDate);
		let lsPattern = "";
		const lsText = "Hier";

		// Il faut vérifier les jours parce que la différence des jours se base sur 24h et non le jour en lui-même.

		if (lnDiffDay === 0) // Cas même jour et si bien le même jour, sinon hier.
			lsPattern = "HH:mm";

		else if (lnDiffDay > 1 && lnDiffDay < 7) // Cas même semaine et si pas le même jour de la semaine alors ça fait moins d'une semaine.
			lsPattern = "EEE";

		else if (lnDiffDay >= 7)
			lsPattern = "dd MMM";

		// Retourne une date formatée si la variable "lsPattern" est valide, renvoie le texte contenu dans "lsText" sinon.
		return !StringHelper.isBlank(lsPattern) ? this.moDatePipe.transform(poValue, lsPattern) : lsText;
	}

	//#endregion
}