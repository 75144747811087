import { Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers';
import { IContact } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TabComponent } from 'lighting-up-angular';
import { Subscription, forkJoin, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { MenuDetailPatientComponent } from '../../../features/patients/components/menu-detail-patient/menu-detail-patient.component';
import { MenuPatientComponent } from '../../../features/patients/components/menu-patient/menu-patient.component';
import { PanneauAdressePatientComponent } from '../../../features/shared/components/panneaux/panneau-adresse-patient/panneau-adresse-patient.component';
import { PanneauChoixContactComponent } from '../../../features/shared/components/panneaux/panneau-choix-contact/panneau-choix-contact.component';
import { PanneauChoixMedecinTraitantComponent } from '../../../features/shared/components/panneaux/panneau-choix-medecin-traitant/panneau-choix-medecin-traitant.component';
import { PanneauPatientAllergiesComponent } from '../../../features/shared/components/panneaux/panneau-patient-allergies/panneau-patient-allergies.component';
import { PanneauPatientAmcComponent } from '../../../features/shared/components/panneaux/panneau-patient-amc/panneau-patient-amc.component';
import { PanneauPatientAmoComponent } from '../../../features/shared/components/panneaux/panneau-patient-amo/panneau-patient-amo.component';
import { PanneauPatientDecesComponent } from '../../../features/shared/components/panneaux/panneau-patient-deces/panneau-patient-deces.component';
import { PanneauPatientPriseEnChargeComponent } from '../../../features/shared/components/panneaux/panneau-patient-prise-en-charge/panneau-patient-prise-en-charge.component';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';

@Component({
  selector: 'di-detail-patient',
  templateUrl: './detail-patient.page.html',
  styleUrls: ['./detail-patient.page.scss']
})
export class DetailPatientPage implements OnInit, OnDestroy {
  @ViewChild('menuPatient', { static: false }) menuPatient!:
    ElementRef<HTMLDivElement>;

  public idpatient: string;
  public patient: IPatient;
  public doctor: IContact = null;
  public contact: IContact = null;

  public age = "";
  public birthDate = "";
  public genderColor: string;
	public phoneNumber: string = '';

	public showTabFilSoins: boolean = false;
  public showTabSurveillance: boolean = false;
  public showTabInformation: boolean = false;
  public tabSelected: string = "";

  public panneauTitle = "";
  public panneauContent: Type<any>;
  public panneauInputs: any;

  private closePanelSubscription: Subscription;

  public errorMessage: string;

  public activeAmo: AMOP;
  public amcPList: AMCP[];

  public etablissementAmo: IAMO;
  public etablissementsAmc: IAMC[];

  public amcMap: Map<string, AMCP>;
	public fullLastName :string;

  constructor(
    private route: ActivatedRoute,
    private svcPatient: PatientsService,
    private el: ElementRef,
    private svcPanneau: PanneauService,
    private svcContacts: ContactsService,
    private router: Router,
    private svcCouvertures: CouverturesService,
    private svcDrawerPopover: DrawerPopoverService,
     
  ) { }

  ngOnInit(): void { 
		this.getTabSelectedFromRoute();
    this.idpatient = this.route.snapshot.paramMap.get('idPatient');
    this.refreshPatientDetails();
    this.closePanelSubscription = this.svcPanneau.closePanel$.subscribe(() => {
      this.refreshPatientDetails();
    });
		this.closePanelSubscription = this.svcPatient.patientChanges$.subscribe(() => {
      this.refreshPatientDetails();
    });  }

  ngOnDestroy(): void {
    if (this.closePanelSubscription) {
      this.closePanelSubscription.unsubscribe();
    }
  }

	private getTabSelectedFromRoute() {
		const state = this.router.getCurrentNavigation()?.extras.state as { tabSelected: string; };
		this.tabSelected = state?.tabSelected;
		if (!this.tabSelected) {
			this.tabSelected = history.state.tabSelected;
			if (!this.tabSelected) {
				this.tabSelected = "Fil de soins";
			}
			this.showTabFilSoins= this.tabSelected === "Fil de soins";
			this.showTabInformation= this.tabSelected  === "Informations";
			this.showTabSurveillance = this.tabSelected  === "Surveillance";
		}
	}

  public refreshPatientDetails(): void {
    this.svcPatient.getPatient(this.idpatient).pipe(
      switchMap((patient) => {
        this.patient = patient;
				if(this.patient.phone) this.phoneNumber = this.formatPhoneNumber(this.patient.phone)

        if (patient.birthDate) {
          this.age = `${new Date().getFullYear() - new Date(patient.birthDate).getFullYear()} ans`;
          const formattedBirthDate = new Date(patient.birthDate);
          this.birthDate = formattedBirthDate.getDate().toString().padStart(2, '0') + "/"
            + (formattedBirthDate.getMonth() + 1).toString().padStart(2, '0') + "/"
            + formattedBirthDate.getFullYear();
        }

				this.fullLastName = this.svcPatient.getFullNamePatient(this.patient); 
 

        this.setGenderColor(patient.gender);

        return forkJoin([
          patient.doctors?.length > 0 ? this.svcContacts.getContact(patient.doctors[0]) : of(null),
          patient.contacts?.length > 0 ? this.svcContacts.getContact(patient.contacts[0]) : of(null)
        ]);
      })
    ).subscribe(([doctor, contact]) => {
      this.doctor = doctor;
      this.contact = contact;
    }, (error) => {
      console.error('Error fetching patient or contacts', error);
    });

    this.svcCouvertures.getPatientSortedCouvertures(this.idpatient, true)
      .pipe(
        mergeMap((poCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[] }) => {
          this.activeAmo = ArrayHelper.getFirstElement(poCouvertures.AMOPs.filter((amo: AMOP) => amo.isActiveAnakin));
          this.amcPList = poCouvertures.AMCPs;

          this.amcMap = new Map<string, AMCP>(
            this.amcPList.map((amc: AMCP) => [amc.amcId, amc])
          );

          const etablissementsAmcIds = this.amcPList.map((poAMCP: AMCP) => poAMCP.amcId);

          const amoEtablissement$ = this.activeAmo ? this.svcCouvertures.getEtablissementsByIds([this.activeAmo.amoId]) : of([]);
          const amcEtablissements$ = etablissementsAmcIds.length > 0 ? this.svcCouvertures.getEtablissementsByIds(etablissementsAmcIds) : of([]);

          return forkJoin([amoEtablissement$, amcEtablissements$]);
        }),
        tap(([amoEtablissement, amcEtablissements]: [(IAMO | IAMC)[], (IAMO | IAMC)[]]) => {
          this.etablissementAmo = ArrayHelper.getFirstElement(amoEtablissement) as IAMO
          this.etablissementsAmc = amcEtablissements as IAMC[]
        })
      )
      .subscribe();
  }

  public getAddress(): string {
    const { street, zipCode, city } = this.patient || {};

    const addressParts = [
      street,
      [zipCode, city].filter(Boolean).join(' ')
    ].filter(Boolean);

    return addressParts.join('<br>');
  }

  public formatSocialSecurityNumber(): string {
    const ssn = this.patient?.socialNumber;
    if (!ssn || ssn.replace(/\D/g, '').length !== 15) {
      return ssn;
    } else {
      const cleaned = ssn.replace(/\D/g, '');
      return `${cleaned.slice(0, 1)} ${cleaned.slice(1, 3)} ${cleaned.slice(3, 5)} ${cleaned.slice(5, 7)} ${cleaned.slice(7, 10)} ${cleaned.slice(10, 13)} ${cleaned.slice(13)}`;
    }
  }

  public getFormatedId(poEtablissement: IAMO | IAMC): string {
    return poEtablissement._id.split("_")[1].split("-").join(" ");
  }

  public getPatientGender(gender: string): string {
    switch (gender) {
      case '1':
        return 'homme';
      case '2':
        return 'femme';
      default:
        return '';
    }
  }

  setGenderColor(gender: string): void {
    if (this.patient.deceased)
      this.genderColor = "Noir"
    else {
      switch (gender) {
        case '1':
          this.genderColor = "Bleu"
          break;
        case '2':
          this.genderColor = "Raspberry2"
          break;
        default:
          this.genderColor = "Gris2"
      }
    }
    this.el.nativeElement.style.setProperty('--my-color', `var(--${this.genderColor})`);
  }

	private formatPhoneNumber(input: string): string {
    input = input.replace(/\D/g, '');
    return input.match(/.{1,2}/g)?.join(' ') || '';
  }

  public openPanneauAdresse(): void {
    this.panneauTitle = "Adresse et contact du patient"
    this.panneauContent = PanneauAdressePatientComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

  public openPanneauMedecin(): void {
    this.panneauTitle = "Médecin traitant"
    this.panneauContent = PanneauChoixMedecinTraitantComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

	openMenuPanneau = (element: HTMLElement,typeAction:string) => {
    this.svcDrawerPopover.open("", "30%", element, MenuDetailPatientComponent, { patient: this.patient, anchorElement: element,typeAction :typeAction });
	};

	public openPanneauContact(): void {
    this.panneauTitle = "Qui doit on contacter ?"
    this.panneauContent = PanneauChoixContactComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

  

  public openPanneauSecuriteSociale(amo?: AMOP): void {
    this.panneauTitle = "Assurance maladie obligatoire"
    this.panneauContent = PanneauPatientAmoComponent
    this.panneauInputs = {
      patient: this.patient,
      ...(amo && { couverture: amo }),
    }
    this.openPanneau();
  }

  public openPanneauMutuelle(amc?: AMCP): void {
    this.panneauTitle = "Mutuelle";
    this.panneauContent = PanneauPatientAmcComponent
    this.panneauInputs = {
      patient: this.patient,
			...(amc && { couverture: amc }),

    }
    this.openPanneau();
  }

  public openPanneauPriseEnChargeSpecifique(): void {
    this.panneauTitle = "Prise en charge spécifique";
    this.panneauContent = PanneauPatientPriseEnChargeComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

  public openPanneauAllergies(): void {
    this.panneauTitle = "Allergies, antécédents";
    this.panneauContent = PanneauPatientAllergiesComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

  public openPanneauDeces(): void {
    this.panneauTitle = "Patient décédé";
    this.panneauContent = PanneauPatientDecesComponent
    this.panneauInputs = {
      patient: this.patient
    }
    this.openPanneau();
  }

  public openPanneau() {
    this.svcPanneau.open(this.panneauTitle, this.panneauContent, this.panneauInputs);
  }

  public deletePatient() {
    this.svcPatient.deletePatientAnakin(this.patient).subscribe((result: boolean) => {
      if (result) {
        this.router.navigate(["patients"])
      } else {
        this.errorMessage = `Erreur lors de la suppression du patient ${this.patient.firstName} ${this.patient.lastName}`
      }
    })
  }

  public editPatient() {
    this.router.navigate(["patients", "edit", this.patient._id])
  }

  public navigatePatient() {
    console.log("navigate patient")
  }

  public emailPatient() {
    window.location.href = `mailto:${this.patient.email}`;
  }

  public phonePatient() {
    window.location.href = `tel:${this.patient.phone}`;
  }

	public getNumAmc(id : string)
	{
		return id.replace('amc_' ,'');
	}
  public openMenu(): void {
    this.svcDrawerPopover.open("", "75%", this.menuPatient?.nativeElement, MenuPatientComponent, { patient: this.patient })
  }

  public closeMenu(): void {
    this.svcDrawerPopover.close();
  }

  handleTabSelection = (tabLabel: string) => {
    this.showTabFilSoins = tabLabel === "Fil de soins";
    this.showTabInformation = tabLabel === "Informations";
    this.showTabSurveillance = tabLabel === "Surveillance";
  }

}
