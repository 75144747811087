import { IDomainSettings } from './IDomainSettings';
import { IUserSettings } from './IUserSettings';

export class DomainsUserSettings implements IUserSettings {

	//#region FIELDS

	public static readonly C_DOMAINS_USER_SETTINGS_ID: string = "domains";

	//#endregion

	//#region PROPERTIES

	public _id: string;
	public _rev?: string;
	public userSettingsId: string = DomainsUserSettings.C_DOMAINS_USER_SETTINGS_ID;
	/** Settings par défaut pour l'application */
	public common?: IDomainSettings;
	/** Settings des calendriers */
	public calendars?: IDomainSettings;
	/** Settings des contacts */
	public contacts?: IDomainSettings;

	//#endregion

	//#region METHODS

	constructor() { }

	//#endregion

}