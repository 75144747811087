import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OsappHeaderModalComponent } from './components/osapp-header-modal.component';
import { ModalService } from './services/modal.service';

const modules: Type<any>[] = [
	CommonModule,
	IonicModule
];
const components: Type<any>[] = [
	OsappHeaderModalComponent
];

@NgModule({
	imports: modules,
	declarations: components,
	exports: components,
	providers: [ModalService]
})
export class ModalModule { }