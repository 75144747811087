<div *ngIf="initialized" class="center">
	<ion-datetime #mobileTimePicker presentation="time" locale="fr-FR" [value]="params.value" [min]="params.min"
		[max]="params.max">
		<ion-buttons slot="buttons">
			<ion-button color="danger" (click)="close()">
				{{params.cancelText}}</ion-button>
			<ion-button color="primary" (click)="onTimeChanged(mobileTimePicker)">
				{{params.doneText}}
			</ion-button>
		</ion-buttons>
	</ion-datetime>
</div>