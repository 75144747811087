import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ObjectHelper } from '../helpers/objectHelper';
import { EType } from '../model/EType';

@Pipe({ name: 'isDate' })
export class IsDatePipe implements PipeTransform {

	//#region FIELDS

	private moDatePipe: DatePipe;

	//#endregion

	//#region METHODS

	constructor(poDatePipe: DatePipe) {
		this.moDatePipe = poDatePipe;
		(this.moDatePipe as any).locale = "fr-FR"; // Localisation forcée en dur, externalisation dans le fichier de configuration à prévoir.
	}

  /*
   * Exemple: {{ variableBindée | filtreDate: 'fr-FR' : 'EEE dd MMMM yyyy' }}
   * => lun. 14 mars 1994
   * @param poValue valeur à transformer/vérifier (correspond à 'variableBindée').
   * @param psPattern paramètre de filtrage (correspond à 'fr-FR').
   */
	public transform(poValue: any, psPattern: string): string {
		return this.isDate(poValue) ? this.moDatePipe.transform(poValue, psPattern) : poValue;
	}

  /** Vérifie si le champ passé en paramètre est une date ou non.
   * @param poValue Champ à afficher dont il faut vérifier si c'est une date ou non.
   */
	private isDate(poValue: any): boolean {
		// Date enregistrée en string : 2018-09-27T11:04:42.364Z | toISOString = fonction de l'objet date (à priori, aucun autre objet n'a cette fonction).
		return poValue && (poValue instanceof Date || ObjectHelper.checkType(poValue.toISOString, EType.function) ||
			(ObjectHelper.checkType(poValue, EType.string) && (poValue as string).split("-").length === 3 && (poValue as string).split(":").length === 3));
	}

	//#endregion
}