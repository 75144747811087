import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArrayHelper } from '@osapp/helpers';
import { UserData } from '@osapp/model';
import { IChangePasswordInfos } from '@osapp/modules/password/model/IChangePasswordInfos';
import { PasswordService } from '@osapp/modules/password/services/password.service';
import { ContactsService } from '@osapp/services';
import { IIdelizyContact } from 'apps/idl/src/model/IIdelizyContact';
import { tap } from 'rxjs/operators';
import { EExerciceCondition } from '../../../modules/patients/model/EExerciceCondition';
import { ConnexionService } from '../../features/connexion/services/connexion.service';
import { optsConditionExercice, optsSpeExercice } from '../../features/contacts/components/model/constants';
import { SignatureComponent } from '../../features/shared/components/signature/signature.component';
import { ColorOption } from '../../models/IColorOption';

@Component({
  selector: 'di-mon-compte',
  templateUrl: './mon-compte.page.html',
  styleUrls: ['./mon-compte.page.scss'],
})


export class MonComptePage implements OnInit {
  public resetPasswordControle: string[] = [];
  public isReset: boolean = true;
  public msgChangePasswordError: string = "";

  public showOldPasswordDrawer: boolean = true;
  public showNewPasswordDrawer: boolean = false;

	public selectedColor : ColorOption [];

	


  public validations = [
    { errorKey: 'notMinusculeValid', message: 'au moins une minuscule' },
    { errorKey: 'notSizeValid', message: 'plus de 8 caractères' },
    { errorKey: 'notMajusculeValid', message: 'au moins une majuscule' },
    { errorKey: 'notNumberValid', message: 'au moins un chiffre' },
    { errorKey: 'notSpecialCarValid', message: 'un caractère spécial (#@&é§!%+=)' },
    { errorKey: 'notCourantValid', message: 'pas trop courant' },
    { errorKey: 'notDifLoginValid', message: 'pas trop proche du login', show: () => !this.isReset }
  ];

  public isValideMdp: boolean = false;
  public isPasswordError: boolean = false;

  public isPasswordOldVisible: boolean = false;
  public passwordIconOld: string = "visibility";
  public passwordInputTypeOld: string = "password";
  public oldPassword: string = "";

  public isPasswordNewVisible: boolean = false;
  public passwordIconNew: string = "visibility";
  public passwordInputTypeNew: string = "password";
  public newPassword: string = "";

  public isPasswordConfirmVisible: boolean = false;
  public passwordIconConfirm: string = "visibility";
  public passwordInputTypeConfirm = "password";
  public confirmNewPassword: string = "";

  public showPasswordDrawer: boolean = false;

  @ViewChild('signaturePad') signaturePad: SignatureComponent;
  @ViewChild('signaturePadReadOnly') signaturePadReadOnly: SignatureComponent;

  public showSignatureDrawer: boolean = false;
  public imageSignature: string | null = '';

  public myAccountForm: FormGroup;
  public user: IIdelizyContact = {
    firstName: "",
    lastName: "",
    _id: "",
    email: "",
    finess: "",
    facturationNumber: "",
    exerciceCondition: EExerciceCondition.liberal
  };
  public optsConditionExercice = optsConditionExercice;
  public optsSpeExercice = optsSpeExercice;
  public typeSnackbar: string = "success";
  public messageSnackbar: string = "";


  constructor(
    private fb: FormBuilder,
    private svcConnexion: ConnexionService,
    private svcPassword: PasswordService,
    private svcContact: ContactsService,
  ) { }

  ngOnInit() {
    this.initFormMyAccount();
    this.initContact();
  }

  initContact() {
    this.svcContact.getContactFromUserId(UserData.current?.name).subscribe((userContact) => {
      this.user = userContact;
			this.initValueErgo();
      this.initFormMyAccount();
      this.imageSignature = this.user.signature;
			this.selectedColor = this.user.avatarCouleur ? [{name:this.user.avatarCouleur,value:this.user.avatarCouleur}] : [{name:"CouleurPrimaire",value:"CouleurPrimaire"}]
    });
  }

	private initValueErgo() :void{
		this.user.isDoctor = this.user.isDoctor || !!this.user.finess;
	}

  initFormMyAccount() {
    this.myAccountForm = this.fb.group({
      firstName: [this.user.firstName ?? ""],
      lastName: [this.user.lastName ?? "", Validators.required],
      finess: [this.user.finess ?? ""],
      facturationNumber: [this.user.rpps ?? this.user.adeli ?? ""],
      email: [this.user.email ?? ""],
      exerciceCondition: [this.user.exerciceCondition ?? EExerciceCondition.liberal],
			codeSpecialite: [this.user.codeSpecialite ?? ""],
      //On met un faux mdp juste pour afficher le champs mdp avec quelque chose dedans 
      //pour montrer à l'utilisateur qu'il doit cliquer sur le bouton changer mot de passe
      password: ["é((kdsqp14Sdop"]
    });
  }

  handleClickCancelBtn() {
    this.initFormMyAccount();
    //TODO :  a finir
    // this.resetImageSignature();
    this.typeSnackbar = "warning";
    this.messageSnackbar = "Modifications annulées !";
  }

	onOptionClick = (colColorOptior: any[]) => {
		if(colColorOptior.length == 0) 
			this.selectedColor= [];
		else
			this.selectedColor = colColorOptior;
	}


  handleClickSaveBtn() {
    this.saveContact();
  }

  showChangePasswordDrawer() {
    this.showPasswordDrawer = !this.showPasswordDrawer;
  }

  continueToPasswordChange() {
    this.showOldPasswordDrawer = false;
    this.showNewPasswordDrawer = true;
  }

  onGoBackClick(event: Event) {
    this.showPasswordDrawer = !this.showPasswordDrawer;
    this.resetPasswordFields();
    this.showOldPasswordDrawer = true;
    this.showNewPasswordDrawer = false;
  }

  onCloseButtonClick(event: Event) {
    this.showPasswordDrawer = !this.showPasswordDrawer;
  }

  handleChangeOldPassword(pwd: string) {
    this.oldPassword = pwd;
  }

  handleChangeNewPassword(pwd: string) {
    this.handleChangePassword(pwd, false);
  }

  handleChangeConfirmPassword(pwd: string) {
    this.handleChangePassword(pwd, true);
  }

  public showNewPassword(event: MouseEvent) {
    this.isPasswordNewVisible = !this.isPasswordNewVisible;
    this.passwordInputTypeNew = this.isPasswordNewVisible ? 'text' : 'password';
    this.passwordIconNew = this.isPasswordNewVisible ? 'visibility_off' : 'visibility';
  }

  public showOldPassword(event: MouseEvent) {
    this.isPasswordOldVisible = !this.isPasswordOldVisible;
    this.passwordInputTypeOld = this.isPasswordOldVisible ? 'text' : 'password';
    this.passwordIconOld = this.isPasswordOldVisible ? 'visibility_off' : 'visibility';
  }

  public showConfirmPassword(event: MouseEvent) {
    this.isPasswordConfirmVisible = !this.isPasswordConfirmVisible;
    this.passwordInputTypeConfirm = this.isPasswordConfirmVisible ? 'text' : 'password';
    this.passwordIconConfirm = this.isPasswordConfirmVisible ? 'visibility_off' : 'visibility';
  }

	public shouldShowSpecialiteSelect(): boolean {
    return this.myAccountForm.get('exerciceCondition')?.value === EExerciceCondition.salarie || this.myAccountForm.get('exerciceCondition')?.value === EExerciceCondition.benevole;
  }

  changePassword() {
    const passwordPayload: IChangePasswordInfos = {
      login: this.myAccountForm.value.email,
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    }

    this.svcPassword.changePassword(passwordPayload)
      .pipe(
        tap(
          _ => {
            this.showPasswordDrawer = !this.showPasswordDrawer;
          },
          poError => {
            this.showOldPasswordDrawer = true;
            this.showNewPasswordDrawer = false;
            this.resetPasswordFields();
            this.msgChangePasswordError = "Le mot de passe actuel n'est pas correct";
            console.error("Une erreur est survenue lors du changement du mdp : ", poError);
          }
        )
      )
      .subscribe();
  }

  public hasError(errorCode: string): boolean {
    if (!this.resetPasswordControle || this.resetPasswordControle.length === 0) {
      return true;
    }
    return this.svcConnexion.hasError(this.resetPasswordControle, errorCode);
  }

  public showEditSignatureDrawer() {
    this.toggleSignatureDrawer();
  }

  public closeSignatureDrawer(event: Event) {
    this.toggleSignatureDrawer();
    this.signaturePad.clear();
    this.signaturePad.image = this.imageSignature;
  }

  public saveSignatureDrawer(event: Event) {
    this.toggleSignatureDrawer();
    const dataUrl = this.signaturePad.save();
    this.signaturePadReadOnly.clear();
    this.imageSignature = dataUrl;
  }

  public resetSignature(event: Event) {
    this.signaturePad.clear();
  }

  private toggleSignatureDrawer() {
    this.showSignatureDrawer = !this.showSignatureDrawer;
  }

  private validatePassword(password: string, confirmPassword: string): string[] {
    let validationErrors: string[] = [];
    if (password === "") return validationErrors;
    const passwordErrors = this.svcConnexion.isValidCriters(password, true);
    validationErrors = validationErrors.concat(passwordErrors);
    this.isPasswordError = password !== confirmPassword;
    this.isValideMdp = (validationErrors.length === 6 && !this.isPasswordError);
    return validationErrors;
  }

  private handleChangePassword(newValue: string, isConfirm: boolean): void {
    if (isConfirm) {
      this.confirmNewPassword = newValue;
    } else {
      this.newPassword = newValue;
    }
    this.resetPasswordControle = this.validatePassword(this.newPassword, this.confirmNewPassword);
  }

	private setRppsAdeliValue()
	{
		delete this.user.rpps;
		delete this.user.adeli;
		this.user.facturationNumber.length == 11 ? this.user.rpps = this.user.facturationNumber : this.user.facturationNumber.length == 9 ? this.user.adeli = this.user.facturationNumber : "" ;
	}

  private saveContact() {
    const valuesCleaned = this.getCleanFormValues();
    this.user = { ...this.user, ...valuesCleaned };
		this.user = this.svcContact.setRppsAdeliValue(this.user);
    this.user.signature = this.imageSignature;
		this.user.avatarCouleur = ArrayHelper.getFirstElement(this.selectedColor).value;


    //TODO: ajouter un loader
    this.svcContact.saveContactAnakin(this.user).subscribe((isSaved: boolean) => {
      if (isSaved) {
        this.messageSnackbar = "Enregistrement OK !";
        this.typeSnackbar = "success";
				this.svcContact.emitReloadContact();
      } else {
        this.messageSnackbar = "Une erreur est survenue lors de l'enregistrement.";
        this.typeSnackbar = "error";
      }
    });
  }

  private getCleanFormValues() {
    const formValues = { ...this.myAccountForm.value };
    delete formValues.password;
    return formValues;
  }

  private resetPasswordFields() {
    this.oldPassword = "";
    this.newPassword = "";
    this.confirmNewPassword = "";
    this.resetPasswordControle = this.validatePassword("", "");
    this.msgChangePasswordError = "";
  }

  //TODO :  a finir
  // private resetImageSignature() {
  //   this.signaturePadReadOnly.clear();
  //   this.signaturePadReadOnly.image = this.user.signature;
  //   // this.imageSignature = this.user.signature;
  // }
}
