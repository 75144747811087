import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ContactHelper } from '../../../../helpers/contactHelper';
import { IContact } from '../../../../model/contacts/IContact';
import { IContactsSelectorParams } from '../../../../model/contacts/IContactsSelectorParams';
import { IContactSelection } from './icontact-selection';
import { IContactSelectorItemParams } from './icontact-selector-item-params';

@Component({ template: "" })
export abstract class ContactSelectorItemBase<T extends IContact = IContact, P extends IContactsSelectorParams = IContactsSelectorParams>
	extends ComponentBase implements IContactSelectorItemParams<T, P>, OnInit {

	//#region PROPERTIES

	protected moContactSelection: IContactSelection<T>;
	/** @implements */
	public get contactSelection(): IContactSelection<T> { return this.moContactSelection; }
	@Input() public set contactSelection(poContactSelection: IContactSelection<T>) {
		if (poContactSelection !== this.moContactSelection) {
			this.moContactSelection = poContactSelection;
			this.detectChanges();
		}
	}

	/** Nom du contact à afficher. */
	public get displayName(): string | undefined { return this.moContactSelection?.data ? ContactHelper.getCompleteFormattedName(this.moContactSelection.data) : undefined; }

	protected moParams?: P;
	/** @implements */
	public get params(): P { return this.moParams; }
	@Input() public set params(poContactsSelectorParams: P) {
		if (poContactsSelectorParams !== this.moParams) {
			this.moParams = poContactsSelectorParams;
			this.detectChanges();
		}
	}

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.params) // On évite les accès undefined si le paramètre n'est pas renseigné.
			this.params = {} as P;
	}

	//#endregion

}
