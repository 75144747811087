<div class="ctn">
	<!-- ZONE DE RECHERCHE -->
	<div *ngIf="hasSearchbox" class="search-box txt-center form-list width100pc">
		<search #search class="search" [data]="groups" [options]="searchOptions"
			(filteredEntriesChanged)="onFilteredDocumentsChanged($event)"></search>
	</div>

	<!-- Message si aucun groupe. -->
	<div *ngIf="filteredGroups?.length === 0" class="empty-result-ctn">
		<ion-note>Aucun groupe</ion-note>
	</div>

	<ng-container *ngIf="filteredGroups?.length > 0">
		<ion-list class="groups-list list">
			<ion-item-sliding *ngFor="let group of filteredGroups" #itemSliding data-cy="group-item"
				[disabled]="!itemSlidingEnabled">

				<ion-item lines="full" class="dispo-group" (click)="onItemClicked(group)">
					<!-- Chaque division de groupe -->
					<calao-conflict-list-marker *ngIf="group._conflicts"></calao-conflict-list-marker>
					<!-- Avatar du groupe -->
					<avatar [src]="getGroupAvatar(group)" [cssClass]="'round'" style="margin-right: 16px; margin-left: 16px;">
					</avatar>
					<ion-grid class="no-margin">
						<ion-row>
							<!-- Nom du groupe -->
							<ion-label class="ion-no-padding ion-text-wrap group-name">{{group.name}}</ion-label>
						</ion-row>
						<ion-row *ngIf="group.roles">
							<!-- Nom du groupe -->
							<ion-note class="ion-text-wrap group-roles">{{getRolesNames(group)}}</ion-note>
						</ion-row>
						<ion-row>
							<!-- Membres du groupe -->
							<ion-note class="ion-text-wrap contacts-group">
								{{ contactsByGroupId.get(group._id) | groupMemberNamesEllipsis }}
							</ion-note>
						</ion-row>
					</ion-grid>
					<!-- Bouton du menu contextuel -->
					<ion-button class="itemb btn-more" fill="clear" slot="end" *ngIf="itemSlidingEnabled"
						(click)="openOrCloseItemSliding(itemSliding, $event)">
						<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
					</ion-button>
				</ion-item>

				<!-- Options des items. -->
				<ion-item-options side="end">
					<ion-button class="swipe-btn" color="danger" (click)="emitRemoveGroup(group, itemSliding, $event)">
						<ion-icon name="trash"></ion-icon>
						Supprimer
					</ion-button>
				</ion-item-options>
			</ion-item-sliding>
		</ion-list>
	</ng-container>
</div>