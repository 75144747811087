import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { AlertButton } from "@ionic/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IUiResponse } from "../../../../model/uiMessage/IUiResponse";
import { ShowMessageParamsPopup } from "../../../../services/interfaces/ShowMessageParamsPopup";
import { UiMessageService } from "../../../../services/uiMessage.service";
import { DynamicPageComponent } from "../../../dynamicPage/dynamicPage.component";
import { ConversationEditComponent } from "../conversationEdit.component";

@Injectable()
export class CanLeaveConversationEdit implements CanDeactivate<DynamicPageComponent<ConversationEditComponent>> {

	//#region METHODS

	constructor(private isvcUiMessage: UiMessageService) { }

	/** Retourne `true` si on peut quitter le composant, `false` sinon.
		 * @param poComponent Composant qu'on veut quitter.
		 */
	public canDeactivate(poComponent: DynamicPageComponent<ConversationEditComponent>): Observable<boolean> {
		if (poComponent.childComponent.hasChanges() && !poComponent.childComponent.isValidated) {
			return this.isvcUiMessage.showAsyncMessage<boolean>(
				new ShowMessageParamsPopup({
					header: "Modifications non enregistrées",
					message: "Des modifications ont été réalisées. Si vous continuez, les données modifiées seront perdues. Voulez-vous vraiment continuer ?",
					buttons: [
						{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() },
						{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() }
					] as AlertButton[],
					backdropDismiss: false
				})
			).pipe(
				map((poUiResponse: IUiResponse<true, any>) => poUiResponse.response)
			);
		}
		else
			return of(true);
	}

	//#endregion METHODS
}
