import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Directory } from '@capacitor/filesystem';
import { ModalController } from '@ionic/angular';
import { AlertButton, ModalOptions, OverlayEventDetail } from '@ionic/core';
import { classToPlain } from 'class-transformer';
import PCancelable, { CancelError } from 'p-cancelable';
import { BehaviorSubject, EMPTY, GroupedObservable, Observable, Subject, combineLatest, defer, from, fromEvent, merge, of, throwError, timer } from 'rxjs';
import { bufferWhen, catchError, concatMap, delay, distinctUntilChanged, expand, filter, finalize, groupBy, last, map, mapTo, mergeMap, mergeMapTo, reduce, retryWhen, scan, startWith, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { DebugPouch, PouchDB, PouchdbAdapterCordovaSqlite } from '../../lib/pouchdb';
import { IUnlockDeviceResult } from '../components/security/unlock-device-modal/IUnlockDeviceResult';
import { UnlockDeviceModalComponent } from '../components/security/unlock-device-modal/unlock-device-modal.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { DateHelper } from '../helpers/dateHelper';
import { GuidHelper } from '../helpers/guidHelper';
import { IdHelper } from '../helpers/idHelper';
import { MapHelper } from '../helpers/mapHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StoreDocumentHelper } from '../helpers/storeDocumentHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EPrefix } from '../model/EPrefix';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { EEnvironmentId } from '../model/application/EEnvironmentId';
import { ENetworkFlag } from '../model/application/ENetworkFlag';
import { EPlatform } from '../model/application/EPlatform';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { UserData } from '../model/application/UserData';
import { Version } from '../model/application/Version';
import { ConfigData } from '../model/config/ConfigData';
import { IDatabaseMeta } from '../model/databaseDocument/IDatabaseMeta';
import { IFlag } from '../model/flag/IFlag';
import { ESecurityFlag } from '../model/security/ESecurityFlag';
import { ICredentials } from '../model/security/ICredentials';
import { Database } from '../model/store/Database';
import { EChangeType } from '../model/store/EChangeType';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../model/store/EStoreEventStatus';
import { EStoreEventType } from '../model/store/EStoreEventType';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { ESyncType } from '../model/store/ESyncType';
import { ICacheData } from '../model/store/ICacheData';
import { IChangeEvent } from '../model/store/IChangeEvent';
import { IDataFromPouchQuery } from '../model/store/IDataFromPouchQuery';
import { IDataSource } from '../model/store/IDataSource';
import { IDataSourceViewParams } from '../model/store/IDataSourceViewParams';
import { IDatabaseConfig } from '../model/store/IDatabaseConfig';
import { IDatabaseInitEvent } from '../model/store/IDatabaseInitEvent';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IStoreEvent } from '../model/store/IStoreEvent';
import { IStoreReplicationOptions } from '../model/store/IStoreReplicationOptions';
import { IStoreReplicationResponse } from '../model/store/IStoreReplicationResponse';
import { IStoreReplicationToLocalResponse } from '../model/store/IStoreReplicationToLocalResponse';
import { IStoreReplicationToServerResponse } from '../model/store/IStoreReplicationToServerResponse';
import { ISyncType } from '../model/store/ISyncType';
import { DeviceNotAuthorizedError } from '../model/store/error/device-not-authorized-error';
import { FirstInitFailedError } from '../model/store/error/first-init-failed-error';
import { IConfigInitPouchDB } from '../model/store/pouchDB/IConfigInitPouchDB';
import { ILocalDatabaseConfig } from '../model/store/pouchDB/ILocalDatabaseConfig';
import { IRemoteDatabaseConfig } from '../model/store/pouchDB/IRemoteDatabaseConfig';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { WorkspaceFilter } from '../model/workspaces/WorkspaceFilter';
import { DownloadService } from '../modules/download/services/download.service';
import { FilesystemService } from '../modules/filesystem/services/filesystem.service';
import { EFlag } from '../modules/flags/models/EFlag';
import { Loader } from '../modules/loading/Loader';
import { LogAction } from '../modules/logger/decorators/log-action.decorator';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { ILogActionHandler } from '../modules/logger/models/ILogActionHandler';
import { LogActionHandler } from '../modules/logger/models/log-action-handler';
import { LoggerService } from '../modules/logger/services/logger.service';
import { NoOnlineReliableNetworkError } from '../modules/network/models/errors/NoOnlineReliableNetworkError';
import { OsappApiHelper } from '../modules/osapp-api/helpers/osapp-api.helper';
import { PerformanceManager } from '../modules/performance/PerformanceManager';
import { DevicesSecurityService } from '../modules/security/devices/services/devices-security.service';
import { ChangeTrackingService } from '../modules/store/change-tracking/services/change-tracking.service';
import { EDatabaseSyncStatus } from '../modules/store/model/EDatabaseSyncStatus';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { IDatabaseSyncMarker } from '../modules/store/model/IDatabaseSyncMarker';
import { IDumpProgressEvent } from '../modules/store/model/IDumpProgressEvent';
import { IResetDatabasesResult } from '../modules/store/model/IResetDatabasesResult';
import { DumpReader } from '../modules/store/model/dump-reader';
import { ELocalToServerReplicationMode } from '../modules/store/model/elocal-to-server-replication-mode.enum';
import { ConflictError } from '../modules/store/model/errors/ConflictError';
import { IPouchDBFailedToFetchError } from '../modules/store/model/errors/ipouchdb-failed-to-fetch-error';
import { NoDatabaseInternetConnectionError } from '../modules/store/model/errors/no-database-internet-connection-error';
import { TokenError } from '../modules/store/model/errors/token-error';
import { UnknownSyncTypeError } from '../modules/store/model/errors/unknown-sync-type-error';
import { ISyncronizationEvent } from '../modules/store/model/isyncronization-event';
import { IOnProgressFunction } from '../modules/store/replicator/models/ion-progress-function';
import { ChangeTrackingReplicatorService } from '../modules/store/replicator/services/change-tracking-replicator.service';
import { ClassicReplicatorService } from '../modules/store/replicator/services/classic-replicator.service';
import { UnzipService } from '../modules/unzip/services/unzip.service';
import { PatternsHelper } from '../modules/utils/helpers/patterns.helper';
import { Queue } from '../modules/utils/queue/decorators/queue.decorator';
import { afterSubscribe } from '../modules/utils/rxjs/operators/after-subscribe';
import { bufferUntil } from '../modules/utils/rxjs/operators/buffer-until';
import { DestroyableServiceBase } from '../modules/utils/services/destroyable-service-base';
import { ValidationPopup } from '../modules/validations/decorators/validation-popup.decorator';
import { EntityBuilder } from './EntityBuilder';
import { ApplicationService } from './application.service';
import { FlagService } from './flag.service';
import { IStore } from './interfaces/IStore';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { LoadingService } from './loading.service';
import { MailService } from './mail.service';
import { NetworkService } from './network.service';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';
import { IDataSourceViewCount } from '../model/store/IDataSourceViewCount';
import { IViewCountResult } from '../model/IViewCountResult';

PouchDB.plugin(PouchdbAdapterCordovaSqlite);
PouchDB.plugin(DebugPouch);

interface IGroupedDocuments<T> {
	databaseId: string;
	documents: T[];
}

interface IReplicatedDoc {
	_id: string;
	_rev: string;
}

interface IReplicateLogActionData {
	readonly databaseId: string;
	readonly nbDocReplicated: number;
	readonly source: string;
	readonly target: string;
	localSeqBeforeReplication?: number
	serverSeqBeforeReplication?: string;
	docs?: IReplicatedDoc[];
}

/** Le store permet l'accès aux bases de données. */
@Injectable({ providedIn: "root" })
export class Store extends DestroyableServiceBase implements IStore, ILogActionHandler {

	//#region FIELDS

	/** Identifiant du service dans les logs. */
	private static readonly C_LOG_ID = "STO.S::";
	private static readonly C_POUCH_CONFLICT_ERROR_NAME = "conflict";

	/** Nom du fichier json contenant le dump d'une base de données. */
	private static readonly C_DUMP_DOC_NAME = "dump.json";
	/** Nom du fichier json contenant les metadonnées du dump d'une base de données. */
	private static readonly C_META_DOC_NAME = "meta.json";

	/** Nom du document local de chaque base de config : "_local/databaseMeta". */
	private readonly C_DATABASE_META = "_local/databaseMeta";
	private readonly C_DELETED_FILTER_NAME = "filters/deletedfilter";
	/** Temps d'attente entre chaque changements depuis le getLive(), en ms. */
	private readonly C_GET_LIVE_BUFFER_TIME_MS = 100;
	/** La taille par défaut des paquets de données lorsque les données ne sont pas chargées en un coup. */
	private readonly C_DEFAULT_BATCH_SIZE = 999;
	/** Nombre limite de révisions à conserver en local. */
	private readonly C_DEFAULT_REVS_LIMIT = 100;
	/** Map des bases de données indexées par leur identifiant. */
	private readonly moDatabaseById = new Map<string, Database>();
	/** Delai avant de retenter. */
	private static readonly C_RETRY_DELAY_MS = 30000;
	/** Nombre de documents répliqués maximum à intégrer dans les logs de réplications. */
	private static readonly C_MAX_REPLICATED_DOCS_NB = 9;

	/** Subject pour l'envoi d'event */
	private moEventSubject = new Subject<IStoreEvent>();
	/** Sujet d'émission des événements de changement local des données. */
	private moLocalChangesSubject = new Subject<IChangeEvent<IStoreDocument>>();
	private moReplicationChangesSubject = new Subject<IChangeEvent<IStoreDocument>>();
	/** Adapter PouchDB (voir @method getAdapter) */
	private msAdapter: string;
	/** Sujet d'émission des événements de changement du nombre d'actions utilisateur en cours. */
	private moUserActionsCounterSubject = new BehaviorSubject<number>(0);

	//#endregion

	//#region PROPERTIES

	/** Caractères "n'importe quoi" pour les startkey endkey notamment : \ufff0". */
	public static readonly C_ANYTHING_CODE_ASCII = "\ufff0";
	/** Propriété d'identifiant d'un document enregistré en base de données : "_id". */
	public static readonly C_ID_PROPERTY = "_id" as keyof IStoreDocument;
	/** Propriété de révision d'un document enregistré en base de données : "_rev". */
	public static readonly C_REVISION_PROPERTY = "_rev" as keyof IStoreDocument;
	/** Propriété de conflits d'un document enregistré en base de données : "_conflicts". */
	public static readonly C_CONFLICTS_PROPERTY = "_conflicts";
	/** Nom de la vue des documents par défaut, "all_docs". */
	public static readonly C_DEFAULT_VIEW_DOCS = "_all_docs";
	/** Message indiquant qu'un réseau est impératif au premier lancement de l'app. */
	public static readonly C_NETWORK_NEEDED_FOR_FIRST_LAUNCH = "Une connexion réseau est impérative pour le premier lancement de l'application.";
	/** Intervalle entre 2 réplications serveur vers local pour une base de donnée. */
	public static readonly C_OFFLINE_FIRST_TO_LOCAL_INTERVAL = 30000;

	/** Adapter PouchDB (voir @method getAdapter) */
	private get adapter(): string {
		if (!this.msAdapter)
			this.msAdapter = this.getAdapter();

		return this.msAdapter;
	}

	private moConflictsSubject = new Subject<string[]>();
	/** Lève un événement à chaque conflit récupéré depuis une requête get par l'utilisateur. */
	public get conflicts$(): Observable<string[]> { return this.moConflictsSubject.asObservable(); }

	/** @implements */
	public readonly logSourceId: string = Store.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private isvcNetwork: NetworkService,
		private isvcPlatform: PlatformService,
		private isvcFlag: FlagService,
		private isvcApplication: ApplicationService,
		private isvcUiMessage: UiMessageService,
		private isvcLoading: LoadingService,
		private isvcUnzip: UnzipService,
		private isvcDownload: DownloadService,
		private isvcMail: MailService,
		private ioModalCtrl: ModalController,
		private isvcDevicesSecurity: DevicesSecurityService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly ioRouter: Router,
		private readonly isvcChangeTrackingReplicator: ChangeTrackingReplicatorService,
		private readonly isvcClassicReplicator: ClassicReplicatorService,
		private readonly isvcChangeTracker: ChangeTrackingService,
		private readonly isvcFilesystem: FilesystemService
	) {
		super();
		//Nécessaire pour utiliser le store dans les tests e2e
		// @ts-ignore
		if (window.Cypress) {
			// @ts-ignore
			window.store = this;
		}

		isvcApplication.observeFlag(ESecurityFlag.authenticated)
			.pipe(
				filter((poFlag: IFlag) => poFlag.value !== undefined),
				map((poFlag: IFlag) => poFlag.value),
				distinctUntilChanged(),
				mergeMap((pbValue: boolean) => pbValue ? this.initDynamicDatabases() : of(this.closeDatabases()))
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.moConflictsSubject.complete();
		this.moLocalChangesSubject.complete();
		this.moReplicationChangesSubject.complete();
		super.ngOnDestroy();
	}

	/** Ferme les bases de données autres que l'appStorage et config (qui sont nécessaires). */
	private closeDatabases(): void {
		this.moDatabaseById.forEach((poDatabase: Database, psId: string) => {
			if (!poDatabase.hasRole(EDatabaseRole.config) && !poDatabase.hasRole(EDatabaseRole.applicationStorage)) {
				poDatabase.close();
				this.moDatabaseById.delete(psId);
			}
		});

		ConfigData.databases = ConfigData.databases?.filter(
			(poDatabaseConfig: IDatabaseConfig) => !poDatabaseConfig.roles?.includes(EDatabaseRole.workspace) ?? poDatabaseConfig.role === EDatabaseRole.workspace
		);

		this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.Init, ConfigData.databases));
	}

	/** Demande l'id d'une base de donnée qui n'est pas renseigné.
	 * @param peRole Rôle de la base de données recherchée.
	 */
	private askAndWaitDatabaseId(peRole: EDatabaseRole): Observable<string> {
		const loEvent: IStoreEvent = {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: EStoreEventType.NeedInfos,
				status: EStoreEventStatus.required,
				role: peRole
			}
		};

		const loWaitDatabaseId$: Observable<string> = this.isvcApplication.appEvent$
			.pipe(
				filter((poEvent: IApplicationEvent) =>
					poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.storeEventType === EStoreEventType.NeedInfos
				),
				take(1),
				mergeMap((poEvent: IStoreEvent) => poEvent.data.error ? throwError(poEvent.data.error) : of(poEvent.data.databaseId))
			);

		return merge(loWaitDatabaseId$, defer(() => this.raiseStoreEvent(loEvent)));
	}

	/** Gère le passage de la base de données au statut 'isInitialized' (booléen), ajoute le nom de cette bdd dans l'observable
	 * et termine cet observable si le compteur a atteint 0.
	 * @param psDatabaseId id de la base de données sur laquelle on fait des opérations.
	 * @param pbSuccess l'initialisation de la base de données s'est bien passée ou non.
	 */
	private completeInitDatabase(psDatabaseId: string, pbSuccess: boolean): Observable<Database> {
		const loDatabase: Database = this.moDatabaseById.get(psDatabaseId);
		loDatabase.isInitialized = pbSuccess;

		if (pbSuccess) {
			console.debug(`${Store.C_LOG_ID}Database ${psDatabaseId} initialized.`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(psDatabaseId, EStoreEventStatus.successed));
		}
		else {
			console.warn(`${Store.C_LOG_ID}Database ${psDatabaseId} not initialized.`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(psDatabaseId, EStoreEventStatus.failed));
		}

		return of(loDatabase);
	}

	private createInitErrorEvent(peType: EStoreEventType, paDatabases: IDatabaseConfig[]): IStoreEvent {
		return this.createInitEvent(peType, EStoreEventStatus.failed, paDatabases);
	}

	private createInitEvent(peType: EStoreEventType, peStatus: EStoreEventStatus, paDatabases: IDatabaseConfig[]): IDatabaseInitEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: peType,
				status: peStatus
			},
			databases: paDatabases
		};
	}

	private createInitSuccessEvent(peType: EStoreEventType, paDatabases: IDatabaseConfig[]): IStoreEvent {
		return this.createInitEvent(peType, EStoreEventStatus.successed, paDatabases);
	}

	/** Crée une base de données Pouch locale et une distante.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin
	 * @param psPassword
	 */
	private createLocalAndRemoteDatabase(poDatabaseConfig: IDatabaseConfig, psLogin?: string, psPassword?: string): Database {
		const loDatabase = new Database(
			poDatabaseConfig.id,
			this.extractSyncTypeFromConfig(poDatabaseConfig),
			poDatabaseConfig.roles,
			poDatabaseConfig.checkDatabaseMetaInstance,
			poDatabaseConfig.localToServerReplicationMode,
			poDatabaseConfig.optimization
		);

		const loConfig: IConfigInitPouchDB = this.getDatabaseConfig(loDatabase, poDatabaseConfig.revs_limit, psLogin, psPassword);

		loDatabase.createLocalInstance(loConfig.localPouchConfig.name, loConfig.localPouchConfig);
		loDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);

		return loDatabase;
	}

	/** Crée une base de données Pouch locale.
	 * @param poDatabaseConfig Configurations de la base de données.
	 */
	private createLocalDatabase(poDatabaseConfig: IDatabaseConfig): Database {
		const loDatabase = new Database(
			poDatabaseConfig.id,
			this.extractSyncTypeFromConfig(poDatabaseConfig),
			poDatabaseConfig.roles,
			poDatabaseConfig.checkDatabaseMetaInstance,
			poDatabaseConfig.localToServerReplicationMode,
			poDatabaseConfig.optimization
		);

		const loConfig: IConfigInitPouchDB = this.getDatabaseConfig(loDatabase, poDatabaseConfig.revs_limit);

		loDatabase.createLocalInstance(loConfig.localPouchConfig.name, loConfig.localPouchConfig);

		return loDatabase;
	}

	private createPreparingInitDatabaseEvent(): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				storeEventType: EStoreEventType.Init,
				status: EStoreEventStatus.preparing,
			}
		} as IStoreEvent;
	}

	/** Crée une base de données Pouch distante.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin
	 * @param psPassword
	 */
	private createRemoteDatabase(poDatabaseConfig: IDatabaseConfig, psLogin?: string, psPassword?: string): Database {
		const loDatabase = new Database(
			poDatabaseConfig.id,
			this.extractSyncTypeFromConfig(poDatabaseConfig),
			poDatabaseConfig.roles,
			poDatabaseConfig.checkDatabaseMetaInstance,
			poDatabaseConfig.localToServerReplicationMode,
			poDatabaseConfig.optimization
		);

		const loConfig: IConfigInitPouchDB = this.getDatabaseConfig(loDatabase, poDatabaseConfig.revs_limit, psLogin, psPassword);

		loDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);

		return loDatabase;
	}

	/** @implements */
	public delete<T extends IStoreDocument>(
		poDocument: T | string,
		psDatabaseId?: string,
		pbUpdateDeletion?: boolean,
		pbFailIfNotFound: boolean = true)
		: Observable<IStoreDataResponse> {
		if (ObjectHelper.isNullOrEmpty(poDocument))
			return throwError("Impossible de supprimer un document vide.");

		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument as IStoreDocument);
		const lsDatabaseId: string = loCacheData ? loCacheData.databaseId : psDatabaseId;

		return pbUpdateDeletion ? this.deleteByUpdateDeletion(poDocument, lsDatabaseId, pbFailIfNotFound) : this.deleteByDeletionDocument(poDocument, lsDatabaseId);
	}

	/** Supprime un document de la base de données en mettant à jour son état à '_deleted: true'.
	 * @param poDocument Document qu'il faut mettre à jour à l'état supprimer.
	 * @param psDatabaseId Identifiant de la base de données où se trouve le document à mettre à jour.
	 * @param pbFailIfNotFound Indique si une erreur doit être levée lorsque la suppression du document échoue.
	 */
	private deleteByUpdateDeletion<T extends IStoreDocument>(poDocument: T | string, psDatabaseId: string, pbFailIfNotFound: boolean)
		: Observable<IStoreDataResponse> {

		const lbIsStringDocument: boolean = typeof poDocument === "string";
		const loParams: IDataSource<T> = {
			databaseId: psDatabaseId,
			viewParams: {
				key: lbIsStringDocument ? poDocument as string : (poDocument as IStoreDocument)._id,
				include_docs: true
			}
		};

		return this.getOne<T>(loParams, false)
			.pipe(
				mergeMap((poResult: T) => {
					if (poResult) // Document récupéré en base de données, on le met à jour.
						return this.updateAsDeleted(poResult, psDatabaseId);

					else if (lbIsStringDocument) // Document non présent en base de données et est une chaîne de caractères : erreur.
						return throwError(`Le document ${poDocument as string} à supprimer n'existe pas en base.`);

					else { // Document non présent en base de données, pas de suppression.
						const lsErrorMessage = `update deletion document "${(poDocument as IStoreDocument)._id}" failed because not in database "${psDatabaseId}".`;
						if (pbFailIfNotFound)
							return throwError(lsErrorMessage);
						else {
							console.warn(`${Store.C_LOG_ID}${lsErrorMessage}`);
							return of({
								id: (poDocument as IStoreDocument)._id,
								rev: (poDocument as IStoreDocument)._rev,
								ok: true
							} as IStoreDataResponse);
						}
					}
				})
			);
	}

	/** Supprime un document en base de données.
	 * @param poDocument Document qu'il faut supprimer de la base de données.
	 * @param psDatabaseId Identifiant de la base de données où se trouve le document à supprimer.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private deleteByDeletionDocument<T extends IStoreDocument>(poDocument: T | string, psDatabaseId: string): Observable<IStoreDataResponse> {
		if (this.isInitializedDatabase(psDatabaseId))
			return this.deleteDocumentIntoDatabase(psDatabaseId, poDocument);

		else {
			return this.askAndWaitDatabaseId(this.getDocumentRole(poDocument))
				.pipe(
					mergeMap((psId: string) => {
						if (this.isInitializedDatabase(psId))
							return this.deleteDocumentIntoDatabase(psId, poDocument);
						else {
							const lsDocumentId: string = typeof poDocument === "string" ? poDocument : (poDocument as IStoreDocument)._id;
							return throwError(`Erreur lors de la suppression du document '${lsDocumentId}', base de données "${psId}" non initialisée.`);
						}
					})
				);
		}
	}

	/** Met à jour le document avec la valeur '_deleted' à true.
	 * @param poDocument Document à mettre à jour avec la valeur '_deleted' à true.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	private updateAsDeleted<T extends IStoreDocument>(poDocument: T, psDatabaseId?: string): Observable<IStoreDataResponse> {
		poDocument._deleted = true;
		poDocument.deleted = true;
		return this.put(poDocument, psDatabaseId).pipe(tap(_ => this.raiseChangeEvent(poDocument, [psDatabaseId], EChangeType.delete)));
	}

	/** Supprime un document sur la base de données en le récupérant si l'on passe uniquement l'identifiant.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @param poDocument Document à supprimer de la base de données.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private deleteDocumentIntoDatabase<T extends IStoreDocument>(psDatabaseId: string, poDocument: T | string): Observable<IStoreDataResponse> {
		let loSelectedDatabase: PouchDB.Database;

		return this.getDatabaseInstance(psDatabaseId)
			.pipe(
				mergeMap((poSelectedDatabase: PouchDB.Database) => {
					loSelectedDatabase = poSelectedDatabase;

					if (typeof poDocument === "string") {
						return from(loSelectedDatabase.get(poDocument)) // On récupère le document sur la base car on n'a que l'identifiant.
							.pipe(
								catchError(poError => {
									console.error(`${Store.C_LOG_ID}Erreur récupération document "${poDocument}" dans la bdd "${psDatabaseId}" avant suppression :`, poError);
									return throwError(poError);
								})
							);
					}
					else
						return of(poDocument);
				}),
				mergeMap((poResultDoc: IStoreDocument) =>
					from(this.trackIfNeeded(this.getDatabaseById(psDatabaseId), poResultDoc)).pipe(mapTo(poResultDoc))
				),
				mergeMap((poResultDoc: IStoreDocument) => this.deleteDocument(loSelectedDatabase, poResultDoc, psDatabaseId)),
				tap((poResponse: PouchDB.Core.Response) => this.onDocumentUpdated(poResponse, psDatabaseId))
			);
	}

	private deleteDocument<T extends IStoreDocument>(poSelectedDatabase: PouchDB.Database, poDocument: T, psDatabaseId: string): Observable<IStoreDataResponse> {
		return from(poSelectedDatabase.remove(poDocument as PouchDB.Core.IdMeta & PouchDB.Core.RevisionIdMeta))
			.pipe(
				tap(_ => this.raiseChangeEvent(poDocument, [psDatabaseId], EChangeType.delete)),
				catchError(poError => {
					console.error(`${Store.C_LOG_ID}Erreur suppression document "${poDocument._id}" depuis la bdd "${psDatabaseId}".`, poDocument, poError);
					return this.onPouchDbSaveError(poDocument, poError);
				}),
				tap(_ => console.log(`${Store.C_LOG_ID}Suppression document ${poDocument._id} OK.`))
			);
	}

	/** Retourne `true` si le document existe, `false` sinon.
	 * @param psDocId Identifiant du document à rechercher.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public docExists(psDocId: string, psDatabase: string): Observable<boolean>;
	/** Retourne `true` si un document existe, `false` dans le cas contraire.
	 * @param psDocId Identifiant du document dont on veut vérifier l'existence.
	 * @param paDatabaseIds Tableau des identifiants de bases de données où chercher le document.
	 */
	public docExists(psDocId: string, paDatabaseIds: string[]): Observable<boolean>;
	public docExists(psDocId: string, poDatabaseIdData: string | string[]): Observable<boolean> {
		const lbIsDatabaseId: boolean = typeof poDatabaseIdData === "string";

		return this.getOne({
			databaseId: lbIsDatabaseId ? poDatabaseIdData : undefined,
			databasesIds: lbIsDatabaseId ? undefined : poDatabaseIdData,
			viewParams: {
				key: psDocId,
				include_docs: false
			}
		} as IDataSource)
			.pipe(map((poResult?: IStoreDocument) => !!poResult));
	}

	/** Crée une base locale pour le mode 'local' et l'initialise en créant temporairement une base distante.
	 * @param psDatabaseId Id/nom de la base de données locale à initialiser.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private fillLocalDatabase(psDatabaseId: string, psLogin: string, psPassword: string, pfOnProgress?: (poEvent: ISyncronizationEvent) => void): Observable<IStoreReplicationResponse> {
		return this.replicateToLocal(psDatabaseId, undefined, psLogin, psPassword, pfOnProgress) // Synchronisation avec la base locale.
			.pipe(
				catchError(poError => { console.error(`${Store.C_LOG_ID}Local database "${psDatabaseId}" initialization failed:`, poError); return throwError(poError); }),
				map((poResponse: IStoreReplicationResponse) => poResponse)
			);
	}

	

	/** @implements */
	public get<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]>;
	public get<T extends IStoreDocument>(poDataSource: IDataSourceRemoteChanges<T>): Observable<T[]>;
	public get<T extends IStoreDocument>(poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>): Observable<T[]> {
		const loGetLive$: Observable<IChangeEvent<T>[]> =
			defer(() => poDataSource.live || (poDataSource as IDataSourceRemoteChanges<T>).remoteChanges ? this.innerGet_live<T>(poDataSource) : EMPTY)
				.pipe(startWith([]));

		return combineLatest([loGetLive$, this.innerGet(poDataSource)])
			.pipe(
				scan((paAccumulators: T[], paCurrentResults: [IChangeEvent<T>[], T[]]) => {
					const laCurrentEvents: IChangeEvent<T>[] = paCurrentResults[0];
					const laCurrentDocuments: T[] = Array.from(paAccumulators ?? paCurrentResults[1]);

					laCurrentEvents.forEach((poCurrentEvent: IChangeEvent<T>) => this.handleChangeEvent<T>(poCurrentEvent, laCurrentDocuments));

					return laCurrentDocuments;
				}, undefined) // On met une graine undefined pour forcer le premier passage dans le scan
			);
	}

	public handleChangeEvent<T extends IStoreDocument>(poCurrentEvent: IChangeEvent<T>, laCurrentDocuments: T[]): void {
		switch (poCurrentEvent.changeType) {
			case EChangeType.create:
				laCurrentDocuments.push(poCurrentEvent.document);
				break;
			case EChangeType.delete:
				ArrayHelper.removeElementById(laCurrentDocuments, poCurrentEvent.document._id);
				break;
			case EChangeType.update:
				ArrayHelper.replaceElementByFinder(laCurrentDocuments, (poDocument: T) => poDocument._id === poCurrentEvent.document._id, poCurrentEvent.document);
				break;
		}
	}

	private viewParamsToSelector(poParams: IDataSourceViewParams): PouchDB.Find.Selector {
		return {
			_id: {
				$gte: poParams.startkey,
				$lte: poParams.endkey,
				$in: poParams.keys,
				$eq: poParams.key
			}
		};
	}

	/** Permet la récupération des changements sur une base distante.
	 * @param paDatabaseIds Identifiants de bases de données.
	 * @param poParams Paramètres de filtrage des données à récupérer.
	 * @returns
	 */
	public changes<T extends IStoreDocument>(poDataSource: IDataSourceRemoteChanges<T>): Observable<IChangeEvent<T>> {
		if (!poDataSource)
			return EMPTY;

		const loDataSource: IDataSourceRemoteChanges<T> = this.prepareDataSource<T, IDataSourceRemoteChanges<T>>(poDataSource);

		if (!ArrayHelper.hasElements(loDataSource.databasesIds)) {
			const lsMessage = "Veuillez préciser une base de données pour récupérer les changements.";

			console.error(`${Store.C_LOG_ID}${lsMessage}`);
			return throwError(lsMessage);
		}

		return merge(...loDataSource.databasesIds.map((psDatabaseId: string) => {
			const loDatabase: Database = this.getDatabaseById(psDatabaseId);

			if (loDatabase.hasLocalInstance())
				return this.moReplicationChangesSubject.asObservable() as Observable<IChangeEvent<T>>;
			else if (loDatabase.hasRemoteInstance())
				return this.getChangesFromServer<T>(loDatabase, loDataSource, psDatabaseId);
			else
				return EMPTY;
		}))
			.pipe(
				filter((poChange: IChangeEvent<T>) => this.changeMatchesDataSource(poChange, loDataSource)),
				bufferUntil(() => loDataSource.activePageManager.isActive$.pipe(filter((pbIsActive: boolean) => pbIsActive))),
				mergeMap((paChangeEvents: IChangeEvent<T>[]) => paChangeEvents)
			);
	}

	private getChangesFromServer<T extends IStoreDocument>(poDatabase: Database, poDataSource: IDataSourceRemoteChanges<T>, psDatabaseId: string): Observable<IChangeEvent<T>> {
		let loChange: PouchDB.Core.Changes<T>;
		let lsLastSeq = "now";
		return poDataSource.activePageManager.isActive$.pipe(
			switchMap((pbIsActive: boolean) => {
				if (pbIsActive) {
					loChange = poDatabase.getRemoteInstance().changes({
						selector: poDataSource.selector ? poDataSource.selector : this.viewParamsToSelector(poDataSource.viewParams),
						include_docs: poDataSource.viewParams.include_docs,
						since: lsLastSeq,
						live: true,
						descending: poDataSource.viewParams.descending,
						limit: poDataSource.viewParams.limit
					});

					const loCompleteEvent$: Observable<PouchDB.Core.ChangesResponse<T>> = fromEvent(loChange, "complete");
					const loError$: Observable<never> = fromEvent(loChange, "error").pipe(mergeMap(poError => throwError(poError)), takeUntil(loCompleteEvent$));
					const loChange$: Observable<PouchDB.Core.ChangesResponseChange<T>> =
						fromEvent(loChange, "change").pipe(map((paValues: any[]) => ArrayHelper.getFirstElement(paValues)), takeUntil(loCompleteEvent$)) as Observable<PouchDB.Core.ChangesResponseChange<T>>;

					return merge(loChange$, loError$)
						.pipe(
							takeUntil(poDatabase.canReplicate$.pipe(filter((pbDatabaseMetaOk: boolean) => !pbDatabaseMetaOk))),
							finalize(() => loChange.cancel()) // Permet de clôturer la récupération des changements lors de la clôture du flux.
						);
				}
				else if (loChange)
					loChange.cancel();

				return EMPTY;
			}),
			tap((poResponse: PouchDB.Core.ChangesResponseChange<T>) => lsLastSeq = poResponse.seq as string),
			map((poResponse: PouchDB.Core.ChangesResponseChange<T>) => {
				const loDoc: T = poResponse.doc ?? { _id: poResponse.id, _rev: ArrayHelper.getLastElement(poResponse.changes)?.rev } as T;

				return {
					document: loDoc,
					databaseIds: [psDatabaseId],
					changeType: this.getChangeTypeFromChange(loDoc, poResponse.deleted)
				};
			}),
			retryWhen((poErrors$: Observable<any>) => this.getRequestRetryStrategy(poErrors$))
		);
	}

	/** Retourne la stratégie d'essai de nouvelle tentative pour une requête vers la base de données.
	 * @param poErrors$
	 * @param pnDelayMs Delai avant de retenter (en ms).
	 */
	public getRequestRetryStrategy(poErrors$: Observable<any>, pnDelayMs = Store.C_RETRY_DELAY_MS): Observable<any> {
		return poErrors$.pipe(
			mergeMap((poError: any) => {
				return this.handlePouchDbError(poError).pipe(
					catchError((poPouchDBError: any) => {
						if (poPouchDBError instanceof TokenError) {
							this.handleTokenError();
							return throwError(poPouchDBError);
						}
						else if (poPouchDBError instanceof DeviceNotAuthorizedError)
							return this.handleUnauthorizedDevice().pipe(mergeMapTo(throwError(poPouchDBError)));

						return timer(pnDelayMs).pipe(mergeMap(() => this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)));
					})
				);
			})
		);
	}

	private getChangeTypeFromChange<T extends IStoreDocument>(poDoc: T, pbDeleted: boolean): EChangeType {
		if (pbDeleted)
			return EChangeType.delete;
		if (poDoc._rev.startsWith("1-"))
			return EChangeType.create;

		return EChangeType.update;
	}

	/** Partie du get() qui s'occupe de récupérer un lot de documents dans les bases de données.
	 * @param poDataSource Source de données permettant la récupération des documents.
	 */
	private innerGet<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]> {
		// Si la source de données possède un tableau d'identifiants de base de données ou un rôle, il faut faire un get multi, sinon get simple.
		const loGet$: Observable<T[]> = (ArrayHelper.hasElements(poDataSource.databasesIds) && poDataSource.databasesIds.length > 1) || poDataSource.role ?
			this.getFromMultiDatabases(poDataSource) :
			this.getFromDatabase(poDataSource, poDataSource.databaseId ?? ArrayHelper.getFirstElement(poDataSource.databasesIds));
		const loPerformance = new PerformanceManager();

		return defer(() => {
			console.debug(`${Store.C_LOG_ID}Nouvelle requête récupération :`, poDataSource);
			this.appendUserActionsCounter(1);
			return of(loPerformance.markStart());
		})
			.pipe(
				mergeMap(_ => loGet$),
				tap((paResults: T[]) => {
					if (poDataSource.viewParams.conflicts) {
						const laConflictedDocIds: string[] = [];
						paResults.forEach((poResult: T) => {
							if (ArrayHelper.hasElements(poResult._conflicts))
								laConflictedDocIds.push(poResult._id);
						});

						if (ArrayHelper.hasElements(laConflictedDocIds))
							this.moConflictsSubject.next(laConflictedDocIds);
					}
				}),
				mergeMap((paResults: T[]) => {
					return defer(() => StringHelper.isBlank(poDataSource.viewName) ? of(true) : this.createCompiledViewDoc(poDataSource.viewName))
						.pipe(
							catchError(poError => {
								console.error(`${Store.C_LOG_ID}Erreur création document de vue '${this.getCompiledViewDocumentNameFromViewName(poDataSource.viewName)}'`, poError);
								return of(false);
							}),
							map(_ => paResults)
						);
				}),
				tap(
					_ => console.debug(`${Store.C_LOG_ID}Get duration (ms) : ${loPerformance.markEnd().measure()} ; dataSource:`, poDataSource),
					poError => {
						loPerformance.clear();
						console.error(`${Store.C_LOG_ID}Erreur lors de la requête de récupération ; dataSource:`, poDataSource, poError);
					}
				),
				finalize(() => this.appendUserActionsCounter(-1))
			);
	}

	/** Récupère les documents initiaux puis filtre les changements détectés sur les bases de données.
	 * @param poDataSource Source de données permettant la récupération des documents.
	 */
	private innerGet_live<T extends IStoreDocument>(poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>): Observable<IChangeEvent<T>[]> {
		const laSources$: Observable<IChangeEvent<T>>[] = [];
		const loEmittedSubject = new Subject<void>();

		if (poDataSource.live)
			laSources$.push(this.localChanges(poDataSource));
		if ((poDataSource as IDataSourceRemoteChanges<T>).remoteChanges)
			laSources$.push(this.changes(poDataSource as IDataSourceRemoteChanges<T>));

		return merge(...laSources$).pipe( // On s'abonne aux différentes sources.
			tap(() => loEmittedSubject.next()), // Dès qu'un événement est envoyé on le signale dans le sujet.
			// A chaque événement dans le sujet on crée un buffer qui va garder les résultats pendant le temps indiqué.
			// Si un buffer existe déjà, il n'en crée pas un autre.
			bufferWhen(() => loEmittedSubject.asObservable().pipe(delay(this.C_GET_LIVE_BUFFER_TIME_MS))),
			finalize(() => loEmittedSubject.complete())
		);
	}

	/** Retourne les changements fait localement.
	 * @param poDataSource
	 */
	public localChanges<T extends IStoreDocument = IStoreDocument>(poDataSource: IDataSource<T>): Observable<IChangeEvent<T>> {
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);
		return (this.moLocalChangesSubject.asObservable() as Observable<IChangeEvent<T>>)
			.pipe(filter((poChange: IChangeEvent<T>) => this.changeMatchesDataSource(poChange, loDataSource)));
	}

	/** Vérifie si le changement opéré correspond à la source de données.
	 * @param poChange Événement qui s'est produit.
	 * @param poDataSource Source de données qui permet d'écouter les changements du store.
	 */
	private changeMatchesDataSource<T extends IStoreDocument>(poChange: IChangeEvent<T>, poDataSource: IDataSource<T>): boolean {
		// On vérifie que l'une des bases de données d'où proviennent les changements correspond à l'une des bases de données de la source de données.
		const lbMatches: boolean = poChange.databaseIds.some((psChangeDatabaseId: string) => {
			if (ArrayHelper.hasElements(poDataSource.databasesIds))
				return poDataSource.databasesIds.some((psDataSourceDatabaseId: string) => psChangeDatabaseId === psDataSourceDatabaseId);
			else
				return psChangeDatabaseId === poDataSource.databaseId;
		});

		return lbMatches && (ObjectHelper.isEmpty(poDataSource.viewParams) || this.changeMatchesViewParams(poChange, poDataSource));
	}

	private changeMatchesViewParams<T extends IStoreDocument>(poChange: IChangeEvent<T>, poDataSource: IDataSource<T>): boolean {
		const loViewParams: IDataSourceViewParams = poDataSource.viewParams;
		let lbMatches = true;

		if (StringHelper.isValid(loViewParams.key) || NumberHelper.isValid(loViewParams.key))
			lbMatches = poChange.document._id === loViewParams.key;

		else if (ArrayHelper.hasElements(loViewParams.keys))
			lbMatches = loViewParams.keys.includes(poChange.document._id);

		else
			lbMatches = (StringHelper.isBlank(loViewParams.startkey) || poChange.document._id >= loViewParams.startkey) &&
				(StringHelper.isBlank(loViewParams.endkey) || poChange.document._id <= loViewParams.endkey);

		if (poDataSource.filter && lbMatches) // Si on a un filtre et que le document correspond, on applique le filtre.
			lbMatches = poDataSource.filter(poChange.document);

		return lbMatches;
	}

	/** @implements */
	public getOne<T extends IStoreDocument>(poDataSource: IDataSource<T>, pbFailIfNoResult?: boolean): Observable<T>;
	public getOne<T extends IStoreDocument>(poDataSource: IDataSourceRemoteChanges<T>, pbFailIfNoResult?: boolean): Observable<T>;
	public getOne<T extends IStoreDocument>(poDataSource: IDataSource<T> | IDataSourceRemoteChanges<T>, pbFailIfNoResult: boolean = true): Observable<T> {
		const loDataSource: IDataSource<T> | IDataSourceRemoteChanges<T> = this.prepareDataSource<T>(poDataSource);

		return this.get(loDataSource)
			.pipe(
				mergeMap((paResults: T[]) => {
					const lsDatabaseIds: string = !StringHelper.isBlank(loDataSource.databaseId) ? loDataSource.databaseId : loDataSource.databasesIds.toString();

					if (pbFailIfNoResult && !ArrayHelper.hasElements(paResults)) {
						const lsMessage = `No result found for document ${poDataSource.viewParams.key} in database ${lsDatabaseIds}.`;
						console.error(`${Store.C_LOG_ID}${lsMessage}`);
						return throwError({ isEmptyResult: true, message: lsMessage });
					}
					else {
						if (paResults.length > 1)
							console.warn(`${Store.C_LOG_ID}More than 1 result found for document ${poDataSource.id} in database ${lsDatabaseIds}.`);

						return of(ArrayHelper.getFirstElement(paResults));
					}
				})
			);
	}

	/** Permet de récupérer les documents avec la view allDocs.
	 * @param poParams paramètres de requête PouchDB (clé, vue, paramètres de la vue).
	 * @param psDatabaseId Id de la base de données à requêter.
	 */
	private getAll<T extends IStoreDocument>(poParams: IDataSource<T>, psDatabaseId: string): Observable<T[]> {
		if (poParams.viewParams?.keys && !ArrayHelper.hasElements(poParams.viewParams.keys))
			return of([]);

		return from((this.moDatabaseById.get(psDatabaseId).defaultDatabase as PouchDB.Database).allDocs(poParams.viewParams))
			.pipe(
				catchError(poError => { console.error(`${Store.C_LOG_ID}Error retrieve all data of database "${psDatabaseId}":`, poError); return throwError(poError); }),
				map((poResult: PouchDB.Core.AllDocsResponse<any>) => this.getDataValuesFromPouchQuery(poResult))
			);
	}

	/** Permet de récupérer les documents avec une vue autre que `allDocs`.
	 * @param poDataSource Source de données pour la requête PouchDB (clé, vue, paramètres de la vue).
	 * @param psDatabaseId Identifiant de la base de données à requêter.
	 */
	private getByView<T extends IStoreDocument>(poDataSource: IDataSource<T>, psDatabaseId: string): Observable<T[]> {
		return from((this.moDatabaseById.get(psDatabaseId).defaultDatabase as PouchDB.Database).query(poDataSource.viewName, poDataSource.viewParams))
			.pipe(
				catchError(poError => { console.error(`${Store.C_LOG_ID}Error request getByView on ${psDatabaseId}:`, poError); return throwError(poError); }),
				map((poResult: PouchDB.Query.Response<any>) => this.getDataValuesFromPouchQuery(poResult))
			);
	}

	/** @implements */
	public getDatabaseById(psDatabaseId: string): Database {
		const loDatabase: Database = this.moDatabaseById.get(psDatabaseId);

		if (!loDatabase)
			throw new Error(`Unknown database with id "${psDatabaseId}".`);

		return loDatabase;
	}

	/** @implements */
	public getDatabaseConfig(poDatabase: Database, pnRevsLimit?: number, psLogin?: string, psPassword?: string): IConfigInitPouchDB {
		return {
			localPouchConfig: this.innerGetDatabaseConfig_localPouchConfig(poDatabase.id, pnRevsLimit),
			remotePouchConfig: this.innerGetDatabaseConfig_remotePouchConfig(poDatabase, psLogin, psPassword)
		};
	}

	private innerGetDatabaseConfig_localPouchConfig(psDatabaseId: string, pnRevsLimit?: number): ILocalDatabaseConfig {
		return {
			adapter: this.adapter,
			auto_compaction: true,
			revs_limit: isNaN(pnRevsLimit) ? this.C_DEFAULT_REVS_LIMIT : pnRevsLimit,
			name: psDatabaseId,
			location: "default"
		} as ILocalDatabaseConfig;
	}

	/** Retourne un objet de configuration pouch pour les bases de données distantes.
	 * @param poDatabase Base de données.
	 * @param psLogin Login de l'utilisateur.
	 * @param psPassword Mot de passe de l'utilisateur.
	 */
	private innerGetDatabaseConfig_remotePouchConfig(poDatabase: Database, psLogin?: string, psPassword?: string): IRemoteDatabaseConfig {
		return {
			fetch: (psUrl: string, opts: RequestInit) => { //https://github.com/pouchdb-community/pouchdb-authentication/issues/239#issuecomment-431872289
				(opts.headers as any).set("appInfo", OsappApiHelper.stringifyForHeaders(ConfigData.appInfo));

				if (ConfigData.authentication.token)
					(opts.headers as any).set("Token", ConfigData.authentication.token);

				if (!StringHelper.isBlank(poDatabase.meta?.originInstanceId))
					(opts.headers as any).set("databaseMetaInstance", poDatabase.meta.originInstanceId);

				opts.credentials = "omit";

				return PouchDB.fetch(psUrl, opts);
			},
			crossDomain: true,
			ajax: { cache: false },
			auth: {
				username: psLogin,
				password: psPassword
			},
			name: this.buildRemoteDatabaseName(poDatabase.id),
			skip_setup: true
		};
	}

	private buildRemoteDatabaseName(psDatabaseId: string): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_data_suffix}${psDatabaseId}`;
	}

	/** Détermine dynamiquement l'adapter approprié en fonction du contexte. */
	private getAdapter(): string {
		// On utilise SQLite si on est sur un mobile, sinon on utilise idb (websql n'étant plus supporté sur divers navigateurs).
		const lsAdapter = this.isvcPlatform.isMobileApp ? "cordova-sqlite" : "idb";

		console.debug(`${Store.C_LOG_ID}Store adapter is ${lsAdapter}.`);

		return lsAdapter;
	}

	/** @implements */
	public getDatabases(): ReadonlyMap<string, Database> {
		return this.moDatabaseById as ReadonlyMap<string, Database>;
	}

	/** Retourne les bases de données qui correspondent au rôle indiqué.
	 * @param peRole Rôle recherché.
	 * @param pbFailIfNoResult Indique si on lève une erreur dans le cas où on ne trouve pas de base de données ayant le rôle.
	 */
	public getDatabasesByRole(peRole: EDatabaseRole, pbFailIfNoResult: boolean = true): Database[] {
		const laDatabases: Database[] = MapHelper.valuesToArray(this.moDatabaseById).filter((poDatabase: Database) => poDatabase.hasRole(peRole));

		if (pbFailIfNoResult && !ArrayHelper.hasElements(laDatabases))
			throw new Error(`Impossible de trouver les bases de données correspondant au rôle "${peRole}".`);
		else
			return laDatabases;
	}

	/** Retourne les bases de données à optimiser. */
	public getDatabasesToOptimize(): Database[] {
		return MapHelper.valuesToArray(this.moDatabaseById).filter((poDatabase: Database) => poDatabase.optimization?.enabled);
	}

	/** @implements */
	public getDatabasesIdsByRole(peRole: EDatabaseRole, pbFailIfNoResult: boolean = true): string[] {
		return this.getDatabasesByRole(peRole, pbFailIfNoResult).map((poDatabase: Database) => poDatabase.id);
	}

	/** Récupère seulement les valeurs des données contenues dans la propriété 'rows': Array<any> (données utiles).
	 * @param poDataFromQuery objet de données récupéré après une requête sur une base de données Pouch, contenant un tableau de résultats.
	 */
	private getDataValuesFromPouchQuery<T extends IStoreDocument>(poDataFromQuery: PouchDB.Core.AllDocsResponse<T>): T[] {
		let laDataValues: T[];

		if (ArrayHelper.hasElements(poDataFromQuery.rows)) {
			// Si le premier élément du tableau de données contient une prop 'doc' alors il faut prendre les données avec les infos.
			const lbGetAllInfo: boolean = poDataFromQuery.rows.some((poItem: IDataFromPouchQuery<T>) => poItem.doc !== undefined);

			laDataValues = poDataFromQuery.rows
				.filter((poItem: IDataFromPouchQuery<T>) =>
					(!lbGetAllInfo || (lbGetAllInfo && !ObjectHelper.isNullOrEmpty(poItem.doc)))
					&& this.isValidValueFromPouchQuery(poItem, !lbGetAllInfo)
				)
				.map((poItem: IDataFromPouchQuery<T>) => lbGetAllInfo ? poItem.doc : { _id: poItem.id, _rev: poItem.value.rev, _deleted: poItem.value.deleted, ...poItem.value } as any as T);
		}
		else
			laDataValues = [];

		return laDataValues;
	}

	/** Vérifie si la valeur est valide.
	 * @param poItem Item à tester.
	 * @param pbAllowDesign Permet de savoir si l'on doit accepter les documents '_design'.
	 */
	private isValidValueFromPouchQuery<T extends IStoreDocument>(poItem: IDataFromPouchQuery<T>, pbAllowDesign: boolean): boolean {
		return poItem.key && poItem.value && !poItem.value.deleted && (pbAllowDesign || poItem.id.indexOf("_design") < 0);
	}

	/** Retourne l'observable utilisé pour faire un get vers PouchDB.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Id de la base de données à requêter.
	 * @param pbHandleUnknownDatabases Indique si l'on doit gérer les bases de données inconnues.
	 */
	private getRequestType<T extends IStoreDocument>(poDataSource: IDataSource<T>, psDatabaseId: string, pbHandleUnknownDatabases: boolean): Observable<T[]> {
		if (!this.moDatabaseById.has(psDatabaseId)) // Si la base n'existe pas.
			return pbHandleUnknownDatabases ? of([]) : throwError(`Erreur : la base ${psDatabaseId} n'existe pas`);

		else if (StringHelper.isBlank(poDataSource.viewName) || poDataSource.viewName === Store.C_DEFAULT_VIEW_DOCS) // La base existe et est initialisée.
			return this.getAll(poDataSource, psDatabaseId);

		else
			return this.getByView(poDataSource, psDatabaseId);
	}

	/** Requête sur une base de données.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Identifiant de la base de données à requêter.
	 */
	private getFromDatabase<T extends IStoreDocument>(poDataSource: IDataSource<T>, psDatabaseId: string): Observable<T[]> {
		// On clone la dataSource préparée pour ne pas altérer l'objet qui peut être utilisé dans un getLive() et qui ne doit pas être changé.
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);

		return defer(() => {
			console.debug(`${Store.C_LOG_ID}Getting data from database : ${psDatabaseId}`);
			return of(null);
		})
			.pipe(
				mergeMap(_ => this.getRequestType(loDataSource, psDatabaseId, loDataSource.handleUnknownDatabases)),
				expand((paResults: T[]) => {
					if (ArrayHelper.hasElements(loDataSource.viewParams.keys)) { // Cas 'keys'.
						loDataSource.viewParams.keys.splice(0, loDataSource.viewParams.batch_size ?? loDataSource.viewParams.keys.length); // Dans le cas où pas de batch_size, on passe.
						if (!ArrayHelper.hasElements(loDataSource.viewParams.keys))
							return EMPTY;
					}
					else { // Cas 'startkey'/'endkey'.
						// On a atteint la fin des résultats.
						if (paResults.length !== loDataSource.viewParams.batch_size)
							return EMPTY;

						loDataSource.viewParams.skip = 1;
						loDataSource.viewParams.startkey = ArrayHelper.getLastElement(paResults)._id;
					}

					return this.getRequestType(loDataSource, psDatabaseId, loDataSource.handleUnknownDatabases);
				}, 1),
				reduce((paResults: T[], paCurrentResults: T[]) => {
					if (ArrayHelper.hasElements(paCurrentResults))
						paResults.push(...paCurrentResults);

					return paResults;
				}, []),
				map((paResults: T[]) => this.onGetResult(paResults, loDataSource, psDatabaseId))
			);
	}

	private prepareDataSource<T extends IStoreDocument = IStoreDocument, U extends IDataSource<T> = IDataSource<T>>(poDataSource: U): U {
		if (!poDataSource.viewParams)
			poDataSource.viewParams = {};

		if (ArrayHelper.hasElements(poDataSource.fields)) {
			poDataSource.fields.push(Store.C_ID_PROPERTY, Store.C_REVISION_PROPERTY);
			if (poDataSource.viewParams.conflicts)
				poDataSource.fields.push(Store.C_CONFLICTS_PROPERTY);
			poDataSource.fields = ArrayHelper.unique(poDataSource.fields);
			poDataSource.viewParams.include_docs = true;
		}

		poDataSource.handleUnknownDatabases = poDataSource.handleUnknownDatabases !== false;

		const loDataSource: U = {
			...poDataSource,
			viewParams: { // On n'utilise pas le clone car dans le cas d'un long tableau dans keys il demande trop de ressources.
				...poDataSource.viewParams,
				keys: poDataSource.viewParams?.keys ? [...poDataSource.viewParams.keys] : undefined
			}
		};

		// Si on inclus les conflits, on est obligé d'inclure le document.
		if (poDataSource.viewParams.conflicts)
			poDataSource.viewParams.include_docs = true;

		//! ATTENTION : Si on fait un get avec une vue et si la clé est un nombre sous forme de string, on convertit la clé en nombre.
		//! Une requête par vue dans IDL a son id en string mais si on ne le convertit pas en nombre, on n'obtient aucun résultat.
		//! Si on fait cette transformation tout le temps, cela pose problème dans COM (récupération des clients) avec l'adapteur 'idb'.
		if (!StringHelper.isBlank(loDataSource.viewName) && (loDataSource.viewName !== Store.C_DEFAULT_VIEW_DOCS && NumberHelper.isValid(+loDataSource.viewParams.key)))
			loDataSource.viewParams.key = +loDataSource.viewParams.key;

		if (loDataSource.viewParams?.include_docs && (isNaN(loDataSource.viewParams.batch_size) || loDataSource.viewParams.batch_size === 0))
			loDataSource.viewParams.batch_size = this.C_DEFAULT_BATCH_SIZE;

		if (ArrayHelper.hasElements(loDataSource.viewParams.keys))
			loDataSource.viewParams.keys = ArrayHelper.unique(loDataSource.viewParams.keys.filter((psKey: string) => !StringHelper.isBlank(psKey)));

		if (!NumberHelper.isValidStrictPositive(loDataSource.viewParams.limit))
			loDataSource.viewParams.limit = loDataSource.viewParams.batch_size;

		if (loDataSource.role)
			loDataSource.databasesIds = this.getDatabasesIdsByRole(poDataSource.role);

		if (!ArrayHelper.hasElements(loDataSource.databasesIds) && !StringHelper.isBlank(loDataSource.databaseId))
			loDataSource.databasesIds = [loDataSource.databaseId];

		if (UserData.current)
			loDataSource.databasesIds = this.prepareWorkspaceFiltersDatabases(loDataSource.databasesIds);

		return loDataSource;
	}

	/** Requête sur plusieurs bases de données.
	 * @param poDataSource Paramètres de la requête.
	 */
	private getFromMultiDatabases<T extends IStoreDocument>(poDataSource: IDataSource<T>): Observable<T[]> {
		const loDataSource: IDataSource<T> = this.prepareDataSource<T>(poDataSource);

		return from(loDataSource.databasesIds)
			.pipe(
				mergeMap((psDatabaseId: string) => this.getFromDatabase(loDataSource, psDatabaseId)),
				reduce((paAccumulResults: T[], paCurrentResults: T[]) => paAccumulResults.concat(paCurrentResults), [])
			);
	}

	/** @implements */
	@Queue<Store, Parameters<Store["initDatabase"]>, ReturnType<Store["initDatabase"]>>({
		idBuilder: (_, poDatabaseConfig: IDatabaseConfig) => poDatabaseConfig?.id,
		paramsReducer: (_, paNewArgs: [IDatabaseConfig, Loader, string, string]) => paNewArgs
	})
	public initDatabase(poDatabaseConfig: IDatabaseConfig, poLoader: Loader, psLogin?: string, psPassword?: string): Observable<Database> {
		this.isvcLogger.action(Store.C_LOG_ID, `Initializing database ${poDatabaseConfig.id}.`, ELogActionId.storeInitDb, { databaseId: poDatabaseConfig.id });

		if (!ConfigData.databases.some((poCachedDatabaseConfig: IDatabaseConfig) => poDatabaseConfig.id === poCachedDatabaseConfig.id))
			ConfigData.databases.push(poDatabaseConfig);

		const leSyncType: ESyncType = this.extractSyncTypeFromConfig(poDatabaseConfig);

		poDatabaseConfig.roles = poDatabaseConfig.roles ? poDatabaseConfig.roles : [poDatabaseConfig.role];

		if (this.moDatabaseById.get(poDatabaseConfig.id)?.isInitialized) {
			console.warn(`${Store.C_LOG_ID}Database ${poDatabaseConfig.id} has already been initialized.`);
			return of(this.moDatabaseById.get(poDatabaseConfig.id));
		}
		else {
			console.debug(`${Store.C_LOG_ID}Initializing database ${poDatabaseConfig.id} (sync : ${leSyncType}).`);
			this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.working));

			// On est obligé de garder cette forme d'init car la création dans l'instance de Database est dans la méthode getInitDatabaseObservable;
			const loInit$: Observable<Database> = this.getInitDatabaseObservable(leSyncType, poDatabaseConfig, poLoader, psLogin, psPassword);

			return this.prepareDatabase(this.getDatabaseById(poDatabaseConfig.id)).pipe(
				mergeMap(() => loInit$),
				mergeMap(() => this.completeInitDatabase(poDatabaseConfig.id, true))
			);
		}
	}

	private prepareDatabase(poDatabase: Database): Observable<boolean> {
		return defer(() => poDatabase.hasDatabaseMeta ? this.getDatabaseMeta(poDatabase.id) : of(undefined)).pipe(
			tap((poDatabaseMeta?: IDatabaseMeta) => poDatabase.meta = poDatabaseMeta),
			mergeMap(() => {
				if (poDatabase.syncType === ESyncType.offlineFirst || poDatabase.syncType === ESyncType.replicateOnStart)
					return this.innerPrepareDatabase(poDatabase);
				else
					return of(true);
			})
		);
	}

	private innerPrepareDatabase(poDatabase: Database): Observable<boolean> {
		return this.getSyncMarker(poDatabase.id).pipe(
			tap((poSyncMarker: IDatabaseSyncMarker) => poDatabase.syncMarker = poSyncMarker), // On initialise le marqueur de synchronisation de la base de données.
			mergeMap((poSyncMarker: IDatabaseSyncMarker) => { // Retrocompat
				return poDatabase.isLocalInstanceEmpty().pipe(
					mergeMap((pbIsEmpty: boolean) => {
						// Si la base a été répliquée et que on est dans un cas de mise à jour de retrocompatibilité, on la considère initialisée.
						if (!pbIsEmpty && poSyncMarker.fromServer && ConfigData.appInfo.previousAppVersion === Version.C_UNKNOWN_VERSION)
							return poDatabase.markLocalInstanceAsFirstReplicated();
						else if (pbIsEmpty) // Sinon si elle est vide on force le fait qu'elle n'ai pas eu de première init.
							return poDatabase.markLocalInstanceAsNotFirstReplicated();
						else
							return of(true);
					})
				);
			})
		);
	}

	/** Retourne l'observable permettant d'initialiser une base de données en fonction de son type de synchro.
	 * @param peSyncType Type de synchro de la base de données.
	 * @param poDbConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login, optionnel.
	 * @param psPassword Mot de passe, optionnel.
	 * @throws
	 * - `UnknownSyncTypeError` si type de synchro inconnu.
	 * -
	 */
	private getInitDatabaseObservable(peSyncType: ESyncType, poDbConfig: IDatabaseConfig, poLoader: Loader, psLogin?: string, psPassword?: string)
		: Observable<Database> {
		switch (peSyncType) {

			case ESyncType.fillIfEmpty:
				return this.initDatabaseFillIfEmpty(poDbConfig, psLogin, psPassword);

			case ESyncType.offlineFirst:
				return this.initDatabaseOfflineFirst(poDbConfig, poLoader, psLogin, psPassword);

			case ESyncType.remote:
				return this.initDatabaseRemote(poDbConfig, psLogin, psPassword);

			case ESyncType.replicateOnStart:
				return this.initDatabaseReplicateOnStart(poDbConfig, poLoader, psLogin, psPassword);

			case ESyncType.none:
				return this.initDatabaseNone(poDbConfig);

			case ESyncType.manual:
				return this.initDatabaseManualReplication(poDbConfig, psLogin, psPassword);

			default:
				return throwError(new UnknownSyncTypeError(peSyncType));
		}
	}

	/** Lance la réplication d'une base de données. DOIT S'EXECUTER après que la base soit initialisée. */
	public initReplication(poDatabaseConfig: IDatabaseConfig, psLogin?: string, psPassword?: string): Observable<Database> {
		const leSyncType: ESyncType = this.extractSyncTypeFromConfig(poDatabaseConfig);

		console.debug(`${Store.C_LOG_ID}Lancement d'une réplication pour la base de donnée "${poDatabaseConfig.id}".`);

		switch (leSyncType) {

			case ESyncType.offlineFirst:
				return this.replicateDatabaseOfflineFirst(poDatabaseConfig);

			case ESyncType.replicateOnStart:
				return this.replicateDatabaseReplicateOnStart(poDatabaseConfig, psLogin, psPassword);

			case ESyncType.manual:
			case ESyncType.none:
			case ESyncType.uploadOnly:
			case ESyncType.remote:
			case ESyncType.fillIfEmpty:
				console.warn(`${Store.C_LOG_ID}La réplication doit se faire pendant l'initialisation pour la sync de type ${leSyncType}. La base "${poDatabaseConfig.id}" risque de ne pas être à jour.`);
				return of(this.moDatabaseById.get(poDatabaseConfig.id));
			default:
				throw new Error(`Unkown sync type : ${leSyncType}.`);
		}
	}

	/** Retourne le type de synchronisation pour la base de données passée en paramètre. */
	private extractSyncTypeFromConfig(poDatabaseConfig: IDatabaseConfig): ESyncType {
		let leSyncType: ESyncType = ESyncType.none;

		if (poDatabaseConfig) {
			if (poDatabaseConfig.syncType)
				leSyncType = poDatabaseConfig.syncType;
			else if (ArrayHelper.hasElements(poDatabaseConfig.syncTypes)) {
				poDatabaseConfig.syncTypes.forEach((poSyncTypeByPlatform: ISyncType) => {
					if (poSyncTypeByPlatform.platforms.indexOf(ConfigData.appInfo.platform) >= 0)
						leSyncType = poSyncTypeByPlatform.syncType;
				});
			}
		}
		else
			console.warn(`${Store.C_LOG_ID}Le type de synchronisation par défaut à été retourné.`)

		return leSyncType;
	}

	/** Initialise une base de données au comportement "fillIfEmpty" (la remplit uniquement si elle est vide).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseFillIfEmpty(poDatabaseConfig: IDatabaseConfig, psLogin: string, psPassword: string): Observable<Database> {
		const loDatabase: Database = this.createLocalDatabase(poDatabaseConfig);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);

		return this.moDatabaseById.get(poDatabaseConfig.id).isLocalInstanceEmpty()
			.pipe(
				mergeMap((pbIsEmpty: boolean) => pbIsEmpty ?
					this.fillLocalDatabase(poDatabaseConfig.id, psLogin, psPassword) : of({} as IStoreReplicationResponse)
				),
				catchError(poError => {
					this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed));
					return throwError(poError);
				}),
				mapTo(loDatabase)
			);
	}

	/** Initialise une base de données qu'on réplique manuellement (conversations notamment).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseManualReplication(poDatabaseConfig: IDatabaseConfig, psLogin: string, psPassword: string): Observable<Database> {
		// On initialise également la base remote pour permettre la réplication manuelle (comme dans le cas de la base de conversation).
		const loDatabase: Database = this.createLocalAndRemoteDatabase(poDatabaseConfig, psLogin, psPassword);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);

		return of(loDatabase);
	}

	/** Initialise une base de données locale, qu'on ne synchronise pas.
	 * @param poDatabaseConfig Configurations de la base de données.
	 */
	private initDatabaseNone(poDatabaseConfig: IDatabaseConfig): Observable<Database> {
		const loDatabase: Database = this.createLocalDatabase(poDatabaseConfig);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);

		return of(loDatabase);
	}

	/** Initialise une base de données au comportement "offlineFirst".
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseOfflineFirst(poDatabaseConfig: IDatabaseConfig, poLoader: Loader, psLogin: string, psPassword: string): Observable<Database> {
		this.moDatabaseById.set(poDatabaseConfig.id, this.createLocalAndRemoteDatabase(poDatabaseConfig, psLogin, psPassword));
		const loDatabase: Database = this.moDatabaseById.get(poDatabaseConfig.id);

		return loDatabase.isLocalInstanceNew().pipe(
			mergeMap((pbIsDatabaseNew: boolean) => {
				return this.isvcNetwork.asyncIsNetworkReliable()
					.pipe(
						map((pbHasNetwork: boolean) => {
							if (pbIsDatabaseNew && !pbHasNetwork) // Base de données jamais initialisé et il n'y a pas d'internet.
								throw new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH);
						})
					);
			}),
			mergeMap(_ => this.canReplicationBeDeferred(loDatabase)),
			mergeMap((pbCanReplicationBeDeferred: boolean) => {
				if (pbCanReplicationBeDeferred) { // Si la réplication peut être asynchrone, on lance un événement.
					console.debug(`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée mais sa réplication sera différée.`);
					this.raiseDatabaseReplicationEvent(poDatabaseConfig.id);
					return of(loDatabase);
				}
				else { // Sinon, on réplique la base tout de suite.
					console.debug(`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée, réplication non différée.`);

					return this.installDump(loDatabase, poDatabaseConfig, poLoader, () => this.replicateDatabaseOfflineFirstWithNetwork(poDatabaseConfig, poLoader))
						.pipe(tap(() => this.raiseDatabaseReplicationEvent(poDatabaseConfig.id)));
				}
			})
		);
	}

	public getProgressPercentageString(poSynchronizationEvent?: ISyncronizationEvent): string {
		if (!poSynchronizationEvent)
			return "0%";
		else if (poSynchronizationEvent.loaded === 0 && poSynchronizationEvent.total === 0) // On est dans le cas d'une base à jour
			return "100%";

		const lnPercentage: number = (poSynchronizationEvent.loaded / poSynchronizationEvent.total) * 100;

		return `${(lnPercentage >= 100 ? 100 : NumberHelper.round(lnPercentage, 1))}%`;
	}

	/** Remonte les données en attente sur le serveur, puis détruit les bases locales et télécharge/installe un dump des bases de données en paramètres.
	 * @param paDatabases Tableau des bases de données d'espace de travail qu'il faut "optimiser".
	 * @returns Un résumé du nombre de bases optimisées.
	 * @throws
	 * - `NoOnlineReliableNetworkError` si pas de connexion internet,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour une base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour une base de données,
	 * - `IPouchDBFailedToFetchError`, si une erreur pouchDb survient (à priori tout le temps "Failed to fetch").
	 * - autre erreur.
	 */
	public resetDatabases(paDatabases: Database[]): Observable<IResetDatabasesResult> {
		let loLoader: Loader;
		const loResult: IResetDatabasesResult = {
			totalCount: 0,
			successCount: 0,
			failedCount: 0
		};

		if (!ArrayHelper.hasElements(paDatabases))
			return of(loResult);

		return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap((pbIsNetworkOnlineReliable: boolean) => pbIsNetworkOnlineReliable ?
					from(this.isvcLoading.create()) : throwError(new NoOnlineReliableNetworkError())
				),
				tap((poLoader: Loader) => {
					loLoader = poLoader;
					loLoader.present();
					// On lève le flag qui arrête le BTS afin de ne pas avoir de réplication d'une base de données en arrière plan qui peut casser la réinitialisation.
					this.isvcFlag.setFlagValue(EFlag.stopBackgroundTaskService, true);
				}),
				mergeMap(_ => {
					return from(paDatabases)
						.pipe(
							concatMap((poDatabase: Database, pnIndex: number) => this.resetWorkspaceDatabase(poDatabase, pnIndex, paDatabases.length, loLoader)),
							tap(() => {
								loResult.totalCount++;
								loResult.successCount++;
							}),
							toArray(),
						);
				}),
				mapTo(loResult),
				finalize(() => loLoader.dismiss())
			);
	}

	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poCurrentDatabase Base de données d'espace de travail à réinitialiser.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 * @returns
	 * - la base de données réinitialisée,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour la base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour la base de données,
	 * - autre erreur.
	 */
	private resetWorkspaceDatabase(poCurrentDatabase: Database, poLoader: Loader): Observable<Database>;
	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poCurrentDatabase Base de données d'espace de travail à réinitialiser.
	 * @param pnDatabaseIndex Index de la base de données d'espace de travail si on est dans un processus de réinitialisations multiples (pour mettre un compteur sur le loader).
	 * @param pnDatabasesLength Nombre de base de données d'espace de travail total à réinitialiser si on est dans un processus de réinitialisations multiples.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 * @returns
	 * - la base de données réinitialisée,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour la base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour la base de données,
	 * - autre erreur.
	 */
	private resetWorkspaceDatabase(poCurrentDatabase: Database, pnDatabaseIndex: number, pnDatabasesLength: number, poLoader: Loader): Observable<Database>;
	@LogAction<Parameters<Store["resetWorkspaceDatabase"] | any>, ReturnType<Store["resetWorkspaceDatabase"]>>({
		actionId: ELogActionId.resetDatabase,
		successMessage: (poDatabaseResult: Database) => `La réinitialisation de la base de données '${poDatabaseResult.id}' a réussi.`,
		errorMessage: (_, poDatabase: Database) => `La réinitialisation de la base de données '${poDatabase.id}' a échoué.`,
		dataBuilder: (_, __, poDatabaseParam: Database) => { return { databaseId: poDatabaseParam.id }; }
	})
	private resetWorkspaceDatabase(poCurrentDatabase: Database, poDatabaseIndexOrLoader: number | Loader, pnDatabasesLength?: number, poLoader?: Loader): Observable<Database> {
		if (!poCurrentDatabase.hasLocalInstance()) {
			console.warn(`${Store.C_LOG_ID}Reset database '${poCurrentDatabase.id}' skipped because no local instance found.`);
			return of(poCurrentDatabase);
		}
		else if (!poCurrentDatabase.hasRemoteInstance()) {
			console.warn(`${Store.C_LOG_ID}Reset database '${poCurrentDatabase.id}' skipped because no remote instance found.`);
			return of(poCurrentDatabase);
		}
		else
			return this.innerResetWorkspaceDatabase(poCurrentDatabase, poDatabaseIndexOrLoader, (NumberHelper.isValidStrictPositive(pnDatabasesLength) ? pnDatabasesLength : 1), poLoader);
	}

	/** Réinitialise une base de données d'espace de travail et la retourne,
	 * `undefined` si elle n'a pas pu être réinitialisée et qu'il n'y a pas eu d'erreur (pas l'instance locale notamment).
	 * @param poDatabase Base de données de l'espace de travail à réinitialiser.
	 * @param poDatabaseIndexOrLoader Index de la base de données de l'espace de travail si on est dans un processus de réinitialisations multiples
	 * (pour mettre un compteur sur le loader) ou loader pour suivre l'avancement de la réinitialisation.
	 * @param pnDatabasesLength Nombre de base de données d'espaces de travail total à réinitialiser si on est dans un processus de réinitialisations multiples.
	 * @param poLoader Loader pour suivre l'avancement de la réinitialisation.
	 */
	private innerResetWorkspaceDatabase(poDatabase: Database, poDatabaseIndexOrLoader: number | Loader, pnDatabasesLength?: number, poLoader?: Loader): Observable<Database> {
		const lsConsoleMessageBase = `${Store.C_LOG_ID}Reset workspace database '${poDatabase.id}'`;
		let lnDatabaseIndex: number;
		let loLoader: Loader;

		if (typeof poDatabaseIndexOrLoader === "number") {
			lnDatabaseIndex = poDatabaseIndexOrLoader;
			loLoader = poLoader;
		}
		else {
			lnDatabaseIndex = 0;
			loLoader = poDatabaseIndexOrLoader;
		}

		console.debug(`${lsConsoleMessageBase} ongoing.`);
		loLoader.text = `Optimisation de l'espace de travail${pnDatabasesLength === 1 ? "" : ` ${lnDatabaseIndex + 1}/${pnDatabasesLength ?? 1}`} ...`;

		return this.replicateToServer(poDatabase.id).pipe(
			mergeMap(_ => this.loadDump(poDatabase, loLoader, true)),
			tap(
				_ => console.debug(`${lsConsoleMessageBase} complete.`),
				poError => console.error(`${lsConsoleMessageBase} failed.`, poError)
			)
		);
	}

	/** Récupère et charge un dump dans la base.
	 * @param poDatabase
	 * @param poLoader Indicateur de chargement.
	 * @param pbDestroyDataBeforeLoad Indique si les données de la base de données doivent être supprimée avant de fournir les nouvelles données, `false` par défaut.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private loadDump(poDatabase: Database, poLoader: Loader, pbDestroyDataBeforeLoad?: boolean): Observable<Database> {
		const lsDatabaseDumpDirectory: string = this.getDatabaseDumpDirectory(poDatabase);
		const loPerformanceManager = new PerformanceManager;
		const lsBaseMessage: string = poLoader.text;
		let loDumpReader: DumpReader;

		// On télécharge le zip et on dézipe.
		return defer(() =>
			from(this.downloadAndUnzipDump(poLoader, poDatabase, lsDatabaseDumpDirectory)).pipe(tap(_ => loPerformanceManager.markStart()))
		)
			.pipe(
				// On le lit ligne par ligne pour évite de saturer la ram
				mergeMap(() => {
					poLoader.text = `${lsBaseMessage}</br>Chargement : 0%`;
					loDumpReader = new DumpReader(this.isvcFilesystem, `${poDatabase.id}/${Store.C_DUMP_DOC_NAME}`);
					const loReadDump$: Observable<IDumpProgressEvent> = loDumpReader.onChunkRead().pipe(afterSubscribe(() => loDumpReader.readChunk()));

					return pbDestroyDataBeforeLoad ? this.destroyAndCreateLocalInstanceDatabase(poDatabase).pipe(mergeMapTo(loReadDump$)) : loReadDump$;
				}),
				// On provisionne la base
				concatMap((poEvent: IDumpProgressEvent) => this.bulkDocs(poDatabase.id, poEvent.data, false).pipe(tap(() => { }, () => { }, () => {
					poLoader.text = `${lsBaseMessage}</br>Chargement : ${this.getProgressPercentageString(poEvent)}`;
					loDumpReader.readChunk();
				}
				))),
				// On attend la fin de l'init de la base.
				toArray(),
				// On lit les metadonnées qui sont liées au dump pour retrouver le numéro de séquence.
				mergeMap(async () => {
					poLoader.text = `${lsBaseMessage}</br>Finalisation de la base de données`;

					return JSON.parse(await this.isvcFilesystem.readFileAsTextAsync(`${lsDatabaseDumpDirectory}${Store.C_META_DOC_NAME}`)) as PouchDB.Core.DatabaseInfo;
				}),
				mergeMap((poDumpMeta: PouchDB.Core.DatabaseInfo) => {
					console.debug(`${Store.C_LOG_ID}Temps de chargement du dump dans la base ${poDatabase.id} : ${loPerformanceManager.markEnd().measure()}ms.`);
					return this.fixLastSeqAfterDump(poDatabase, poDumpMeta);
				}),
				mergeMap(() => poDatabase.markLocalInstanceAsFirstReplicated()),
				mergeMap(() => {
					poLoader.text = `${lsBaseMessage}</br>Synchronisation 0%`;
					return this.replicateToLocal(poDatabase.id, undefined, undefined, undefined,
						(poEvent: ISyncronizationEvent) => poLoader.text = `${lsBaseMessage}</br>Synchronisation : ${this.getProgressPercentageString(poEvent)}`); // On synchronise avec la base distante.
				}),
				// On nettoie le répertoire de dump après passage.
				mergeMap(() => this.isvcFilesystem.removeAsync(lsDatabaseDumpDirectory).then(() => poDatabase)),
				tap(_ => poLoader.text = lsBaseMessage, _ => poLoader.text = lsBaseMessage)
			);
	}

	private async downloadAndUnzipDump(poLoader: Loader, poDatabase: Database, lsDatabaseDumpDirectory: string): Promise<void> {
		const lsBaseMessage: string = poLoader.text;
		const lsDirectoryPath = await this.isvcFilesystem.getFileUriAsync(lsDatabaseDumpDirectory, Directory.External);
		poLoader.text = `${lsBaseMessage}</br>Téléchargement : 0%`;
		await this.isvcDownload.download(
			`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_data_suffix}${poDatabase.id}/dump`,
			`${lsDirectoryPath}${this.getDatabaseDumpZipName(poDatabase)}`,
			(poEvent: ProgressEvent<EventTarget>) => poLoader.text = `${lsBaseMessage}</br>Téléchargement : ${this.getProgressPercentageString(poEvent)}`
		);

		poLoader.text = `${lsBaseMessage}</br>Extraction : 0%`;
		await this.isvcUnzip.extract(
			`${lsDirectoryPath}${this.getDatabaseDumpZipName(poDatabase)}`,
			lsDirectoryPath,
			(poEvent: ProgressEvent) => poLoader.text = `${lsBaseMessage}</br>Extraction : ${this.getProgressPercentageString(poEvent)}`
		);
	}

	/** Permet de fixer les numéros de séquence pour se comporter comme si la réplication avait eu lieu normalement.
	 * @param poDatabase
	 * @param poDumpMeta
	 */
	private fixLastSeqAfterDump(poDatabase: Database, poDumpMeta: PouchDB.Core.DatabaseInfo): Observable<boolean> {
		return poDatabase.getLastSeqFromInstance("local").pipe(
			mergeMap((pnLastSeq: number) =>
				this.replicate(poDatabase, "fixServerSeq", poDatabase.getLocalInstance(), poDatabase.getRemoteInstance(), { since: pnLastSeq })
			),
			mergeMap(() => this.markSynchro(poDatabase, this.buildRemoteDatabaseName(poDatabase.id), poDatabase.id, { last_seq: poDumpMeta?.update_seq, end_time: Date.now() } as any)),
			mapTo(true)
		);
	}

	/** Récupère le répertoire de sauvegarde local des dump.
	 * @param poDatabase
	 */
	private getDatabaseDumpDirectory(poDatabase: Database): string {
		switch (this.isvcPlatform.platform) {
			case EPlatform.ios:
			case EPlatform.android:
				return `${poDatabase.id}/`;
			default:
				return "";
		}
	}

	/** Récupère le nom du zip contenant le dump de la base de données.
	 * @param poDatabase
	 */
	private getDatabaseDumpZipName(poDatabase: Database): string {
		return `${poDatabase.id}_dump.zip`;
	}

	/** Détruit puis recrée une base de données locale et retourne l'instance de `Database` associée.
	 * @param poDatabase Base de données à détruire puis recréer.
	 */
	public destroyAndCreateLocalInstanceDatabase(poDatabase: Database): Observable<Database> {
		return poDatabase.destroyLocalInstance().pipe(
			map(() => {
				const loLocalDatabaseConfig: ILocalDatabaseConfig = this.innerGetDatabaseConfig_localPouchConfig(
					poDatabase.id,
					ConfigData.databases.find((poDbConfig: IDatabaseConfig) => poDbConfig.id === poDatabase.id)?.revs_limit
				);

				poDatabase.createLocalInstance(poDatabase.id, loLocalDatabaseConfig);

				return poDatabase;
			})
		);
	}

	/** Réplique une base de données au comportement "offlineFirst".
	 * @param poDatabaseConfig Configurations de la base de données.
	 */
	private replicateDatabaseOfflineFirst(poDatabaseConfig: IDatabaseConfig): Observable<Database> {
		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (pbHasNetwork) { // Réplication de la base de données.
						return this.replicateDatabaseOfflineFirstWithNetwork(poDatabaseConfig)
							.pipe(
								catchError(poError => { // Peut tomber en erreur si on a une perte de connexion pendant l'initialisation.
									return this.moDatabaseById.get(poDatabaseConfig.id).isLocalInstanceNew()
										.pipe(
											mergeMap((pbIsNew: boolean) => {
												if (pbIsNew)
													return throwError(new FirstInitFailedError()); // Retourne une erreur spécifique si c'est la première initialisation.
												else {
													console.error(`${Store.C_LOG_ID}Erreur lors de la réplication de la base "${poDatabaseConfig.id}".`, poError);
													return throwError(poError);
												}
											})
										);
								})
							);
					}
					else { // Si internet est coupé, on le fait de manière asynchrone (avec un événement).
						this.raiseDatabaseReplicationEvent(poDatabaseConfig.id);
						console.warn(`${Store.C_LOG_ID}Base '${poDatabaseConfig.id}' déjà initialisée, pas d'internet, réplication de la base différée.`);
						return of(this.moDatabaseById.get(poDatabaseConfig.id));
					}
				})
			);
	}

	/** Crée un objet événement générique pour l'initialisation d'une base de données.
	 * @param psId Identifiant de la base de donneés à initialiser.
	 * @param peStatus Status de l'événement du store.
	 * @param peStoreEventType Type de l'événement du store, "init" par défaut.
	 */
	private createInitDatabaseEvent(psId: string, peStatus: EStoreEventStatus, peStoreEventType: EStoreEventType = EStoreEventType.Init): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psId,
				storeEventType: peStoreEventType,
				status: peStatus
			}
		};
	}

	/** Initialise une base de données au comportement "offlineFirst" si on n'a pas de réseau.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 */
	private replicateDatabaseOfflineFirstWithNetwork(poDatabaseConfig: IDatabaseConfig, poLoader?: Loader): Observable<Database> {
		const loReplicationOptions: IStoreReplicationOptions = {
			live: false,
			retry: false,
			batch_size: 200
		};

		const lsBaseText: string = poLoader?.text;

		return this.replicateToServer(poDatabaseConfig.id, loReplicationOptions, poLoader ?
			(poEvent: ISyncronizationEvent) => poLoader.text = `${lsBaseText}</br>Envoi des données vers le serveur : ${this.getProgressPercentageString(poEvent)}` :
			undefined
		).pipe(
			tap(() => {
				if (poLoader)
					poLoader.text = lsBaseText;
			}),
			mergeMap(_ => this.replicateToLocal(poDatabaseConfig.id, loReplicationOptions, undefined, undefined, poLoader ?
				(poEvent: ISyncronizationEvent) => poLoader.text = `${lsBaseText}</br>Réception des données depuis le serveur : ${this.getProgressPercentageString(poEvent)}` :
				undefined)),
			catchError(poError => {
				this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed, EStoreEventType.Synchro));
				return throwError(poError);
			}),
			map(_ => {
				// On lance l'événement qui déclenche l'ajout de la tâche qui va répliquer les données du serveur vers la base locale.
				this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.initToLocalReplication));
				return this.getDatabaseById(poDatabaseConfig.id);
			}),
			tap(() => { }, () => { }, () => {
				if (poLoader)
					poLoader.text = lsBaseText;
			})
		);
	}

	/** Initialise une base de données au comportement "remote" (distante uniquement).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseRemote(poDatabaseConfig: IDatabaseConfig, psLogin: string, psPassword: string): Observable<Database> {
		const loDatabase: Database = this.createRemoteDatabase(poDatabaseConfig, psLogin, psPassword);
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase);

		return of(loDatabase);
	}

	/** Initialise une base de données au comportement "replicateOnStart" (réplication après initialisation de la base uniquement).
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param poLoader Indicateur de chargement.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 */
	private initDatabaseReplicateOnStart(poDatabaseConfig: IDatabaseConfig, poLoader: Loader, psLogin: string, psPassword: string): Observable<Database> {
		let loDatabase: Database;
		this.moDatabaseById.set(poDatabaseConfig.id, loDatabase = this.createLocalAndRemoteDatabase(poDatabaseConfig, psLogin, psPassword));

		return loDatabase.isLocalInstanceNew().pipe(
			mergeMap((pbIsDatabaseNew: boolean) => {
				return this.isvcNetwork.asyncIsNetworkReliable()
					.pipe(
						map((pbHasNetwork: boolean) => {
							if (pbIsDatabaseNew && !pbHasNetwork) { // Il faut internet pour le premier lancement.
								console.error(`${Store.C_LOG_ID}Une connexion réseau est nécessaire lors de la première initialisation.`);
								throw new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH);
							}
						}));
			}),
			mergeMap(() => this.canReplicationBeDeferred(loDatabase)),
			mergeMap((pbCanReplcationBeDeferred: boolean) => {
				if (pbCanReplcationBeDeferred) { // Si la réplication peut être asynchrone, on lance un événement.
					console.debug(`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée mais sa réplication sera différée.`);
					this.raiseDatabaseReplicationEvent(poDatabaseConfig.id);
					return of(loDatabase);

				}
				else { // Sinon, on réplique la base tout de suite.
					console.debug(`${Store.C_LOG_ID}Base de données "${poDatabaseConfig.id}" initialisée, réplication non différée.`);
					return this.installDump(loDatabase, poDatabaseConfig, poLoader, () => this.replicateDatabaseReplicateOnStart(poDatabaseConfig, psLogin, psPassword, poLoader));
				}
			}),
			catchError(poError => poError.status === 401 ? // Appareil non autorisé.
				throwError(new DeviceNotAuthorizedError()) : this.onReplicateDatabaseReplicateOnStartFailed(loDatabase, poError)
			),
			finalize(() => loDatabase.removeRemoteInstance())
		);
	}

	/**
	 * @param poDatabase Base de données dont il faut installer le dump.
	 * @param poDatabaseConfig Configuration de la base de données à installer.
	 * @param poLoader Loader pour afficher l'avancement à l'utilisateur.
	 * @param errorFallback Gestion de l'erreur.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private installDump(poDatabase: Database, poDatabaseConfig: IDatabaseConfig, poLoader: Loader, errorFallback: () => Observable<Database>): Observable<Database> {
		return defer(() => {
			if (this.isvcPlatform.isMobileApp)
				return poDatabase.isLocalInstanceEmpty();
			return throwError("Dump supporté sur application mobile uniquement."); // TODO En attente de gestion propre browser (download et unzip)
		})
			.pipe(
				// Si la base est vide, on continue, sinon on nettoie la base pour éviter les conflits avec le dump. (Arrive dans le cas où la réplication initiale a été interrompue).
				mergeMap((pbIsEmpty: boolean) => pbIsEmpty ? of(true) : this.clearDbBeforeDumpLoad(poDatabase, poDatabaseConfig, poLoader)),
				mergeMap(() => this.loadDump(poDatabase, poLoader)),
				// Si le téléchargement du dump n'a pas fonctionné, on lance une réplication classique.
				catchError((poError: any) => {
					console.warn(`${Store.C_LOG_ID}Erreur lors du téléchargement du dump de la base de données ${poDatabase.id}. Lancement du comportement en cas d'erreur.`, poError);
					return errorFallback();
				})
			);
	}

	private clearDbBeforeDumpLoad(poDatabase: Database, poDatabaseConfig: IDatabaseConfig, poLoader: Loader): Observable<Database> {
		const lsBaseText: string = poLoader.text;

		return defer(() => {
			poLoader.text = `${lsBaseText}</br>Préparation de la base de données`;
			return poDatabase.syncStatus$.pipe(take(1));
		}).pipe(
			mergeMap((peSyncStatus: EDatabaseSyncStatus) => {
				if (poDatabase.syncType === ESyncType.offlineFirst &&
					peSyncStatus === EDatabaseSyncStatus.obsolete && !StringHelper.isBlank(poDatabase.syncMarker?.remoteSequenceNumber)) // Si il y a des données en attente d'envoi
					return this.replicateToServer(poDatabase.id, { retry: false }); // On réplique vers le serveur sans retry (on ne veut pas bloquer l'utilisateur dans cette boucle).
				return of(null);
			}),
			mergeMap(() => this.getDatabaseMeta(poDatabase.id)),
			mergeMap((poDatabaseMeta: IDatabaseMeta) => poDatabase.destroyLocalInstance().pipe(mapTo(poDatabaseMeta))), // On détruit l'instance locale pour vider toutes les données de la base.
			tap(() => poDatabase.createLocalInstance(poDatabase.id, this.innerGetDatabaseConfig_localPouchConfig(poDatabase.id, poDatabaseConfig.revs_limit))),
			mergeMap((poDatabaseMeta: IDatabaseMeta) => this.putLocal({ ...poDatabaseMeta, _rev: undefined }, poDatabase.id)),
			tap(() => poLoader.text = lsBaseText, () => poLoader.text = lsBaseText),
			mapTo(poDatabase)
		);
	}

	/** Réplique une base de données "replicateOnStart" qui a déjà été initialisé.
	 * @param poDatabaseConfig Configurations de la base de données.
	 * @param psLogin Login.
	 * @param psPassword Mot de passe.
	 * @param poLoader Indicateur de chargement.
	 */
	private replicateDatabaseReplicateOnStart(poDatabaseConfig: IDatabaseConfig, psLogin: string, psPassword: string, poLoader?: Loader): Observable<Database> {
		const lsBaseText: string = poLoader?.text;
		return this.fillLocalDatabase(poDatabaseConfig.id, psLogin, psPassword, poLoader ?
			(poEvent: ISyncronizationEvent) => poLoader.text = `${lsBaseText}</br>Réception des données depuis le serveur : ${this.getProgressPercentageString(poEvent)}` :
			undefined
		)
			.pipe(
				mapTo(this.getDatabaseById(poDatabaseConfig.id)),
				tap(() => { }, () => { }, () => {
					if (poLoader)
						poLoader.text = lsBaseText;
				})
			);
	}

	/** Gère le cas d'erreur d'un appareil non autorisé. Affiche un bouton pour envoyer un mail afin de se faire débloquer. */
	private handleUnauthorizedDevice(): Observable<void> {
		this.isvcLoading.dismissAll(); // L'UI de chargement ne doit pas être devant les boutons.

		return from(this.isvcMail.canSendMail())
			.pipe(
				// Si l'appareil peut envoyer des mails, on propose à l'utilisateur de le faire avec un bouton ; sinon on retourne l'erreur.
				mergeMap((pbCanSendMail: boolean) => pbCanSendMail ? this.askUnlockDeviceMessage() : this.displaySendMailMessage()),
				mergeMap((pbUnlockStatus: IUiResponse<boolean>) => {
					if (pbUnlockStatus.response) // Si l'utilisateur clique sur le bouton de déverrouillage.
						return this.openUnlockDeviceModal();
					else {
						// L'utilisateur a cliqué sur le bouton "Réessayer".
						this.isvcLoading.present("Redémarrage ...");
						ApplicationService.reloadApp();
						return EMPTY;
					}
				}),
				mergeMap((pbResult: boolean) => pbResult ? this.displayUnlockDeviceWaitingMessage() : this.displaySendMailMessage())
			);
	}

	/** Affiche une modale demandant à l'utilisateur son nom et son prénom. */
	private openUnlockDeviceModal(): Observable<boolean> {
		const loModalOptions: ModalOptions = {
			component: UnlockDeviceModalComponent,
			backdropDismiss: false,
			showBackdrop: false,
			componentProps: { params: {} }
		};

		return from(this.ioModalCtrl.create(loModalOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				mergeMap((poValue: OverlayEventDetail<IUnlockDeviceResult>) => poValue.data.cancel ?
					of(false) : this.isvcDevicesSecurity.enrollDevice(poValue.data.firstname, poValue.data.lastname).pipe(mapTo(true))
				)
			);
	}

	/** Affichage un message d'erreur indiquant que l'appareil n'est pas autorisé.
	* L'utilisateur peut cliquer sur un bouton pour déverrouiller l'appareil. Dans ce cas, la valeur de retour sera `true`.
	* @returns `response.true` si l'utilisateur veut déverrouiller l'appareil, sinon `false`.
	*/
	private askUnlockDeviceMessage(): Observable<IUiResponse<boolean>> {
		this.isvcLoading.dismissAll();

		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: "Appareil non autorisé",
				message: "L'appareil n'est pas autorisé.",
				backdropDismiss: false,
				buttons: [
					{
						text: "Faire une demande d'autorisation",
						handler: () => { console.debug("CFG.S::Demande d'autorisation."); return UiMessageService.getTruthyResponse(); }
					} as AlertButton,
					{
						text: "Réessayer",
						handler: () => UiMessageService.getFalsyResponse()
					} as AlertButton
				]
			})
		);
	}

	/** Affichage d'une modale à l'utilisateur en cas d'erreur lors d'un mail de déblocage par mail (ou annulation). */
	private displaySendMailMessage(): Observable<void> {
		return this.displayWaitingMessage(
			"Appareil non autorisé",
			`Votre appareil doit être autorisé pour accéder à ${ConfigData.appInfo.appName}. Contacter l'adresse <b>${ConfigData.appInfo.supportEmail}</b> pour autoriser votre appareil.`,
		);
	}

	/** Affichage un message demandant à l'utilisateur d'attendre que son appareil soit autorisé avant d'accéder à l'application. */
	private displayUnlockDeviceWaitingMessage(): Observable<void> {
		return this.displayWaitingMessage(
			"Appareil en attente d'autorisation",
			`Vous recevrez un mail quand votre appareil sera autorisé à accéder à ${ConfigData.appInfo.appName}.`
		);
	}

	/** Affiche un message à l'utilisateur où il ne peut que quitter ou redémarrer l'application. */
	private displayWaitingMessage(psTitle: string, psMessage: string): Observable<void> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: psTitle,
				message: psMessage,
				backdropDismiss: false,
				buttons: [
					{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
					{ text: "Redémarrer", handler: () => { this.isvcLoading.present("Redémarrage ..."); ApplicationService.reloadApp(); } } as AlertButton
				]
			})
		).pipe(mapTo(undefined));
	}

	/** Gère l'échec de réplication d'une base de données en mode "replicateOnStart".
	 * @param poDatabaseConfig Configuration de la base de données tombée en échec de réplication.
	 * @param poError Erreur survenue lors de la réplication.
	 */
	private onReplicateDatabaseReplicateOnStartFailed(poDatabase: Database, poError: any): Observable<Database> {
		return (this.moDatabaseById.get(poDatabase.id)).isLocalInstanceEmpty()
			.pipe(
				mergeMap((pbIsEmpty: boolean) => {
					if (pbIsEmpty) // La base est vide => premier lancement de l'application, le chargement de la base de données a échoué.
						return this.throwDatabaseReplicationFailedError(poDatabase, poError);
					else { // Si la base n'est pas vide, on demande un chargement asynchrone.
						this.raiseDatabaseReplicationEvent(poDatabase.id);

						return this.canReplicationBeDeferred(this.moDatabaseById.get(poDatabase.id))
							.pipe(
								mergeMap((pbCanBeDeferred: boolean) => {
									if (!pbCanBeDeferred) {
										console.error(`${Store.C_LOG_ID}Base de données "${poDatabase.id}" initialisée vide, alors qu'elle doit être initialisée au démarrage.`);
										return throwError(new FirstInitFailedError(Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH));
									}
									else {
										console.warn(`${Store.C_LOG_ID}Base de données ${poDatabase.id} non initialisée, réplication de la base différée.`);
										return of(poDatabase);
									}
								})
							);
					}
				})
			);
	}

	/** Lève une erreur de première initialisation.
	 * @param poDatabaseConfig Configuration de la base de données qui n'a pas pu s'initialiser.
	 * @param poError Erreur survenue.
	 */
	private throwDatabaseReplicationFailedError(poDatabaseConfig: IDatabaseConfig, poError: any): Observable<never> {
		console.error(`${Store.C_LOG_ID}Réplication de la base de données "${poDatabaseConfig.id}" échouée, mais nécessaire pour poursuivre :`, poError);

		this.raiseStoreEvent(this.createInitDatabaseEvent(poDatabaseConfig.id, EStoreEventStatus.failed));

		return throwError(this.isFailedToFetchError(poError) ? new NoDatabaseInternetConnectionError(poDatabaseConfig.id) : new FirstInitFailedError());
	}

	/** Retourne `true` s'il s'agit d'une erreur Pouch "Failed to fetch" (pas internet à priori), `false` sinon.
	 * @param poError Erreur à déterminer s'il s'agit d'une erreur pouchDb "failed to fetch".
	 */
	private isFailedToFetchError(poError: any): boolean {
		return (poError as IPouchDBFailedToFetchError).message === "Failed to fetch";
	}

	/** Initialise les bases de données.
	 * @param paDatabases Liste des configs des bases de données à initialiser.
	 */
	private initDatabases(paDatabases: Array<IDatabaseConfig>, poLoader: Loader): Observable<Database[]> {
		if (ArrayHelper.hasElements(paDatabases)) {
			const lsBaseText: string = "Initialisation de la base de données";
			return from(paDatabases)
				.pipe(
					concatMap((poDatabaseConfig: IDatabaseConfig, pnIndex: number) => { // Gestion de l'initialisation des bases.
						poLoader.text = `${lsBaseText} ${pnIndex + 1}/${paDatabases.length}`;
						return this.initDatabase(poDatabaseConfig, poLoader)
							.pipe(
								catchError(poError => {
									console.error(`${Store.C_LOG_ID}${poDatabaseConfig.id} database initialization failed:`, poError);
									return throwError(this.isFailedToFetchError(poError) ? new NoDatabaseInternetConnectionError(poDatabaseConfig.id) : poError);
								})
							);
					}),
					toArray()
				);
		}
		else // S'il n'y a pas de bdd à init alors on renvoie en succès.
			return of([]);
	}

	/** Retourne `true` si la réplication de la base passée en paramètre peut être asynchrone.
	 * @param poDatabase Base de données à vérifier si elle peut être répliquée en différée.
	 */
	private canReplicationBeDeferred(poDatabase: Database): Observable<boolean> {
		return poDatabase.isLocalInstanceNew()
			.pipe(
				map((pbDatabaseNew: boolean) => {
					// Si la base de données n'a jamais été initialisé, sa réplication ne peut pas être différée.
					if (pbDatabaseNew)
						return false;
					else // La base de données est de type `offLineFirst` ou `replicateOnStart`. Seules les bases de ces types peuvent être différées.
						return poDatabase.syncType === ESyncType.offlineFirst || poDatabase.syncType === ESyncType.replicateOnStart;
				})
			);
	}

	/** @implements */
	public initDynamicDatabases(): Observable<Array<Database>> {
		let loLoader: Loader;
		console.debug(`${Store.C_LOG_ID}Initializing dynamic databases ...`);

		return defer(() => {
			this.raiseStoreEvent(this.createPreparingInitDatabaseEvent()); // Lève un évènement pour permettre à d'autres composants d'agir sur les configuration avant l'initialisation.
			return from(this.isvcLoading.create("Initialisation des bases de données ...")); // Initialise les base de données de l'appli.
		})
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.initDatabases(ConfigData.databases, loLoader)),
				tap(
					(paDatabases: Database[]) => {
						loLoader.dismiss();
						this.raiseStoreEvent(this.createInitSuccessEvent(EStoreEventType.Init, paDatabases));
					},
					_ => {
						loLoader?.dismiss();
						this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.Init, ConfigData.databases));
					}
				),
				retryWhen((poError$: Observable<any>) => this.initDynamicDatabasesRetryStrategy(poError$)),
				catchError(poError => this.initDynamicDatabasesError(poError)),
				tap(() => this.initDatabasesLastLocalSeq())
			);
	}

	private initDatabasesLastLocalSeq(): void {
		this.moLocalChangesSubject.asObservable()
			.pipe(
				mergeMap((poChangeEvent: IChangeEvent<IStoreDocument>) => poChangeEvent.databaseIds),
				map((psDatabaseId: string) => this.getDatabaseById(psDatabaseId)),
				filter((poDatabase: Database) => !!poDatabase && poDatabase.hasLocalInstance()),
				mergeMap((poDatabase: Database) => poDatabase.getLastSeqFromInstance("local").pipe(tap((pnLastSeq: number) => poDatabase.localLastSeq = pnLastSeq)))
			)
			.subscribe();
	}

	private initDynamicDatabasesError(poError: any): Observable<never> {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Erreur",
				message: typeof poError === "string" ? poError : "Une erreur lors de l'initialisation des bases de données est survenue. Impossible de continuer.",
				backdropDismiss: false,
				buttons: [{ text: "Redémarrer", handler: () => { ApplicationService.reloadApp(); } }]
			})
		);
		console.error(`${Store.C_LOG_ID}Dynamic databases initialization failed : `, poError);
		return throwError(poError);
	}

	/** Stratégie de réessaie en cas d'erreur dans la fonction `initDynamicDatabases`.\
	 * __Stratégie__: Si l'erreur est de type `FirstInitFailedError` (première initialisation) et qu'il n'y a pas de réseau,
	 * alors on affiche à l'utilisateur un bouton pour réessaie, s'il accepte, alors la fonction retourne `true`.
	 * sinon `false`.
	 */
	private initDynamicDatabasesRetryStrategy(poError$: Observable<any>): Observable<boolean> {
		return poError$.pipe(
			mergeMap((poError: any): Observable<boolean> => {
				if (poError instanceof FirstInitFailedError)
					return this.retryStrategyForFirstInitFailedError(poError);
				else if (poError instanceof NoDatabaseInternetConnectionError)
					return this.retryStrategyForNoDatabaseInternetConnectionError(poError);
				else
					return throwError(poError);
			})
		);
	}

	/** Retourne l'erreur `FirstInitFailedError` telle quelle si on a internet (erreur inattendue), affiche une popup d'échec d'initialisation sinon
	 * puis retourne l'action à réaliser (`true` pour réessayer, `false` pour quitter).
	 * @param poError Erreur lors d'une première initialisation.
	 */
	private retryStrategyForFirstInitFailedError(poError: FirstInitFailedError): Observable<boolean> {
		let loManageError$: Observable<IUiResponse<boolean>>;

		if (poError.message === Store.C_NETWORK_NEEDED_FOR_FIRST_LAUNCH)
			loManageError$ = this.showInitFailedBecauseOfNoInternetPopup(poError);
		else {
			loManageError$ = this.isvcNetwork.asyncIsNetworkReliable()
				.pipe(
					mergeMap((pbHasNetwork: boolean): Observable<IUiResponse<boolean>> => {
						if (pbHasNetwork) // Erreur de première initialisation avec internet -> inattendue.
							return throwError(poError);
						else // S'il n'y a pas internet, on l'indique à l'utilisateur.
							return this.showInitFailedBecauseOfNoInternetPopup(poError);
					})
				);
		}

		return loManageError$.pipe(map((poRetry: IUiResponse<boolean>): boolean => poRetry.response));
	}

	/** Retourne `true` si la réplication peut être différée, affiche une popup d'échec d'initialisation sinon puis retourne l'action à réaliser
	 * (`true` pour réessayer, `false` pour quitter).
	 * @param poError Erreur de panne internet d'une base de données.
	 */
	private retryStrategyForNoDatabaseInternetConnectionError(poError: NoDatabaseInternetConnectionError): Observable<boolean> {
		return this.canReplicationBeDeferred(this.getDatabaseById(poError.databaseId))
			.pipe(
				mergeMap((pbCanBeDeferred: boolean) => pbCanBeDeferred ? // Si la réplication peut être différée, on passe à la suite, sinon on montre la popup d'erreur.
					of({ response: true } as IUiResponse<boolean>) : this.showInitFailedBecauseOfNoInternetPopup(poError)
				),
				map((poRetry: IUiResponse<boolean>): boolean => poRetry.response)
			);
	}

	/** Affiche une popup d'erreur de panne internet en cas d'échec d'initialisation d'une base de données et retourne le choix de l'utilisateur (réessayer ou quitter).
	 * @param poErrorData Erreur ou message d'erreur survenu.
	 */
	private showInitFailedBecauseOfNoInternetPopup(poErrorData: Error): Observable<IUiResponse<boolean>> {
		console.warn(`${Store.C_LOG_ID}L'initialisation des données a échoué à cause d'une panne internet.`);

		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				message: poErrorData.message,
				header: "Erreur de connexion",
				backdropDismiss: false,
				buttons: [
					{ text: "Réessayer", handler: () => UiMessageService.getTruthyResponse() },
					{ text: "Quitter", handler: () => { ApplicationService.exitApp(); return UiMessageService.getFalsyResponse(); } }
				]
			})
		);
	}

	public initLocalDatabase(): Observable<Database> {
		console.debug(`${Store.C_LOG_ID}Initializing local database ...`);
		let loLoader: Loader;

		return from(this.isvcLoading.create("Initialisation du stockage local."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => {
					return this.initDatabase({
						id: ConfigData.appInfo.applicationDatabaseId,
						roles: [EDatabaseRole.applicationStorage],
						syncType: ESyncType.none,
						revs_limit: 1
					}, loLoader); // Initialisation de la base "appStorage".
				}),
				tap(
					() => this.isvcFlag.setFlagValue(EStoreFlag.DBLocalInitialized, true),
					(poError: any) => console.error(`${Store.C_LOG_ID}${ConfigData.appInfo.applicationDatabaseId} database initialization failed:`, poError)
				),
				finalize(() => loLoader?.dismiss())
			);
	}

	/** @implements */
	public initStartupDatabases(): Observable<Database[]> {
		console.debug(`${Store.C_LOG_ID}Initializing startup databases ...`);
		const loCredentials: ICredentials = ConfigData.authentication.appCredentials;
		const lsConfigDatabaseId: string = ConfigData.environment.coreRoleAppConfig;
		let loLoader: Loader;

		return from(this.isvcLoading.create("Initialisation de la base de données de configuration."))
			.pipe(
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap(() => // Initialisation de la base "config" (afin de récupérer les autres bdd).
					this.initDatabase({
						id: lsConfigDatabaseId,
						roles: [EDatabaseRole.config],
						syncType: ESyncType.replicateOnStart,
						checkDatabaseMetaInstance: true,
						revs_limit: 1
					}, loLoader, loCredentials.login, loCredentials.password)
				),
				catchError(poError => {
					this.raiseStoreEvent(this.createInitErrorEvent(EStoreEventType.InitStartup, null));
					console.error(`${Store.C_LOG_ID}${lsConfigDatabaseId} database initialization failed:`, poError);
					return throwError(poError);
				}),
				map((poConfigDatabase: Database) => {
					this.raiseStoreEvent(this.createInitSuccessEvent(EStoreEventType.InitStartup, null));
					return [poConfigDatabase];
				}),
				finalize(() => loLoader?.dismiss())
			);
	}

	/** Récupère le document databaseMeta en ligne (réseau internet disponible).
	 * @param poDatabase Base de données qui contient le document databaseMeta à récupérer.
	 */
	private getInitDatabaseMetaOnline(poDatabase: Database): Observable<IDatabaseMeta> {
		// Tous les chemins ne retournent pas d'erreur sauf le dernier mergeMap car l'opérateur finalize() qui ferme les bases de données temporaires
		// intervient trop tard.
		return from(poDatabase.getRemoteInstance()?.get(this.C_DATABASE_META) ?? throwError("La base de données distante n'est pas initialisée."))
			.pipe(
				catchError(poError => this.onGetDatabaseMetaFromServerError(poDatabase.id, poError)),
				mergeMap((poDatabaseMetaResult: IDatabaseMeta) => this.saveDatabaseMetaLocal(poDatabase, poDatabaseMetaResult)),
			);
	}

	/** @implements */
	public getDatabaseMeta(psDatabaseId: string = ConfigData.environment.coreRoleAppConfig): Observable<IDatabaseMeta> {
		return this.getLocal(this.C_DATABASE_META, psDatabaseId);
	}

	/** Gère l'erreur de récupération du databaseMeta depuis le serveur : tente de le récupérer en local s'il existe ou fait transiter une erreur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 * @param poError Erreur survenue lors de la récupération du document databaseMeta.
	 */
	private onGetDatabaseMetaFromServerError(psConfigDatabaseId: string, poError: any): Observable<never> {
		if (poError.status === 404) // Erreur "not_found" ; document non existant, erreur grave.
			return throwError(`Le document de métadonnées de la base '${psConfigDatabaseId}' n'existe pas sur le serveur.\nVeuillez contacter le support technique.`);
		else
			return this.handlePouchDbError(poError);
	}

	private handlePouchDbError(poError: any): Observable<never> {
		if (poError.status === 401) {
			if (poError.subStatusCode === 20)// Erreur l'appareil n'est pas autorisé.
				return throwError(new DeviceNotAuthorizedError());
			else // Token invalide
				return throwError(new TokenError);
		}
		else
			return throwError(poError);
	}

	private handleStoreError(poError: any): Observable<never> {
		this.isvcLoading.dismissAll(); // Fermeture de tous les loaders possibles.;

		if (poError instanceof DeviceNotAuthorizedError)
			return this.handleUnauthorizedDevice().pipe(mergeMapTo(EMPTY));
		else if (poError instanceof TokenError) {
			this.handleTokenError();
			return EMPTY;
		}

		return throwError(poError);
	}

	@ValidationPopup({
		title: "Session expirée.",
		message: "Votre session est expirée. Voulez-vous vous reconnecter?"
	})
	private handleTokenError(): void {
		this.ioRouter.navigate(["disconnect"]);
	}

	/** Enregistre le document databaseMeta en local si besoin (ou fait trnasiter l'erreur si la récupération du document a échoué).
	 * @param poDatabase Base de données.
	 * @param poDatabaseMeta Document databaseMeta récupéré depuis le serveur, peut-être un string si une erreur est survenue.
	 */
	private saveDatabaseMetaLocal(poDatabase: Database, poDatabaseMeta: IDatabaseMeta)
		: Observable<IDatabaseMeta> {
		// On a un document récupéré depuis le serveur, il faut vérifier s'il existe déjà localement ou non.
		return this.getDatabaseMeta(poDatabase.id)
			.pipe(
				mergeMap((poGetResult?: IDatabaseMeta) => {
					if (poGetResult) // Document présent en local, pas besoin de l'enregistrer à nouveau.
						return this.compareDatabaseMeta(poGetResult, poDatabaseMeta, poDatabase.id);

					else // Document non présent localement, il faut l'enregistrer.
						return this.putDatabaseMeta(poDatabaseMeta, poDatabase.id).pipe(tap((poDatabaseMeta: IDatabaseMeta) => poDatabase.meta = poDatabaseMeta));
				})
			);
	}

	/** Compare la databaseMeta du mobile avec celle du serveur afin de vérifier si l'app peut se lancer ou non.
	 * @param poMobileDatabaseMeta Objet databaseMeta provenant du mobile.
	 * @param poServerDatabaseMeta Objet databaseMeta provenant du serveur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 */
	private compareDatabaseMeta(poMobileDatabaseMeta: IDatabaseMeta, poServerDatabaseMeta: IDatabaseMeta, psConfigDatabaseId: string)
		: Observable<IDatabaseMeta> {

		const lsMessageBegin = "Les données présentes sur cet appareil ne sont pas issues";
		const lsMessageEnd = "Veuillez contacter le support technique.";
		let loCompare$: Observable<IDatabaseMeta>;

		if (poMobileDatabaseMeta.originDomainId !== ConfigData.environment.cloud_url) // Domaines différents : l'app ne pointe plus sur le même environnement.
			loCompare$ = throwError(`${lsMessageBegin} du serveur ${ConfigData.environment.cloud_url}. ${lsMessageEnd}`);

		else if (poMobileDatabaseMeta.originInstanceId !== (poServerDatabaseMeta as IDatabaseMeta).instanceId) // Instances différentes : divergence de version.
			loCompare$ = throwError(`${lsMessageBegin} de l'instance de données ${psConfigDatabaseId}/${poMobileDatabaseMeta.originInstanceId}. ${lsMessageEnd}`);

		else
			loCompare$ = of(poMobileDatabaseMeta);

		return loCompare$;
	}

	/** Retourne `true` si la version du databaseMeta locale est identique à celle sur le serveur, `false` sinon.
	 * @param poDatabase Base de données à vérifier le databaseMeta.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue lors de la vérification.
	 */
	public checkDatabaseMetaInstance(poDatabase: Database): Observable<boolean> {
		if (!poDatabase.hasDatabaseMeta)
			return of(true);

		return this.getDatabaseMeta(poDatabase.id)
			.pipe(
				mergeMap((poLocalDoc: IDatabaseMeta) => {
					if (!poLocalDoc) // Si pas de document local, on initialise les databaseMeta avec les données qui sont sur le serveur.
						return this.getInitDatabaseMetaOnline(poDatabase).pipe(mapTo(true));
					return this.innerCheckDatabaseMetaInstance(poDatabase, poLocalDoc);
				}),
				catchError((poError: any) => this.handleStoreError(poError)),
				catchError(poError => {
					if (this.isFailedToFetchError(poError))
						return throwError(new NoDatabaseInternetConnectionError(poDatabase.id, "Une coupure réseau est intervenue."));
					else {
						console.error(`${Store.C_LOG_ID}Erreur vérification databaseMeta bdd "${poDatabase.id}" local-serveur`, poError);
						return of(false);
					}
				})
			);
	}

	/** Retourne `true` si la version du databaseMeta locale est identique à celle sur le serveur, `false` sinon.
	 * @param poDatabase Base de données à vérifier le databaseMeta.
	 */
	private innerCheckDatabaseMetaInstance(poDatabase: Database, poLocalDoc: IDatabaseMeta) {
		const lbHasRemoteInstance: boolean = poDatabase.hasRemoteInstance();

		if (!lbHasRemoteInstance) { // Si on n'a pas de base distante, on la crée temporairement.
			const loAppCredentials: ICredentials | undefined = ConfigData.environment.appCredentials;
			const loConfig: IConfigInitPouchDB = this.getDatabaseConfig(poDatabase, undefined, loAppCredentials?.login, loAppCredentials?.password);
			poDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);
		}

		return from(poDatabase.getRemoteInstance().get(this.C_DATABASE_META))
			.pipe(
				catchError((poError: any) => poError?.reason === "missing" ? of(undefined) : throwError(poError)),
				map((poServerDoc: IDatabaseMeta | undefined) => this.compareDatabaseMetaInstance(poServerDoc, poLocalDoc)),
				catchError(poError => {
					if (poError.status === 404) {
						console.warn(`${Store.C_LOG_ID}DatabaseMeta de la bdd "${poDatabase.id}" non présentes sur le serveur.`, poError);
						return of(false);
					}
					console.error(`${Store.C_LOG_ID}Erreur récupération databaseMeta serveur pour la bdd "${poDatabase.id}"`, poError);
					return this.handlePouchDbError(poError);
				}),
				tap(_ => {
					if (!lbHasRemoteInstance) // Si on n'avait pas de base distante, on la supprime.
						poDatabase.removeRemoteInstance();
				},
					poError => console.error(`${Store.C_LOG_ID}Erreur récupération databaseMeta serveur`, poError)
				)
			);
	}

	private compareDatabaseMetaInstance(poServerDoc: IDatabaseMeta, poLocalDoc: IDatabaseMeta): boolean {
		// Si l'identifiant de l'instance d'origine en local (originInstanceId) est égal à l'identifiant d'instance du serveur alors mêmes versions.
		return poServerDoc?.instanceId === poLocalDoc.originInstanceId;
	}

	/** Enregistre le document databaseMeta reçu du serveur sur la base de données locale.
	 * @param poServerDatabaseMeta Objet databaseMeta provenant du serveur.
	 * @param psConfigDatabaseId Identifiant de la base de données de config.
	 */
	private putDatabaseMeta(poServerDatabaseMeta: IDatabaseMeta, psConfigDatabaseId: string): Observable<IDatabaseMeta> {
		const loMobileDatabaseMeta: IDatabaseMeta = {
			_id: poServerDatabaseMeta._id,
			_rev: undefined,
			databaseId: psConfigDatabaseId,
			instanceId: GuidHelper.newGuid(),
			originDatabaseId: poServerDatabaseMeta.databaseId,
			originInstanceId: poServerDatabaseMeta.instanceId,
			originDomainId: ConfigData.environment.cloud_url
		};

		return this.putLocal(loMobileDatabaseMeta, psConfigDatabaseId).pipe(map(_ => loMobileDatabaseMeta));
	}

	/** @implements */
	public isInitializedDatabase(psDatabaseId: string): boolean {
		return this.moDatabaseById.has(psDatabaseId);
	}

	private raiseStoreEvent(poEvent: IStoreEvent): void {
		this.moEventSubject.next(poEvent);
	}

	/** Créé un événement pour répliquer la base de données.
	 * Pour éviter des problèmes avec le service de réseau qui n'émet pas un événement tout de suite, causant l'événement de réplication, de lever un autre événement de réplication,
	 * l'événement ne sera pas levé instantanément.
	 */
	private raiseDatabaseReplicationEvent(psDatabaseId: string): void {
		of(undefined).pipe(	 // Attente de 0.2 secondes, sinon tourne en boucle en cas de coupure réseau, car l'événement de réplication bloque la création de l'événement du Network.
			delay(200),
			tap(() => {
				this.raiseStoreEvent(this.createInitDatabaseEvent(psDatabaseId, EStoreEventStatus.needReplication));
			})
		)
			.subscribe();
	}

	/** @implements */
	public prepareWorkspaceFiltersDatabases(paDatabasesIds: string[]): Array<string> {
		if (!ArrayHelper.hasElements(paDatabasesIds))
			return [];

		const laDatabasesIds: Array<string> = [];

		if (UserData.workspaceFilters) {
			paDatabasesIds.forEach((psDatabaseId: string) => {
				const lnStartPatternIndex: number = psDatabaseId.indexOf(PatternsHelper.C_START_PATTERN);

				if (lnStartPatternIndex >= 0) {
					const lnEndPatternIndex: number = psDatabaseId.indexOf(PatternsHelper.C_END_PATTERN);
					const lsPattern: string = psDatabaseId.substring(lnStartPatternIndex + 2, lnEndPatternIndex);

					if (lsPattern.indexOf("*user.workspaceFilters") >= 0) {
						UserData.workspaceFilters.forEach(
							(poItem: WorkspaceFilter) => {
								// ajout de l'id avec application du patern :
								// début de l'id jusqu'à "{{" + id du workspace + reste de l'id après "}}" (+2 pour ne pas prendre le contenu des accolades).
								laDatabasesIds.push(`${psDatabaseId.substring(0, lnStartPatternIndex)}${poItem.workspaceId}${psDatabaseId.substring(lnEndPatternIndex + 2)}`);
							}
						);
					}
				}
				else
					laDatabasesIds.push(psDatabaseId);
			});
		}

		return ArrayHelper.hasElements(laDatabasesIds) ? laDatabasesIds : paDatabasesIds;
	}

	/** @implements */
	public put<T extends IStoreDocument>(poDocument: T, psDatabaseId?: string, pbCheckRevisionBeforePut: boolean = false): Observable<IStoreDataResponse> {
		if (ObjectHelper.isNullOrEmpty(poDocument))
			return throwError("Impossible de sauvegarder un document vide.");

		if (typeof poDocument === "string") // Au cas où on aurait un string et pas un objet, anormal (héritage v1).
			poDocument = JSON.parse(poDocument as any as string);

		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);
		const lsDatabaseId: string = loCacheData && loCacheData.databaseId ? loCacheData.databaseId : psDatabaseId;
		let loPut$: Observable<IStoreDataResponse | never>;

		// Si la base de données est indiquée dans la cacheData et qu'elle est différente de la base de données passée en paramètre alors pas normal.
		if (loCacheData && !StringHelper.isBlank(loCacheData.databaseId) && !StringHelper.isBlank(psDatabaseId) && loCacheData.databaseId !== psDatabaseId)
			loPut$ = throwError("L'id de base de données est passé en paramètre alors que la data a déjà une base de données.");

		else if (this.isInitializedDatabase(lsDatabaseId))
			loPut$ = this.continuePut(poDocument, lsDatabaseId, pbCheckRevisionBeforePut);

		else {
			loPut$ = this.askAndWaitDatabaseId(this.getDocumentRole(poDocument))
				.pipe(
					mergeMap((psId: string) => {
						if (this.isInitializedDatabase(psId))
							return this.continuePut(poDocument, psId, pbCheckRevisionBeforePut);
						else
							return throwError(`Erreur lors de l'enregistrement du document '${poDocument._id}', base de données "${psId}" non initialisée.`);
					})
				);
		}

		return loPut$;
	}

	private onPouchDbSaveError(poData: IStoreDocument | IStoreDocument[], poError: PouchDB.Core.Error): Observable<never> {
		if (poError?.name === Store.C_POUCH_CONFLICT_ERROR_NAME) {
			this.openConflictAlert(poData);
			return throwError(new ConflictError(poData));
		}
		return throwError(poError);
	}

	private openConflictAlert(poData: IStoreDocument | IStoreDocument[]): void {
		const laDocuments: IStoreDocument[] = coerceArray(poData);

		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
			header: "Conflit",
			message: `${laDocuments.length > 1 ? "Les données" : "La donnée"} que vous tentez de modifier ${laDocuments.length > 1 ? "ont été modifiées" : "a été modifiée"}
			 par un autre utilisateur de l'application.`
		}));
	}

	/**@implements */
	public getDocumentRole<T extends IStoreDocument>(poDocument: T | string): EDatabaseRole {
		const lsDocumentId = typeof poDocument === "string" ? poDocument : poDocument?._id;
		let loBuilder: EntityBuilder;

		if (ConfigData.entityBuilders)
			loBuilder = ConfigData.entityBuilders.find(
				(poEntityBuilder: EntityBuilder) => poEntityBuilder.match(lsDocumentId)
			);

		if (!loBuilder) {
			const lsErrorMessage = `No entity builder matching document ${lsDocumentId}.`;
			console.error(`${Store.C_LOG_ID}${lsErrorMessage}`, poDocument);
			throw Error(lsErrorMessage);
		}

		return loBuilder.role;
	}

	/** Continue l'enregistrement en base de données.
	 * @param poDocument Document à enregistrer en base de données.
	 * @param psDatabaseId Id de la base de données sur laquelle faire la requête, optionnel.
	 * @param pbCheckRevision Indique si on doit vérifier la révision du document avant de l'enregistrer en base (pour éviter des conflits) ou non, `false` par défaut.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private continuePut<T extends IStoreDocument>(poDocument: T, psDatabaseId: string, pbCheckRevision: boolean): Observable<IStoreDataResponse> {
		return pbCheckRevision ? this.putWithCheckRevision(poDocument, psDatabaseId) : this.putDocumentIntoDatabase(psDatabaseId, poDocument);
	}

	/** Vérifie la révision du document avant de l'enregistrer pour ne pas avoir de conflit.
	 * @param poDocument Document à enregistrer en base de données.
	 * @param psDatabaseId Id de la base de données sur laquelle faire la requête.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private putWithCheckRevision<T extends IStoreDocument>(poDocument: T, psDatabaseId: string): Observable<IStoreDataResponse> {
		const loDataSource: IDataSource<T> = {
			databaseId: psDatabaseId,
			viewParams: { key: poDocument._id }
		};

		return this.getOne(loDataSource, false)
			.pipe(
				tap((poResult: T) => poResult ? poDocument._rev = poResult._rev : undefined),
				mergeMap(_ => this.putDocumentIntoDatabase(psDatabaseId, poDocument))
			);
	}

	/** Enregistre le document dans la base de données spécifiée.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle faire la requête.
	 * @param poDocument Document à enregistrer en base de données.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private putDocumentIntoDatabase<T extends IStoreDocument>(psDatabaseId: string, poDocument: T): Observable<IStoreDataResponse> {
		return defer(() => this.trackIfNeeded(this.getDatabaseById(psDatabaseId), poDocument)).pipe(
			mergeMap(() => this.getDatabaseInstance(psDatabaseId)),
			mergeMap((poDatabase: PouchDB.Database) => this.putDocument(poDocument, poDatabase, psDatabaseId))
		);
	}

	/** Envoi le résultat d'enregistrement sur la base de données aux abonnés en faisant une réplication des données si nécessaire.
	 * @param poResult Résultat de l'enregistrement sur la base de données.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle on requête.
	 */
	private onDocumentUpdated(poResult: PouchDB.Core.Response, psDatabaseId: string): IStoreDataResponse {
		console.debug(`${Store.C_LOG_ID}Document '${poResult.id}' put into / delete from database '${psDatabaseId}'.`);

		if (!IdHelper.hasPrefixId(poResult.id, EPrefix.local) && this.getDatabaseById(psDatabaseId).syncType === ESyncType.offlineFirst)
			this.raiseStoreEvent(this.createOfflineFirstSyncEvent(psDatabaseId));

		(poResult as IStoreDataResponse).databaseId = psDatabaseId;

		return poResult;
	}

	/** Envoi le tableau de résultats des enregistrements sur la base de données aux abonnés en faisant une réplication des données si nécessaire.
	 * @param paResults Tableau de résultats des enregistrements sur la base de données.
	 * @param paDocuments Tableau des documents enregistrés en base de données.
	 * @param peChangeType Type de changement effectué (`delete` | `update`).
	 */
	private onDocumentsUpdated<T extends IStoreDocument>(paResults: IStoreDataResponse[], paDocuments: T[], peChangeType: EChangeType): IStoreDataResponse[] {
		const laDatabaseIds: string[] = [];

		try {
			this.logUpdatedDocuments(paResults, peChangeType, (poResponse: IStoreDataResponse) => laDatabaseIds.push(poResponse.databaseId));
		}
		catch (poError) {
			console.error(`${Store.C_LOG_ID}Updated documents logging failed.`, poError);
		}

		// On sélectionne uniquement les documents qui ont changé avec ce lot de résultats.
		const laUpdatedDocuments: T[] = paDocuments.filter((poItem: T) => paResults.some((poResponse: IStoreDataResponse) => poResponse.id === poItem._id));
		// On supprime les doublons.
		const laUniqueDatabaseIds: string[] = ArrayHelper.unique(laDatabaseIds);
		// Levée des événements de synchronisation des bases de données pour celles qui sont 'offlineFirst'.
		laUniqueDatabaseIds.forEach((psUniqueDatabaseId: string) => {
			if (this.getDatabaseById(psUniqueDatabaseId).syncType === ESyncType.offlineFirst) // Si la base est 'offlineFirst' il faut la synchroniser.
				this.raiseStoreEvent(this.createOfflineFirstSyncEvent(psUniqueDatabaseId)); // On envoie l'événement de synchronisation des données.
		});

		this.raiseChangeEvent(laUpdatedDocuments, laUniqueDatabaseIds, peChangeType);

		return paResults;
	}

	private logUpdatedDocuments(
		paResults: IStoreDataResponse[],
		peChangeType: EChangeType,
		pfForEachItemCallback: (poResponse: IStoreDataResponse) => void
	): void {
		const laDocumentsIds: string[] = [];
		const laDocumentsIdsErrors: string[] = [];

		paResults.forEach((poResponse: IStoreDataResponse) => {
			pfForEachItemCallback(poResponse);

			try {
				if (!poResponse.ok)
					laDocumentsIdsErrors.push(poResponse.id);
				else
					laDocumentsIds.push(poResponse.id);
			}
			catch (poError) {
				console.error(`${Store.C_LOG_ID}Error while preparing data for mulitple documents update logging.`);
			}
		});

		// On affiche les logs en transformant le tableau de logs en une unique chaîne de caractères en séparant chaque élément par un retour à la ligne.
		console.debug(`${Store.C_LOG_ID}${peChangeType}:`, laDocumentsIds);

		if (ArrayHelper.hasElements(laDocumentsIdsErrors))
			console.error(`${Store.C_LOG_ID}Error:`, laDocumentsIdsErrors);
	}

	/** Crée un événement de synchronisation des données du store pour les bases de données offlineFirst.
	 * @param psDatabaseId Identifiant de la base de données sur laquelle synchroniser les données.
	 */
	private createOfflineFirstSyncEvent(psDatabaseId: string): IStoreEvent {
		return {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psDatabaseId,
				storeEventType: EStoreEventType.Synchro,
				status: EStoreEventStatus.required
			}
		};
	}

	/** Synchronise des documents sur la base locale si il y en a une.
	 * @param psDatabaseId
	 * @param paDocuments
	 */
	public async syncDocumentsToLocal(psDatabaseId: string, paDocuments: IStoreDocument[]): Promise<void> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);
		const laUpdatedDocs: IStoreDocument[] = [];
		const laCreatedDocs: IStoreDocument[] = [];

		if (loDatabase.hasLocalInstance() && ArrayHelper.hasElements(paDocuments)) {
			const loLocalDocumentById: Map<string, IStoreDocument> = ArrayHelper.groupByUnique(
				await this.get({
					databaseId: psDatabaseId,
					viewParams: { keys: paDocuments.map((poDocument: IStoreDocument) => poDocument._id) }
				}).toPromise(),
				(poDocument: IStoreDocument) => poDocument._id
			);

			paDocuments.forEach((poDocument: IStoreDocument) => {
				const loLocalDoc: IStoreDocument | undefined = loLocalDocumentById.get(poDocument._id);

				if (loLocalDoc?._rev !== poDocument._rev) {
					const loDocument: IStoreDocument = this.getSerializableDoc(poDocument);
					StoreHelper.deleteDocumentCacheData(loDocument);
					if (loLocalDoc)
						laUpdatedDocs.push(loDocument);
					else
						laCreatedDocs.push(loDocument);
				}
			});

			await loDatabase.getLocalInstance().bulkDocs([...laUpdatedDocs, ...laCreatedDocs], { new_edits: false });
		}
		else {
			paDocuments.forEach((poDoc: IStoreDocument) => {
				if (StoreDocumentHelper.isNew(poDoc))
					laCreatedDocs.push(poDoc);
				else
					laUpdatedDocs.push(poDoc);
			});
		}

		console.debug(`${Store.C_LOG_ID}Documents updated from server.`, laUpdatedDocs, laCreatedDocs);
		this.raiseChangeEvent(laUpdatedDocs, [psDatabaseId], EChangeType.update);
		this.raiseChangeEvent(laCreatedDocs, [psDatabaseId], EChangeType.create);
	}

	/** @implements */
	public putMultipleDocuments<T extends IStoreDocument>(
		paDocuments: T[],
		psDatabaseId: string = "",
		pbCheckRevision?: boolean,
		pbSilent?: boolean
	): Observable<IStoreDataResponse[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return from(paDocuments)
			.pipe( // On groupe tous les documents par identifiant de base de données.
				groupBy((poDocument: T) => !StringHelper.isBlank(psDatabaseId) ? psDatabaseId : StoreHelper.getDocumentCacheData(poDocument).databaseId),
				mergeMap((poGroup$: GroupedObservable<string, T>) => {
					return poGroup$
						.pipe(
							toArray(),
							map((paResults: T[]) => { return { databaseId: poGroup$.key, documents: paResults } as IGroupedDocuments<T>; })
						);
				}),
				mergeMap((poGroupedDocuments: IGroupedDocuments<T>) => this.checkBeforePutMultipleDocuments(poGroupedDocuments, pbCheckRevision)),
				mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
					from(this.trackIfNeeded(this.getDatabaseById(poGroupedDocuments.databaseId), poGroupedDocuments.documents)).pipe(mapTo(poGroupedDocuments))
				),
				mergeMap((poGroupedDocuments: IGroupedDocuments<T>) => this.bulkDocs(poGroupedDocuments.databaseId, poGroupedDocuments.documents, undefined, pbSilent)),
				map((paResponses: IStoreDataResponse[]) => this.onDocumentsUpdated(paResponses, paDocuments, EChangeType.update)),
				reduce((paAccumulatedResponses: IStoreDataResponse[], paCurrentResponses: IStoreDataResponse[]) =>
					paAccumulatedResponses.concat(paCurrentResponses), []
				),
				finalize(() => console.debug(`${Store.C_LOG_ID}PutMultiple ended in ${loPerformanceManager.markEnd().measure()}ms.`))
			);
	}

	/** Vérifie les documents avant de les enregistrer en base de données et les retourne groupés comme ils le sont déjà.
	 * @param poGroupedDocuments Documents à enregistrer regroupés par base de données.
	 * @param pbCheckRevision Indique si on doit vérifier la dernière révision de chaque document, `false` par défaut.
	 */
	private checkBeforePutMultipleDocuments<T extends IStoreDocument>(poGroupedDocuments: IGroupedDocuments<T>, pbCheckRevision?: boolean)
		: Observable<IGroupedDocuments<T>> {

		let loCheckedDocuments$: Observable<IGroupedDocuments<T> | never>;

		// Pour chaque clé de base de données, on enregistre les documents associés en récupérant si besoin la dernière révision de chacun pour éviter des conflits.
		if (!this.isInitializedDatabase(poGroupedDocuments.databaseId))
			loCheckedDocuments$ = throwError(`Erreur lors du put du document, base de données "${poGroupedDocuments.databaseId}" inexistante.`);

		else if (!pbCheckRevision)
			loCheckedDocuments$ = of(poGroupedDocuments);

		else {
			const loDataSource: IDataSource<T> = {
				databaseId: poGroupedDocuments.databaseId,
				viewParams: { include_docs: true, keys: poGroupedDocuments.documents.map((poDocument: T) => poDocument._id) }
			};
			loCheckedDocuments$ = this.get(loDataSource)
				.pipe(
					tap((paResults: T[]) => ArrayHelper.applyLastRevisions(poGroupedDocuments.documents, paResults)),
					mapTo(poGroupedDocuments)
				);
		}

		return loCheckedDocuments$;
	}

	/** Sauvegarde les documents sur la base en utilisant une requête bulk.
	 * @param psDatabaseId Id de la base de données à requêter.
	 * @param paDocumentsToSave Documents à sauvegarder.
	 * @param pbNewEdits Indique si l'on doit forcer la révision (utilisé pour initialisation base depuis le dump). Si `false` on force, sinon on ne fait rien.
	 * @param pbSilent Indique si l'on doit afficher la popup de conflit.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	public bulkDocs<T extends IStoreDocument>(psDatabaseId: string, paDocumentsToSave: T[], pbNewEdits?: boolean, pbSilent?: boolean): Observable<IStoreDataResponse[]> {
		return from(paDocumentsToSave)
			.pipe(
				map((poDocument: T) => {
					const loDocument: T = this.getSerializableDoc(poDocument);
					StoreHelper.deleteDocumentCacheData(loDocument);
					return loDocument;
				}),
				toArray(),
				mergeMap((paDocuments: T[]) => this.getDatabaseInstance(psDatabaseId).pipe(
					mergeMap((poDatabase: PouchDB.Database) => poDatabase.bulkDocs(paDocuments, pbNewEdits === false ? { new_edits: pbNewEdits } : undefined)),
				)),
				tap((paResults: Array<IStoreDataResponse>) => {
					paResults.forEach((poResult: IStoreDataResponse) => poResult.databaseId = psDatabaseId);
					const laResultsById: Map<string, IStoreDataResponse[]> = ArrayHelper.groupBy(paResults, (poResult: IStoreDataResponse) => poResult.id);

					paDocumentsToSave.forEach((poDocument: T) => {
						StoreHelper.updateDocumentCacheData(poDocument, { databaseId: psDatabaseId, dirty: false });
						const loResponse: IStoreDataResponse = ArrayHelper.getFirstElement(laResultsById.get(poDocument._id));
						if (loResponse?.ok)
							poDocument._rev = loResponse.rev;
					});

					// Gestion de l'erreur de sauvegarde (pour un ou plusieurs doc, sans crash).
					const laErrorResponses: IStoreDataResponse[] = paResults.filter((poResponse: IStoreDataResponse) => poResponse.name === Store.C_POUCH_CONFLICT_ERROR_NAME);
					const laDocumentsOnError: T[] = ArrayHelper.intersection(paDocumentsToSave, laErrorResponses,
						(poDocument: T, poResponse: IStoreDataResponse) => poDocument._id === poResponse.id
					);

					if (ArrayHelper.hasElements(laDocumentsOnError) && !pbSilent) {
						console.error(`${Store.C_LOG_ID}Erreurs lors de la mise à jour de masse des documents.`, laDocumentsOnError.map((poDoc: T) => poDoc._id));
						this.openConflictAlert(laDocumentsOnError);
					}
				})
			);
	}

	/** Réplique les données de la base Pouch source sur la base cible.
	 * @param poDatabase Base de données.
	 * @param psReplicationLabel Libellé pour sorties Console.
	 * @param poSourceInstance Adapteur PouchDB sur la base de données source.
	 * @param poTargetInstance Adapteur PouchDB sur la base de données cible.
	 * @param poReplicateOptions Objet représentant les options de réplication.
	 * @param pfOnProgress Callback appelée lors de l'avancement de la réplication.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue pour la réplication.
	 */
	@LogAction<Parameters<Store["replicate"]>, ReturnType<Store["replicate"]>>({
		actionId: ELogActionId.storeReplicate,
		successMessage: (_, __, ___, poSourceInstance: PouchDB.Database, poTargetInstance: PouchDB.Database) =>
			`La réplication de la base '${poSourceInstance.name}' vers '${poTargetInstance.name}' a réussi.`,
		errorMessage: (_, __, ___, poSourceInstance: PouchDB.Database, poTargetInstance: PouchDB.Database) =>
			`La réplication de la base '${poSourceInstance.name}' vers '${poTargetInstance.name}' a échoué.`,
		dataBuilder: (_, poResult: IStoreReplicationResponse, poDatabase: Database, __, poSourceInstance: PouchDB.Database, poTargetInstance: PouchDB.Database) => {
			const loData: IReplicateLogActionData = {
				databaseId: poDatabase.id,
				nbDocReplicated: poResult?.docs?.length,
				source: poSourceInstance.name,
				target: poTargetInstance.name
			};

			if (poResult) {
				if (poResult.docs && loData.nbDocReplicated < Store.C_MAX_REPLICATED_DOCS_NB)
					loData.docs = poResult.docs.map((poDoc: IStoreDocument): IReplicatedDoc => ({ _id: poDoc._id, _rev: poDoc._rev }));
				else if (poResult.docs)
					loData.docs = StoreHelper.getNineDocs(poResult.docs).map((poDoc: IStoreDocument): IReplicatedDoc => ({ _id: poDoc._id, _rev: poDoc._rev }));

				typeof poResult.seqBefore === "number" ?
					loData.localSeqBeforeReplication = poResult.seqBefore : loData.serverSeqBeforeReplication = poResult.seqBefore;
			}

			return loData;
		},
		ignoreResultLogging: (poResult: IStoreReplicationResponse) => !(poResult?.docs?.length > 0)
	})
	private replicate(poDatabase: Database, psReplicationLabel: string, poSourceInstance: PouchDB.Database, poTargetInstance: PouchDB.Database,
		poReplicateOptions?: IStoreReplicationOptions, pfOnProgress?: (poEvent: ISyncronizationEvent) => void): Observable<IStoreReplicationResponse> {

		const loReplicationEndSubject = new Subject<void>();

		if (!poDatabase.canReplicate) // Si on ne peux pas répliquer on arrête tout de suite
			return EMPTY;


		return this.logStartReplicateAction$(poDatabase, psReplicationLabel, poSourceInstance, poTargetInstance, poReplicateOptions)
			.pipe(
				switchMap(() => this.moUserActionsCounterSubject.asObservable()),
				distinctUntilChanged(),
				takeUntil(loReplicationEndSubject.asObservable()),
				// Le switchMap permet de fermer les réplications si une action utilisateur arrive.
				switchMap((pnInProgressActions: number) => {
					if (pnInProgressActions === 0) {
						return this.getSequenceBeforeReplicate(poDatabase, StoreHelper.isRemoteDatabase(poSourceInstance.name), poReplicateOptions)
							.pipe(
								switchMap((poSeqBeforeReplication: string | number) => {
									return this.replicateDatabase(loReplicationEndSubject, poDatabase, psReplicationLabel, poSourceInstance, poTargetInstance, poReplicateOptions, pfOnProgress)
										.pipe(tap((poResponse: IStoreReplicationResponse) => poResponse.seqBefore = poSeqBeforeReplication));
								})
							);
					}
					else
						return EMPTY;
				}),
				takeUntil(poDatabase.canReplicate$.pipe(filter((pbCanReplicate: boolean) => !pbCanReplicate))),
				catchError(poError => {
					console.error(`${Store.C_LOG_ID}Erreur réplication bdd '${poDatabase.id}' en mode '${psReplicationLabel}'.`, poError);
					// Dans le cas d'une erreur liée au réseau internet, on arrête la réplication sans erreur, .
					return poError instanceof NoDatabaseInternetConnectionError ? EMPTY : this.handlePouchDbError(poError);
				}),
				catchError((poError: any) => this.handleStoreError(poError)),
				finalize(() => loReplicationEndSubject.complete())
			);
	}

	/** Log l'action qu'une réplication a débuté.
	 * @param poDatabase Base de données à répliquer.
	 * @param psReplicationLabel Libellé de la réplication.
	 * @param poSourceInstance Instance de la base de données depuis laquelle répliquer.
	 * @param poTargetInstance Instance de la base de données vers laquelle répliquer.
	 * @param poReplicateOptions Opions de réplications.
	 */
	private logStartReplicateAction$(poDatabase: Database, psReplicationLabel: string, poSourceInstance: PouchDB.Database, poTargetInstance: PouchDB.Database,
		poReplicateOptions?: IStoreReplicationOptions): Observable<void> {

		return poDatabase.localLastSeq$.pipe(take(1))
			.pipe(
				map((pnLocalSeq: number) => {
					this.isvcLogger.action(
						Store.C_LOG_ID, `Replication (${psReplicationLabel}) of database '${poSourceInstance.name}' to database '${poTargetInstance.name}' begun`,
						ELogActionId.storeReplicate,
						{ since: poReplicateOptions?.since, lastLocalSeq: pnLocalSeq }
					);
				})
			);
	}

	private getSequenceBeforeReplicate(poDatabase: Database, pbIsFromRemote: boolean, poReplicateOptions?: IStoreReplicationOptions): Observable<string | number> {
		if (poReplicateOptions?.since)
			return of(poReplicateOptions?.since);
		else
			return pbIsFromRemote ? poDatabase.getLastSeqFromInstance("server") : poDatabase.localLastSeq$.pipe(take(1));
	}

	/**
	 * @param poReplicationEndSubject
	 * @param poDatabase
	 * @param psReplicationLabel
	 * @param poSourceInstance
	 * @param poTargetInstance
	 * @param poReplicateOptions
	 * @param pfOnProgress
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si la connexion internet est rompue lors de la réplication ou de la vérification de l'instance du databaseMeta.
	 */
	private replicateDatabase(poReplicationEndSubject: Subject<void>, poDatabase: Database, psReplicationLabel: string, poSourceInstance: PouchDB.Database,
		poTargetInstance: PouchDB.Database, poReplicateOptions?: IStoreReplicationOptions, pfOnProgress?: (poEvent: ISyncronizationEvent) => void)
		: Observable<IStoreReplicationResponse<IStoreDocument>> {

		return this.checkDatabaseMetaInstance(poDatabase)
			.pipe(
				mergeMap((pbAreDbMetaOk: boolean) => {
					if (pbAreDbMetaOk)
						return this.inner_replicate(poSourceInstance, poTargetInstance, poDatabase, psReplicationLabel, poReplicateOptions, pfOnProgress)
							.pipe(
								tap(() => {
									if (!poReplicateOptions?.live)
										poReplicationEndSubject.next();
								}, _ => {
									if (!poReplicateOptions?.live)
										poReplicationEndSubject.next();
								})
							);
					else {
						this.isvcLoading.dismissAll();
						return this.resetDatabaseAndReloadApp(poDatabase);
					}
				})
			);
	}

	private inner_replicate(
		poSourceInstance: PouchDB.Database<{}>,
		poTargetInstance: PouchDB.Database<{}>,
		poDatabase: Database,
		psReplicationLabel: string,
		poReplicateOptions?: IStoreReplicationOptions,
		pfOnProgress?: IOnProgressFunction
	): Observable<IStoreReplicationResponse> {
		const lbIsSyncFromRemote: boolean = StoreHelper.isRemoteDatabase(poSourceInstance.name);
		const lbIsSyncToRemote: boolean = StoreHelper.isRemoteDatabase(poTargetInstance.name);
		let loCancelablePromise: PCancelable<IStoreReplicationResponse>;

		return defer(() => {
			if (poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.classic || lbIsSyncFromRemote) {
				if (poReplicateOptions?.live)
					return this.isvcClassicReplicator.replicateLive$(poDatabase, poSourceInstance, poTargetInstance, poReplicateOptions);
				else {
					return loCancelablePromise = this.isvcClassicReplicator.replicateAsync(
						poDatabase,
						poSourceInstance,
						poTargetInstance,
						poReplicateOptions,
						async (poEvent: ISyncronizationEvent, poResponse: IStoreReplicationResponse) => {
							await this.markSynchro(poDatabase, poSourceInstance.name, poTargetInstance.name, poResponse).toPromise();

							if (pfOnProgress)
								await pfOnProgress(poEvent, poResponse);
						}
					);
				}
			}
			else if (lbIsSyncToRemote && poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking)
				return loCancelablePromise = this.isvcChangeTrackingReplicator.replicateAsync(poDatabase, poSourceInstance, poTargetInstance, poReplicateOptions, pfOnProgress);
			else
				return throwError(new Error(`Impossible de répliquer avec le mode local to server ${poDatabase.localToServerReplicationMode}. From remote: '${lbIsSyncFromRemote}'. To remote: '${lbIsSyncToRemote}'. `));
		}).pipe(
			// On gère l'annulation des promesses
			catchError((poError: any) => poError instanceof CancelError ? EMPTY : throwError(poError)),
			mergeMap((poResponse: IStoreReplicationResponse) => this.markSynchro(poDatabase, poSourceInstance.name, poTargetInstance.name, poResponse).pipe(mapTo(poResponse))),
			mergeMap((poResponse: IStoreReplicationResponse) => lbIsSyncFromRemote ?
				poDatabase.markLocalInstanceAsFirstReplicated().pipe(mapTo(poResponse)) : of(poResponse)
			),
			tap(
				(poResponse: IStoreReplicationResponse) => {
					this.innerReplicate_sendEvent(poResponse, EStoreEventStatus.successed, psReplicationLabel, poDatabase.id);
					if (lbIsSyncFromRemote) // On garde uniquement les changements des réplications depuis le serveur.
						this.onReplicationChange(poResponse.docs, poDatabase.id);
				},
				(poError: any) => {
					tap(() => {
						if (lbIsSyncFromRemote)
							poDatabase.isSynchroFromServerOnError = true;
						else if (lbIsSyncToRemote)
							poDatabase.isSynchroToServerOnError = true;
					}),
						this.innerReplicate_sendEvent(poError, EStoreEventStatus.failed, psReplicationLabel, poDatabase.id);
				}
			),
			finalize(() => loCancelablePromise?.cancel())
		);
	}

	/** Permet de marquer la date d'une synchronisation en base.
	 * @param poDatabase
	 * @param psSourceName
	 * @param psTargetName
	 * @param poReplicationResponse
	 * @returns
	 */
	@Queue<Store, Parameters<Store["markSynchro"]>, ReturnType<Store["markSynchro"]>>({ idBuilder: (_, poDatabase: Database) => poDatabase.id })
	public markSynchro(
		poDatabase: Database,
		psSourceName: string,
		psTargetName: string,
		poReplicationResponse: IStoreReplicationResponse
	): Observable<IStoreDataResponse> {
		const lbIsSyncFromRemote: boolean = StoreHelper.isRemoteDatabase(psSourceName);
		const lbIsSyncToRemote: boolean = StoreHelper.isRemoteDatabase(psTargetName);

		if (lbIsSyncFromRemote || lbIsSyncToRemote) {
			return defer(() => {
				console.debug(`${Store.C_LOG_ID}Saving ${poDatabase.id}'s last sync date...`);
				return this.getSyncMarker(poDatabase.id);
			})
				.pipe(
					mergeMap((poSyncMarker: IDatabaseSyncMarker) => {
						let ldSyncEndDate = new Date(poReplicationResponse.end_time);

						if (!DateHelper.isDate(ldSyncEndDate))
							ldSyncEndDate = new Date;

						if (lbIsSyncFromRemote) {
							poSyncMarker.fromServer = ldSyncEndDate;
							poSyncMarker.remoteSequenceNumber = poReplicationResponse.last_seq as string;
						}
						else if (lbIsSyncToRemote) {
							poSyncMarker.toServer = ldSyncEndDate;
							poSyncMarker.localSequenceNumber = poReplicationResponse.last_seq as number;
						}

						poDatabase.syncMarker = poSyncMarker;

						return this.saveSyncMarker(poSyncMarker);
					}),
					catchError((poError: any) => {
						console.error(`${Store.C_LOG_ID}Error while saving ${poDatabase.id}'s last sync date.`, poError);
						return throwError(poError);
					})
				);
		}
		return throwError(new Error("La synchronisation doit être soit serveur>local soit local>server."));
	}

	private saveSyncMarker(poSyncMarker: IDatabaseSyncMarker): Observable<IStoreDataResponse> {
		return this.putLocal(poSyncMarker, poSyncMarker.databaseId)
			.pipe(
				tap(
					() => console.debug(`${Store.C_LOG_ID}${poSyncMarker.databaseId}'s last sync date saved.`, poSyncMarker),
					poError => console.error(`${Store.C_LOG_ID}${poSyncMarker.databaseId}'s last sync date save failed.`, poError)
				)
			);
	}

	private getSyncMarker(psDatabaseId: string, pbLive?: boolean): Observable<IDatabaseSyncMarker> {
		const lsSyncMarkerId: string = IdHelper.buildId(EPrefix.sync, psDatabaseId);

		return this.getLocal<IDatabaseSyncMarker>(lsSyncMarkerId, psDatabaseId, pbLive)
			.pipe(
				map((poSyncMarker: IDatabaseSyncMarker) => poSyncMarker ?? {
					_id: lsSyncMarkerId,
					databaseId: psDatabaseId
				})
			);
	}

	/** Récupère les marqueurs de synchronisation des bases de données.
	 * @param pbLive
	 * @returns
	 */
	public getSyncMarkers(pbLive?: boolean): Observable<IDatabaseSyncMarker[]> {
		return combineLatest(MapHelper.valuesToArray(this.moDatabaseById)
			.filter((poDatabase: Database) => poDatabase.hasLocalInstance())
			.map((poDatabase: Database) => this.getSyncMarker(poDatabase.id, pbLive))
		).pipe(
			map((paSyncMarkers: IDatabaseSyncMarker[]) => paSyncMarkers.filter((poSyncMarker: IDatabaseSyncMarker) => !!poSyncMarker && !StringHelper.isBlank(poSyncMarker._rev)))
		);
	}

	private innerReplicate_sendEvent(poResponseOrError: IStoreReplicationResponse | any, peStatus: EStoreEventStatus, psReplicationLabel: string,
		psDatabaseId: string): void {

		const loEvent: IStoreEvent = {
			type: EApplicationEventType.StoreEvent,
			createDate: new Date(),
			data: {
				databaseId: psDatabaseId,
				storeEventType: EStoreEventType.Synchro,
				status: peStatus
			}
		};

		if (peStatus === EStoreEventStatus.successed) {
			const loResponse: IStoreReplicationResponse = poResponseOrError;

			if (!ArrayHelper.hasElements(loResponse.docs))
				loResponse.docs = [];

			loEvent.data.result = poResponseOrError;
			console.debug(`${Store.C_LOG_ID}${psReplicationLabel} replication completed for database ${psDatabaseId}.`, loResponse.docs);
		}
		else
			console.error(`${Store.C_LOG_ID}${psReplicationLabel} replication failed for database "${psDatabaseId}":`, poResponseOrError);

		this.raiseStoreEvent(loEvent);
	}

	/** @implements */
	public replicateDocumentIntoDatabases<T extends IStoreDocument>(psDatabaseSourceId: string, poDocument: T, paDatabaseTargetIds: string[])
		: Observable<boolean> {

		const loSource: Database = this.moDatabaseById.get(psDatabaseSourceId);
		let loReplication$: Observable<boolean | never>;

		if (loSource) {
			const loCacheData: ICacheData = StoreHelper.deleteDocumentCacheData(poDocument);

			loReplication$ = from(loSource.defaultDatabase.get(poDocument._id, { revs: true }))
				.pipe(
					catchError(poError => this.onReplicateDocumentIntoDatabasesFail(poDocument, loCacheData, poError)),
					mergeMap((poGetDocumentResult: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta) => from(paDatabaseTargetIds)
						.pipe(mergeMap((psId: string) => this.execReplicateDocumentIntoDatabases(psId, poDocument, poGetDocumentResult, loCacheData))))
				);
		}
		else
			loReplication$ = throwError(`Unknown source database "${psDatabaseSourceId}".`);

		return loReplication$;
	}

	/** Exécute la réplication du document dans la base de données cible.
	 * @param psTargetId Identifiant de la base de données où répliquer le document.
	 * @param poOriginalDocument Document original à répliquer.
	 * @param poDocumentToReplicate Document contenant les révisions (historique) qu'il faut répliquer.
	 * @param poCacheData Objet cacheData du document à répliquer.
	 */
	private execReplicateDocumentIntoDatabases<T extends IStoreDocument>(psTargetId: string, poOriginalDocument: T,
		poDocumentToReplicate: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta, poCacheData: ICacheData): Observable<boolean> {

		const loTarget: Database = this.moDatabaseById.get(psTargetId);
		let loReplication$: Observable<boolean>;

		if (loTarget) {
			loReplication$ = defer(() => {
				console.debug(`${Store.C_LOG_ID}Replicating document into database ${psTargetId}.`);
				return from(loTarget.defaultDatabase.bulkDocs([poDocumentToReplicate], { new_edits: false }));
			})
				.pipe(
					catchError(poError => this.onReplicateDocumentIntoDatabasesFail(poDocumentToReplicate, poCacheData, poError)),
					last(),
					tap(_ => {
						this.onDocumentUpdated({ id: poDocumentToReplicate._id, rev: poDocumentToReplicate._rev, ok: true }, psTargetId);
						StoreHelper.updateDocumentCacheData(poOriginalDocument, poCacheData);
					}),
					mapTo(true)
				);
		}
		else
			loReplication$ = this.onReplicateDocumentIntoDatabasesFail(poDocumentToReplicate, poCacheData, `Unknown target database "${psTargetId}".`);

		return loReplication$;
	}

	/** La réplication du document a échoué, on restaure la cacheData du document avant de lever une erreur.
	 * @param poDocument Document à répliquer.
	 * @param poCacheData Objet cacheData du document à répliquer.
	 * @param poError Erreur survenue lors de la réplication.
	 */
	private onReplicateDocumentIntoDatabasesFail<T extends IStoreDocument>(poDocument: T, poCacheData: ICacheData, poError: any): Observable<never> {
		StoreHelper.updateDocumentCacheData(poDocument, poCacheData);

		return throwError(poError);
	}

	/** @implements */
	public replicateToLocal(psDatabaseId: string,
		poReplicateOptions: IStoreReplicationOptions = {},
		psLogin?: string,
		psPassword?: string,
		pfOnProgress?: (poEvent: ISyncronizationEvent) => void): Observable<IStoreReplicationToLocalResponse> {
		let lbHasToRemoveRemoteInstance = false;
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		if (!loDatabase.hasRemoteInstance()) {
			const loConfig: IConfigInitPouchDB = this.getDatabaseConfig(loDatabase, null, psLogin, psPassword);
			// Création temporaire de la base distante pour l'initialisation.
			loDatabase.createRemoteInstance(loConfig.remotePouchConfig.name, loConfig.remotePouchConfig);
			lbHasToRemoveRemoteInstance = true;
		}

		return loDatabase.isLocalInstanceNew()
			.pipe(
				tap((pbIsNew: boolean) => {
					if (pbIsNew && !poReplicateOptions.selector && !poReplicateOptions.filter)
						poReplicateOptions.view = this.C_DELETED_FILTER_NAME;
				}),
				mergeMap((pbIsNew: boolean) => this.innerReplicateToLocal(pbIsNew, loDatabase, poReplicateOptions, pfOnProgress)),
				catchError(poError => {
					console.error(`${Store.C_LOG_ID}Erreur réplication bdd '${psDatabaseId}' serveur vers local.`, poError);

					// Si on a un réseau suffisant, alors c'est une erreur sinon juste erreur de manque de réseau.
					return this.isvcNetwork.asyncIsNetworkReliable()
						.pipe(mergeMap((pbHasNetwork: boolean) => pbHasNetwork ? throwError(poError) : of({} as IStoreReplicationToLocalResponse)));
				}),
				finalize(() => {
					if (lbHasToRemoveRemoteInstance)
						loDatabase.removeRemoteInstance(); // Fermeture et suppression de la base distante temporaire.
				})
			);
	}

	private innerReplicateToLocal(pbIsNew: boolean, poDatabase: Database, poReplicateOptions: IStoreReplicationOptions, pfOnProgress?: (poEvent: ISyncronizationEvent) => void)
		: Observable<IStoreReplicationToLocalResponse> {

		// On vérifie si il y a déjà eu une synchro depuis le serveur.
		return this.getSyncMarker(poDatabase.id)
			.pipe(
				tap((poSyncMarker: IDatabaseSyncMarker) => {
					if (poDatabase.syncType !== ESyncType.manual && !StringHelper.isBlank(poSyncMarker?.remoteSequenceNumber) && StringHelper.isBlank(poReplicateOptions.since))
						poReplicateOptions.since = poSyncMarker.remoteSequenceNumber;
				}),
				mergeMap(() => this.replicate(poDatabase, "serverToLocal", poDatabase.getRemoteInstance(), poDatabase.getLocalInstance(), poReplicateOptions, pfOnProgress)),
				mergeMap((poReplicationResponse: IStoreReplicationToLocalResponse) => {
					let loReplicateResponse$: Observable<IStoreReplicationToLocalResponse>;

					if (!pbIsNew)
						loReplicateResponse$ = of(poReplicationResponse);

					else {
						loReplicateResponse$ = poDatabase.getLastSeqFromInstance("local")
							.pipe(
								// Sert juste à imposer le numéro de séquence, ne réplique rien.
								mergeMap((pnLastSeq: number) => this.replicateToServer(poDatabase.id, { since: pnLastSeq })),
								mergeMap(_ => poDatabase.getLastSeqFromInstance("server")),
								// Sert juste à imposer le numéro de séquence, ne réplique rien.
								mergeMap((psLastSeq: string) =>
									this.replicate(poDatabase, "fixLocalSeq", poDatabase.getRemoteInstance(), poDatabase.getLocalInstance(), { since: psLastSeq })
								),
								mapTo(poReplicationResponse)
							);
					}

					return loReplicateResponse$;
				})
			);
	}

	/** @implements */
	public replicateToServer(psDatabaseId: string, poReplicateOptions?: IStoreReplicationOptions, pfOnProgress?: (poEvent: ISyncronizationEvent) => void)
		: Observable<IStoreReplicationToServerResponse> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		return this.replicate(loDatabase, "localToServer", loDatabase.getLocalInstance(), loDatabase.getRemoteInstance(), poReplicateOptions, pfOnProgress)
			.pipe(map((poReplicationResponse: IStoreReplicationToServerResponse) => poReplicationResponse));
	}

	/** Permet de sauvegarder les données.
	 * @param poDocument Données à sauvegarder.
	 * @param poSelectedDatabase Base de données sélectionnée.
	 * @param psDatabaseId Identifiant de la base à requêter.
	 */
	private putDocument<T extends IStoreDocument>(poDocument: T, poSelectedDatabase: PouchDB.Database, psDatabaseId: string): Observable<IStoreDataResponse> {
		const loDocumentCopy: T = this.getSerializableDoc(poDocument);
		StoreHelper.deleteDocumentCacheData(loDocumentCopy);
		loDocumentCopy._conflicts = undefined;
		const loDocumentCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);

		let loSave$: Observable<IStoreDataResponse>;

		if (loDocumentCacheData && !StringHelper.isBlank(loDocumentCacheData.databaseId)) // Si document déjà en base.
			loSave$ = this.innerPutDocument_put(poDocument, loDocumentCopy, poSelectedDatabase, psDatabaseId, loDocumentCacheData);
		else
			loSave$ = this.innerPutDocument_post(poDocument, loDocumentCopy, poSelectedDatabase, psDatabaseId);

		return loSave$.pipe(catchError((poError: any) => {
			console.error(`${Store.C_LOG_ID}Erreur lors de la sauvegarde du document dans la bdd ${psDatabaseId}.`, poError, poDocument);
			return this.onPouchDbSaveError(poDocument, poError);
		}));
	}

	private getSerializableDoc<T extends IStoreDocument>(poDoc: T): T {
		return classToPlain(poDoc, { excludePrefixes: ["#"] }) as T;
	}

	private innerPutDocument_put<T extends IStoreDocument>(poOriginalDocument: T, poDocumentCopy: T, poSelectedDatabase: PouchDB.Database,
		psDatabaseId: string, poDocumentCacheData: ICacheData): Observable<IStoreDataResponse> {

		return from(poSelectedDatabase.put(poDocumentCopy))
			.pipe(
				tap(
					_ => this.raiseChangeEvent(poOriginalDocument, [psDatabaseId], EChangeType.update),
					poError => console.error(`${Store.C_LOG_ID}Put document in database "${psDatabaseId}" failed `, poOriginalDocument, ` Error : `, poError)
				),
				map((poResult: PouchDB.Core.Response) => {
					poOriginalDocument._rev = poResult.rev;
					StoreHelper.updateDocumentCacheData(poOriginalDocument, { ...poDocumentCacheData, dirty: false });
					return this.onDocumentUpdated(poResult, psDatabaseId);
				})
			);
	}

	private innerPutDocument_post<T extends IStoreDocument>(poOriginalDocument: T, poDocumentCopy: T, poSelectedDatabase: PouchDB.Database,
		psDatabaseId: string): Observable<IStoreDataResponse> {

		return from(poSelectedDatabase.post(poDocumentCopy))
			.pipe(
				tap(
					_ => this.raiseChangeEvent(poOriginalDocument, [psDatabaseId], EChangeType.create),
					poError => console.error(`${Store.C_LOG_ID}Post document in database "${psDatabaseId}" failed '`, poOriginalDocument, "' : ", poError)
				),
				map((poResult: PouchDB.Core.Response) => {
					poOriginalDocument._id = poResult.id;
					poOriginalDocument._rev = poResult.rev;
					// On crée le cacheData indiquant la base de données source du document.
					StoreHelper.updateDocumentCacheData(poOriginalDocument, { databaseId: psDatabaseId, dirty: false });
					return this.onDocumentUpdated(poResult, psDatabaseId);
				})
			);
	}

	private raiseChangeEvent<T extends IStoreDocument>(poDocument: T, paDatabaseIds: string[], peChangeType: EChangeType): void
	private raiseChangeEvent<T extends IStoreDocument>(paDocuments: T[], paDatabaseIds: string[], peChangeType: EChangeType): void
	private raiseChangeEvent<T extends IStoreDocument>(poData: T | T[], paDatabaseIds: string[], peChangeType: EChangeType): void {
		if (poData instanceof Array)
			poData.forEach((poDocument: T) => this.raiseChangeEvent(poDocument, paDatabaseIds, peChangeType));
		else
			this.moLocalChangesSubject.next({ document: poData, databaseIds: paDatabaseIds, changeType: peChangeType });
	}

	/** Permet de sélectionner une base en fonction de son type de synchro.
	 * @param psDatabaseId Identifiant de la base de données.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro 'remote' ou 'onlineFirst'.
	 */
	private getDatabaseInstance(psDatabaseId: string): Observable<PouchDB.Database> {
		const loDatabase: Database = this.getDatabaseById(psDatabaseId);

		// Si mode de synchro différent de 'remote' et 'onlineFirst'.
		if (loDatabase.syncType !== ESyncType.remote && loDatabase.syncType !== ESyncType.onlineFirst)
			return of(loDatabase.getLocalInstance());
		else {
			return this.isvcNetwork.asyncIsNetworkReliable()
				.pipe(
					map((pbHasNetwork: boolean) => {
						if (pbHasNetwork)
							return loDatabase.getRemoteInstance();
						else
							throw new NoDatabaseInternetConnectionError(psDatabaseId, `Erreur d'envoi des données sur la base ${psDatabaseId} : pas internet, et le mode de synchro l'exige.`);
					})
				);
		}
	}

	private onReplicationChange(paDocs: IStoreDocument[], psDatabaseId: string): void {
		ArrayHelper.groupBy(
			paDocs,
			(poDoc: IStoreDocument) => {
				if (poDoc._deleted)
					return EChangeType.delete;
				else if (StoreDocumentHelper.isNew(poDoc))
					return EChangeType.create;
				else
					return EChangeType.update;
			}
		)
			.forEach((paGroupedDocs: IStoreDocument[], peChangeType: EChangeType) =>
				paGroupedDocs.forEach((poDoc: IStoreDocument) =>
					this.moReplicationChangesSubject.next({ changeType: peChangeType, document: poDoc, databaseIds: [psDatabaseId] })
				)
			);
	}

	/** @implements */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moEventSubject.asObservable()
			.subscribe(
				(poResult: IStoreEvent) => pfNext(poResult),
				poError => pfError(poError),
				() => pfComplete()
			);
	}

	/** Regroupe les actions à exécuter lorsque le résultat est récupéré.
	 * @param paResults Liste des résultats de la requête.
	 * @param poDataSource Paramètres de la requête.
	 * @param psDatabaseId Identifiant de la base de données requêtée.
	 */
	private onGetResult<T extends IStoreDocument>(paResults: T[], poDataSource: IDataSource<T>, psDatabaseId: string): T[] {
		const lbHasToTruncate: boolean = ArrayHelper.hasElements(poDataSource.fields);

		if (poDataSource.viewParams.keys) {
			// NB : Dans le cas d'une vue on élimine en même temps les documents vides et les documents à filtrer pour ne parcourir qu'une seule fois les résultats.
			paResults = paResults.filter((poDocument: T) =>
				// Le document n'est pas vide ET (aucun filtrage n'est demandé OU ALORS le document n'est pas filtré).
				!!poDocument && (!poDataSource.filter || poDataSource.filter(poDocument))
			);
		}
		else if (poDataSource.filter) // Applique un filtre aux résultats.
			paResults = paResults.filter(poDataSource.filter);

		// Ajoute l'information sur la provenance de l'entité.
		paResults.forEach((poDocument: T) => {
			if (lbHasToTruncate)
				this.truncateResult(poDocument, poDataSource);

			StoreHelper.updateDocumentCacheData(poDocument, { databaseId: psDatabaseId });
		});

		return paResults;
	}

	/** Tronque une partie du document pour ne garder que ce qui nous interresse.
	 * @param poDocument Document à tronquer.
	 * @param poDataSource Paramètres de la requête.
	 */
	private truncateResult<T extends IStoreDocument>(poDocument: T, poDataSource: IDataSource<T>): void {
		for (const lsKey in poDocument) {
			// '!= undefined' permet d'accepter les valeurs booléennes négatives et la valeur 0, et n'accepte pas les valeurs 'null'.
			if (poDocument[lsKey] !== undefined && poDocument[lsKey] !== null && !poDataSource.fields.includes(lsKey))
				delete poDocument[lsKey];
		}
	}

	/** Retourne le chemin d'un document.
	 * @param poDocument Document dont il faut récupérer le chemin.
	 * @param psDefaultDatabaseId Identifiant de la base de données (optionnel).
	 */
	public static getDocumentPath<T extends IStoreDocument>(poDocument: T, psDefaultDatabaseId: string = null): string {
		return StoreHelper.getDocumentPathFromIdDatabaseId(poDocument._id, StoreHelper.getDatabaseIdFromCacheData(poDocument, psDefaultDatabaseId));
	}

	/** Retourne l'identifiant de base de données d'un chemin de document.
	 * @param psDocumentPath Chemin du document.
	 */
	public static getDatabaseIdFromDocumentPath(psDocumentPath: string): string {
		return StringHelper.isBlank(psDocumentPath) || !psDocumentPath.includes("/") ? "" : ArrayHelper.getFirstElement(psDocumentPath.split('/'));
	}

	/** Retourne l'identifiant du document depuis son chemin.
	 * @param psDocumentPath Chemin du document.
	 */
	public static getDocumentIdFromPath(psDocumentPath: string): string {
		return StringHelper.isBlank(psDocumentPath) ? "" : ArrayHelper.getLastElement(psDocumentPath.split('/'));
	}

	/** @implements */
	public deleteMultipleDocuments(paDocumentsIds: string[], psDatabaseId: string): Observable<boolean>;
	/** @implements */
	public deleteMultipleDocuments<T extends IStoreDocument>(paDocuments: T[], psDatabaseId?: string): Observable<boolean>;
	public deleteMultipleDocuments<T extends IStoreDocument>(paDocuments: T[] | string[], psDatabaseId: string = undefined): Observable<boolean> {
		let loDelete$: Observable<boolean>;

		if (!ArrayHelper.hasElements(paDocuments as string[]))
			loDelete$ = of(true);
		else
			loDelete$ = this.innerDeleteMultipleDocuments(paDocuments, psDatabaseId);

		return loDelete$;
	}

	/**
	 * @param paDocuments Tableau des documents à supprimer.
	 * @param psDatabaseId Identifiant de la base de données où supprimer les documents.
	 * @throws
	 * - `NoDatabaseInternetConnectionError` si pas internet et mode de synchro `remote` ou `onlineFirst`.
	 */
	private innerDeleteMultipleDocuments<T extends IStoreDocument>(paDocuments: T[] | string[], psDatabaseId: string): Observable<boolean> {
		let laDocuments$: Observable<T[]>;
		let laDocuments: T[];

		if (typeof ArrayHelper.getFirstElement(paDocuments as string[]) === "string")
			laDocuments$ = this.getBeforeDeleteMultipleStringDocuments(paDocuments as string[], psDatabaseId);
		else
			laDocuments$ = of(paDocuments as T[]);

		return laDocuments$
			.pipe(
				mergeMap((paResultDocuments: T[]) => laDocuments = paResultDocuments),
				tap((poDocument: T) => poDocument._deleted = true),
				groupBy((poDocument: T) => StringHelper.isBlank(psDatabaseId) ? StoreHelper.getDatabaseIdFromCacheData(poDocument) : psDatabaseId),
				mergeMap((poGroupedObservable: GroupedObservable<string, T>) => {
					return poGroupedObservable
						.pipe(
							toArray(),
							map((paResultDocuments: T[]) => { return { databaseId: poGroupedObservable.key, documents: paResultDocuments } as IGroupedDocuments<T>; })
						);
				}),
				mergeMap((poGroupedDocuments: IGroupedDocuments<T>) =>
					from(this.trackIfNeeded(this.getDatabaseById(poGroupedDocuments.databaseId), poGroupedDocuments.documents)).pipe(mapTo(poGroupedDocuments))
				),
				mergeMap((poGroup: IGroupedDocuments<T>) => this.bulkDocs(poGroup.databaseId, poGroup.documents)),
				map((paResponses: IStoreDataResponse[]) => this.onDocumentsUpdated(paResponses, laDocuments, EChangeType.delete)),
				reduce((paAccumulatedResponses: IStoreDataResponse[], paCurrentResponses: IStoreDataResponse[]) =>
					paAccumulatedResponses.concat(paCurrentResponses), []
				),
				map((paResults: Array<IStoreDataResponse>) => paResults.every((poResult: IStoreDataResponse) => poResult.ok))
			);
	}

	private getBeforeDeleteMultipleStringDocuments<T extends IStoreDocument>(paDocuments: string[], psDatabaseId: string): Observable<T[]> {
		if (StringHelper.isBlank(psDatabaseId))
			return throwError("Documents à supprimer par identifiants mais la base de données n'est pas renseignée.");
		else
			return this.get({ databaseId: psDatabaseId, viewParams: { keys: paDocuments as string[] } });
	}

	/** @implements */
	public getLocal<T extends IStoreDocument>(psDocId: string, psDatabaseId: string = this.getLocalDbId(), pbLive?: boolean): Observable<T> {
		const loDatabase: Database = this.moDatabaseById.get(psDatabaseId);
		let loResult$: Observable<T | never>;

		if (loDatabase) {
			// On ne lève pas d'erreur car l'erreur vient du fait que nous n'avons pas trouvé de résultat.

			loResult$ = defer(() => {
				return merge(
					pbLive ?
						this.moLocalChangesSubject.pipe(
							filter((poChangeEvent: IChangeEvent<T>) => poChangeEvent.document?._id === psDocId && poChangeEvent.databaseIds.includes(psDatabaseId)),
							map((poChangeEvent: IChangeEvent<T>) => poChangeEvent.changeType === EChangeType.delete ? undefined : poChangeEvent.document)
						) :
						EMPTY,
					from(loDatabase.defaultDatabase.get(IdHelper.buildId(EPrefix.local, psDocId)).catch(_ => undefined))
				);
			});
		}
		else {
			const lsErrorMessage = `Erreur lors de la récupération du document local : la base de donnée ${psDatabaseId} n'existe pas.`;
			console.error(`${Store.C_LOG_ID}${lsErrorMessage}`);
			loResult$ = throwError(lsErrorMessage);
		}

		return loResult$;
	}

	/** Récupère l'identifiant de la base locale (non répliquée) de l'application. */
	private getLocalDbId(): string {
		return ArrayHelper.getFirstElement(this.getDatabasesIdsByRole(EDatabaseRole.applicationStorage))
	}

	/** @implements */
	public putLocal<T extends IStoreDocument>(poDocument: T, psDatabaseId: string = this.getLocalDbId()): Observable<IStoreDataResponse> {
		poDocument._id = this.addLocalPrefix(poDocument._id);

		return this.put(poDocument, psDatabaseId)
			.pipe(catchError(poError => throwError(`Erreur lors de la sauvegarde du document local : ${poError}`)));
	}

	/** @implements */
	public deleteLocal(poDocument: IStoreDocument, psDatabaseId: string = this.getLocalDbId()): Observable<IStoreDataResponse> {
		poDocument._id = this.addLocalPrefix(poDocument._id);

		return this.delete(poDocument, psDatabaseId)
			.pipe(catchError(poError => throwError(`Erreur lors de la suppression du document local : ${poError}`)));
	}

	/** Ajoute le prefix _local/ à une chaine de caractère et la retourne
	 * @param psId Identifiant sur lequel nous devons ajouter le prefix `_local/`.
	 */
	private addLocalPrefix(psId: string): string {
		return !StringHelper.isBlank(psId) && !psId.startsWith(EPrefix.local) ?
			IdHelper.buildId(EPrefix.local, psId) : psId;
	}

	/** @implements */
	public getDatabaseIdsByFragmentId(psFragmentId: string): Array<string> {
		const laDatabases: Database[] = MapHelper.valuesToArray(this.moDatabaseById)
			.filter((poDatabase: Database) => poDatabase.id.indexOf(psFragmentId) >= 0);

		if (laDatabases.length < 1)
			throw new Error(`Impossible de trouver les bases de données comportant "${psFragmentId}" dans leur identifiant.`);
		else
			return laDatabases.map((poDatabase: Database) => poDatabase.id);
	}

	/** @implements */
	public getDatabaseIdByFragmentIdAndRole(psFragmentId: string, peRole: EDatabaseRole): string {
		const loDatabase: Database = MapHelper.valuesToArray(this.moDatabaseById)
			.find((poDatabase: Database) => poDatabase.id.indexOf(psFragmentId) >= 0 && poDatabase.hasRole(peRole));

		if (loDatabase)
			return loDatabase.id;
		else
			throw new Error(`Impossible de trouver la base de données comportant "${psFragmentId}" dans son identifiant et ayant le rôle "${peRole}".`);
	}

	/** Active le debug pouchDB. */
	public static enablePouchDBDebug(): void {
		PouchDB.debug.enable('*');
	}

	/** Désactive le debug pouchDB. */
	public static disablePouchDBDebug(): void {
		PouchDB.debug.disable();
	}

	/** Récupère le nom d'un document de vue compilé à partir d'un nom de vue en remplaçant si nécessaire les '/' par des '-' pour éviter des problèmes sur pouchDB.
	 * @param psViewName Nom de la vue qu'on veut changer en nom de document.
	 */
	private getCompiledViewDocumentNameFromViewName(psViewName: string): string {
		return psViewName.replace(/\//g, '-');
	}

	/** @implements */
	public isViewCompiled(psViewName: string): Observable<boolean> {
		let loIsCompiled$: Observable<boolean>;

		if (StringHelper.isBlank(psViewName) || psViewName === Store.C_DEFAULT_VIEW_DOCS)
			loIsCompiled$ = of(true);

		else {
			const loDataSource: IDataSource = {
				databaseId: ConfigData.appInfo.applicationDatabaseId,
				viewParams: {
					key: this.getCompiledViewDocumentNameFromViewName(psViewName)
				}
			};

			loIsCompiled$ = this.getOne<IStoreDocument>(loDataSource, false)
				.pipe(map((poResult: IStoreDocument) => !!poResult));
		}

		return loIsCompiled$;
	}

	/** Crée un document afin de signaler qu'une vue a été compilée.
	 * @param psViewName Nom de la vue dont il faut créer le document associé.
	 */
	@Queue<Store, Parameters<Store["createCompiledViewDoc"]>, ReturnType<Store["createCompiledViewDoc"]>>({
		idBuilder: (_, psViewName: string) => psViewName
	})
	private createCompiledViewDoc(psViewName: string): Observable<boolean> {
		return this.isViewCompiled(psViewName)
			.pipe(
				mergeMap((pbIscompiled: boolean) => {
					if (pbIscompiled)
						return of(true);

					else {
						const loCompiledViewDoc: IStoreDocument & { isCompiled: boolean } = {
							_id: this.getCompiledViewDocumentNameFromViewName(psViewName),
							isCompiled: true
						};
						return this.put(loCompiledViewDoc, ConfigData.appInfo.applicationDatabaseId).pipe(map((poResponse: IStoreDataResponse) => poResponse.ok));
					}
				})
			);
	}

	/** Réinitialise une base de données d'espace de travail en supprimant les données actuelles puis recharge l'app.
	 * @param psDatabaseId Identifiant de la base de données qui a été réinitialisée.
	 */
	private resetDatabaseAndReloadApp(psDatabaseId: string): Observable<undefined>;
	/** Réinitialise une base de données d'espace de travail en supprimant les données actuelles puis recharge l'app.
	 * @param poDatabase Instance de la base de données qui a été réinitialisée.
	 */
	private resetDatabaseAndReloadApp(poDatabase: Database): Observable<undefined>
	private resetDatabaseAndReloadApp(poDatabaseData: string | Database): Observable<undefined> {
		let loDatabase: Database | undefined;

		try { loDatabase = poDatabaseData instanceof Database ? poDatabaseData : this.getDatabaseById(poDatabaseData); }
		catch (poError) { } // Même en cas d'erreur de récupération de la base, il faut continuer le processus.

		return this.showResetWorkspacePopup()
			.pipe(
				mergeMap((poUiResponse: IUiResponse<boolean>) => {
					if (loDatabase)
						loDatabase.canReplicate = false;

					if (poUiResponse.response && loDatabase) {
						return loDatabase.destroyLocalInstance().pipe(
							tap(_ => ApplicationService.reloadApp()),
							mapTo(undefined)
						);
					}
					else
						return of(undefined);
				})
			);
	}

	private showResetWorkspacePopup(): Observable<IUiResponse<boolean>> {
		const laButtons: AlertButton[] = [];
		let lsMessage = "L'espace de travail a été réinitialisé";

		// Affichage du bouton de reset des workspaces si on est pas en beta ni en prod.
		if (ConfigData.environment.id !== EEnvironmentId.beta && ConfigData.environment.id !== EEnvironmentId.prod) {
			laButtons.push({ text: "Supprimer", handler: () => UiMessageService.getTruthyResponse(), role: UiMessageService.C_CANCEL_ROLE });
			lsMessage += ", vous devez supprimer vos données pour pouvoir synchroniser votre espace de travail.\n Toutes les données présentes sur votre appareil seront perdues.";
		}
		else
			lsMessage += ".";

		laButtons.push({ text: "Continuer", handler: () => UiMessageService.getFalsyResponse() });

		return this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
			header: "L'espace de travail a été réinitialisé",
			backdropDismiss: false,
			message: lsMessage,
			buttons: laButtons
		}))
			.pipe(catchError(_ => of({ response: false } as IUiResponse<boolean>)));
	}

	/** Met à jour le compteur d'actions utilisateur et émet la modification.
	 * @param pnNumber Nombre d'action à ajouter.
	 */
	private appendUserActionsCounter(pnNumber: number): void {
		this.moUserActionsCounterSubject.next(this.moUserActionsCounterSubject.value + pnNumber);
	}

	private async trackIfNeeded(poDatabase: Database, poData: IStoreDocument | IStoreDocument[]): Promise<void> {
		if (poDatabase.hasLocalInstance() && poDatabase.hasRemoteInstance() && poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking) {
			if (poData instanceof Array)
				await this.isvcChangeTracker.trackMultiple(poDatabase.id, poData.map((poDoc: IStoreDocument) => ({ id: poDoc._id, rev: poDoc._rev })));
			else
				await this.isvcChangeTracker.track(poDatabase.id, { id: poData._id, rev: poData._rev });
		}
	}

	// Permet d'appeler une vue CouchDb qui va retourner un count unique
	public getSingleDocumentCountFromView<T extends IStoreDocument>(poDataSource: IDataSourceViewCount<T>, psDatabaseId: string): Observable<number> {
		return from((this.moDatabaseById.get(psDatabaseId).defaultDatabase as PouchDB.Database).query(poDataSource.viewName, poDataSource.viewParams))
			.pipe(
				catchError(poError => {
					console.error(`${Store.C_LOG_ID}Error request getDocumentCountByView on ${psDatabaseId}:`, poError);
					return throwError(poError);
				}),
				map((poResult: PouchDB.Query.Response<any>) => {					
					if (poResult.rows && poResult.rows.length > 0) {
						return poResult.rows[0].value; // Renvoie le compte
					}
					return 0; // Renvoie 0 si aucun résultat n'est trouvé					
				})
			);
	}

	// Permet d'appeler une vue CouchDb qui va retourner un count en utilisant 'group'
	public getGroupedDocumentCountFromView<T extends IStoreDocument>(poDataSource: IDataSourceViewCount<T>, psDatabaseId: string): Observable<IViewCountResult[]> {
		return from((this.moDatabaseById.get(psDatabaseId).defaultDatabase as PouchDB.Database).query(poDataSource.viewName, poDataSource.viewParams))
			.pipe(
				catchError(poError => {
					console.error(`${Store.C_LOG_ID}Error request getDocumentCountByView on ${psDatabaseId}:`, poError);
					return throwError(poError);
				}),
				map((poResult: PouchDB.Query.Response<any>) => {
					return poResult.rows;				
				})
			);
	}

	//#endregion
}
