import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dyn-host]',
  exportAs: 'dynhost'
})
export class DynHostDirective {

  //#region METHODS

  constructor(public viewContainerRef: ViewContainerRef) { }

  //#endregion
}