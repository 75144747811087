/** Orientation dans les données exif, comme suit :
 * https://www.daveperrett.com/images/articles/2012-07-28-exif-orientation-handling-is-a-ghetto/EXIF_Orientations.jpg
 */
export enum EImageOrientation {
	Normal = 1,
	Right = 8,
	Down = 3,
	Left = 6,
	Reverse = 2,
	RightAndReverse = 7,
	LeftAndReverse = 5,
	DownAndReverse = 4,
	/** Cas où l'image orientation n'est pas défini. */
	Unknown = 100
}