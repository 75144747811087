<div class="panneau-choix-contact__container">
	<div class="container__content">
		<ng-container *ngIf="!showListContact">
			<di-form-contacts (onCancelContact)="handleCancelContact($event)" (onSaveContact)="udpdatePatient($event)"
				[patient]="patient" [contact]="contactPatient" context="patient_contact"></di-form-contacts>
		</ng-container>
		<ng-container *ngIf="showListContact">
			<di-liste-contacts [contactsList]="contacts" (contactSelection)="udpdateContact($event)" [displayHeader]="false"
				(clickAddContact)="handleClickAddContact()"></di-liste-contacts>
			<lua-bouton-floating class="btn-ajout-contact-mobile" bottom="40px" right="40px" iconName="add"
				(click)="handleClickAddContact()">
			</lua-bouton-floating>
		</ng-container>
	</div>
</div>