<osapp-header-modal title="État de la synchronisation"></osapp-header-modal>

<ion-content class="ion-padding">
	<calao-databases-sync-status-list [databasesSyncStatus]="databasesSyncStatus"></calao-databases-sync-status-list>

	<ng-container>
		<ion-button [disabled]="isBusy$ | async" (click)="syncDatabases()" expand="block">{{ syncText$ | async }}
		</ion-button>
		<ion-button [disabled]="isBusy$ | async" (click)="resetWorkspaceDatabases()" expand="block">Optimiser</ion-button>
	</ng-container>

</ion-content>