/** Permet de mettre à disposition des méthodes pour aider à manipuler un mail. */
export abstract class MailHelper {

	//#region METHODS

	/** Retourne `true` si la valeur est un mail valide, `false` sinon.
	 * @param psValue Valeur à vérifier si elle est un mail valide.
	 */
	public static isValid(psValue: string): boolean {
		return /^.*[@].*\.[a-z]{2,4}/.test(psValue);
	}

	//#endregion
}