import { merge, Observable, Subject, Subscription } from 'rxjs';

/** Execute du code juste après le subscribe. */
export function afterSubscribe<T>(pfCallback: () => void): (poSource: Observable<T>) => Observable<T> {
	return (poSource: Observable<T>) => {
		const loErrorSubject = new Subject<never>();
		// On surcharge le subscribe pour pouvoir lancer la callback juste après le subscribe.
		const lfOldSubscribe = poSource.subscribe;
		poSource.subscribe = (...paArgs: any[]) => {
			const loResult: Subscription = lfOldSubscribe.apply(poSource, paArgs);

			try {
				pfCallback();
			}
			catch (poError) {
				loErrorSubject.error(poError);
			}
			finally {
				loErrorSubject.complete();
			}

			return loResult;
		}

		return merge(loErrorSubject.asObservable(), poSource);
	}
}