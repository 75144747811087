import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';

@Injectable({
	providedIn: 'root'
})
export class EventsService {

	//#region FIELDS

	private readonly moEventsSubject = new Subject<IApplicationEvent>();

	//#endregion

	//#region PROPERTIES

	public get events$(): Observable<IApplicationEvent> {
		return this.moEventsSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	public getEventsByType(peType: EApplicationEventType): Observable<IApplicationEvent> {
		return this.events$.pipe(filter((poEvent: IApplicationEvent) => poEvent?.type === peType));
	}

	public raiseEvent(poEvent: IApplicationEvent): void;
	public raiseEvent(peEventType: EApplicationEventType): void;
	public raiseEvent(poData: IApplicationEvent | EApplicationEventType): void {
		if (typeof poData === "number")
			this.moEventsSubject.next({ type: poData, createDate: new Date, data: null });
		else
			this.moEventsSubject.next(poData);
	}

	//#endregion

}
