import { ObjectHelper } from '../../../../helpers/objectHelper';

export class NoDatabaseRemoteInstanceError extends Error {

	constructor(psDatabaseId: string) {
		super(`La base de données '${psDatabaseId}' n'existe pas sur le serveur.`);
		// Nécessaire pour que le 'instanceof NoDatabaseRemoteInstanceError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, NoDatabaseRemoteInstanceError);
	}

}