/**
 * Composant de démarrage de l'application
 * Initialisation de la navigation et du menu
 */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ObjectHelper } from '@osapp/helpers';
import { IAuthStatus } from '@osapp/model';
import { IAppStartPauseLogData } from '@osapp/model/application/iapp-start-pause-log-data';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IConfig } from '@osapp/model/config/IConfig';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { DeviceNotAuthorizedError } from '@osapp/model/store/error/device-not-authorized-error';
import { BatteryService } from '@osapp/modules/battery/services/battery.service';
import { EFlag } from '@osapp/modules/flags/models/EFlag';
import { Loader } from '@osapp/modules/loading/Loader';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { PatchService } from '@osapp/modules/patch/services/patch.service';
import { PwaUpdateService } from '@osapp/modules/pwa/services/pwa-update.service';
import { AppComponentBase } from '@osapp/modules/utils/components/app-component-base';
import { ApplicationService, EntityLinkService, FlagService, LoadingService, PlatformService, SecurityService } from '@osapp/services';
import { ConfigService } from '@osapp/services/config.service';
import { PanneauDesktopComponent, PopoverComponent, SwipeableDrawerComponent } from 'lighting-up-angular';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/internal/operators';
import packageJson from '../../package.json';
import * as constants from '../config';
import { LoaderComponent } from './features/shared/components/loader/loader.component';
import { AuthenticatorService } from './features/shared/services/authenticator.service';
import { DeviceService } from './features/shared/services/device.service';
import { DrawerPopoverService } from './features/shared/services/drawer-popover.service';
import { LoaderService } from './features/shared/services/loader.service';
import { PanneauService } from './features/shared/services/panneau.service';
import { PopoverService } from './features/shared/services/popover.service';

@Component({
	selector: "ion-anakin",
	templateUrl: 'anakin.component.html',
	styleUrls: ['./anakin.component.scss']
})
export class AnakinComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('panneauContainer', { read: ViewContainerRef }) panneauContainer: ViewContainerRef;
	@ViewChild('popoverContainer', { read: ViewContainerRef }) popoverContainer: ViewContainerRef;
	@ViewChild('drawerContainer', { read: ViewContainerRef }) drawerContainer: ViewContainerRef;
	@ViewChild('loaderContainer', { read: ViewContainerRef }) loaderContainer: ViewContainerRef;

	private moHomeActivePageManager: ActivePageManager;
	private subscription: Subscription;
	public readonly logSourceId = "IDL.APP.C::";

	public isMobile = false;

	public isAuthenticated: boolean;
	public menuOpened = false;
	

	constructor(
		private svcConfig: ConfigService,
		private svcLoading: LoadingService,
		private svcSecurity: SecurityService,
		private svcLogger: LoggerService,
		private svcFlag: FlagService,
		private svcPatch: PatchService,
		private svcBattery: BatteryService,
		private svcApplication: ApplicationService,
		private svcEntityLink: EntityLinkService,
		private readonly svcPlatform: PlatformService,
		router: Router,
		/** Service de menu angular. */
		private menuCtlr: MenuController,
		private svcDevice: DeviceService,
		private svcAuthenticator: AuthenticatorService,
		private svcPanneau: PanneauService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcPopover: PopoverService,
		private svcPwaUpdate : PwaUpdateService,
		private svcLoader: LoaderService,
		private cdr: ChangeDetectorRef,
	) {
		super(svcApplication, svcPlatform, svcBattery);
		// this.isvcDeeplink.init();
		// psvcPageManager.addComponents(IdlComponentRegister.getIdlRouteComponents());
		// psvcRapport.initNumberOfNonCompletedTransmission();
		// psvcTraitement.initNumberOfTraitEndingSoon();
		// psvcConflicts.initConflictsLogging();
		this.moHomeActivePageManager = new ActivePageManager(this, router, (newUrl: string, pageUrl: string) => newUrl === "/home");
	}

	public ngOnInit(): void {
		let loLoader: Loader;
		console.log("IDL.APP.C:: En attente d'initialisation de la plateforme...");

		constants.environment.version = packageJson.version;
		this.svcPatch.applyPatches() // Application des patchs.
			.pipe(
				mergeMap(_ => this.svcLoading.create("Démarrage de l'application ...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.svcConfig.init(constants as IConfig)),
				tap(
					_ => {
						this.svcLogger.action(this.logSourceId, "Démarrage de l'application.", ELogActionId.appStart, { version: ConfigData.appInfo.appVersion, environment: ConfigData.environment.id } as IAppStartPauseLogData);
						// this.initPopovers();
						loLoader.dismiss();
						this.svcFlag.setFlagValue(EFlag.appInitialized, true);
						console.log("IDL.APP.C:: Application initialized");
					},
					poError => this.initError(poError)
				),
				mergeMap(() => this.initRouteSubscriptions()),
				finalize(() => {
					loLoader.dismiss();
				})
			)
			.subscribe();

		this.svcPwaUpdate.initUpdateCheck();

		this.subscription = this.svcDevice.isMobile$.subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		this.subscription = this.svcSecurity.anakinAuthenticationStatus$.subscribe((flag: IAuthStatus) => {
			if(this.isAuthenticated && !flag.isAuthenticated)
			{
				this.svcAuthenticator.setAuthenticationStatus(false);
			}
		});

		this.svcAuthenticator.getAuthenticationStatus().subscribe((authenticatorStatus: boolean) => {
			this.isAuthenticated = authenticatorStatus;
			if (!authenticatorStatus) {
				this.menuOpened = false;
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
	
	public ngAfterViewInit(): void {
		const componentRef = this.panneauContainer.createComponent(PanneauDesktopComponent);	
		this.svcPanneau.setPanneauComponentRef(componentRef);
		componentRef.instance.open = false;

		const loaderRef = this.loaderContainer.createComponent(LoaderComponent);	
		this.svcLoader.setLoaderComponentRef(loaderRef);
		loaderRef.instance.visible = false;

		const popoverRef = this.popoverContainer.createComponent(PopoverComponent);	
		const drawerRef = this.drawerContainer.createComponent(SwipeableDrawerComponent);	
		this.svcDrawerPopover.setPopoverComponentRef(popoverRef);
		this.svcDrawerPopover.setDrawerComponentRef(drawerRef);
		this.svcPopover.setPopoverComponentRef(popoverRef);
		popoverRef.instance.open = false;
		drawerRef.instance.visible = false;
		this.cdr.detectChanges();
	}

	private initRouteSubscriptions(): Observable<boolean> {
		return this.moHomeActivePageManager.isActive$.pipe(
			mergeMap((pbIsActive: boolean) => {
				if (pbIsActive && !ObjectHelper.isNullOrEmpty(this.svcEntityLink.currentEntity))
					return this.svcEntityLink.clearCurrentEntity(this.svcEntityLink.currentEntity.id);
				return EMPTY;
			})
		);
	}

	private initError(poError: string | DeviceNotAuthorizedError | any): void {
		console.error(`IDL.APP.C:: Application initialization error.`, poError);
	}	

	onMenuToggle= () => {
		this.menuOpened = !this.menuOpened;
	}
	
}
