import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalComponentBase } from '../../../modules/modal/model/ModalComponentBase';
import { PlatformService } from '../../../services/platform.service';
import { IDateRange } from '../date-range-picker/model/IDateRange';

@Component({
	selector: 'calao-date-range-picker-modal',
	templateUrl: './date-range-picker-modal.component.html',
	styleUrls: ['./date-range-picker-modal.component.scss'],
})
export class DateRangePickerModalComponent extends ModalComponentBase<IDateRange> {

	//#region PROPERTIES

	public dateRange: IDateRange;
	@Input()
	public set defaultRange(poDefaultRange: IDateRange) {
		if (poDefaultRange)
			this.dateRange = poDefaultRange;
	}

	@Input() public title: string;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform);
	}

	/** Envoie les données à la page principale. */
	public submitDates() {
		this.close(this.dateRange);
	}

	public onDateRangeChanged(poDateRange: IDateRange): void {
		this.dateRange = poDateRange;
	}

	//#endregion

}