import { Component, OnInit } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';


import { Router } from '@angular/router';
import { PanneauService } from '../../../shared/services/panneau.service';


export interface ControlConfig {
  name: string;
  value: any;
  validators?: ValidatorFn[] | ValidatorFn;
}

@Component({
  selector: 'di-panneau-patient-ordonance',
  templateUrl: './panneau-patient-ordonnance.component.html',
  styleUrls: ['./panneau-patient-ordonnance.component.scss'],
})
export class PanneauPatientOrdonanceComponent implements OnInit {
	
  public patient: IPatient;

  constructor(
    private svcPanneau: PanneauService,
 
		private router: Router,
		) { }

  ngOnInit() {

  }

	onClickPatient(patientId :string)
	{
		this.close()
		this.router.navigate(['ordonnances', 'add'],{ state: { patientId: patientId }});
	}
	
	public close()
	{
		this.svcPanneau.close();
	}
  public navigateToCreatePatient(): void {
    this.close();
    this.router.navigate(['patients', 'add'],{ state: { patientOrdonnance: true }});
  }
}