import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';

@Component({
	selector: 'calao-conflict-list-marker',
	templateUrl: './conflict-list-marker.component.html',
	styleUrls: ['./conflict-list-marker.component.scss'],
	host: {
		class: "just-flex background-warning flex-col flex-justify-center",
		"[style.height]": "'100%'"
	},
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConflictListMarkerComponent extends ComponentBase {

	//#region PROPERTIES

	private msColor: string;
	/** Couleur de l'icône, blanc par défaut. */
	public get color(): string {
		return this.msColor ?? "light";
	}
	@Input()
	public set color(psColor: string) {
		if (psColor !== this.msColor)
			this.msColor = psColor;
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}
