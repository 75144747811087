import { Injectable } from '@angular/core';
import { ArrayHelper, DateHelper, IdHelper } from '@osapp/helpers';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { EDatabaseRole, EPrefix, IDataSource, IStoreDocument, UserData } from '../../../../../../../libs/osapp/src/model';
import { Store } from '../../../../../../../libs/osapp/src/services/store.service';
import { Historique } from '../../../models/notifications/Historique';
import { NavigationService } from './navigation.service';
import { ITransmissionRapport } from 'apps/idl/src/modules/patients/model/ITransmissionRapport';
import { RapportService } from 'apps/idl/src/modules/patients/services/rapport.service';


@Injectable({
	providedIn: 'root',
})
export class HistoriqueService {

	constructor(
		private isvcStore: Store,
		private svcNavigation: NavigationService,
		private svcTransmission: RapportService
	) { }

	/** Creation ou update du document d'historique
	 * @param historique Le document historique à creer ou modifier.
	 */
	public createOrUpdate(historique: Historique) {
		return this.isvcStore.put(historique).pipe(mapTo(historique));
	}

	public setLastVisitedTransmission(historique?: Historique): Observable<Historique> {
		const userId: string = UserData.current?.name || "";
		const siteId: string = UserData.currentSite._id || "";
		if (!userId && !siteId) return EMPTY;

		const historique$ = historique ? of(historique) : this.getByUserAndSite(userId, siteId);

		return historique$
			.pipe(
				filter(histo => !!histo),
				tap((histo: Historique) => {
					//pour ne pas que this.historique soit modifiée par référence
					let historiqueCopie = { ...histo };
					historiqueCopie.lastVisitedTransmission = new Date();
					this.createOrUpdate(historiqueCopie).subscribe();
				}),
				delay(1500),
				tap(() => this.svcNavigation.setBadgeCount('Transmissions', 0))
			);
	}

	/** Get du document d'historique grace à son user et son site
		 * @param userId user Id du user connecté.
		 * @param siteId site Id du site connecté. * 
		 */
	public getByUserAndSite(userId: string, siteId: string, isLive: boolean = false): Observable<Historique> {
		if (!userId && !siteId) return of(null);

		const databaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		const loDataSource: IDataSource = {
			databaseId: databaseId,
			viewParams: {
				include_docs: true,
				keys: [IdHelper.buildId(EPrefix.historique, `${userId}~${siteId}`)]
			},
			live: isLive
		};

		return this.isvcStore.getOne(loDataSource).pipe(
			map((doc: IStoreDocument) => {
				return doc as Historique;
			}),
			catchError((error: any) => {
				console.error(error);
				return of(null);
			})
		);
	}

	/** Delete du document d'historique
	 * @param historique Le document d'historique à supprimer.
	 */
	public delete(historique: Historique) {
		return this.isvcStore.delete(historique);

	}

	public getBadgeTransmissionFromHistorique() {
		const userId: string = UserData.current?.name || "";
		const userIdSansPrefix: string = IdHelper.extractIdWithoutPrefix(userId, EPrefix.user);
		const siteId: string = UserData.currentSite._id || "";
		return this.getByUserAndSite(userId, siteId)
			.pipe(
				switchMap((historique) => {
					if (!historique) {
						//On doit créer le document historique
						historique = new Historique(userId, siteId);
						return this.createOrUpdate(historique);
					}
					return of(historique);
				}),
				switchMap((historique: Historique) =>
					this.svcTransmission.getTransmissionsAnakin().pipe(
						map((transmissions: ITransmissionRapport[]) => {
							return transmissions.filter(x =>
								DateHelper.compareTwoDates(historique.lastVisitedTransmission, x.dateLastModification) <= 0
								&&
								(IdHelper.getGuidFromId(x.authorPath, EPrefix.contact) !== userIdSansPrefix
									||
									(ArrayHelper.hasElements(x.commentaires) &&
										ArrayHelper.getLastElement(x.commentaires)?.auteurId !== userIdSansPrefix))
							).length;
						}),
						tap((nbTransmissions: number) => {
							this.svcNavigation.setBadgeCount('Transmissions', nbTransmissions);
						}),
					)
				)
			);
	}
}
