<osapp-header-modal title="Adresse"></osapp-header-modal>

<ion-content>
	<ng-container *ngIf="data?.length > 0">
		<ion-list-header>
			<ion-label class="ion-label">Sélectionnez une adresse</ion-label>
		</ion-list-header>
		<!--todo else template = "aucune donnée gps trouvée" avec bouton réessayer ? -->
		<ion-list>
			<ion-radio-group [(ngModel)]="selectedIndex">
				<ion-item *ngFor="let item of data; let i = index;">
					<ion-radio slot="start" (click)="onItemSelected(item, i)" [value]="i"></ion-radio>

					<ion-grid>
						<ion-row>
							<ion-note class="ion-text-wrap">{{item.title}}</ion-note>
						</ion-row>
						<ion-row>
							<ion-label class="ion-text-wrap">{{item.street}}</ion-label>
						</ion-row>
						<ion-row>
							<ion-label class="ion-text-wrap">{{item.zipCode}} {{item.city}}</ion-label>
						</ion-row>
					</ion-grid>

				</ion-item>
			</ion-radio-group>
		</ion-list>

		<ion-button (click)="onUpdateClick()" [disabled]="!selectedAddress">Mettre à jour</ion-button>
	</ng-container>
</ion-content>