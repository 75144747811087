import { Pipe, PipeTransform } from '@angular/core';
import { PatternsHelper } from '../modules/utils/helpers/patterns.helper';

/** Filtre de données : remplace les données dont la clé est entre doubles accolades par les valeurs du tableau. */
@Pipe({ name: "fieldsBinding" })
export class FieldsBindingPipe implements PipeTransform {

	//#region METHODS

	/** Remplace les key entre les séparateur d'une chaine de caractère par les données d'un tableau.
	 * @param psString chaine d'entrée à transformer
	 * @param paFieldMappingTable Table des données à placer dans la chaine, rangées par leur clé
	 */
	public transform(psString: string, paFieldMappingTable: string[]): string {
		let lsResultValue = "";

		// On parse la valeur pour lui attribuer les bonnes variables.
		if (psString.indexOf(PatternsHelper.C_START_PATTERN) !== -1) {
			const laSplitedValue: Array<string> = psString.split(PatternsHelper.C_START_PATTERN);
			lsResultValue += laSplitedValue.shift();

			laSplitedValue.forEach((poItem: string) => {
				const laEndSplitedValue: Array<string> = poItem.split(PatternsHelper.C_END_PATTERN);
				lsResultValue += paFieldMappingTable[laEndSplitedValue[0]];
				lsResultValue += laEndSplitedValue[1];
			});
		}
		else
			lsResultValue = psString;

		return lsResultValue;
	}

	//#endregion

}