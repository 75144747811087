import { Component, Input } from '@angular/core';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { IAccordPrealable } from 'apps/idl/src/modules/patients/model/IAccordPrealable';

@Component({
	selector: 'di-panneau-accord-prealable',
	templateUrl: './panneau-accord-prealable.component.html',
	styleUrls: ['./panneau-accord-prealable.component.scss'],
})
export class PanneauAccordPrealableComponent {

	@Input()
	public ordonnance: ITraitement;
	
	@Input()
	public accord?: IAccordPrealable;

	constructor() { }

}
