import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { StepperComponent, TabComponent } from 'lighting-up-angular';
import { Observable, forkJoin, merge, of } from 'rxjs';
import { delay, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITraitement } from '../../../../model/ITraitement';
import { Traitement } from '../../../../model/Traitement';
import { TraitementService } from '../../../../services/traitement.service';
import { MenuOrdonnanceComponent } from '../../../features/ordonnances/components/menu-ordonnance/menu-ordonnance.component';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { LoaderService } from '../../../features/shared/services/loader.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { SeancesGeneratorService } from '../../../features/shared/services/seances-generator.service';
import { StoredSeance } from '../../../models/StoredSeance';

@Component({
	selector: 'di-detail-ordonnance',
	templateUrl: './detail-ordonnance.page.html',
	styleUrls: ['./detail-ordonnance.page.scss'],
})
export class DetailOrdonnancePage extends DestroyableComponentBase implements OnInit {
	@ViewChild(StepperComponent) stepper!: StepperComponent;
	
	constructor(
		private svcPatients: PatientsService,
		private svcOrdonnance: TraitementService,
		private router: Router,
		private route: ActivatedRoute,
		private svcSeancesGenerator: SeancesGeneratorService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService,
		private svcLoader: LoaderService
	) {
		super();
	}
	public showPopover: boolean;

	public mode: "add" | "edit" = "add";
	public patientId: string;
	public currentStep: number = 0;
	public patient: IPatient;
	public medecin: IContact;
	public ordonnance?: ITraitement;
	public stepNames = ['Ordonnance', 'Actes et soins', 'Séances']
	public showTabActe: boolean = false;
	public showTabSeances: boolean = false;
	public showTabFactures: boolean = false;
	public tabSelected: string = "";
	idOrdonnance!: string;
	public seances: StoredSeance[] = [];
	public fullLastName :string;

	public generatingSeance: boolean = false;

	ngOnInit(): void {

		this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
this.getTabSelectedFromRoute();

// Vérifiez si l'ordonnance est définie pour lancer le chargement
if (this.idOrdonnance) {
    // Afficher le loader initial
    this.svcLoader.showLoader("Récupération des informations de l'ordonnance...");

    // Fusionne les observables pour déclencher à l'initialisation et lors des changements
    merge(
        this.svcOrdonnance.ordonnanceChanges$, // observable des changements
        of(true) // observable pour déclencher au chargement initial
    ).pipe(
        // Intercepte pour afficher le loader lors de chaque changement
        tap(() => this.svcLoader.showLoader("Mise à jour des informations de l'ordonnance...")),
        switchMap(() => this.svcOrdonnance.getTraitementANAKIN(this.idOrdonnance)),
        tap((ordonnance: Traitement) => {
            // Mise à jour des données de l'ordonnance et des informations associées
            this.ordonnance = ordonnance;
            this.patientId = Traitement.extractPatientId(this.idOrdonnance);
            this.getPatient(this.patientId);
        }),
        switchMap(() => {
            // Chargement des séances si l'onglet "Séances" est sélectionné
            if (this.tabSelected === "Séances") {
                return this.svcSeance.selectSeancesByTraitement(this.ordonnance);
            }
            return of([]);
        }),
        tap((seances: StoredSeance[]) => {
            // Mise à jour des séances et affichage de l'onglet si sélectionné
            if (this.tabSelected === "Séances") {
                this.seances = seances;
                this.showTabSeances = true;
            }
            this.svcLoader.hideLoader();
        }),
        takeUntil(this.destroyed$)
    	).subscribe();

			// Récupération du médecin prescripteur en cas de redirection depuis le form-contact
			this.medecin = history.state?.medecin;
			this.mode = "edit";
		} else {
				// Si aucune ordonnance n'est spécifiée, on récupère le patient lié à la nouvelle ordonnance
				const state = this.router.getCurrentNavigation()?.extras.state as { patientId: string };
				this.patientId = state?.patientId || history.state?.patientId;
				this.getPatient(this.patientId);

				// Récupérer le médecin prescripteur
				this.medecin = history.state?.medecin;
		}

	}

	private getTabSelectedFromRoute() {
		const state = this.router.getCurrentNavigation()?.extras.state as { tabSelected: string; };
		this.tabSelected = state?.tabSelected;
		if (!this.tabSelected) {
			this.tabSelected = history.state.tabSelected;
		}
	}

	private getPatient(patientId: string) {
		this.svcPatients.getPatient(patientId).pipe(
			tap((patient: IPatient) => {
				this.patient = patient;
				this.fullLastName = this.svcPatients.getFullNamePatient(this.patient); 
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	onStepChange(stepIndex: number): void {
		// On ne peut pas cliquer sur le step 3
		if(stepIndex !== 2){
			// Si on est au step 1 et qu'on a pas encore d'ordonnance, on ne peut pas changer de step
			if(this.currentStep !== 0 || this.ordonnance){
				this.stepper.goToStep(stepIndex);
				this.currentStep = stepIndex;
			}
		}
	}

	public setOrdonnance = (ordo: Traitement): void => {
		this.ordonnance = ordo;
	}

	public handleSaveOrdonnance(ordo: Traitement) {
		this.setOrdonnance(ordo);
		if (this.mode === "add") {
			this.currentStep++;
		}
		if (this.mode === "edit") {
			this.router.navigate(['ordonnances']);
		}
	}

	public handleGenerateSeances = (): void => {
		if (this.mode === "add") {
			this.generateSeances().subscribe();
		}
		if (this.mode === "edit") {
			this.deleteAllSeanceAndGenerate();
		}
	};

	public openMenu(event: Event): void {
		this.showPopover = true;
		const menuOrdonnanceInputs = {
			edit: false,
			ordonnance: this.ordonnance,
			hasSeanceFacturee: this.seances.some((seance: StoredSeance) => seance.status === EStatusSeance.completed)
		}
		this.svcDrawerPopover.open("", "50%", event.currentTarget, MenuOrdonnanceComponent, menuOrdonnanceInputs, () => this.showPopover = false)
	}

	handleTabSelection = (tabLabel: string) => {
		this.showTabActe = tabLabel === "Actes";
		this.showTabSeances = tabLabel === "Séances";
		this.showTabFactures = tabLabel === "Factures";
	}

	public generateSeances(seancesNotDeleted?: StoredSeance[]): Observable<Boolean> {
		return this.svcOrdonnance.getTraitement(this.ordonnance?._id)
			.pipe(
				tap((traitement: Traitement) => {
					if (this.mode === 'add') {
						this.currentStep++;
					}
					this.ordonnance = traitement;
				}),
				switchMap((traitement: Traitement) => this.svcSeancesGenerator.saveSeancesBulk(traitement, this.patient, seancesNotDeleted)),
				delay(500),//TODO: a supprimer si trop long, simule une latence pour le moment
				tap(() => {
					if (this.mode === 'add') {
						this.router.navigate(
							['ordonnances/edit/', this.ordonnance._id],
							{ state: { tabSelected: "Séances" } }
						);
					}
				}),
				takeUntil(this.destroyed$)
			);
	}


	public deleteAllSeanceAndGenerate(): void {
		// Pour éviter que la génération des séances ne soient lancées plusieurs fois
		if (this.generatingSeance) return;
		this.svcLoader.showLoader("Mise à jour des seances ...")
		this.tabSelected = 'Actes';
		this.showTabSeances = false;
		this.showTabActe = true;
		let seancesNotDeleted: StoredSeance[] = []
		this.generatingSeance = true;
		this.svcSeance.selectSeancesByTraitement(this.ordonnance).pipe(
			map((listSeances: StoredSeance[]) => {
				// Parmis les séances existantes, on stocke les séances facturées ou réalisées pour ne pas les générer de nouveau
				const result = listSeances.reduce((acc, seance: StoredSeance) => {
					if (seance.status === EStatusSeance.completed || seance.status === EStatusSeance.done || seance.isManuel) {
						acc.seancesNotDeleted.push(seance);
					} else {
						acc.seancesToDelete.push(seance);
					}
					return acc;
				}, { seancesNotDeleted: [], seancesToDelete: [] });

				seancesNotDeleted = result.seancesNotDeleted;
				return result.seancesToDelete;
			}),
			mergeMap((listSeance: StoredSeance[]) => {
				if (listSeance.length === 0) {
					return of(null);
				}
				const deleteSeanceObservables = listSeance.map(seance =>
					this.svcSeance.deleteSeance(seance)
				);
				return forkJoin(deleteSeanceObservables);
			}),
			switchMap(() => this.generateSeances(seancesNotDeleted)),
			tap(() => {
				this.tabSelected = 'Séances';
				this.showTabSeances = true;
				this.showTabActe = false;
				this.generatingSeance = false;
				this.svcLoader.hideLoader()
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

}