import { OsappError } from '../../../modules/errors/model/OsappError';
export class BlobUrlManager {

	//#region FIELDS

	private maUrls: string[] = [];

	//#endregion

	//#region METHODS

	constructor() { }

	public createUrl(poBlob: Blob): string {
		if (!poBlob)
			throw new OsappError("Erreur lors de la récupération de l'url de l'image.");

		const lsUrl: string = URL.createObjectURL(poBlob);

		this.maUrls.push(lsUrl);

		return lsUrl;
	}

	public releaseUrls(): void {
		this.maUrls.forEach((psUrl: string) => URL.revokeObjectURL(psUrl));

		this.maUrls = [];
	}

	//#endregion

}
