import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../../modules/avatar/calao-avatar.module';
import { LinkModule } from '../link/link.module';
import { PopoverModule } from '../popover/popover.module';
import { MenuComponent } from './menu.component';
import { MenuPopoverComponent } from './menuPopover.component';
import { SectionComponent } from './section/section.component';

/** Module de gestion des menus. */
@NgModule({
	imports: [CommonModule, IonicModule, LinkModule, PopoverModule, CalaoAvatarModule],
	exports: [MenuComponent, MenuPopoverComponent, SectionComponent],
	declarations: [MenuComponent, MenuPopoverComponent, SectionComponent],
	providers: []
})
export class MenuModule { }