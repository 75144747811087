import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SelectorModule } from '../selector/selector.module';
import { EntitySelectorComponent } from './entitySelector/entitySelector.component';
import { EntityTypeSelectorComponent } from './entityTypeSelector/entityTypeSelector.component';

const exportComponents: Array<Type<any>> = [
	EntitySelectorComponent,
	EntityTypeSelectorComponent
];

@NgModule({
	imports: [IonicModule, CommonModule, SelectorModule],
	exports: exportComponents,
	declarations: exportComponents
})
export class EntityModule { }