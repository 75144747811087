import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IDatabaseSyncStatus } from '../../model/IDatabaseSyncStatus';
import { DatabaseSynchroService } from '../../services/database-synchro.service';

@Component({
	selector: 'calao-database-sync-status',
	templateUrl: './database-sync-status.component.html',
	styleUrls: ['./database-sync-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabaseSyncStatusComponent extends ComponentBase {

	//#region PROPERTIES

	private moDatabaseSyncStatus: IDatabaseSyncStatus;
	public get databaseSyncStatus(): IDatabaseSyncStatus { return this.moDatabaseSyncStatus; }
	@Input() public set databaseSyncStatus(poDatabaseSyncStatus: IDatabaseSyncStatus) {
		if (!DatabaseSynchroService.areDatabasesStatusEquals(poDatabaseSyncStatus, this.moDatabaseSyncStatus)) {
			this.moDatabaseSyncStatus = poDatabaseSyncStatus;
			this.msIconName = DatabaseSynchroService.getSyncIcon(this.moDatabaseSyncStatus.status);
			this.detectChanges();
		}
	}

	private msIconName: string;
	public get iconName(): string { return this.msIconName; }

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}