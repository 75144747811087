import { ObjectHelper } from '../../../helpers/objectHelper';

export class OsappError extends Error {

	//#region METHODS

	constructor(psMessage?: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof OsappError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, OsappError);
	}

	//#endregion

}
