import { EMultipleDatabaseIdsStrategy } from '../model/datasource/EMultipleDatabaseIdsStrategy';
import { MultipleDatabaseIdsFoundError } from '../model/datasource/errors/MultipleDatabaseIdsFoundError';
import { NoDatabaseIdFoundError } from '../model/datasource/errors/NoDatabaseIdFoundError';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { ArrayHelper } from './arrayHelper';
import { StoreHelper } from './storeHelper';
import { StringHelper } from './stringHelper';

/** Permet de mettre à disposition des méthodes pour aider à manipuler les datasources. */
export abstract class DataSourceHelper {

	//#region METHODS

	/** Retourne l'id d'une base de données d'une Datasource.\
	 * S'il y a un databaseId dans la datasource il est retourné,\
	 * sinon s'il y a un databaseId dans le cache data il est retourné,\
	 * sinon s'il existe un seul élément dans le tableau databasesIds il est retourné,\
	 * sinon si le modèle passé en paramètre est une nouvelle donnée (pas encore enregistrée en base de données), on retourne une chaîne vide,\
	 * sinon on utilise une stratégie dans le cas où il y a plusieurs databaseIds.
	 * @param poDatasource Source de données contenant les attributs databaseId et databasesIds.
	 * @param poModel Modèle pouvant contenir des cache data.
	 * @param peStrategy Stratégie à utiliser si on doit retenir un databaseId parmi plusieurs. Par défaut `THROW_ERROR` qui lève une erreur.
	 * @throws {NoDatabaseIdFoundError} Si databaseId, le cache data et databasesIds sont vides.
	 * @throws {MultipleDatabaseIdsFoundError} Si databaseId et le cache data sont vides et que peStrategy ne permet pas de définir l'id à utiliser dans databasesIds.
	 */
	public static getDatabaseId<T extends IStoreDocument = IStoreDocument>(poDatasource: IDataSource<T>, poModel?: IStoreDocument,
		peStrategy: EMultipleDatabaseIdsStrategy = EMultipleDatabaseIdsStrategy.THROW_ERROR): string {

		if (!StringHelper.isBlank(poDatasource.databaseId)) // S'il y a un databaseId il est retourné.
			return poDatasource.databaseId;
		else {
			if (poModel) {
				const lsDatabaseIdFromCacheData: string = StoreHelper.getDatabaseIdFromCacheData(poModel, undefined, false);

				if (!StringHelper.isBlank(lsDatabaseIdFromCacheData)) // Si modèle avec identifiant de base de données dans ses cacheData.
					return lsDatabaseIdFromCacheData;
			}

			if (ArrayHelper.hasElements(poDatasource.databasesIds)) {
				if (poDatasource.databasesIds.length === 1)	// S'il existe un seul élément dans le tableau databasesId il est retourné.
					return poDatasource.databasesIds[0];
				else if (StringHelper.isBlank(poModel?._rev)) // Sinon si le modèle est une nouvelle donnée.
					return "";
				else
					return DataSourceHelper.applyMultipleDatabaseIdsStrategy(peStrategy, poDatasource.databasesIds);
			}
			else
				throw new NoDatabaseIdFoundError();
		}
	}

	/** Retourne la logique à appliquer pour la résolution de plusieurs databaseIds en fonction de l'énumération passée en paramètre. */
	private static applyMultipleDatabaseIdsStrategy(peStrategy: EMultipleDatabaseIdsStrategy, paDatabaseIds: Array<string>): string {
		switch (peStrategy) {
			case EMultipleDatabaseIdsStrategy.TAKE_FIRST:
				return paDatabaseIds[0];

			case EMultipleDatabaseIdsStrategy.THROW_ERROR:
				throw new MultipleDatabaseIdsFoundError();

			default:
				console.error(`DataS.H::Cas ${peStrategy} non géré.`);
				throw new Error("Cas non géré.");
		}
	}

	//#endregion
}