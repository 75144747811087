import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { ObjectHelper } from '@osapp/helpers/objectHelper';
import { IContact, IStoreDocument } from '@osapp/model';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { isEqual } from 'lodash';
import { Acte } from '../../model/Acte';
import { EMajorationType } from '../../model/EMajorationType';
import { EPlace } from '../../model/EPlace';
import { EStatusSeance } from '../../model/EStatusSeance';
import { IActe } from '../../model/IActe';
import { IMajoration } from '../../model/IMajoration';
import { ISeance } from '../../model/ISeance';
import { Majoration } from '../../model/Majoration';
import { IIndemnite } from '../../modules/traitement/model/IIndemnite';
import { Indemnite } from '../../modules/traitement/model/Indemnite';

import { DayRepetition } from '@osapp/modules/event-markers/models/day-repetition';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IDayRepetition } from '@osapp/modules/event-markers/models/iday-repetition';
import { EMoments } from '../features/shared/enums/EMoments';
import { Exclude } from 'class-transformer';
import { Traitement } from '../../model/Traitement';
import { IPatient } from '../../modules/patients/model/IPatient';

export class StoredSeance implements ISeance, IStoreDocument {

	//#region PROPERTIES

	/** Durée par défaut d'une séance. */
	public static readonly C_DEFAULT_DURATION = 15;

	_id: string = "";
	_rev?: string;
	_deleted?: boolean = false;
	deleted?: boolean = false;
	_conflicts?: string[];

	/** @implements */
	public scheduled?: boolean = false;
	/** @implements */
	public patientId: string;
	/** @implements */
	public status: EStatusSeance
	/** @implements */
	public intervenantIds?: string[];
	/** @implements */
	public infirmierId?: string = "";
	/** @implements */
	public actes: Array<Acte>;
	/** @implements */
	public startDate: Date;
	/** @implements */
	public moment: DayRepetition;
	/** @implements */
	public get endDate(): Date {
		return DateHelper.addMinutes(this.startDate, this.duration);
	};
	/** @implements */
	public readonly id: number;
	/** @implements */
	public majorations: Array<Majoration>;
	/** @implements */
	public get price(): number {
		return NumberHelper.reduceNumbers(this.actes.map((poActe: Acte) => poActe.price), 0);
	}
	public mePlace: EPlace;
	/** @implements */
	public get place(): EPlace { return this.actes.some((poActe: Acte) => poActe.place === EPlace.center) ? EPlace.center : this.mePlace ?? EPlace.home; }
	public set place(peValue: EPlace) { this.mePlace = peValue; }
	/** @implements */
	public duration: number;
	private maIndemnites: Indemnite[];
	/** @implements */
	public get indemnites(): Indemnite[] {
		return this.place === EPlace.center ? [] : this.maIndemnites;
	}
	public set indemnites(paIndemnites: Indemnite[]) {
		this.maIndemnites = paIndemnites;
	}
	/** @implements */
	public planningApplied?: boolean = false;
	/** @implements */
	public isBillingLocked?: boolean = false;
	/** @implements */
	public billingLockedReason?: string = "";
	/** @implements */
	public traitementId: string = "";
	/** @implements */
	public traitementRev: string = "";
	/** @implements */
	public statusChangeDate?: Date;
	/** @implements */
	public commentaireIndisponibilitePatient?: string;

	/** True si la séance a été créée manuellement */
	public isManuel?: boolean;
	public commentaireManuel?: string

	/** Indique si la séance est validée. */
	public get isPending(): boolean {
		return !this.status || this.status === EStatusSeance.pending;
	}
	/** Indique si la séance est en retard. */
	public get isLate(): boolean {
		return (DateHelper.compareTwoDates(new Date(), this.endDate) >= 0);
	}
	/** Indique si la séance est facturable */
	public get isBillable(): boolean {
		return this.status === EStatusSeance.done || this.status === EStatusSeance.inProgress;
	}
	/** Indique si la séance est complétée (facturée). */
	public get isCompleted(): boolean {
		return this.status === EStatusSeance.completed;
	}
	/** Indique si la séance est annulée. */
	public get isCanceled(): boolean {
		return this.status === EStatusSeance.canceled;
	}
	/** Indique si la séance est en cours de facturation. */
	public get isInProgress(): boolean {
		return this.status === EStatusSeance.inProgress;
	}
	/** Indique si le circuit de facturation à commencé ou est fini pour la séance. */
	public get isBilled(): boolean {
		return this.isInProgress || this.isCompleted;
	}
	/** Indique si la séance est validée. */
	public get validated(): boolean {
		return this.status === EStatusSeance.done;
	}
	/** Indique si la séance est considérée comme protected.
	 */

	@Exclude()
	public infirmier?: IContact;

	@Exclude()
	public ordonnance?: Traitement;

	@Exclude()
	public patient?: IPatient;

	public get isProtected(): boolean {
		return this.status === EStatusSeance.canceled ||
			this.status === EStatusSeance.completed ||
			this.status === EStatusSeance.inProgress ||
			this.status === EStatusSeance.done;
	}

	public get statusLabel(): string {
		if (this.validated)
			return "validée";
		if (this.isInProgress)
			return "facturée partiellement";
		else if (this.isCompleted)
			return "facturée";
		else if (this.isCanceled)
			return "annulée";
		else
			return undefined;
	}

	public get label(): string {
		return `Séance du ${DateHelper.transform(this.startDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash)}`;
	}

	public shadow: boolean = false;
	public isLast: boolean = false;

	//#endregion

	//#region METHODS
	// A partir d'une IDayRepetition ou d'une date, retourne le moment associé 
	static determineMoment(input: IDayRepetition | Date): EMoments | string {
		let hour: number;
	
		if (input instanceof Date) {
			hour = input.getHours();
		} else if (input.type === "range") {
			hour = input.from.hours;
		} else {
			return (typeof input === "string") ? input : `${input.hours}:${input.minutes}`;
		}
	
		switch (true) {
			case (hour >= 6 && hour < 12):
				return EMoments.MATIN;
			case (hour >= 12 && hour < 18):
				return EMoments.APRES_MIDI;
			case (hour >= 18 && hour < 23):
				return EMoments.SOIR;
			case (hour < 6 || hour >= 23):
				return EMoments.NUIT;
			default:
				throw new Error("Invalid time range for moment");
		}
	}

	static getStartDateFromMoment(moment: EMoments | HoursMinutesRepetition, date: Date): Date {
		let startdate = new Date(date);

		switch (moment) {
			case EMoments.MATIN:
				startdate = new Date(startdate.setHours(6, 0, 0, 0));
				break;
			case EMoments.APRES_MIDI:
				startdate = new Date(startdate.setHours(12, 0, 0, 0));
				break;
			case EMoments.SOIR:
				startdate = new Date(startdate.setHours(18, 0, 0, 0));
				break;
			case EMoments.NUIT:
				startdate = new Date(startdate.setHours(23, 0, 0, 0));
				break;
			default:
				moment as HoursMinutesRepetition
				const hours = moment.hours;
				const minutes = moment.minutes;
				startdate = new Date(startdate.setHours(hours, minutes, 0, 0));

		}

		return startdate;
	}

	constructor(paActes: Array<Acte>, pnId: number, pdStartDate: Date, moment?: DayRepetition) {
		ObjectHelper.initInstanceOf(this, StoredSeance);

		this.actes = paActes;
		this.startDate = pdStartDate;
		this.duration = StoredSeance.C_DEFAULT_DURATION;
		this.id = pnId;
		this.majorations = [];
		this.indemnites = [];
		this.moment = moment;
	};

	/** Crée une instance de séance à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Seance`.
	 */
	public static createFromData(poData: ISeance): StoredSeance {
		const laActes: Acte[] = ArrayHelper.hasElements(poData.actes) ?
			poData.actes.map((poActe: IActe) => new Acte({ ...poActe, guid: poActe.guid ?? poActe._id })) : []; // Pour rétrocompat
		const loSeance: StoredSeance = new StoredSeance([], poData.id, new Date(poData.startDate));
		const laIndemnites: Indemnite[] = [];

		//! On filtre les indemnités non valides pour prévenir un bug non reproduit :
		//! https://dev.azure.com/calaosoft/osapp-project/_git/osapp/pullrequest/1546?discussionId=17990
		if (ArrayHelper.hasElements(poData.indemnites))
			laIndemnites.push(...poData.indemnites.filter((poItem: IIndemnite) => !!poItem).map((poItem: IIndemnite) => Indemnite.getInstanceFromData(poItem)));

		delete poData.price; // Gestion de la propriété get only;
		ObjectHelper.assign(loSeance, poData);

		if (loSeance.startDate)
			loSeance.startDate = new Date(loSeance.startDate);

		loSeance.actes = laActes;
		loSeance.majorations = poData.majorations ? poData.majorations.map((poMajoration: IMajoration) => Majoration.getInstanceFromData(poMajoration)) : [];
		loSeance.indemnites = laIndemnites;

		return loSeance;
	}

	/** Retourne `true` si la séance est planifiée, `false` sinon. */
	public isPlanned(): boolean {
		return this.status !== undefined && this.status !== null && this.status !== EStatusSeance.pending;
	}

	public getDeplacementDetail(pnDistance: number = 1): string {
		const laIndemnitesDetail: string[] = [];

		this.indemnites.forEach((poIndemnite: Indemnite) => {
			if (!poIndemnite.disabled) {
				const loIndemniteDetail: string = poIndemnite.isIKType ? (`${pnDistance}IK`) : poIndemnite.type;
				laIndemnitesDetail.push(loIndemniteDetail);
			}
		});

		return laIndemnitesDetail.join(", ");
	}

	public equals(poOtherSeance: StoredSeance): boolean {
		return isEqual(this, poOtherSeance);
	}

	public hasMajoration(peMajorationType: EMajorationType): boolean {
		return this.majorations?.some((poMajoration: Majoration) => poMajoration.type === peMajorationType && !poMajoration.disabled);
	}

	public wasProtected(pdDate: Date): boolean {
		return this.isProtected && DateHelper.compareTwoDates(this.statusChangeDate, pdDate) < 0;
	}

	//#endregion
}