import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Loader } from '../modules/loading/Loader';
import { ILoaderOptions } from '../modules/loading/models/iloader-options';
import { FlagService } from './flag.service';
import { PlatformService } from './platform.service';

@Injectable({ providedIn: 'root' })
export class LoadingService {

	//#region FIELDS

	/** Instance du spinner. */
	private moLoader: HTMLIonLoadingElement;

	//#endregion

	//#region METHODS

	constructor(private readonly ioLoadingCtrl: LoadingController, private readonly isvcPlatform: PlatformService, private readonly isvcFlag: FlagService) { }

	/** Permet de créer un loader.
	 * @param psText Texte à afficher dans le loader.
	 * @param pbBackdropDismiss Indique si un clic en dehors ferme le loader.
	 * @param pbIconOnly Indique si on veut afficher seulement une icône.
	 */
	public create(psText?: string, pbBackdropDismiss?: boolean, pbIconOnly?: boolean): Promise<Loader>;
	/** Permet de créer un loader.
	 * @param poOptions Options du loader à créer.
	 */
	public create(poOptions: ILoaderOptions): Promise<Loader>;
	public async create(poData: string | ILoaderOptions, pbBackdropDismiss?: boolean, pbIconOnly?: boolean): Promise<Loader> {

		const loLoaderOptions: ILoaderOptions = typeof poData === "object" ?
			poData : { message: poData, backdropDismiss: pbBackdropDismiss, iconOnly: pbIconOnly };
		const loLoader: HTMLIonLoadingElement =
			await this.ioLoadingCtrl.create({ message: loLoaderOptions.message, backdropDismiss: loLoaderOptions.backdropDismiss });

		if (this.isLoaderPresented())
			await this.dismiss();

		this.moLoader = loLoader;
		loLoader.onDidDismiss().then(_ => this.moLoader = null);

		return new Loader(loLoader, this.isvcFlag, loLoaderOptions.iconOnly);
	}

	/** Fait disparaître le dernier loader affiché.
	 * @deprecated Utiliser `Loader.dismiss()` à la place.
	 */
	public async dismiss(): Promise<boolean> {
		try {
			const lbResult: boolean = await this.ioLoadingCtrl.dismiss();
			this.moLoader = null;
			return lbResult;
		}
		catch (poError) {
			return false;
		}
	}

	/**
	 * @deprecated Utiliser la propriété isPresented de la classe `Loader`.
	 */
	public isLoaderPresented(): boolean {
		return !!this.moLoader;
	}

	/** Affiche un spinner.
	 * ### Penser à appeler `dismiss()` pour supprimer le possible loader.
	 * @param psText Texte à afficher avec le loader.
	 * @deprecated Utiliser la méthode `create` puis la méthode `present` de la classe `Loader`.
	 */
	public async present(psText?: string, pbBackdropDismiss?: boolean): Promise<HTMLIonLoadingElement> {
		if (this.isLoaderPresented())
			return this.updateText(psText);
		else {
			const loLoader: HTMLIonLoadingElement = await this.ioLoadingCtrl.create({ message: psText, backdropDismiss: pbBackdropDismiss });

			await this.prepareLoader(loLoader);
			return this.moLoader;
		}
	}

	private async prepareLoader(loLoader: HTMLIonLoadingElement): Promise<void> {
		if (this.isLoaderPresented())
			await this.dismiss();

		this.moLoader = loLoader;
		await loLoader.present();
		loLoader.onDidDismiss().then(_ => this.moLoader = null);
	}

	/** Met a jour le texte du spinner.
	 * @deprecated Utiliser la propriété `text` de la classe `Loader`.
	 */
	public updateText(psText?: string, pbBackdropDismiss?: boolean): HTMLIonLoadingElement {
		if (this.isLoaderPresented()) {
			if (typeof pbBackdropDismiss === "boolean")
				this.moLoader.backdropDismiss = pbBackdropDismiss;
			this.moLoader.setAttribute("message", psText);

			return this.moLoader;
		}
		return null;
	}

	/** Affiche un loader indiquant qu'un téléversement est en cours si on est en mode browser (téléversement live).
	 * ### Penser à appeler `dismiss()` pour supprimer le possible loader.
	 * @deprecated Ne devrait pas exister.
	 */
	public async presentUploadLoaderIfRequired(psText: string): Promise<void> {
		// Si on est pas sur mobile (mode browser), le téléversement des fichiers se fait en live,
		// on averti donc l'utilisateur qu'il faut attendre la fin des téléversements pour continuer.
		if (!this.isvcPlatform.isMobileApp)
			await this.present(psText);
	}

	/** Supprime tous les loaders qui sont instanciés.
	 * ### À utiliser avec parcimonie (cas d'erreurs graves notamment).
	 */
	public dismissAll(): void {
		const laLoadingElements: HTMLIonLoadingElement[] = Array.from(document.getElementsByTagName("ion-loading"));

		for (let lnIndex = laLoadingElements.length - 1; lnIndex >= 0; --lnIndex) {
			laLoadingElements[lnIndex].remove();
		}
	}

	//#endregion

}