import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CalaoAvatarModule } from '../modules/avatar/calao-avatar.module';
import { SpinnerModule } from '../modules/spinner';
import { VirtualScrollModule } from '../modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '../pipes/pipes.module';
import { FormlyConfig } from './forms/form/fields.config';
import { FormComponent } from './forms/form/form.component';
import { FormListComponent } from './forms/formList/formList.component';
import { LinkModule } from './link/link.module';
import { SearchModule } from './search/search.module';
import { ToolbarModule } from './toolbar/toolbar.module';

const modules: Array<Type<any>> = [
	IonicModule,
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	FormlyMaterialModule,
	PipeModule,
	ToolbarModule,
	SearchModule,
	CalaoAvatarModule,
	LinkModule,
	SpinnerModule,
	VirtualScrollModule
];
const components: Array<Type<any>> = [
	FormComponent,
	FormListComponent
];

@NgModule({
	imports: [...modules, FormlyModule.forRoot(FormlyConfig)],
	exports: [...modules, ...components],
	declarations: components
})
export class SharedComponentsModule { }