import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';

@NgModule({
	declarations: [ColorPickerComponent],
	exports: [ColorPickerComponent],
	imports: [
		CommonModule,
		IonicModule,
		ColorPickerModule
	]
})
export class ColorsModule { }
