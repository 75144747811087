import { DomainsUserSettings } from '../application/DomainsUserSettings';
import { IDomainSettings } from '../application/IDomainSettings';

export class WorkspaceFilter {

	//#region FIELDS

	public static readonly C_COMMON_DOMAIN_ID = "common";

	//#endregion

	//#region METHODS

	constructor(
		/** Id du workspace */
		public workspaceId: string,
		/** Etat d'affichage du workspace */
		public filter: any = undefined) { }

	public static fromDomainsUserSettings(psDomainId: string, psWorkspaceId: string, poDomainsUserSettings: DomainsUserSettings): WorkspaceFilter {
		const loFilter: WorkspaceFilter = WorkspaceFilter.tryCreateWorkspaceFilter(psDomainId, psWorkspaceId, poDomainsUserSettings);

		return !!loFilter ? loFilter : this.tryCreateWorkspaceFilter(WorkspaceFilter.C_COMMON_DOMAIN_ID, psWorkspaceId, poDomainsUserSettings);
	}

	private static tryCreateWorkspaceFilter(psDomainId: string, psWorkspaceId: string, poDomainsUserSettings: DomainsUserSettings): WorkspaceFilter {
		const loDomainSettings: IDomainSettings = poDomainsUserSettings[psDomainId] as IDomainSettings;

		return loDomainSettings?.workspacesFilter ?
			new WorkspaceFilter(psWorkspaceId, loDomainSettings.workspacesFilter[psWorkspaceId]) : null;
	}

	//#endregion
}