import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UnlockDeviceModalComponent } from './unlock-device-modal.component';

@NgModule({
	declarations: [UnlockDeviceModalComponent],
	imports: [
		FormsModule, ReactiveFormsModule, IonicModule
	],
})
export class UnlockDeviceModalModule { }
