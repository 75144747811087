import { Type } from '@angular/core';

/** Définition du type RouteComponent utilisé pour le routage. */
export class RouteComponent {

	//#region PROPERTIES

	/** Nom de la page, utilisé pour la recherche. */
	public id: string;
	/** Composant de la page. */
	public componentType: Type<any>;

	//#endregion

	//#region METHODS

	constructor(psId: string, poComponent: Type<any>) {
		this.id = psId;
		this.componentType = poComponent;
	}

	//#endregion
}