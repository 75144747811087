import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { tap } from 'rxjs/operators';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { PanneauService } from '../../../shared/services/panneau.service';

@Component({
	selector: 'di-recherche-patients',
	templateUrl: './recherche-patients.component.html',
	styleUrls: ['./recherche-patients.component.scss'],
})
export class RecherchePatientsComponent implements OnInit, OnDestroy {

	@Input()
	public displayCount = false;

	@Input()
	public mobileViewForce? = false;

	@Input()
	public patientOrdonnance = false;

	@Output()
	public onClickLigne = new EventEmitter<string>();

	public patients: IPatient[];
	public filteredPatients: IPatient[];

	public searchedValue: string = "";

	public patientsCount: number;
	public patientsCountMessage: string = "";

	public noResultMessage: string;

	constructor(
		public router: Router,
		private svcPatients: PatientsService,
		private svcPanneau: PanneauService,
		private svcLoader: LoaderService
	) { }

	ngOnDestroy(): void {
		this.svcLoader.hideLoader();
	}

	ngOnInit() {
		this.svcLoader.showLoader("Récupération des patients...")
		this.svcPatients.getSitePatients().pipe(
			tap((patients: IPatient[]) => {
				this.patients = this.mobileViewForce ? patients.filter(patient => !patient.deceased) : patients;
				this.filteredPatients = this.patients;
				this.getPatientsCount();
				this.svcLoader.hideLoader();
			})
		).subscribe();
	}

	onClickPatient(patientId: string) {
		if (this.onClickLigne.observers.length > 0) {
			this.onClickLigne.emit(patientId);
		} else {
			this.router.navigate(['/patients', patientId]);
		}
	}

	public filterPatients(newValue: string): void {
		this.searchedValue = newValue;

		if (this.searchedValue.length > 2) {
			this.filteredPatients = this.patients.filter(patient =>
				(patient.lastName && patient.lastName.toLowerCase().includes(this.searchedValue.toLowerCase())) ||
				(patient.firstName && patient.firstName.toLowerCase().includes(this.searchedValue.toLowerCase())) ||
				(patient.usualLastName && patient.usualLastName.toLowerCase().includes(this.searchedValue.toLowerCase())) ||
				(patient.socialNumber?.startsWith(this.searchedValue)) ||
				(DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash) === this.searchedValue) ||
				((patient.lastName ?? '') + ' ' + (patient.firstName ?? '')).toLowerCase().includes(this.searchedValue.toLowerCase()) ||
				((patient.usualLastName ?? '') + ' ' + (patient.firstName ?? '')).toLowerCase().includes(this.searchedValue.toLowerCase())
			);
		} else {
			this.filteredPatients = this.patients;
		}
		this.getPatientsCount();
	}

	public navigateToCreatePatient(): void {
		if (this.patientOrdonnance) {
			this.svcPanneau.close();
			this.router.navigate(['patients', 'add'], { state: { patientOrdonnance: true } })
		} else {
			this.router.navigate(['patients', 'add']);
		}

	}

	public getPatientsCount(): void {
		this.patientsCount = this.filteredPatients.length;
		this.patientsCountMessage = `${this.patientsCount > 0 ? this.patientsCount : "Aucun"} patient${this.patientsCount > 1 ? "s" : ""}  
    ${this.searchedValue.length > 0 ? (this.patientsCount > 1 ? "trouvés" : "trouvé") : ""}`
		if (this.searchedValue.length > 0) {
			this.noResultMessage = "Aucun patient trouvé"
		} else {
			this.noResultMessage = "Aucun patient"
		}
	}

}
