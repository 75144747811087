<div class="information-abattement">
	<p>Explication sur le fait que le {{index}}{{ index === 1 ? 'er' : 'ème' }} acte est à {{acte.taxAllowance*100}}%</p>
	<div class="ligne-honoraire">
		<small>Honoraire de l'acte</small>
		<small>{{ acte.initialPrice * acte.initialPriceCoefficient | priceFormat:2 }} €</small>
	</div>
	<div class="ligne-honoraire">
		<strong>Honoraire appliqué</strong>
		<strong>{{ acte.price !== 0 ? (acte.price | priceFormat:2) + ' €' : 'GRATUIT' }}</strong>
	</div>
	<div class="button-footer">
		<lua-bouton-texte libelle="Fermer" (click)="close()"></lua-bouton-texte>
	</div>
</div>