import { IGuidOptions } from '../model/IGuidOptions';
import { GUID_HELPER_CONFIG } from '../modules/guid/guid.module';
import { IGuidBuilder } from '../modules/guid/models/iguid-builder';
import { LongGuidBuilder } from '../modules/guid/models/long-guid-builder';
import { InjectorService } from '../services/injector.service';
import { StringHelper } from './stringHelper';

export class GuidHelper {

	//#region FIELDS

	private static moGuidBuilder: IGuidBuilder;
	private static get guidBuilder(): IGuidBuilder {
		try {
			if (!this.moGuidBuilder) {
				this.moGuidBuilder = new (InjectorService.instance.get(GUID_HELPER_CONFIG).builderType);
			}
		}
		catch (poError) {
			this.moGuidBuilder = new LongGuidBuilder;
		}
		finally {
			return this.moGuidBuilder;
		};
	}

	//#endregion

	//#region PROPERTIES

	/** Longueur d'un guid sans tiret. */
	public static readonly C_GUID_LENGTH_WITHOUT_HYPHENS = 32;
	/** Longueur d'un guid avec tirets. */
	public static readonly C_GUID_LENGTH_WITH_HYPHENS = 36;

	//#endregion

	//#region METHODS

	/** Extrait et retourne le Guid d'une chaîne de caractères.
	 * @param psTarget Chaîne de caractères dont il faut extraire le Guid.
	 */
	public static extractGuid(psTarget: string): string {
		return StringHelper.isBlank(psTarget) ? "" : psTarget.substring(psTarget.indexOf('_') + 1);
	}

	/** Vérifie que la chaîne de caractères passée en paramètre est un GUID valide ou non.
	 * @param psGuid Chaîne de caractère dont il faut vérifier si elle est un GUID valide ou non.
	 * @returns Booléen indiquant si le GUID est valide ou non.
	 */
	public static isValid(psGuid: string): boolean {
		return this.guidBuilder.isValid(psGuid);
	}

	/** Crée un GUID valide et le retourne sous forme de chaîne de caractères, sans tiret et en minuscule par défaut.
	 * @param poOptions Paramètre facultatif pour appliquer des règles au Guid qui sera créé.
	 * @returns Un GUID valide.
	 */
	public static newGuid(poOptions: IGuidOptions = {}): string {
		return this.guidBuilder.build(poOptions);
	}

	//#endregion

}