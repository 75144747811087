import {
	Component,
	Input,
	OnInit
} from "@angular/core";
import { IContact } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";

@Component({
	selector: "di-menu-selection-infirmier",
	templateUrl: "./menu-selection-infirmier.component.html",
	styleUrls: ["./menu-selection-infirmier.component.scss"],
})
export class MenuSelectionInfirmierComponent extends DestroyableComponentBase implements OnInit {

	@Input() onSelection: (contacts: IContact[]) => void;
	@Input() infirmiers: IContact[];

	constructor() {
		super();
	}
	ngOnInit(): void {
		this.infirmiers = this.infirmiers.map(infirmier => ({
			...infirmier,
			avatarColor: this.getAvatarColor(infirmier)
		}));	
	}

	getAvatarColor(infirmier: IContact) {
		return infirmier.avatarCouleur ?? 'CouleurPrimaire';
	}

	onSelectionItem(infirmier: IContact) {
		if (this.onSelection) {
			this.onSelection([infirmier]);
		}
	}

	afficherTous(): void {
		if (this.onSelection) {
			this.onSelection(this.infirmiers);
		}
	}
}
