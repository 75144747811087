import { Component, Input, OnInit } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern, IContact } from "@osapp/model";
import { ContactsService } from "@osapp/services";
import { ITagSoin } from "apps/idl/src/anakin/models/ITagSoin";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { EStatusSeance } from "apps/idl/src/model/EStatusSeance";
import { ITraitement } from "apps/idl/src/model/ITraitement";
import { IPatient } from "apps/idl/src/modules/patients/model/IPatient";
import { tap } from "rxjs/operators";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { DrawerPopoverService } from "../../services/drawer-popover.service";
import { MenuModifierSeanceComponent } from "../menu-modifier-seance/menu-modifier-seance.component";

@Component({
	selector: "di-carte-seance",
	templateUrl: "./carte-seance.component.html",
	styleUrls: ["./carte-seance.component.scss"],
})
export class CarteSeanceComponent implements OnInit {
	public title: string = "Description ordonnance";
	@Input() seance: StoredSeance;
	@Input() patient: IPatient;
	@Input() ordonnance: ITraitement;
	
	public infirmier: IContact;
	public commentaire: string = "";
	@Input() public tagsSoins: ITagSoin[];
	@Input() public tailleXs: boolean = false;
	public showPopover: boolean = false;
	public classes: any;
	public fullLastName :string;

	public completed = EStatusSeance.completed;

	public dateDeRealisation: string = "";


	public moment: string = "";

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcContact : ContactsService,
		private svcPatient : PatientsService
	) { }

	public getAdressePatient(pat: IPatient): string {
		if (!pat) return "";

		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}

		return addressParts.join(" ");
	}
	getTailleXsBody(): string {
		if (this.ordonnance?.description) {
			return this.ordonnance.description;
		}
		return this.seance.actes
			.map((acte) => acte.keyLetters + acte.priceCoefficient)
			.join(" + ");
	}

	

	public ngOnInit() {
		this.updateClasses();
		this.fullLastName = this.svcPatient.getFullNamePatient(this.patient); 
		if (this.seance) {
			this.commentaire = [
				this.seance.commentaireManuel,
				this.seance.commentaireIndisponibilitePatient
			]
			.filter(Boolean)
			.join("\n");
		}
		if(this.seance.status === EStatusSeance.done)
			this.dateDeRealisation = DateHelper.transform(this.seance.statusChangeDate, ETimetablePattern.dd_MM_yyyy_HH_mm_slash);
		if (this.seance && this.seance.infirmierId && !this.seance.infirmier) {
			this.svcContact.getContact(this.seance.infirmierId).pipe(
				tap((infirmier) => {
					this.infirmier = infirmier;
				}),
				).subscribe();
		}else{
			this.infirmier = this.seance.infirmier;
		}
		if(this.seance.moment){
			this.moment = StoredSeance.determineMoment(this.seance.moment)
		}
	}

	private updateClasses() {
		this.classes = {
			tailleXS: this.tailleXs,
		};
	}

	getAvatarColor() {
		return this.infirmier.avatarCouleur ?? 'CouleurPrimaire';
  }

	getClasses() {
		return this.classes;
	}

	public openMenu(event: Event): void {

		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"",
			"50%",
			event.currentTarget,
			MenuModifierSeanceComponent,
			{ seance: this.seance,
				patient : this.patient 
			},
			() => (this.showPopover = false)
		);
	}



	public openMenuRapide(event: Event,choix:string): void {

		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"",
			"50%",
			event.currentTarget,
			MenuModifierSeanceComponent,
			{ seance: this.seance,
				patient : this.patient,
				openChoix : choix },
			() => (this.showPopover = false)
		);
	}
}
