import {
	ComponentRef,
	Injectable
} from "@angular/core";
import { LoaderComponent } from "../components/loader/loader.component";

@Injectable({
	providedIn: "root",
})
export class LoaderService {
	private loaderComponentRef: ComponentRef<LoaderComponent>;


	constructor(
	) {}

	setLoaderComponentRef(componentRef: ComponentRef<LoaderComponent>) {
    this.loaderComponentRef = componentRef;
  }

	showLoader(loaderText?: string, color?: string, size?: number): void {
		if (this.loaderComponentRef) {


			size && this.loaderComponentRef.setInput("size", size);
			color && this.loaderComponentRef.setInput("color", color);
			loaderText && this.loaderComponentRef.setInput("loaderText", loaderText);
			this.loaderComponentRef.instance.open();

		}
	}

	hideLoader(): void {
		if (this.loaderComponentRef?.instance?.visible) {
      this.loaderComponentRef.instance.close();
    }
	}
}
