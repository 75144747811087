import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ComponentRef } from '@ionic/core';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { MapHelper } from '@osapp/helpers/mapHelper';
import { StoreDocumentHelper } from '@osapp/helpers/storeDocumentHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { UserHelper } from '@osapp/helpers/user.helper';
import { UserData } from '@osapp/model/application/UserData';
import { ActivePageManager } from '@osapp/model/navigation/ActivePageManager';
import { ERouteUrlPart } from '@osapp/model/route/ERouteUrlPart';
import { EDatabaseRole } from '@osapp/model/store/EDatabaseRole';
import { IDataSource } from '@osapp/model/store/IDataSource';
import { IStoreDataResponse } from '@osapp/model/store/IStoreDataResponse';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { EFlag } from '@osapp/modules/flags/models/EFlag';
import { Loader } from '@osapp/modules/loading/Loader';
import { LogAction } from '@osapp/modules/logger/decorators/log-action.decorator';
import { ELogActionId } from '@osapp/modules/logger/models/ELogActionId';
import { ILogSource } from '@osapp/modules/logger/models/ILogSource';
import { LogActionHandler } from '@osapp/modules/logger/models/log-action-handler';
import { LoggerService } from '@osapp/modules/logger/services/logger.service';
import { C_SECTORS_ROLE_ID } from '@osapp/modules/permissions/services/permissions.service';
import { ISector } from '@osapp/modules/sectors/models/isector';
import { IDataSourceRemoteChanges } from '@osapp/modules/store/model/IDataSourceRemoteChanges';
import { DestroyableServiceBase } from '@osapp/modules/utils/services/destroyable-service-base';
import { ApplicationService } from '@osapp/services/application.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { EntityLinkService } from '@osapp/services/entityLink.service';
import { FlagService } from '@osapp/services/flag.service';
import { GalleryService } from '@osapp/services/gallery.service';
import { GlobalDataService } from '@osapp/services/global-data.service';
import { GroupsService } from '@osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { ReadStatusService } from '@osapp/services/read-status.service';
import { Store } from '@osapp/services/store.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { EMPTY, Observable, defer, forkJoin, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UploadFileService } from '../../../anakin/features/shared/services/uploadFile.service';
import { C_PREFIX_PATIENT, C_PREFIX_RAPPORT } from '../../../app/app.constants';
import { IIdelizyContact } from '../../../model/IIdelizyContact';
import { ITraitement } from '../../../model/ITraitement';
import { EIdlLogActionId } from '../../logger/models/EIdlLogActionId';
import { ERapportType } from '../model/ERaportType';
import { IPatient } from '../model/IPatient';
import { IRapport } from '../model/IRapport';
import { ITransmissionModalParams } from '../model/ITransmissionModalParams';
import { ITransmissionPageParams } from '../model/ITransmissionPageParams';
import { ITransmissionRapport } from '../model/ITransmissionRapport';
import { PatientsService } from './patients.service';

@Injectable({ providedIn: "root" })
export class RapportService extends DestroyableServiceBase implements ILogSource {

	//#region FIELDS

	private readonly moActivePageManager: ActivePageManager;

	//#endregion

	//#region PROPERTIES

	/** Propriété "createdDate" d'un rapport (de type transmission ou non). */
	public static readonly C_CREATED_DATE_PROPERTY = "createdDate";
	public static readonly C_CREATE_TRANSMISSION_TITLE = "Création de transmission";
	public static readonly C_EDIT_TRANSMISSION_TITLE = "Édition de transmission";

	/** @implements */
	public readonly logSourceId = "IDL.RAP.S::";
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcStore: Store,
		private readonly isvcLoading: LoadingService,
		private readonly ioModalCtrl: ModalController,
		private readonly isvcContacts: ContactsService,
		private readonly isvcReadStatus: ReadStatusService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcFlag: FlagService,
		private readonly isvcGlobalData: GlobalDataService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcPatients: PatientsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcGroups: GroupsService,
		private svcUploadFile : UploadFileService
	) {
		super();
		this.moActivePageManager = new ActivePageManager(this, this.ioRouter, (psNewUrl: string) => psNewUrl.includes(ApplicationService.C_HOME_ROUTE_URL));
	}

	public initNumberOfNonCompletedTransmission(): void {
		this.isvcFlag.waitForFlag(EFlag.appAvailable, true)
			.pipe(
				mergeMap(_ => this.getTransmissions(undefined, this.moActivePageManager)),
				map((paTransmissions: ITransmissionRapport[]) => paTransmissions.filter((poTransmission: ITransmissionRapport) => !poTransmission.closeDate).length),
				tap((pnNumberOfNonCompletedTransmission: number) => this.isvcGlobalData.setData("nbOfNonCompletedTrans", pnNumberOfNonCompletedTransmission))
			)
			.subscribe();
	}

	/** Crée un rapport vierge.
	 * @param peType Type de rapport à créer.
	 * @param psAuthorDatabaseId Identifiant de la base de données où se trouve l'auteur.
	 */
	private createRapport(peType: ERapportType, psPatientId: string, psAuthorDatabaseId: string): IRapport {
		return {
			_id: IdHelper.buildChildId(C_PREFIX_RAPPORT, psPatientId),
			type: peType,
			createdDate: new Date(),
			authorPath: psAuthorDatabaseId
		};
	}

	//#region Transmission

	/** Crée un rapport de type transmission.
	 * @param poPatientModel Modèle du patient qu'il faut lier à la nouvelle transmission (obligatoire).
	 * @param paLinkedEntities Tableau des entités à lier à la nouvelle transmission, optionnel.
	 */
	@LogAction<Parameters<RapportService["createTransmissionRapport"]>, ReturnType<RapportService["createTransmissionRapport"]>>({
		actionId: EIdlLogActionId.transmissionCreate,
		successMessage: "Création d'une transmission.",
		errorMessage: "Echec de la création  d'une transmission.",
		dataBuilder: (_, __, poPatient: IPatient) => ({ userId: UserData.current?._id, patientId: poPatient._id })
	})
	public createTransmissionRapport(poPatientModel: IPatient, paLinkedEntities: ITraitement[] = []): ITransmissionRapport {
		const loNewTransmission: ITransmissionRapport = {
			...this.createRapport(ERapportType.transmission, poPatientModel._id, ContactsService.getCurrentWorkspaceUserPath(poPatientModel)),
			files: []
		};

		// Ajout des traitements liés.
		paLinkedEntities.forEach((poItem: ITraitement) => this.isvcEntityLink.cacheLinkToAdd(loNewTransmission, this.isvcEntityLink.buildEntity(poItem)));
		// On ajoute la même base de données où enregistrer la nouvelle transmission que celle du patient.
		StoreHelper.updateDocumentCacheData(loNewTransmission, { databaseId: StoreHelper.getDatabaseIdFromCacheData(poPatientModel) });

		return loNewTransmission;
	}

	/** Ouvre la page de transmission en mode 'visu'.
	 * @param poTransmission Transmission à afficher.
	 */
	public routeToTransmission(poTransmission: ITransmissionRapport): Promise<boolean> {
		const laUrlsParts: string[] = ["transmissions", poTransmission._id];

		// Si la transmission est clôturée, on navigue en mode readonly, sinon en mode edit.
		if (!poTransmission.closeDate)
			laUrlsParts.push("edit");

		return this.ioRouter.navigate(laUrlsParts);
	}

	/** Ouvre la page de création d'une nouvelle transmission et retourne.
	 * @param poPatientModel Modèle du patient qu'il faut lier à la transmission.
	 * @param paLinkedEntities Tableau des entités à lier à la nouvelle transmission, optionnel.
	 */
	public routeToNewTransmission(poPatientModel: IPatient, paLinkedEntities?: ITraitement[]): Promise<boolean> {
		const loExtras: NavigationExtras = {
			state: {
				patientModel: JSON.stringify(poPatientModel),
				linkedEntities: paLinkedEntities
			} as ITransmissionPageParams
		};

		return this.ioRouter.navigate(["transmissions", ERouteUrlPart.new], loExtras);
	}

	/** Ouvre la page de création d'une nouvelle transmission et retourne.
	 * @param poTransmissionModalComponent Composant modale de transmission à ouvrir.
	 * @param poModalParams Paramètres pour l'ouverture de la modale de transmission.
	 */
	public openTransmissionAsModal(poTransmissionModalComponent: ComponentRef, poModalParams: ITransmissionModalParams): Observable<boolean> {
		return from(this.ioModalCtrl.create({
			component: poTransmissionModalComponent,
			componentProps: poModalParams
		}))
			.pipe(
				mergeMap((poModal: HTMLIonModalElement) => poModal.present()),
				mapTo(true),
				catchError(poError => { console.error("IDL.RPT.S:: Erreur ouverture modale transmission :", poError); return of(false); })
			);
	}

	/** Enregistre dans la base de données la transmission.
	 * @param poTransmission Transmission à enregistrer en base de données.
	 */
	public saveTransmission(poTransmission: ITransmissionRapport): Observable<boolean> {
		if (StoreDocumentHelper.hasRevision(poTransmission) && !poTransmission.closeDate) { // Édition.
			return this.isvcUiMessage.showAsyncMessage<boolean>(
				new ShowMessageParamsPopup({
					message: "Voulez-vous clôturer cette transmission ?",
					header: "Clôture",
					buttons: [
						{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
						{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
					]
				})
			)
				.pipe(
					tap((poCloseTransmisisonResult: IUiResponse<boolean>) => {
						if (poCloseTransmisisonResult.response) { // Clôture.
							poTransmission.closeDate = new Date();
							poTransmission.closeUserId = UserHelper.getUserContactId();
							this.isvcLogger.action(this.logSourceId, "Clôture d'une transmission.", EIdlLogActionId.transmissionClose as unknown as ELogActionId, { userId: UserData.current?._id, transmissionId: poTransmission._id });
						}
					}),
					mergeMap(_ => this.innerSaveTransmission(poTransmission))
				);
		}
		else // Création.
			return this.innerSaveTransmission(poTransmission);
	}

	/** Enregistre dans la base de données la transmission.
	 * @param poTransmission Transmission à enregistrer en base de données.
	 */
	private innerSaveTransmission(poTransmission: ITransmissionRapport): Observable<boolean> {
		const lsSaveMessage = "Enregistrement de la transmission en cours ...";

		return defer(() => {
			// Téléversement live si pas sur app mobile et fichiers à téléverser.
			const lsText = (this.isvcPlatform.isMobileApp || !ArrayHelper.hasElements(poTransmission.files)) ?
				lsSaveMessage : "Téléversement des fichiers joints à la transmission ...";
			return this.isvcLoading.create(lsText);
		})
			.pipe(
				mergeMap((poLoader: Loader) => {
					return from(poLoader.present())
						.pipe(
							mergeMap(_ => this.isvcGallery.saveFiles(poTransmission.files)),
							tap(_ => poLoader.text = lsSaveMessage),
							// Enregistrement des liens avant la transmission pour récupérer ceux-ci une fois la transmission enregistrée (getLive),
							// sinon, on ne peut pas récupérer le nom du patient lié à la transmission car le lien n'est pas encore créé.
							mergeMap(_ => this.isvcEntityLink.saveEntityLinks(poTransmission)),
							mergeMap(_ => this.isvcStore.put(poTransmission)),
							mergeMap(_ => this.isvcReadStatus.markAsRead(poTransmission)),
							tap(_ => console.debug("IDL.TRANS.S:: Transmission enregistrée.")),
							finalize(() => poLoader.dismiss())
						);
				})
			);
	}

	


	public saveTransmissionANAKIN(transmission: ITransmissionRapport): Observable<boolean> {
    this.cleanTransmission(transmission);

    // Appel à la nouvelle méthode pour sauvegarder les fichiers de la transmission avec gestion d'erreur
    const saveTransmissionFiles$ = this.svcUploadFile.saveFilesWithErrorHandler(transmission.files, transmission._id).pipe(
			catchError(e => EMPTY));

    // Enregistrement des fichiers de chaque commentaire avec gestion d'erreur via la nouvelle méthode
    let saveCommentFiles$: Observable<any> = of(null);
    if (ArrayHelper.hasElements(transmission.commentaires)) {
        const saveCommentFilesArray$: Observable<any>[] = transmission.commentaires.map(comment =>
            this.svcUploadFile.saveFilesWithErrorHandler(comment.files, transmission._id).pipe(
							catchError(e => EMPTY))
        );
        saveCommentFiles$ = forkJoin(saveCommentFilesArray$);
    }

    // Chaine des opérations avec propagation des erreurs
    return saveTransmissionFiles$.pipe(
        mergeMap(() => saveCommentFiles$),
        mergeMap(() => this.isvcEntityLink.saveEntityLinks(transmission)),
        mergeMap(() => this.isvcStore.put(transmission)),
        mergeMap(() => this.isvcReadStatus.markAsRead(transmission)),
        map(() => true)
    );
}

	private cleanTransmission(transmission: ITransmissionRapport) {
		//TODO l'idéal serait d'avoir une classe TranmissionRapport avec le tag @Exclude sur ces champs
		//Permet de supprimer les objets que l'on ne veut pas save en bdd
		delete transmission.author;
		delete transmission.patient;
		if (ArrayHelper.hasElements(transmission.commentaires)) {
			transmission.commentaires.forEach(x => delete x.auteur);
		}
	}


	/** Retourne les mises à jour d'une transmission spécifique
	 * (le premier résultat correspond à la récupération de cette même transmission (`startWith` oblige)).
	 * @param poTransmission Transmission dont on veut recevoir les mises à jour.
	 */
	public getTransmissionUpdates(poTransmission: ITransmissionRapport): Observable<ITransmissionRapport> {
		const loDataSource: IDataSource = {
			databaseId: StoreHelper.getDatabaseIdFromCacheData(poTransmission),
			live: true,
			viewParams: {
				include_docs: true,
				key: poTransmission._id
			}
		};

		return this.isvcStore.get<ITransmissionRapport>(loDataSource)
			.pipe(
				map((paResults: ITransmissionRapport[]) =>
					paResults.find((poTransmissionResult: ITransmissionRapport) => poTransmissionResult._id === poTransmission._id)
				),
				filter((poTransmissionResult: ITransmissionRapport) => !!poTransmissionResult)
			);
	}

	/** Supprime une transmisison ainsi que les liens qui lui sont associés.
	 * @param poTransmission Transmission à supprimer.
	 */
	public deleteTransmission(poTransmission: ITransmissionRapport): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poTransmission)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => this.isvcEntityLink.deleteEntityLinksById(poTransmission._id)),
				mergeMap(_ => this.isvcStore.delete(poTransmission)),
				map((poResult: IStoreDataResponse) => poResult.ok)
			);
	}

	/** Récupère tous les rapports de type transmission de façon continue, récupère celles liées à un patient spécifique si renseigné.
	 * @param psPatientId Id du patient dont on veut récupérer les transmissions s'il est renseigné.
	 * @param poActivePageManager
	 */
	public getTransmissions(psPatientId: string = "", poActivePageManager?: ActivePageManager, live?: boolean): Observable<ITransmissionRapport[]> {
		const loDataSource: IDataSourceRemoteChanges = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: `${C_PREFIX_RAPPORT}${psPatientId}`,
				endkey: `${C_PREFIX_RAPPORT}${psPatientId}${Store.C_ANYTHING_CODE_ASCII}`,
				conflicts: true
			},
			live: live,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			filter: (poRapport: IRapport) => poRapport.type === ERapportType.transmission
		};

		return this.isvcStore.get<ITransmissionRapport>(loDataSource)
			.pipe(
				distinctUntilChanged((paOldResults: ITransmissionRapport[], paNewResults: ITransmissionRapport[]) =>
					ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults)
				),
				switchMap((paTransmissions: ITransmissionRapport[]) => {
					return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID]).pipe(
						mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContactsIds(paSectors, C_PREFIX_PATIENT)),
						map((paSitePatientIds: string[]) => paTransmissions.filter((poTransmission: ITransmissionRapport) => paSitePatientIds.some((psSitePatientId: string) => poTransmission._id.includes(psSitePatientId))))
					);
				})
			);

	}

	public getTransmissionsAnakin(live?: boolean): Observable<ITransmissionRapport[]> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: `${C_PREFIX_RAPPORT}`,
				endkey: `${C_PREFIX_RAPPORT}${Store.C_ANYTHING_CODE_ASCII}`,
				conflicts: true
			},
			live: live,
			filter: (poRapport: IRapport) => poRapport.type === ERapportType.transmission
		};

		return this.isvcStore.get<ITransmissionRapport>(loDataSource)
			.pipe(
				distinctUntilChanged((paOldResults: ITransmissionRapport[], paNewResults: ITransmissionRapport[]) =>
					ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults)
				),
				switchMap((paTransmissions: ITransmissionRapport[]) => {
					return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID]).pipe(
						mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContactsIds(paSectors, C_PREFIX_PATIENT)),
						map((paSitePatientIds: string[]) => paTransmissions.filter((poTransmission: ITransmissionRapport) => paSitePatientIds.some((psSitePatientId: string) => poTransmission._id.includes(psSitePatientId))))
					);
				})
			);

	}

	public getTransmissionsAnakinView(live?: boolean): Observable<ITransmissionRapport[]> {
		const dataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewName: "transmissions/by_type",
			viewParams: {
				key: ERapportType.transmission
			},
			live: live
		};
		return this.isvcStore.get<ITransmissionRapport>(dataSource).pipe(
			distinctUntilChanged((paOldResults: ITransmissionRapport[], paNewResults: ITransmissionRapport[]) =>
				ArrayHelper.areArraysFromDatabaseEqual(paOldResults, paNewResults)
			),
			switchMap((paTransmissions: ITransmissionRapport[]) => {
				return this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID]).pipe(
					mergeMap((paSectors: ISector[]) => this.isvcContacts.getSiteContactsIds(paSectors, C_PREFIX_PATIENT)),
					map((paSitePatientIds: string[]) => paTransmissions.filter((poTransmission: ITransmissionRapport) => paSitePatientIds.some((psSitePatientId: string) => poTransmission._id.includes(psSitePatientId))))
				);
			})
		);
	}

	public getTransmissionByIdAnakin(id: string): Observable<ITransmissionRapport> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				key: id,
				conflicts: true
			},
			filter: (poRapport: IRapport) => poRapport.type === ERapportType.transmission
		};

		return this.isvcStore.getOne<ITransmissionRapport>(loDataSource)
	}

	/** Récupère les patients liés aux transmissions et les indexe par identifiant de transmission.
	 * @param paTransmissions Liste des transmissions.
	 * @param pbLive Indique s'il faut récupérer de façon continue les changements ou non.
	 */
	public getPatientByTransmissionsIds(paTransmissions: ITransmissionRapport[], pbLive?: boolean): Observable<Map<string, IPatient>> {
		const loPatientIdByTransmissionIds = new Map<string, string>();
		const loPatientByIds = new Map<string, IPatient>();

		paTransmissions
			.map((poTransmission: ITransmissionRapport) => poTransmission._id)
			.forEach((psTransmissionId: string) => loPatientIdByTransmissionIds.set(psTransmissionId, IdHelper.extractParentId(psTransmissionId)));

		return this.isvcPatients.getPatientsByIds(ArrayHelper.unique(MapHelper.valuesToArray(loPatientIdByTransmissionIds)), pbLive)
			.pipe(
				tap((paPatients: IPatient[]) => paPatients.forEach((poPatient: IPatient) => loPatientByIds.set(poPatient._id, poPatient))),
				map(() => {
					const loPatientByTransmissionIds = new Map<string, IPatient>();
					loPatientIdByTransmissionIds.forEach((psPatientId: string, psSurveillancesId: string) => loPatientByTransmissionIds.set(psSurveillancesId, loPatientByIds.get(psPatientId)));
					return loPatientByTransmissionIds;
				})
			);
	}

	/** Récupère le tableau des contacts qui ont clôturé une transmission.
	 * @param paData Tableau des identifiants des contacts ayant clôturer une transmission ou tableau des rapports de type transmission.
	 */
	public getCloseUsers(paData: string[] | ITransmissionRapport[]): Observable<IIdelizyContact[]> {
		if (!ArrayHelper.hasElements(paData as string[]))
			return of([]);
		else {
			const loFirstElement: string | ITransmissionRapport = ArrayHelper.getFirstElement(paData as string[]);
			let laCloseUserIds: string[];

			if (typeof loFirstElement === "string") // Cas d'un tableau d'identifiants.
				laCloseUserIds = ArrayHelper.unique(paData as string[]);

			else if ((loFirstElement as ITransmissionRapport).type) { // Cas d'un tableau de transmissions.
				laCloseUserIds = (paData as ITransmissionRapport[])
					.filter((poItem: ITransmissionRapport) => !StringHelper.isBlank(poItem.closeUserId))
					.map((poItem: ITransmissionRapport) => poItem.closeUserId);
			}

			return this.isvcContacts.getContactsByIds(ArrayHelper.unique(laCloseUserIds));
		}
	}

	//#endregion

	//#endregion
}
