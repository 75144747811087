<ion-button (click)="showSyncStatusModal()">
	<ng-container *ngIf="iconName !== 'sync-loading-circle' else animatedIcon">
		<ion-icon slot="icon-only" [name]="iconName" class="icon-synchro">
		</ion-icon>
	</ng-container>

	<ng-template #animatedIcon>
		<div class="icon-synchro">
			<calao-database-sync-animated-icon className="toolbar-icon"></calao-database-sync-animated-icon>
		</div>
	</ng-template>
</ion-button>