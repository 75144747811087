export enum EPrefix {
	unknown = "none",
	activity = "act_",
	calendar = "cal",
	component = "comp_",
	contact = "cont_",
	conversation = "conv_",
	conversationMeta = "convMeta_",
	conversationStatus = "convStatus_",
	/** Document représentant un document dms local. */
	dms = "doc_",
	/** Document représentant un document dms sur le serveur. */
	dmsDoc = "dmsDoc_",
	event = "evt_",
	evenement = "event_",
	group = "grp_",
	link = "lnk_",
	local = "_local/",
	message = "msg_",
	pending = "pen_",
	pendingDelete = "pen_del_",
	pendingDownload = "pen_dl_",
	pendingUpload = "pen_ul_",
	purchase = "pur_",
	task = "task_",
	user = "usr_",
	userSettings = "uset_",
	workspace = "ws_",
	readStatus = "readStatus_",
	formDesc = "formDesc_",
	favorites = "favorites_",
	recents = "recents_",
	sync = "sync_",
	gdpr = "gdpr_",
	customer = "cust_",
	integrity = "intg_",
	site = "site_",
	prestation = "presta_",
	observation = "obs_",
	regleRetrocession = "retro_",
	accordPrealable = "ap_",
	amo = "amop_",
	amc = "amcp_",
	patient = "pat_",
	rapport = "report_",
	seance = "seance_",
	historique = "history_",


	//#region Logistic

	/** Préfixe d'un conditionnement. */
	pack = "pack_",
	/** Préfixe d'un portant : `rack_` */
	rack = "rack_",
	/** Préfixe d'un document de bon de reprise sans le préfixe de tournée : `rct_`. */
	receipt = "rct_",
	/** Prefixe d'un document d'un rendez-vous */
	appoint = "appoint_",
	/** Prefixe d'un document de tournée. */
	tour = "tour_",
	/** Préfixe d'un motif. */
	reason = "reason_"

	//#endregion
}