import { Component, ElementRef, OnInit } from '@angular/core';
import { ArrayHelper, DateHelper, UserHelper } from '@osapp/helpers';
import { EPrefix, IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { Subject, forkJoin, merge } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSelectionInfirmierComponent } from '../../features/shared/components/menu-selection-infirmier/menu-selection-infirmier.component';
import { DeviceService } from '../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { EvenementService } from '../../features/shared/services/evenement.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { SeanceService } from '../../features/shared/services/seance.service';
import { Evenement } from '../../models/Evenement';
import { StoredSeance } from '../../models/StoredSeance';

@Component({
  selector: 'di-tournees',
  templateUrl: './tournees.page.html',
  styleUrls: ['./tournees.page.scss'],
})
export class TourneesPage extends DestroyableComponentBase implements OnInit {
  public tourneesfilters: Array<string> = ["Liste", "Agenda"];
  public defaultFilter = this.tourneesfilters[0];
  public isMobile: boolean = false;
  public nbRdvPatient: number = 0;
  public totalPrevu: number = 0;
  public today: Date = new Date();
  public seances: StoredSeance[] = [];
  public filteredSeances: StoredSeance[] = [];
  public events: Evenement[] = [];
  public filteredEvents: Evenement[] = [];
  public infirmierSelected: IContact[];

  public infirmiersWS: IContact[];
  public libelleInfirmier: string;

  public dateSelected: Date;
  private dateSelectedSubject = new Subject<Date>();

  public ordonnanceIdsTab: string[] = [];
  public patientIdsTab: string[];
  public ordonnances: Map<string, Traitement> = new Map();
  public patients: Map<string, IPatient>;


  constructor(
    private svcDevice: DeviceService,
    private svcSeance: SeanceService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcContact: ContactsService,
    private svcLoader: LoaderService,
    private svcEvenement: EvenementService,
    private el: ElementRef,
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService
  ) { super(); }

  ngOnInit() {
    this.initDateSelected();
    this.initInfirmiers();

    // Listen for mobile changes
    this.svcDevice.isMobile$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((flag: boolean) => {
      this.isMobile = flag;
    });

    merge(
      this.svcSeance.refreshSeanceList$,
      this.svcEvenement.refreshEventList$
    ).pipe(
      takeUntil(this.destroyed$),
      switchMap(() => this.fetchData(this.dateSelected))
    ).subscribe();

    this.dateSelectedSubject.pipe(
      tap(() => this.svcLoader.showLoader()),
      takeUntil(this.destroyed$),
      switchMap((date: Date) => {
        this.dateSelected = date;
        return this.fetchData(date);
      }),
      tap(() => this.svcLoader.hideLoader())
    ).subscribe();
  }

  private fetchData(date: Date) {
    return forkJoin([
      this.svcSeance.selectSeancesByDate(date),
      this.svcEvenement.selectEventsByDate(date)
    ]).pipe(
      tap(([seances, events]: [StoredSeance[], Evenement[]]) => {
        this.seances = seances;
        this.events = events;
        this.getOrdonnanceIdsAndPatientId();
      }),
      switchMap(() => forkJoin([
        this.svcTraitement.getTraitementByIds(this.ordonnanceIdsTab),
        this.svcPatient.getPatientsByIds(this.patientIdsTab)
      ])),
      tap(([ordonnances, patients]: [Traitement[], IPatient[]]) => {
        const ordonnanceMap = new Map(ordonnances.map(ordonnance => [ordonnance._id, ordonnance]));
        const patientMap = new Map(patients.map(patient => [patient._id, patient]));

        this.seances = this.seances.map((seance: StoredSeance) => {
          seance.ordonnance = ordonnanceMap.get(seance.traitementId);
          seance.patient = patientMap.get(seance.patientId);
          return seance;
        });
        this.filterSeancesAndEventsByInfirmier();
      })
    );
  }

  private filterSeancesAndEventsByInfirmier(){
    const infirmierIds = this.infirmierSelected.map(infirmier => infirmier._id);
    this.filteredSeances = this.seances.filter((seance: StoredSeance) => !seance.infirmierId || infirmierIds.includes(seance.infirmierId));
    this.filteredSeances = this.filteredSeances.map((seance: StoredSeance) => {
      seance.infirmier = this.infirmiersWS.find((infirmier: IContact) => infirmier._id === seance.infirmierId)
      return seance;
    })
    this.filteredEvents = this.events.filter((event: Evenement) => !event.infirmierId || infirmierIds.includes(event.infirmierId));
    this.filteredEvents = this.filteredEvents.map((event: Evenement) => {
      event.infirmier = this.infirmiersWS.find((infirmier: IContact) => infirmier._id === event.infirmierId)
      return event;
    })
    this.nbRdvPatient = this.filteredSeances.length;
		this.totalPrevu = this.filteredSeances.reduce((total, seance) => {
			// Somme des prix des actes
			const acteTotal = seance.actes ? seance.actes.reduce((acc, acte) => acc + (acte.price || 0), 0): 0;
			// Somme de toutes les majorations de la séance
			const majorationTotal = seance.majorations ? seance.majorations.reduce((acc, majoration) => acc + (majoration.price || 0), 0) : 0;
			// Somme de toutes les indemnités de la séance
			const indemniteTotal = seance.indemnites ? seance.indemnites.reduce((acc, indemnite) => acc + (indemnite.price || 0), 0) : 0;
			// Ajouter le total de cette séance au total général
			return total + acteTotal + majorationTotal + indemniteTotal;
		}, 0);  
	}

  handleClickToday() {
    this.dateSelectedSubject.next(this.today);
  }

  openMenuSelectionInfirmier(event: Event) {
    this.svcDrawerPopover.open(
      "",
      "400px",
      event.currentTarget,
      MenuSelectionInfirmierComponent,
      {
        infirmiers: this.infirmiersWS,
        onSelection: this.selectionNewInfirmier
      }
    );
  }

  selectionNewInfirmier = (infirmiers: IContact[]): void => {
    this.infirmierSelected = infirmiers;
    this.svcDrawerPopover.close();
    this.filterSeancesAndEventsByInfirmier();
    if (infirmiers.length == 1) {
      this.libelleInfirmier = `${ArrayHelper.getFirstElement(this.infirmierSelected).firstName} ${ArrayHelper.getFirstElement(this.infirmierSelected).lastName}`;
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--${ArrayHelper.getFirstElement(this.infirmierSelected).avatarCouleur ?? 'CouleurPrimaire'})`
      );
    }
    else {
      this.libelleInfirmier = 'Tous les infirmiers';
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--CouleurPrimaire)`
      );
    }
  }

  previousDay() {
    const newDate = DateHelper.addDays(this.dateSelected, -1);
    this.dateSelectedSubject.next(newDate);
  }

  nextDay() {
    const newDate = DateHelper.addDays(this.dateSelected, 1);
    this.dateSelectedSubject.next(newDate);
  }

  changeFilter(filtre: string) {
    this.defaultFilter = filtre;
    //il faut changer de vue et recharger toutes les séances d'une semaine
  }

  private initDateSelected() {
    this.dateSelected = this.today;
  }

  private initInfirmiers() {
    this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)
      .pipe(
        map((contacts: IContact[]) => contacts.filter((contact: IContact) => contact.userId)),
        tap((filteredContacts: IContact[]) => {
          // On stocke tous les infirmiers du workspace
          this.infirmiersWS = filteredContacts;

          // On présélectionne l'infimier connecté
          this.infirmierSelected = filteredContacts.filter((contact: IContact) => contact._id === UserHelper.getUserContactId())
          const firstInfirmier = ArrayHelper.getFirstElement(this.infirmierSelected)
          this.libelleInfirmier = `${firstInfirmier.firstName} ${firstInfirmier.lastName}`;
          this.el.nativeElement.style.setProperty(
            '--my-color',
            `var(--${firstInfirmier.avatarCouleur ?? 'CouleurPrimaire'})`
          );
          this.dateSelectedSubject.next(this.dateSelected); 
        }),
        takeUntil(this.destroyed$)
      ).subscribe();
  }

  private getOrdonnanceIdsAndPatientId() {
    const ordonnanceIds = new Set<string>();
    const patientIds = new Set<string>();

    this.seances.forEach(seance => {
      if (seance.traitementId) {
        ordonnanceIds.add(seance.traitementId);
      }

      if (seance.patientId) {
        patientIds.add(seance.patientId);
      }
    });

    if (patientIds.size > 0) {
      this.patientIdsTab = Array.from(patientIds);
    }

    if (ordonnanceIds.size > 0) {
      this.ordonnanceIdsTab = Array.from(ordonnanceIds);
    }
    return Array.from(ordonnanceIds);
  }

  private createOrdonnanceMap(ordonnances: Traitement[]) {
    const ordonnanceMap = new Map<string, Traitement>();
    ordonnances.forEach(ordonnance => {
      ordonnanceMap.set(ordonnance._id, ordonnance); 
    });
    this.ordonnances = ordonnanceMap;
  }

  private createPatientMap(patients: IPatient[]) {
    const patientMap = new Map<string, IPatient>();
    patients.forEach(pat => {
      patientMap.set(pat._id, pat); 
    });
    this.patients = patientMap;
  }
}
