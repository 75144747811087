import { OSActionButton, OSNotificationOpenedResult } from '@awesome-cordova-plugins/onesignal/ngx';
import { capitalize } from 'lodash';
import { OSNotification } from 'onesignal-cordova-plugin/types/Notification';
import { ETaskPrefix } from '../model/backgroundTask/ETaskPrefix';

export abstract class StringHelper {

	//#region METHODS

	/** Vérifie qu'une chaîne de caractère est valide : ne doit pas être vide ou composée uniquement d'espaces.
	 * @param psString chaîne de caractères dont il faut vérifier si elle est valide ou non.
	 */
	public static isBlank(psString: string): boolean {
		return !psString || psString.trim().length === 0; // Remplacement des caractères d'espace par rien.
	}

	/** Passe la 1ere lettre de la chaîne de caractère en majuscule.
	 * @param psString
	 */
	public static toPascalCase(psString: string): string {
		return `${psString[0].toUpperCase()}${psString.substring(1)}`;
	}

	/** Passe la 1ere lettre de la chaîne de caractère en majuscule et les autres en minuscules.
	 * @param psString
	 */
	public static toTitleCase(psString: string): string {
		return capitalize(psString);
	}

	/** Génère une chaîne de caractère contenant les champs de l'objet dont les nom sont données, en les séparant avec le séparateur indiqué.
	 * @param poObect
	 * @param paAttributeNames
	 * @param psSeparator
	 */
	public static concatenateAttributes(poObect: any, paAttributeNames: string[], psSeparator: string): string {
		let lsResult = "";

		paAttributeNames.forEach((psKey: string) => lsResult += `${StringHelper.isBlank(poObect[psKey]) ? "" : poObect[psKey]}${psSeparator}`);

		return lsResult.trim();
	}

	/** Retourne un Id de taskDescriptor pour les tâches des notifications.\
	 * On recherche l'id contenu dans le ActionID, sinon on recherche un _id dans les données additionnelles, sinon on met un texte représentant aucun ID.
	 * @param peTaskPrefix Préfixe de la tâche.
	 * @param psTaskName Nom donné à la tâche.
	 * @param poNotification Information retournée par une notification quand on clique dessus.
	 */
	public static getFormatedRouteTaskDescriptor(peTaskPrefix: ETaskPrefix, psTaskName: string, poNotification: OSNotificationOpenedResult) {
		const lsID: string = poNotification?.action?.actionID ?
			poNotification.action.actionID : ((poNotification?.notification as OSNotification)?.actionButtons[0] as OSActionButton) ?
				((poNotification.notification as OSNotification)?.actionButtons[0] as OSActionButton)?.id : "NO_ID";

		return `${peTaskPrefix}${psTaskName}${lsID}`;
	}

	/** Retourne `true` si la donnée est une chaîne de carctères non vide, `false` sinon.
	 * @param poData Donnée dont il faut vérifier si elle est uns string valide ou non.
	 */
	public static isValid(poData: any): boolean {
		return typeof poData === "string" && !this.isBlank(poData);
	}

	/** Format une chaîne de caractères en remplaçant des chaînes de caractères par d'autres.
	 * @param psTarget Chaîne de caractères de base, qui doit être retourné avec des transformations.
	 * @param pmapReplacer Dictionnaire où les clefs sont les sous-chaîne de `psTarget` à substituer et les valeurs, les chaînes de substitution.
	 * @example StringHelper.format("Je suis {prenom} {nom}", new Map([["{nom}", ["MACHIN"]], ["{prenom}", ["Toto"]]])); // Retourne "Je suis Toto MACHIN".
	 */
	public static format(psTarget: string, pmapReplacer: Map<string, string>): string {
		const loRegex = new RegExp(Array.from(pmapReplacer.keys()).join("|"), "gi");
		return psTarget.replace(loRegex, (psMatchedKey: string) => pmapReplacer.get(psMatchedKey));
	}

	/** Retourne un `string` représentant les queries string de l'objet passé en paramètre.
	 * @param poQueries Objet clé-valeur.
	 * @example StringHelper.toQueryString({ "val1": "Tr uc", "val2": "machin"}); // Retourne "val1=Tr%20uc&val2=machin".
	 */
	public static toQueryString(poQueries: Object): string {
		return Object.keys(poQueries)
			.map((poKey) => `${poKey}=${encodeURIComponent(`${poQueries[poKey]}`.replace(/\s/g, '_'))}`)
			.join('&');
	}

	/** Convertit les queryParams d'une URL sous forme d'un objet.
	 * @param psUrl L'url.
	 */
	public static parseQueryParams(psUrl: string): any {
		const laQueryParams: string[] = psUrl.substring(psUrl.indexOf("?") + 1).split("&");
		const loResult: any = {};

		laQueryParams.forEach((lsParam: string) => {
			const [key, value] = lsParam.split("=");
			loResult[key] = value;
		});

		return loResult;
	}

	public static normalizeString(value: string): string {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
	}

	//#endregion
}
