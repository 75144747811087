import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IonDatetime } from '@ionic/angular';
import { BottomSheetComponentBase } from '../../../modules/bottom-sheet/models/BottomSheetComponentBase';
import { PlatformService } from '../../../services/platform.service';
import { ITimePickerBottomSheetParams } from '../models/itime-picker-bottom-sheet-params';

@Component({
	selector: 'calao-mobile-time-picker',
	templateUrl: './mobile-time-picker.component.html',
	styleUrls: ['./mobile-time-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTimePickerComponent extends BottomSheetComponentBase<ITimePickerBottomSheetParams, string> {

	//#region PROPERTIES

	public initialized: boolean;

	//#endregion

	//#region METHODS

	constructor(
		poMatBottomSheet: MatBottomSheet,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef,
		@Inject(MAT_BOTTOM_SHEET_DATA) poParams: ITimePickerBottomSheetParams
	) {
		super(poMatBottomSheet, psvcPlatform, poChangeDetector, poParams);
		setTimeout(() => { // Corrige un bug de valeur par défaut après plusieurs appels
			this.initialized = true;
			this.detectChanges();
		}, 1)
	}

	public async onTimeChanged(poDateTime: IonDatetime): Promise<void> {
		await poDateTime.confirm();
		this.close(poDateTime.value as string);
	}

	//#endregion

}