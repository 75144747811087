import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InterventionStatementService } from './intervention-statement.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [InterventionStatementService]
})
export class InterventionStatementModule { }
