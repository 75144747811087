import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {

	/** Retourne une copie superficielle inversée d'un tableau. Exemple : [1,2,3] devient [3,2,1]
	 * @param paValue Le tableau à inverser.
	 */
	public transform(paValue: any[]) {
		return Array.from(paValue).reverse();
	}
}