export enum EBarElementAction {
  add = 0,
  clear,
  hide,
  init,
  option,
  reset,
  show,
  save,
  restore
}