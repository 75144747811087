/** Helper pour les API osapp. */
export abstract class OsappApiHelper {
	//#region METHODS

	/** Permet de supprimer les accents avant de mettre la chaine en header car le service worker supprime le header si il contient des accents.
	 * @param poObject 
	 * @returns Objet stringifié sans accent
	 */
	public static stringifyForHeaders(poObject: any): string {
		return JSON.stringify(poObject)?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ?? ""; //https://stackoverflow.com/a/37511463
	}

	//#endregion
}
