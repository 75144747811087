import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { CameraService } from './services/camera.service';


@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		CalaoAvatarModule
	],
	providers: [
		CameraService
	]
})
export class CameraModule { }
