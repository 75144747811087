import { Pipe, PipeTransform } from '@angular/core';
import { ConvertHelper } from '../helpers/convertHelper';
import { EnumHelper } from '../helpers/enumHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { EByteToUnit } from '../model/helper/EByteToUnits';

@Pipe({ name: "byte" })
export class BytePipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Retourne le nombre d'octets passé en paramètre dans une mesure de donnée compréhensible par l'utilisateur, '0.00 o' sinon.
	 * @param pnByte Poids des fichiers en octets.
	 * @param peByteToUnit Paramètre optionnel permettant de spécifier directement l'unité de mesure à utiliser.
	 */
	public transform(pnByte: number, peByteToUnit?: EByteToUnit): string {
		if (!NumberHelper.isValid(pnByte) || pnByte === 0)
			return this.getResult(0, EByteToUnit.o);

		else if (peByteToUnit)
			return this.getResult(ConvertHelper.byteToUnit(pnByte, EByteToUnit[peByteToUnit]), EByteToUnit[peByteToUnit]);

		else {
			/** Tableau répertoriant les clés des unités de mesure. */
			const laUnitKeys: string[] = EnumHelper.getKeys(EByteToUnit);
			/** Clé d'une unité de mesure. */
			let lsUnitKey: string;
			/** Poids total des fichiers dans l'unité de mesure la plus adaptée. */
			let lnByte: number = pnByte;

			for (let lnIndex = 0; lnByte > 1024; ++lnIndex) {
				lsUnitKey = laUnitKeys[lnIndex];
				lnByte = ConvertHelper.byteToUnit(pnByte, EByteToUnit[lsUnitKey]);
			}

			return this.getResult(lnByte, EByteToUnit[lsUnitKey]);
		}
	}

	private getResult(pnByte: number, peUnit: EByteToUnit): string {
		return `${pnByte.toFixed(2)} ${peUnit}`;
	}

	//#endregion

}
