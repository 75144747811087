import { ObjectHelper } from '../../../../helpers/objectHelper';

export class ResetDatabasesError extends Error {

	constructor(psErrorMessage: string) {
		super(psErrorMessage);
		// Nécessaire pour que le 'instanceof ResetDatabasesError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ResetDatabasesError);
	}

}