import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ContactSelectorItemBase } from '../../models/contact-selector/contact-selector-item-base';

@Component({
	selector: "osapp-contact-selector-item",
	templateUrl: './contact-selector-item.component.html',
	styleUrls: ['./contact-selector-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSelectorItemComponent extends ContactSelectorItemBase {

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}