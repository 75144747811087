import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarsComponent } from './components/avatars/avatars.component';
import { AvatarSanitizerPipe } from './pipes/avatar-sanitizer.pipe';

@NgModule({
	imports: [IonicModule, CommonModule],
	exports: [AvatarsComponent, AvatarComponent, AvatarSanitizerPipe],
	declarations: [AvatarsComponent, AvatarSanitizerPipe, AvatarComponent],
	providers: []
})
export class CalaoAvatarModule { }